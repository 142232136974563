import { Box, Button } from "@mui/material";
import Toolbox from "./ToolBox";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FormFieldToolsComponent = ({
  currentProcessingFile,
  setCurrentProcessingFile,
  expandToolBox,
  setExpandToolBox,
  setSelectToolActive,
  typingToolActive,
  setTypingToolActive,
  setCurrCursor,
  fixedInputs,
}) => {
  return (
    <Box
      sx={{
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
        p: 1,
        mx: 0.6,
        mb: 2,
        mt: 1,
      }}
    >
      <Button
        fullWidth
        onClick={() => setExpandToolBox(!expandToolBox)}
        endIcon={
          <ExpandMoreIcon
            sx={{
              transform: expandToolBox ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        }
        disableRipple
        sx={{
          "&:hover": {
            backgroundColor: "transparent", // Removes hover effect
          },
          color: "black",
          fontSize: "1.2rem",
          justifyContent: "space-between",
          p: 0,
          m: 0,
        }}
      >
        Form Field Tools
      </Button>
      <Box
        sx={{
          pl: 2,
          mt: expandToolBox ? 1 : 0,

          borderTop: expandToolBox ? "3px solid #d3d3d3" : undefined,
        }}
      >
        <Toolbox
          currentProcessingFile={currentProcessingFile}
          setCurrentProcessingFile={setCurrentProcessingFile}
          expandToolBox={expandToolBox}
          setSelectToolActive={setSelectToolActive}
          typingToolActive={typingToolActive}
          setTypingToolActive={setTypingToolActive}
          setCurrCursor={setCurrCursor}
          fixedInputs={fixedInputs}
        />
      </Box>
    </Box>
  );
};

export default FormFieldToolsComponent;
