import { useEffect, useState } from "react";
import {
  Box,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  MenuItem,
  Select,
  InputAdornment,
  Typography,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { formatMoneyValue } from "../../../functions/permitFormHelpers";
import { useDispatch, useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { getCustomers } from "../../../store/actions/dataActions";

const CreateCouponForm = ({
  selectedCategory,
  selectedBasePlan,
  onCancel,
  limits,
  createCouponLoading,
  setCreateCouponLoading,
  rowID,
  setSelectedRow,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [discountType, setDiscountType] = useState("amount"); // Default to amount
  const [discountValue, setDiscountValue] = useState("");
  const [duration, setDuration] = useState("once");
  const [minCounties, setMinCounties] = useState("");
  const [currentLimit, setCurrentLimit] = useState(0);
  const [countyLimit, setCountyLimit] = useState(0);
  const [maxCountyLimit, setMaxCountyLimit] = useState(0);

  useEffect(() => {
    setCurrentLimit(limits?.[selectedBasePlan]?.[selectedCategory]);
    setCountyLimit(limits?.[selectedBasePlan]?.county);
    setMaxCountyLimit(limits?.[selectedBasePlan]?.max_county);
  }, [selectedCategory, limits, selectedBasePlan]);

  useEffect(() => {
    if (selectedCategory === "extra_county_cost" && duration !== "once") {
      setDuration("once");
    }
  }, [selectedCategory]);

  const handleAddCoupon = async () => {
    try {
      setCreateCouponLoading(true);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/admin/addCoupon`,
        {
          currentSession: currentSession,
          title,
          discountValue,
          duration,
          selectedCategory,
          selectedBasePlan,
          minCounties,
          currentLimit,
        },
        { withCredentials: true }
      );
      const rows = await dispatch(getCustomers());
      setSelectedRow(rows?.find((row) => row.id === rowID));
      onCancel();
    } catch (error) {
    } finally {
      setCreateCouponLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        mt: 2,
      }}
    >
      <Typography variant="body1" align="center">
        Original Price:{" "}
        <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
          {formatMoneyValue(currentLimit)}
        </span>
      </Typography>
      {/* Coupon Title */}
      <TextField
        label="Title"
        variant="filled"
        fullWidth
        value={title}
        disabled={createCouponLoading}
        onChange={(e) => {
          if (e.target.value.length <= 40) {
            setTitle(e.target.value);
          } else {
            return;
          }
        }}
        inputProps={{
          maxLength: 40, // Hard limit on input length
        }}
      />

      {/* Discount Type Selection */}
      {/* <FormControl>
        <RadioGroup
          row
          value={discountType}
          onChange={(e) => setDiscountType(e.target.value)}
        >
          <FormControlLabel
            value="amount"
            control={<Radio disabled={createCouponLoading} />}
            label="Amount"
          />
          <FormControlLabel
            value="percentage"
            control={<Radio disabled={createCouponLoading} />}
            label="Percentage"
          />
        </RadioGroup>
      </FormControl> */}

      {/* Discount Value Input */}
      <Box>
        <TextField
          label="Discount Value"
          variant="filled"
          fullWidth
          type="number"
          value={discountValue}
          disabled={createCouponLoading}
          onKeyDown={(e) => {
            if (e.key === "-" || e.key === "e") {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            if (
              e.target.value < 0 ||
              (discountType === "percentage" && e.target.value > 100)
            ) {
              return;
            }
            setDiscountValue(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {discountType === "amount" ? "$" : "%"}
              </InputAdornment>
            ),
          }}
        />
        <FormHelperText sx={{ ml: 0.5, color: "red" }}>
          {parseFloat(discountValue) > parseFloat(currentLimit) &&
          discountType === "amount"
            ? `Discount must be less than $${currentLimit}`
            : parseFloat(discountValue) <= 0
            ? "Discount must be greater than 0"
            : ""}
        </FormHelperText>
      </Box>

      {/* Duration Selection */}
      <FormControl
        fullWidth
        variant="filled"
        disabled={
          selectedCategory === "extra_county_cost" || createCouponLoading
        }
      >
        <InputLabel>Duration</InputLabel>
        <Select value={duration} onChange={(e) => setDuration(e.target.value)}>
          <MenuItem value="once">Once</MenuItem>
          <MenuItem value="forever">Forever</MenuItem>
        </Select>
      </FormControl>

      {selectedCategory === "extra_county_cost" && (
        <Box>
          <TextField
            label="Minimum Counties Required"
            variant="filled"
            fullWidth
            type="number"
            value={minCounties}
            disabled={createCouponLoading}
            onKeyDown={(e) => {
              if (e.key === "-" || e.key === "e" || e.key === ".") {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              if (e.target.value < 0) {
                return;
              }
              setMinCounties(e.target.value);
            }}
          />
          <FormHelperText sx={{ ml: 0.5, color: "red" }}>
            {parseInt(minCounties) >
            parseInt(maxCountyLimit) - parseInt(countyLimit)
              ? `Minimum counties must be less than ${
                  parseInt(maxCountyLimit) - parseInt(countyLimit)
                }`
              : parseInt(minCounties) <= 0
              ? "Minimum counties must be greater than 0"
              : ""}
          </FormHelperText>
        </Box>
      )}

      {/* Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          disabled={createCouponLoading}
          variant="outlined"
          color="error"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <LoadingButton
          disabled={
            !title ||
            !discountValue ||
            parseFloat(discountValue) <= 0 ||
            (parseFloat(discountValue) > parseFloat(currentLimit) &&
              discountType === "amount") ||
            (selectedCategory === "extra_county_cost" &&
              (!minCounties ||
                minCounties <= 0 ||
                parseInt(minCounties) >
                  parseInt(maxCountyLimit) - parseInt(countyLimit)))
          }
          variant="contained"
          color="primary"
          loading={createCouponLoading}
          onClick={handleAddCoupon}
        >
          Create Coupon
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default CreateCouponForm;
