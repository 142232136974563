import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Grid2 as Grid,
  Container,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid } from "@mui/x-data-grid";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { getCustomers } from "../../store/actions/dataActions";
import CustomerInfoCard from "./management/CustomerInfoCard";

const CustomersGrid = ({
  selectedRow,
  setSelectedRow,
  dataLoading,
  setDataLoading,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.nocData?.customers);

  const handleRowClick = (params, event, details) => {
    // Only update selectedRow if it's different from the current one
    if (selectedRow?.id !== params?.row?.id) {
      setSelectedRow(params?.row);
    }
  };

  const handlePaginationModelChange = async (model, details) => {
    if (dataLoading) {
      return;
    }
    const newPage = model.page;

    setDataLoading(true);
    const resp = await dispatch(getCustomers(newPage));

    if (resp) {
      setDataLoading(false);
    }
  };

  const customersColumn = [
    {
      field: "registrationDate",
      headerName: "Registration Date",
      flex: 1,
      editable: false,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      flex: 1.5,
      editable: false,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 0.5,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      editable: false,
    },
    {
      field: "tierName",
      headerName: "Base Plan",
      flex: 1,
      editable: false,
    },
    {
      field: "has_active_coupons",
      headerName: "Coupons",
      headerAlign: "center",
      flex: 0.7,
      editable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {params.value ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
        </Box>
      ),
    },
    {
      field: "status",
      headerName: "Active",
      headerAlign: "center",
      flex: 0.5,
      editable: false,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {params.value ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
        </Box>
      ),
    },
  ];

  // const handleChartValues = () => {
  //   if (selectedRow) {
  //     let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  //     selectedRow?.analytics?.nocUsage.map((item) => {
  //       if (item.year === year) {
  //         chartList[item.month] = item.noc;
  //       }
  //       return null;
  //     });

  //     return chartList;
  //   }
  // };

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 1,
          backgroundColor: "#EEF2F6",
        }}
      >
        <Container maxWidth="xl">
          <Grid container sx={{ my: 2 }} spacing={3}>
            <Grid size={{ xs: 12 }}>
              <Card>
                <CardHeader title="Customers" />
                <CardContent>
                  <DataGrid
                    sx={{
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader": {
                        "&:hover": {
                          ".MuiDataGrid-menuIcon, .MuiDataGrid-sortIcon, .MuiDataGrid-columnHeader--sorted":
                            {
                              visibility: "hidden",
                              pointerEvents: "none",
                            },
                          cursor: "default",
                        },
                      },
                      // Additionally target specific sort button
                      "& .MuiDataGrid-columnHeader--sortable": {
                        pointerEvents: "none",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        padding: 0, // Adjust as needed
                        margin: 0, // Adjust as needed
                      },
                      "& .MuiDataGrid-columnHeadersInner": {
                        width: "100%", // Adjust as needed
                        padding: 0,
                      },
                      border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                      "& .MuiDataGrid-columnHeader": {
                        backgroundColor: "#333333", // Change column header background color
                        color: "white", // Optional: Change text color for contrast
                      },
                      "& .MuiDataGrid-cell": {
                        borderColor: "#333333", // Change cell border color
                      },
                      "& .MuiDataGrid-columnSeparator": {
                        display: "none", // Optional: Hide the column separators
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-columnHeader:focus-within": {
                        outline: "none !important",
                      },
                      "& .MuiDataGrid-row": {
                        cursor: "pointer", // Change cursor to pointer for rows
                        // backgroundColor: "#fff4c0 !important",
                      },
                      // Apply light yellow background for specified rows
                      "& .light-yellow-row": {
                        backgroundColor: "#fff4c0",
                      },
                      "& .selected-row-color": {
                        backgroundColor: "#ebf1f4 !important",
                      },
                      // Apply darker yellow only for selected rows that are light-yellow
                      "& .light-yellow-row.Mui-selected": {
                        backgroundColor: "#ffe499 !important", // Slightly darker yellow
                      },
                    }}
                    rows={customers?.rows || []}
                    columns={customersColumn}
                    getRowId={(row) => row?.id}
                    onPaginationModelChange={(model, details) => {
                      handlePaginationModelChange(model, details);
                    }}
                    loading={dataLoading}
                    paginationMode="server"
                    paginationModel={{
                      page: customers?.pagination?.page, // Set the current page value here
                      pageSize: customers?.pagination?.rowsPerPage, // Set the page size value here
                    }}
                    rowCount={customers?.pagination?.totalCount}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 5,
                        },
                      },
                    }}
                    onRowClick={(params, event, details) => {
                      handleRowClick(params, event, details);
                    }}
                    pageSizeOptions={[5]}
                    rowHeight={34}
                  />

                  {selectedRow && (
                    <CustomerInfoCard
                      selectedRow={selectedRow}
                      setSelectedRow={setSelectedRow}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CustomersGrid;
