export const getCitiesForCounty = (availablePermitForms, currentCounty) => {
  // Helper function to format city names
  const formatCityName = (city) => {
    return city
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Find the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Create a list of cities by filtering out empty arrays and formatting names
  const cities = Object.keys(countyData)
    .filter((city) => city !== "county_specific" && city !== "noc")
    .map((city) => formatCityName(city));

  // Return the filtered and formatted cities list
  return cities;
};

// Helper function to revert formatted city name to original format
const revertCityName = (formattedCity) => {
  return formattedCity.toLowerCase().replace(/ /g, "_");
};

export const getCombinedListForCity = (
  availablePermitForms,
  currentCounty,
  selectedCity
) => {
  // Revert the formatted city name to the original format
  const originalCityName = revertCityName(selectedCity);

  // Get the "Global" list of objects
  const globalData = availablePermitForms.find((item) => item.Global) || {};
  const globalList = globalData.Global || [];

  // Get the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Get the permits for the selected city
  const cityPermits = countyData[originalCityName] || [];

  // Combine the "Global" list with the city's permits
  const combinedPermits = [...cityPermits, ...globalList];

  // Return an object for the selected city with combined permits
  return {
    city: selectedCity,
    permits: combinedPermits,
  };
};

export const getCombinedList = (
  availablePermitForms,
  currentCounty,
  selectedCity,
  selectedForms,
  setSelectedForms,
  oldSelectedForms,
  formsOrder
) => {
  // Revert the formatted city name to the original format
  const originalCityName = revertCityName(selectedCity);

  // Get the "Global" list of objects
  const globalData = availablePermitForms.find((item) => item.Global) || {};
  const globalList = globalData.Global || [];

  // Get the data for the current county
  const countyData =
    availablePermitForms.find((item) => item[currentCounty])?.[currentCounty] ||
    {};

  // Get the permits for the selected city
  let cityPermits = selectedCity ? countyData[originalCityName] || [] : [];

  // Initialize combinedPermits with the global list
  let combinedPermits = [...globalList];

  // Append city-specific permits if a city is selected
  if (selectedCity) {
    combinedPermits = [...cityPermits, ...combinedPermits];
  }

  // Append "county_specific" if no city is selected, or if city-specific permits don't already include the form names from county-specific
  if (!selectedCity) {
    combinedPermits = [
      ...combinedPermits,
      ...(countyData.county_specific || []),
    ];
  } else {
    // Filter out any county-specific permits that also exist in cityPermits by form name
    combinedPermits = [
      ...combinedPermits,
      ...(countyData.county_specific || []).filter(
        (countyForm) =>
          !cityPermits.some((cityForm) => cityForm.name === countyForm.name)
      ),
    ];
  }

  // Ensure "noc" is added at the start if it exists
  if (countyData?.noc) {
    combinedPermits = [{ ...countyData.noc }, ...combinedPermits];
  }

  if (oldSelectedForms) {
    // Special logic to exclude forms the user has already selected, including stormwater check
    combinedPermits = combinedPermits.filter((permit) => {
      // Exclude already selected forms
      if (oldSelectedForms.includes(permit.name)) {
        return false;
      }

      // Special logic for stormwater forms
      const isStormwater = permit.name.startsWith("stormwater");
      const stormwaterSelected = oldSelectedForms.some((selectedForm) =>
        selectedForm.startsWith("stormwater")
      );

      // If a stormwater form has already been selected, exclude other stormwater forms
      if (isStormwater && stormwaterSelected) {
        return false;
      }

      // Include the permit if it passes all checks
      return true;
    });
  }

  if (!oldSelectedForms) {
    // Ensure "noc" is in the selectedForms
    if (!selectedForms.includes("noc")) {
      setSelectedForms(["noc", ...selectedForms]);
    }
  }

  // Sorting logic: Place forms in the order specified in formsOrder, keeping the rest at the end
  if (formsOrder) {
    combinedPermits.sort((a, b) => {
      const indexA = formsOrder.indexOf(a.name);
      const indexB = formsOrder.indexOf(b.name);

      if (indexA === -1 && indexB === -1) return 0; // Keep original order if both are not in the list
      if (indexA === -1) return 1; // Move `a` to the end if not in the list
      if (indexB === -1) return -1; // Move `b` to the end if not in the list

      return indexA - indexB; // Sort based on the order in formsOrder
    });
  }

  // Return an object for the selected city with combined permits
  return {
    city: selectedCity,
    permits: combinedPermits,
    nocImg: countyData?.noc?.img.map(
      (img) => `${process.env.REACT_APP_URL}${img}`
    ),
  };
};
