import {
  Box,
  Card,
  CardContent,
  Grid2 as Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  Typography,
} from "@mui/material";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useEffect, useState } from "react";
import RenderCustomerContractors from "./RenderCustomerContractors";
import RenderCustomerDiscounts from "./RenderCustomerDiscounts";
import RenderDiscounts from "./RenderDiscounts";
import RenderUsageChart from "./RenderUsageChart";

const CustomerInfoCard = ({ selectedRow, setSelectedRow }) => {
  const [tables, setTables] = useState([]);
  const [cardPage, setCardPage] = useState(1);
  const [discountPage, setDiscountPage] = useState(1);

  useEffect(() => {
    setTables([
      {
        header: "Personal",
        open: false,
        data: selectedRow?.tableDatas?.tableDataPersonal,
      },
      {
        header: "Plan",
        open: false,
        data: selectedRow?.tableDatas?.tableDataPlan,
      },
      {
        header: "Usage",
        open: false,
        data: selectedRow?.tableDatas?.tableDataUsage,
      },
      {
        header: "Contractors",
        open: false,
        data: selectedRow?.contractors,
      },
      {
        header: "Coupons",
        open: false,
        data: selectedRow?.user_discounts,
        coupons: selectedRow?.coupons,
      },
      {
        name: "ppUsage",
        header: "Permit Package Usage Chart",
        open: false,
        data: selectedRow?.ppUsage,
      },
      {
        name: "nocUsage",
        header: "Notice of Commencement Usage Chart",
        open: false,
        data: selectedRow?.nocUsage,
      },
    ]);
  }, [selectedRow]);

  const toggleTableOpen = (header) => {
    setTables((prevTables) =>
      prevTables.map((table) =>
        table.header === header ? { ...table, open: !table.open } : table
      )
    );
  };

  const isTableOpen = (header) => {
    const table = tables.find((table) => table.header === header);
    return table ? table.open : false; // Returns `false` if not found
  };

  return (
    <Box sx={{ mt: "1rem" }}>
      <Card>
        <CardContent sx={{ pt: 2 }}>
          <Box mb={3}>
            <Typography variant="h5" mb={0} gutterBottom align="center">
              {selectedRow?.customerName}
            </Typography>
            <Typography variant="subtitle1" gutterBottom align="center">
              {selectedRow?.email}
            </Typography>
          </Box>

          <Grid container spacing={2}>
            {tables.map((table) => (
              <Grid
                key={table?.header}
                size={{
                  xs: 12,
                  sm:
                    table?.header === "Coupons" ||
                    ["ppUsage", "nocUsage"].includes(table?.name)
                      ? 12
                      : 6,
                }}
              >
                <Box
                  sx={{
                    bgcolor: "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow sx={{ position: "relative" }}>
                          <TableCell
                            sx={
                              table?.header === "Coupons" ||
                              ["ppUsage", "nocUsage"].includes(table?.name)
                                ? {
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    width: "100%",
                                    height: "40px",
                                  }
                                : { fontWeight: "bold" }
                            }
                          >
                            {table?.header}
                          </TableCell>
                          <TableCell
                            align={
                              table?.header !== "Coupons" &&
                              !["ppUsage", "nocUsage"].includes(table?.name)
                                ? "right"
                                : undefined
                            }
                            sx={
                              table?.header === "Coupons" ||
                              ["ppUsage", "nocUsage"].includes(table?.name)
                                ? { position: "absolute", right: 16 }
                                : {}
                            }
                          >
                            <IconButton
                              onClick={() => toggleTableOpen(table?.header)}
                            >
                              {isTableOpen(table?.header) ? (
                                <RemoveCircleIcon color="error" />
                              ) : (
                                <AddCircleIcon color="primary" />
                              )}
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            sx={{
                              p: 0,
                            }}
                          >
                            <Collapse
                              in={isTableOpen(table?.header)}
                              timeout="auto"
                              unmountOnExit
                            >
                              {table?.header === "Contractors" ? (
                                <RenderCustomerContractors
                                  cardPage={cardPage}
                                  setCardPage={setCardPage}
                                  table={table}
                                />
                              ) : table?.header === "Coupons" ? (
                                <RenderDiscounts
                                  discountPage={discountPage}
                                  setDiscountPage={setDiscountPage}
                                  table={table}
                                  selectedRow={selectedRow}
                                  setSelectedRow={setSelectedRow}
                                />
                              ) : ["ppUsage", "nocUsage"].includes(
                                  table?.name
                                ) ? (
                                <RenderUsageChart
                                  table={table}
                                  selectedRow={selectedRow}
                                />
                              ) : (
                                <Table>
                                  <TableBody>
                                    {table?.data?.map((row, index) => (
                                      <TableRow key={index}>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          {row.label}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.value}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              )}
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default CustomerInfoCard;
