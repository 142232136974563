import {
  Grid2 as Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear"; // Import the ClearIcon

export const DemoContractors = ({
  title,
  demoLicensesRow,
  setManagementData,
  isSm,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [selectedRow, setSelectedRow] = useState(null);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [contractorFirstName, setContractorFirstName] = useState("");
  const [contractorLastName, setContractorLastName] = useState("");

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);

  const handleEdit = async (type) => {
    const dataObj = {
      submitType: type,
      selectedRow: selectedRow,
      contractorFirstName: contractorFirstName,
      contractorLastName: contractorLastName,
    };

    try {
      if (type === "remove") {
        setRemoveLoading(true);
      } else if (type === "add") {
        setAddLoading(true);
      }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/demo-license-management`,
        {
          dataObj,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      setManagementData(res.data.data);

      setErrorText(resp.data.message);

      if (type === "remove") {
        setRemoveLoading(false);
      } else if (type === "add") {
        setAddLoading(false);
      }

      setSelectedRow(null);
    } catch (e) {
      setErrorText(e.response.data.message);
      if (type === "remove") {
        setRemoveLoading(false);
      } else if (type === "add") {
        setAddLoading(false);
      }
    }
  };

  const handleRowClick = (params) => {
    setSelectedRow(params?.row);
    setContractorFirstName("");
    setContractorLastName("");
  };

  const demoLicensesColumn = [
    {
      field: "first_name",
      headerName: "First Name",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
    },
    {
      field: "last_name",
      headerName: "Last Name",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
    },
    {
      field: "license",
      headerName: "License",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
    },
    {
      field: "license_user",
      headerName: "License User",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {params.value ? params.value : "N/A"}
        </Box>
      ),
    },
    {
      field: "assigned",
      headerName: "Assigned",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
      headerAlign: "center",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {params.value ? (
            <CheckCircle sx={{ color: "green" }} />
          ) : (
            <Cancel sx={{ color: "red" }} />
          )}
        </Box>
      ),
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minHeight: "24rem",
          }}
          rows={demoLicensesRow}
          columns={demoLicensesColumn}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onRowClick={handleRowClick}
          classes={{
            cell: "cell", // Apply custom cell class
          }}
        />
        <Grid container spacing={isSm ? 1 : 2} alignItems="center">
          <Grid size={{ xs: 12 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {selectedRow && (
                <>
                  Selected License -{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {selectedRow.license}
                  </span>
                </>
              )}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <TextField
              disabled
              label={
                !selectedRow ? "Select a Contractor" : "Remove Demo License"
              }
              variant="filled"
              fullWidth
              value={
                selectedRow
                  ? `${selectedRow?.first_name ?? ""} ${
                      selectedRow?.last_name ?? ""
                    } - ${selectedRow?.license ?? ""}`.trim()
                  : ""
              }
              InputProps={{
                endAdornment: selectedRow ? (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!selectedRow || removeLoading || addLoading}
                      onClick={() => {
                        setSelectedRow(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>

          <Grid size={{ xs: 12, md: 4 }}>
            <LoadingButton
              loading={removeLoading}
              disabled={!selectedRow || addLoading}
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                handleEdit("remove");
              }}
            >
              Remove
            </LoadingButton>
          </Grid>

          <Grid size={{ xs: 12 }} mt={2}>
            <Typography variant="h6" color="primary" align="center">
              Add New Demo Contractor
            </Typography>
          </Grid>

          <Grid size={{ xs: 12, md: 5 }}>
            <TextField
              disabled={addLoading}
              label="First Name"
              variant="filled"
              fullWidth
              value={contractorFirstName || ""}
              onChange={(e) => setContractorFirstName(e.target.value)}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 5 }}>
            <TextField
              disabled={addLoading}
              label="Last Name"
              variant="filled"
              fullWidth
              value={contractorLastName || ""}
              onChange={(e) => setContractorLastName(e.target.value)}
            />
          </Grid>
          <Grid isize={{ xs: 12, md: 2 }}>
            <LoadingButton
              loading={addLoading}
              disabled={!contractorFirstName || !contractorLastName}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                handleEdit("add");
              }}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          color="primary"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
