import {
  Box,
  Collapse,
  Grid2 as Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RenderCustomerDiscounts from "./RenderCustomerDiscounts";
import RenderAvailableCoupons from "./RenderAvailableCoupons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useState } from "react";

const RenderDiscounts = ({
  discountPage,
  setDiscountPage,
  table,
  selectedRow,
  setSelectedRow,
}) => {
  const [availableCouponsOpen, setAvailableCouponsOpen] = useState(false);
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <Box
            sx={{
              mt: 2,

              bgcolor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
            mb={2}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Customer's Coupons
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        p: 0,
                        px: 2,
                      }}
                    >
                      <RenderCustomerDiscounts
                        discountPage={discountPage}
                        setDiscountPage={setDiscountPage}
                        table={table}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <Box
            sx={{
              mt: 2,
              bgcolor: "#fff",
              borderRadius: "8px",
              boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
            }}
            mb={2}
          >
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                      }}
                    >
                      Available Coupons for Base Plan: {selectedRow?.tierName}
                    </TableCell>
                    <TableCell align="right">
                      <IconButton
                        onClick={() =>
                          setAvailableCouponsOpen(!availableCouponsOpen)
                        }
                      >
                        {availableCouponsOpen ? (
                          <RemoveCircleIcon color="error" />
                        ) : (
                          <AddCircleIcon color="primary" />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell
                      colSpan={2}
                      sx={{
                        p: 0,
                      }}
                    >
                      <Collapse
                        in={availableCouponsOpen}
                        timeout="auto"
                        unmountOnExit
                      >
                        <RenderAvailableCoupons
                          table={table}
                          selectedBasePlan={selectedRow?.tierName}
                          limits={selectedRow?.limits}
                          rowID={selectedRow?.id}
                          selectedRow={selectedRow}
                          setSelectedRow={setSelectedRow}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default RenderDiscounts;
