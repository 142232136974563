export const handleMouseUp = (
  currentProcessingFile,
  setCurrentProcessingFile,
  resizing,
  setResizing,
  setCurrCursor,
  moving,
  setMoving,
  isDrawing,
  rect,
  setIsDrawing,
  setRect,
  fixedInputs,
  isSelecting,
  setIsSelecting,
  setSelectionRect,
  setFreeSelectStartPoint
) => {
  if (resizing && setResizing) {
    setResizing(false);
    setCurrCursor("default");
  } else if (moving) {
    setMoving(false);
    setCurrCursor("default");
  } else if (isDrawing) {
    let MIN_WIDTH = 20;
    let MIN_HEIGHT = 10;

    const scaleFactor =
      currentProcessingFile?.document?.tempFields?.currentDocument?.pdfSize /
      currentProcessingFile?.document?.tempFields?.currentDocument?.actualWidth;

    // Flip the Y-coordinate (for bottom-left origin in PDF)
    let fieldHeight = rect.height / scaleFactor;
    let fieldWidth = rect.width / scaleFactor;
    let fieldX = rect.x / scaleFactor;
    let fieldY =
      currentProcessingFile?.document?.tempFields?.currentDocument
        .actualHeight -
      rect.y / scaleFactor -
      fieldHeight;

    // ✅ Define minimum dimensions
    if (
      currentProcessingFile?.document?.tempFields?.formFieldTools.checkbox
        .active ||
      currentProcessingFile?.document?.tempFields?.formFieldTools.radio.active
    ) {
      if (fieldWidth < 10) {
        fieldWidth = 10;
      }
      const maxSize = Math.max(fieldWidth, fieldHeight);

      fieldWidth = maxSize;
      fieldHeight = maxSize;

      // 🛠 Apply Small Offset Correction & Centering
      fieldX = Math.round(fieldX + (rect.width / scaleFactor - maxSize) / 2);
      fieldY = Math.round(fieldY + (rect.height / scaleFactor - maxSize) / 2);
    }

    // ✅ Check if the width/height is smaller than the minimum
    if (
      !currentProcessingFile?.document?.tempFields?.formFieldTools.checkbox
        .active &&
      !currentProcessingFile?.document?.tempFields?.formFieldTools.radio.active
    ) {
      if (fieldWidth < MIN_WIDTH) {
        fieldX = fieldX - (MIN_WIDTH - fieldWidth) / 2; // Center align
        fieldWidth = MIN_WIDTH;
      }

      if (fieldHeight < MIN_HEIGHT) {
        fieldY = fieldY - (MIN_HEIGHT - fieldHeight) / 2; // Adjust Y to maintain positioning
        fieldHeight = MIN_HEIGHT;
      }
    }

    const activeTool = Object.values(
      currentProcessingFile?.document?.tempFields?.formFieldTools
    ).find((field) => field.active);

    const uniqueName = `${activeTool?.name}_${Date.now().toString().slice(-4)}`;

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          formFields: [
            ...prevFile.document.tempFields.formFields,
            {
              name: uniqueName,
              type: prevFile?.document?.tempFields?.activeTool?.type,
              value: "",
              x: fieldX, // Convert X to points
              y: fieldY, // Convert Y to points and flip
              width: fieldWidth, // Convert width to points
              height: fieldHeight, // Convert height to points
              page: prevFile?.document?.tempFields?.currentDocument?.pageNumber,
              fontSize: fieldHeight || 10, // Font size based on height
              fontSizes: [],
              autoFontSize: true,
            },
          ],
        },
      },
    }));

    // Finalize the drawing state
    setIsDrawing(false);
    setRect(null);

    if (fixedInputs.some((prefix) => activeTool?.name.startsWith(prefix))) {
      setCurrentProcessingFile((prevFile) => {
        // Find the matching tool key in formFieldTools based on the name property
        const matchingKey = Object.keys(
          prevFile.document.tempFields.formFieldTools
        ).find(
          (key) =>
            prevFile.document.tempFields.formFieldTools[key]?.name ===
            activeTool?.name
        );

        if (!matchingKey) return prevFile; // No match found, return as is

        return {
          ...prevFile,
          document: {
            ...prevFile.document,
            tempFields: {
              ...prevFile.document.tempFields,
              formFieldTools: {
                ...prevFile.document.tempFields.formFieldTools,
                [matchingKey]: {
                  ...prevFile.document.tempFields.formFieldTools[matchingKey],
                  active: false, // Deactivate only this tool
                  disabled: true, // Disable only this tool
                },
              },
            },
          },
        };
      });

      setCurrCursor("default");
    }
  } else if (isSelecting) {
    setIsSelecting(false);
    setSelectionRect(null);
    setFreeSelectStartPoint(null);
  }
};
