import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ChangeOwnerProfileDetailsModal({
  open,
  setOpen,
  onConfirm,
  fieldValues,
  setFieldValues,
  fieldChangedText,
  loading,
  property_owner,
}) {
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const test = emailRegex.test(value) ? "" : "Invalid email format";
    return test;
  };

  const handlePhoneInputChange = (event) => {
    const { value } = event.target;
    if (value.length > 12) {
      return;
    }
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }
    setFieldValues((prev) => ({ ...prev, ownerMobile: formattedPhoneNumber }));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFieldValues((prev) => ({ ...prev, [name]: value }));

    if (name === "ownerEmail") {
      setFieldValues((prev) => ({
        ...prev,
        ownerEmailError: validateEmail(value),
      }));
    }
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
          mb={2}
        >
          Edit Owner's Details
        </Typography>

        <TextField
          error={Boolean(fieldValues?.ownerEmailError)}
          onChange={handleChange}
          fullWidth
          name="ownerEmail"
          label="Email"
          value={fieldValues?.ownerEmail}
        />
        {Boolean(fieldValues?.ownerEmailError) && (
          <FormHelperText sx={{ ml: 0.5, color: "red" }}>
            {fieldValues?.ownerEmailError}
          </FormHelperText>
        )}
        <TextField
          sx={{ mt: 2 }}
          error={Boolean(fieldValues?.ownerMobileError)}
          onChange={handlePhoneInputChange}
          fullWidth
          name="ownerMobile"
          label="Phone Number"
          value={fieldValues?.ownerMobile}
        />
        {Boolean(fieldValues?.ownerMobileError) && (
          <FormHelperText sx={{ ml: 0.5, color: "red" }}>
            {fieldValues?.ownerMobileError}
          </FormHelperText>
        )}

        <Typography mt={2} align="center" variant="h6" color="success">
          {fieldChangedText}
        </Typography>

        <Box sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={loading}
            sx={{ mr: 1 }}
            fullWidth
            color="error"
            onClick={() => {
              setOpen(false);
              setFieldValues({
                ownerEmail: property_owner?.email || "",
                ownerMobile: property_owner?.mobile || "",
                ownerEmailError: false,
                ownerMobileError: false,
              });
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={loading}
            disabled={
              loading ||
              fieldValues?.ownerEmail === "" ||
              fieldValues?.ownerMobile === "" ||
              Boolean(fieldValues?.ownerEmailError) ||
              Boolean(fieldValues?.ownerMobileError) ||
              fieldValues?.ownerMobile.length < 12
            }
            sx={{ ml: 1 }}
            fullWidth
            variant="contained"
            color="success"
            onClick={onConfirm}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangeOwnerProfileDetailsModal;
