import {
  Box,
  Container,
  Grid2 as Grid,
  CircularProgress,
  Drawer,
  Modal,
  useMediaQuery,
  Backdrop,
  Fade,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import CustomersGrid from "../../admin/CustomersGrid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCustomers } from "../../../store/actions/dataActions";
import EditCustomer from "../../admin/EditCustomer";

const Customers = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  const [removeUserModalOpen, setRemoveUserModalOpen] = useState(false);
  const [confirmRemoveUser, setConfirmRemoveUser] = useState("");
  const [removeUserErrorText, setRemoveUserErrorText] = useState("");

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        navigate("/login");
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      setDataLoading(true);
      const resp = await dispatch(getCustomers());

      if (resp) {
        setDataLoading(false);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleSubmit = () => {
    if (confirmRemoveUser === "") {
      setRemoveUserErrorText("Confirmation is required!");
    } else if (confirmRemoveUser !== `Remove ${selectedRow?.customerName}`) {
      setRemoveUserErrorText("Confirmation input is not match!");
      setConfirmRemoveUser("");
    } else {
      setRemoveUserErrorText("Removed user successfully!");
      setConfirmRemoveUser("");
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              py: 1,
              backgroundColor: "#EEF2F6",
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                  <CustomersGrid
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    dataLoading={dataLoading}
                    setDataLoading={setDataLoading}
                  />
                </Grid>
              </Grid>
            </Container>
          </Box>

          {/* *********************** BOTTOM LINE ******************************************** */}
          {/* ******************************************************************************** */}
          <Drawer
            PaperProps={
              mdUp && {
                sx: {
                  width: "50vw",
                },
              }
            }
            anchor="right"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          >
            <EditCustomer
              row={selectedRow}
              setSelectedRow={setSelectedRow}
              setOpenDrawer={setOpenDrawer}
            />
          </Drawer>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={removeUserModalOpen}
            onClose={() => {
              setRemoveUserModalOpen(false);
            }}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
              backdrop: {
                timeout: 500,
              },
            }}
          >
            <Fade in={removeUserModalOpen}>
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 600,
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                  textAlign: "center",
                }}
              >
                <Typography variant="h3" color="error">
                  Remove User
                </Typography>
                <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>
                  Are you sure to remove this user?
                </Typography>
                <Typography variant="body1">
                  Type in "Remove {selectedRow?.customerName}" to remove this
                  user!
                </Typography>
                <TextField
                  sx={{ mt: 3 }}
                  fullWidth
                  label="Confirm Delete User"
                  onChange={(e) => setConfirmRemoveUser(e.target.value)}
                  value={confirmRemoveUser}
                />
                <Typography
                  variant="body1"
                  color="#F2CD00"
                  fontWeight="bold"
                  textAlign="center"
                  sx={{ minHeight: "2rem", mt: "1rem" }}
                >
                  {removeUserErrorText}
                </Typography>
                <Button
                  fullWidth
                  onClick={handleSubmit}
                  size="large"
                  color="error"
                  sx={{ mt: 0 }}
                  variant="contained"
                >
                  REMOVE USER
                </Button>
              </Box>
            </Fade>
          </Modal>
        </>
      )}
    </>
  );
};

export default Customers;
