import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const DeleteFormModal = ({
  open,
  onClose,
  onConfirm,
  documentName,
  packageName,
  deleteFormLoading,
  savedPackagesActive,
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          position: "absolute",
          left: "60%", // ✅ Move the dialog to the right
          transform: "translateX(-40%)", // ✅ Keep it centered relative to new position
        },
      }}
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle sx={{ color: "primary.main", fontSize: "20px" }}>
        {savedPackagesActive
          ? "Confirm Package Deletion"
          : "Confirm Document Deletion"}
      </DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove{" "}
          <strong>{savedPackagesActive ? packageName : documentName}</strong>?
        </Typography>
        <Typography color="error" fontWeight="bold" sx={{ mt: 1 }}>
          This action is irreversible.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={deleteFormLoading}
          onClick={onClose}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
        <LoadingButton
          loading={deleteFormLoading}
          onClick={onConfirm}
          variant="contained"
          color="error"
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFormModal;
