// authReducer.js
const initialState = {
  user: null,
  notifications: [],
  urls: null,
  expireAlert: null,
  alertSet: false,
  instantRonAccepted: false,
};

const subUserSessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SUBUSER_SELECTED_COUNTIES":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            selectedCountiesNOC: action.payload,
          },
        },
      };
    case "CHANGE_SUB_USER_SMS_CONSENT":
      return {
        ...state,
        user: {
          ...state.user,
          subuser: {
            ...state.user.user,
            smsConsent: action.payload,
          },
        },
      };
    case "UPDATE_ANALYTICS":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user.user,
            analytics: {
              ...state.user.user.analytics,
              ...action.payload, // Merge only the new entries from the payload
            },
          },
        },
      };
    case "UPDATE_SUB_ANALYTICS":
      return {
        ...state,
        user: {
          ...state.user,
          subuser: {
            ...state.user.subuser,
            nocUsage: action.payload?.nocUsage,
            ppUsage: action.payload?.ppUsage,
            nocCreated: action.payload?.nocCreated,
            ppCreated: action.payload?.ppCreated,
          },
        },
      };
    case "SET_INSTANT_RON_ACCEPTED":
      return { ...state, instantRonAccepted: true };
    case "SET_INSTANT_RON_INITIAL_STATE":
      return { ...state, instantRonAccepted: false };
    case "SET_INSTANT_RON_STOP":
      return { ...state, instantRonAccepted: "stop" };
    case "SET_INSTANT_RON_SUCCESS":
      return { ...state, instantRonAccepted: "success" };
    case "SUB_USER_FIRST_LOGIN_OFF":
      return {
        ...state,
        user: {
          ...state.user,
          subuser: {
            ...state.user.user,
            firstLogin: false,
          },
        },
      };
    case "UPDATE_SUB_USER":
      return {
        ...state,
        user: action.payload,
      };
    case "UPDATE_NOTARY_SUB_USER_FORMS": {
      const new_form = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          notary_forms: new_form,
        },
      };
    }
    case "UPDATE_NOC_SUB_USER_TASKS": {
      const new_tasks = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          nocUserTasks: new_tasks,
        },
      };
    }
    case "UPDATE_SUB_USER_SIGNEE_EMAIL": {
      const { source, updatedList } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          notary_forms: {
            ...state.user.notary_forms,
            [source]: updatedList,
          },
        },
      };
    }
    case "ADD_URL_TO_CREATED_FORM_SUB_USER":
      return {
        ...state,
        user: {
          ...state.user,
          nocUserForms: action.payload,
        },
      };
    case "ADD_SUB_USER_FULL_DESIGN_PROFESSIONALS":
      return {
        ...state,
        user: {
          ...state.user,
          design_professionals: action.payload,
          design_professionals_locked: false,
        },
      };
    case "BROADCAST_SUB_USER_UPDATE":
      return {
        ...state,
        user: {
          ...state.user,
          user: {
            ...state.user?.user,
            ...action.payload?.user,
            analytics: {
              ...state.user?.user?.analytics,
              ...action.payload?.user?.analytics,
              additions: {
                ...state.user?.user?.analytics?.additions,
                ...action.payload?.user?.analytics?.additions,
              },
            },
            subuser: {
              ...state.user?.user?.subuser,
              ...action.payload?.user?.subuser,
            },
          },
          tier: {
            ...state.user?.tier,
            ...action.payload?.tier,
          },
        },
      };
    case "UPDATE_NOC_FORMS_SUB_USER": {
      return {
        ...state,
        user: {
          ...state.user,
          nocUserForms: action.payload,
        },
      };
    }
    case "UPDATE_SUB_USER_URLS":
      return {
        ...state,
        urls: action.payload,
      };
    case "ADD_SUB_USER_URL":
      return {
        ...state,
        urls: [...state.urls, action.payload],
      };
    case "SET_WILL_SUB_USER_EXPIRE_ALERT":
      const isSetAlert = !action.payload ? false : true;
      return {
        ...state,
        expireAlert: action.payload,
        alertSet: isSetAlert,
      };
    case "GET_SUB_USER_NOTIFICATION":
      return {
        ...state,
        notifications: action.payload,
      };
    case "UPDATE_SUB_USER_PAYMENT_CARDS": {
      const new_cards = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          payment_cards: new_cards,
        },
      };
    }
    case "REFRESH_SUB_DISCOUNT_DATA":
      return {
        ...state,
        user: {
          ...state.user,
          tier: {
            ...state.user.tier,
            discount: action.payload,
          },
        },
      };
    case "UPDATE_SUB_DISCOUNT_DATA":
      const discount_type = action.payload.discount_type;
      const discount = action.payload.data;
      return {
        ...state,
        user: {
          ...state.user,
          tier: {
            ...state.user.tier,
            discount: {
              ...state.user.tier.discount,
              [discount_type]: discount,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default subUserSessionReducer;
