import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  Card,
  Typography,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Switch,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  changeUserSmsConsent,
  fetchUserData,
} from "../../store/actions/userActions";
import {
  changeSubUserSmsConsent,
  fetchSubUserData,
} from "../../store/actions/subUserActions";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import LottieAnimation from "../../animation/LottieAnimation";
import MobileFooter from "../layout/MobileFooter";
import { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import ChangeFA from "../ChangeFA";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const ProfileSettings = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //? SCREEN SIZES ##################################################################
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################

  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorTextPassword, setErrorTextPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [qrCodeSrc, setQRCodeSrc] = useState(null);
  const [currentFA, setCurrentFA] = useState("");
  const [errorTextCancel, setErrorTextCancel] = useState("");
  const [imageList, setImageList] = useState([]);
  const [currentImg, setCurrentImg] = useState("");
  const [imgWidth, setImgWidth] = useState("");
  const [leaveInput, setLeaveInput] = useState("");
  const [cancelSelected, setCancelSelected] = useState("");
  const [cancelLoading, setCancelLoading] = useState(false);
  const [leavingReasons, setLeavingReasons] = useState([]);

  const [smsConsent, setSmsConsent] = useState(false);
  const [authType, setAuthType] = useState("");
  const [switchLoading, setSwitchLoading] = useState(false);

  let qcount = 0;
  let dataCount = 0;

  useEffect(() => {
    if (currentUser?.subuser) {
      setSmsConsent(currentUser?.subuser?.smsConsent);
      setAuthType(currentUser?.subuser?.auth_type);
    } else {
      setSmsConsent(currentUser?.user?.smsConsent);
      setAuthType(currentUser?.user?.auth_type);
    }
  }, [currentUser]);

  useEffect(() => {
    const getLeavingReasons = async () => {
      const dataResp = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/leaving-reasons`
      );

      setLeavingReasons(dataResp.data.data.reasons);
      setDataLoading(false);
    };

    if (dataCount < 1) {
      getLeavingReasons();
      dataCount += 1;
    }
  }, []);

  useEffect(() => {
    if (errorTextPassword !== "") {
      const passwordTimer = setTimeout(() => setErrorTextPassword(""), 3000);
      return () => clearTimeout(passwordTimer);
    }

    if (errorTextCancel !== "") {
      const cancelTimer = setTimeout(() => setErrorTextCancel(""), 3000);
      return () => clearTimeout(cancelTimer);
    }
  }, [errorTextPassword, errorTextCancel]);

  useEffect(() => {
    const getQr = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/generate_to_change_qr`,
          { currentSession: currentSession },
          {
            responseType: "blob",
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          const qrCodeUrl = URL.createObjectURL(new Blob([response.data]));
          setQRCodeSrc(qrCodeUrl);
        }
      } catch (e) {}
    };

    if (qcount < 1) {
      qcount += 1;
      getQr();
    }
  }, []);

  useEffect(() => {
    setImageList([
      {
        alt: "email",
        src: "/img/logos/emailImage.png",
        // width: 120,
        width: 80,
      },
      {
        alt: "mobile",
        src: "/img/logos/phone.png",
        // width: 180,
        width: 120,
      },

      {
        alt: "authenticator",
        src: "/img/logos/authenticator.png",
        // width: 167,
        width: 110,
      },
    ]);

    if (currentUser?.subuser) {
      if (currentUser?.subuser.auth_type === "totp") {
        setCurrentFA("totp");
        setCurrentImg("/img/logos/authenticator.png");
        setImgWidth(127);
      } else if (currentUser?.subuser.auth_type === "emailFa") {
        setCurrentFA("emailFa");
        setCurrentImg("/img/logos/emailImage.png");
        setImgWidth(80);
      } else if (currentUser?.subuser.auth_type === "smsFa") {
        setCurrentFA("smsFa");
        setImgWidth(140);
        setCurrentImg("/img/logos/phone.png");
      }
    } else {
      if (currentUser?.user.auth_type === "totp") {
        setCurrentFA("totp");
        setCurrentImg("/img/logos/authenticator.png");
        setImgWidth(127);
      } else if (currentUser?.user.auth_type === "emailFa") {
        setCurrentFA("emailFa");
        setCurrentImg("/img/logos/emailImage.png");
        setImgWidth(80);
      } else if (currentUser?.user.auth_type === "smsFa") {
        setCurrentFA("smsFa");
        setImgWidth(140);
        setCurrentImg("/img/logos/phone.png");
      }
    }
  }, [currentUser]);

  const handleChangeSmsConsent = async () => {
    try {
      setSwitchLoading(true);
      const consent = !smsConsent;
      setSmsConsent(consent);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/change-sms-consent`,
        {
          currentSession: currentSession,
          smsConsent: consent,
        },
        { withCredentials: true }
      );

      if (currentSession === "userSession") {
        dispatch(changeUserSmsConsent(consent));
      } else {
        dispatch(changeSubUserSmsConsent(consent));
      }
    } catch (error) {
    } finally {
      setSwitchLoading(false);
    }
  };

  const handleLeave = async () => {
    if (leaveInput !== "Yes, I want to cancel my subscription") {
      setErrorTextCancel("Input does not match!");
      setLeaveInput("");
      setCancelSelected("");
    } else if (cancelSelected === "") {
      setErrorTextCancel("Please select a reason!");
    } else {
      setCancelLoading(true);
      const payload = { leaveInput, cancelSelected };
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/cancel-subscription`,
          {
            payload,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (resp.data.success) {
          if (currentSession === "subUserSession") {
            await dispatch(fetchSubUserData(currentSession));
          } else {
            await dispatch(fetchUserData(currentSession));
          }
          setErrorTextCancel(resp.data.message);
          setCancelLoading(false);
          navigate("/subscription");
        }
      } catch (e) {
        setCancelLoading(false);
        setLeaveInput("");
        setCancelSelected("");
      }
    }
  };

  const passwordFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirmation: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPassword: Yup.string()
        .min(8, "password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character")
        .required("Password is required"),
      newPasswordConfirmation: Yup.string()

        .required("Password confirmation is required")
        .test("passwords-match", "Passwords must match", function (value) {
          return this.parent.newPassword === value;
        }),
    }),
    onSubmit: async (values, helpers) => {
      try {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/password/changepwd`,
            {
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
              newPasswordConfirmation: values.newPasswordConfirmation,
              currentSession: currentSession,
            },
            { withCredentials: true }
          );
          if (res.data.success) {
            setErrorTextPassword(res.data.message);
            setLoading(false);
            values.oldPassword = "";
            values.newPassword = "";
            values.newPasswordConfirmation = "";
          }
        } catch (err) {
          setErrorTextPassword(err.response.data.message);
          values.oldPassword = "";
          values.newPassword = "";
          values.newPasswordConfirmation = "";
          setLoading(false);
        }
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
        setLoading(false);
      }
    },
  });

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              backgroundColor: "#fff",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 9999,
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: largePC ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
              mt: 1,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                pb={3}
                pt={1}
                px={3}
                mb={4}
                component={Grid}
                sx={{
                  width: tabletSize ? "90vw" : "60vw",
                  maxWidth: "1100px",
                  mx: "auto",
                }}
              >
                <Grid container mt={tabletSize ? 1 : undefined}>
                  <Grid item xs={12} mt={1}>
                    <Typography
                      sx={{ textAlign: "center", mb: mobileSize ? 1 : 5 }}
                      variant="h5"
                    >
                      Change Password
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <form noValidate onSubmit={passwordFormik.handleSubmit}>
                      <Stack spacing={mobileSize ? 1 : 3} mt={4}>
                        <TextField
                          size={mobileSize ? "small" : undefined}
                          error={
                            !!(
                              passwordFormik.touched.oldPassword &&
                              passwordFormik.errors.oldPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.oldPassword &&
                            passwordFormik.errors.oldPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Old Password*"
                          name="oldPassword"
                          type={showOldPassword ? "text" : "password"}
                          value={passwordFormik.values.oldPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowOldPassword(!showOldPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showOldPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size={mobileSize ? "small" : undefined}
                          error={
                            !!(
                              passwordFormik.touched.newPassword &&
                              passwordFormik.errors.newPassword
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPassword &&
                            passwordFormik.errors.newPassword
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="New Password*"
                          name="newPassword"
                          type={showNewPassword ? "text" : "password"}
                          value={passwordFormik.values.newPassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => {
                                    setShowNewPassword(!showNewPassword);
                                  }}
                                  onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                  onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                  edge="end"
                                >
                                  {showNewPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          size={mobileSize ? "small" : undefined}
                          error={
                            !!(
                              passwordFormik.touched.newPasswordConfirmation &&
                              passwordFormik.errors.newPasswordConfirmation
                            )
                          }
                          helperText={
                            passwordFormik.touched.newPasswordConfirmation &&
                            passwordFormik.errors.newPasswordConfirmation
                          }
                          onBlur={passwordFormik.handleBlur}
                          onChange={passwordFormik.handleChange}
                          fullWidth
                          label="Confirm Password*"
                          name="newPasswordConfirmation"
                          type="password"
                          value={passwordFormik.values.newPasswordConfirmation}
                        />
                        {passwordFormik.errors.submit && (
                          <Typography
                            color="error"
                            sx={{ mt: 3 }}
                            variant="body2"
                          >
                            {passwordFormik.errors.submit}
                          </Typography>
                        )}
                        <Typography
                          variant="body1"
                          color="error"
                          fontWeight="bold"
                          textAlign="center"
                        >
                          {errorTextPassword}
                        </Typography>

                        <LoadingButton
                          size={mobileSize ? "small" : "large"}
                          loading={loading}
                          type="submit"
                          fullWidth
                          sx={{
                            mt: 2,
                            backgroundColor: "primary",
                          }}
                          variant="contained"
                        >
                          Submit Changes
                        </LoadingButton>
                      </Stack>
                    </form>
                  </Grid>
                </Grid>
              </Card>
              {/* SMS Consent & Notifications */}
              <Card
                pb={3}
                pt={2}
                px={3}
                mb={4}
                sx={{
                  width: tabletSize ? "90vw" : "60vw",
                  maxWidth: "1100px",
                  mx: "auto",
                  textAlign: "center",
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  boxShadow: 2,
                  py: 2,
                  mb: 4,
                }}
              >
                <Typography variant="h5" fontWeight="bold" mb={2}>
                  SMS Consent & Notifications
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 2,
                    mb: 2,
                  }}
                >
                  <Typography variant="body1" fontWeight="bold">
                    {smsConsent ? "Enabled" : "Disabled"}
                  </Typography>
                  {smsConsent ? (
                    <CheckCircleIcon sx={{ color: "green" }} />
                  ) : (
                    <CancelIcon sx={{ color: "red" }} />
                  )}
                </Box>

                <Typography variant="body2" sx={{ mb: 2 }}>
                  Enabling SMS allows us to send **important account
                  notifications** and **2FA security alerts**. You can disable
                  it at any time.
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2" sx={{ mr: 1 }}>
                    {smsConsent
                      ? "Turn Off SMS Notifications"
                      : "Turn On SMS Notifications"}
                  </Typography>
                  <Switch
                    disabled={
                      switchLoading
                        ? true
                        : authType === "smsFa" && smsConsent
                        ? true
                        : process.env.REACT_APP_DISABLE_SMS_FA === "true"
                        ? true
                        : false
                    }
                    checked={smsConsent}
                    onChange={() => {
                      handleChangeSmsConsent();
                    }}
                    color="primary"
                  />
                </Box>
                <Typography fontWeight="bold" color="error" variant="body2">
                  {authType === "smsFa" && smsConsent
                    ? "You must disable SMS 2FA before turning off SMS notifications"
                    : process.env.REACT_APP_DISABLE_SMS_FA === "true"
                    ? "SMS-based 2FA verification is temporarily unavailable"
                    : ""}
                </Typography>
              </Card>
              ;{/* CAHGNE 2FA */}
              <ChangeFA
                qrCodeSrc={qrCodeSrc}
                currentUser={currentUser}
                currentFA={currentFA}
                setCurrentFA={setCurrentFA}
                imageList={imageList}
                setImageList={setImageList}
                currentImg={currentImg}
                setCurrentImg={setCurrentImg}
                imgWidth={imgWidth}
                setImgWidth={setImgWidth}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
                smsConsent={smsConsent}
              />
              {!currentUser?.subuser && (
                <Box sx={{ mx: "auto" }}>
                  <>
                    {/* REMOVE SUBSCRIPTION */}
                    {currentUser &&
                      (currentUser.user.expired ||
                      currentUser.user.cancellation_time ? (
                        <Card
                          py={3}
                          px={mobileSize ? 1 : 3}
                          mt={0}
                          component={Grid}
                          sx={{
                            visibility: "hidden",
                            width: tabletSize ? "90vw" : "60vw",
                            maxWidth: "1100px",
                            mx: "auto",
                          }}
                        ></Card>
                      ) : (
                        <>
                          <Typography
                            variant="h4"
                            color="error"
                            textAlign="center"
                            mt={4}
                          >
                            DANGER ZONE
                          </Typography>
                          <div
                            style={{
                              border: "4px solid #F01329",
                              marginTop: "0.2rem",
                            }}
                          />
                          <Card
                            py={3}
                            px={mobileSize ? 1 : 3}
                            mt={0}
                            component={Grid}
                            sx={{
                              width: tabletSize ? "90vw" : "60vw",
                              maxWidth: "1100px",
                              mx: "auto",
                            }}
                          >
                            <Grid container>
                              <Grid item sm={12} mb={mobileSize ? 0 : 3}>
                                <Typography
                                  sx={{
                                    textAlign: "center",
                                    mb: mobileSize ? 1 : 2,
                                  }}
                                  variant="h5"
                                >
                                  Cancel Subscription
                                </Typography>
                                <Typography
                                  color="error"
                                  sx={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    mb: 3,
                                  }}
                                  variant={mobileSize ? "body2" : "body1"}
                                >
                                  We encourage you to try out our different
                                  packages before making a decision.
                                </Typography>
                              </Grid>
                              <Grid container spacing={mobileSize ? 1 : 10}>
                                <Grid item sm={6} xs={12}>
                                  <Stack>
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        fontWeight: "bold",
                                        mb: 1,
                                      }}
                                      variant="body1"
                                    >
                                      Are you sure to cancel subscription?
                                    </Typography>
                                    <Typography
                                      sx={{
                                        textAlign: "center",
                                        mb: mobileSize ? 1 : 3,
                                      }}
                                      variant="body2"
                                    >
                                      Type in "Yes, I want to cancel my
                                      subscription" to cancel subscription!
                                    </Typography>
                                    <TextField
                                      size={mobileSize ? "small" : undefined}
                                      onChange={(e) => {
                                        setLeaveInput(e.target.value);
                                      }}
                                      fullWidth
                                      label="Please Enter Text"
                                      name="leaveInput"
                                      value={leaveInput}
                                    />
                                  </Stack>
                                </Grid>
                                <Grid
                                  item
                                  sm={6}
                                  xs={12}
                                  mt={mobileSize ? 1 : 0}
                                >
                                  <Typography
                                    sx={{
                                      textAlign: "center",
                                      fontWeight: "bold",
                                      mb: mobileSize ? 0 : 1,
                                    }}
                                    variant="body1"
                                  >
                                    Please select reason!
                                  </Typography>
                                  <FormControl fullWidth variant="filled">
                                    <InputLabel>Select</InputLabel>
                                    <Select
                                      size={mobileSize ? "small" : undefined}
                                      label="Select"
                                      value={cancelSelected}
                                      onChange={(e) =>
                                        setCancelSelected(e.target.value)
                                      }
                                    >
                                      {leavingReasons?.cancel_subscription.map(
                                        function (item, i) {
                                          return (
                                            <MenuItem key={i} value={item}>
                                              {item}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid item xs={12} mt={mobileSize ? 0 : 5}>
                                  <Stack>
                                    <Typography
                                      minHeight={mobileSize ? "0rem" : "1.5rem"}
                                      variant="body1"
                                      color="#F2CD00"
                                      fontWeight="bold"
                                      textAlign="center"
                                    >
                                      {errorTextCancel}
                                    </Typography>
                                    <LoadingButton
                                      loading={cancelLoading}
                                      color="error"
                                      onClick={handleLeave}
                                      fullWidth
                                      size={mobileSize ? "small" : "large"}
                                      sx={{
                                        mt: 3,
                                      }}
                                      variant="contained"
                                    >
                                      CANCEL SUBSCRIPTION
                                    </LoadingButton>
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Card>
                        </>
                      ))}
                  </>
                </Box>
              )}
            </Grid>
            {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
          </Box>
        </>
      )}
    </>
  );
};

export default ProfileSettings;
