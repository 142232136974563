import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import { FaStamp } from "react-icons/fa";
import { FaSignature } from "react-icons/fa";
import { useSelector } from "react-redux";

const SigningsOverlay = ({
  field,
  scaleFactor,
  currentDocument,
  currentProcessingFile,
}) => {
  const reduxSigners = useSelector(
    (state) => state.userPackages.processSigners
  );

  const [signerName, setSignerName] = useState("");

  useEffect(() => {
    if (!currentProcessingFile || !field?.name) return;

    // ✅ Get Redux data safely
    const currentFormData = reduxSigners?.find(
      (signer) => signer?.packageID === currentProcessingFile?.packageID
    );

    if (!currentFormData) return; // ✅ Prevent undefined errors

    const signers = currentFormData?.signers || [];

    // ✅ Ensure field.name exists before calling normalizeName
    const normalizedFieldName = field?.name ? normalizeName(field.name) : "";

    // ✅ Map to signer role
    const signerMap = {
      sign_a: "signer_1",
      sign_b: "signer_2",
      sign_c: "signer_3",
    };

    const signerRole = signerMap[normalizedFieldName] || null;
    const signerDetails = signers.find((s) => s.signer === signerRole);

    // ✅ Ensure firstName & lastName exist
    const newSignerName =
      signerDetails?.firstName && signerDetails?.lastName
        ? `${signerDetails.firstName} ${signerDetails.lastName}`
        : "";

    // ✅ Only update state if necessary (avoiding infinite re-renders)
    setSignerName((prev) => (prev !== newSignerName ? newSignerName : prev));
  }, [reduxSigners, currentProcessingFile, field]); // ✅ Dependencies

  const labelMap = {
    notary_seal_a: "Notary Seal 1",
    notary_sign_a: "Notary Signature 1",
    notary_seal_b: "Notary Seal 2",
    notary_sign_b: "Notary Signature 2",
    sign_a: "Signer 1",
    sign_b: "Signer 2",
    sign_c: "Signer 3",
  };

  const normalizeName = (name) => {
    const parts = name.split("_");
    const result =
      parts.length === 3
        ? parts.slice(0, 2).join("_")
        : parts.length === 4
        ? parts.slice(0, 3).join("_")
        : name;

    return result;
  };

  return (
    <Box
      sx={{
        position: "absolute",
        left: `${field.x * scaleFactor}px`,
        top: `${
          (currentDocument.actualHeight - field.y - field.height) * scaleFactor
        }px`,
        width: `${field.width * scaleFactor + 4}px`,
        height: `${field.height * scaleFactor + 2}px`,
        background: ["notary_seal_a", "notary_sign_a"].some((prefix) =>
          field?.name.startsWith(prefix)
        )
          ? "RGBA(75, 85, 175, 0.6)"
          : ["notary_seal_b", "notary_sign_b"].some((prefix) =>
              field?.name.startsWith(prefix)
            )
          ? "rgba(65, 110, 180, 0.6)"
          : field?.name.startsWith("sign_a")
          ? "RGBA(60, 145, 80, 0.6)"
          : field?.name.startsWith("sign_b")
          ? "RGBA(175, 60, 60, 0.6)"
          : "RGBA(140, 80, 180, 0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        // gap: "4px",
        color: "white",
        fontSize: "14px",
        fontWeight: "bold",
        textAlign: "center",
        textTransform: "uppercase",
        boxShadow: "0px 0px 5px RGBA(0, 0, 0, 0.2)", // Soft shadow effect
        transition: "opacity 0.3s ease-in-out",
        "&:hover": {
          opacity: 0.8, // Optional hover effect
        },
      }}
    >
      {["notary_seal_a", "notary_seal_b"].includes(
        normalizeName(field.name)
      ) ? (
        <>
          <FaStamp size={16} color="white" />
          <Typography fontWeight="bold" mt={0.3} variant="caption">
            {labelMap[normalizeName(field.name)]}
          </Typography>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Typography fontWeight="bold" variant="caption">
            {labelMap[normalizeName(field.name)]}
          </Typography>
          <FaSignature size={18} color="white" />
        </Box>
      )}
      {signerName && (
        <Typography
          sx={{
            position: "absolute",
            bottom: 4,
            right: 8,
            fontSize: "12px",
            fontWeight: "bold",
            color: "white",
            textAlign: "right",
            fontFamily: "'Caveat', cursive",
          }}
        >
          {signerName}
        </Typography>
      )}
    </Box>
  );
};

export default SigningsOverlay;
