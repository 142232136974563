import {
  Grid2 as Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  FormControl,
  Tooltip,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  FormHelperText,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear"; // Import the ClearIcon

export const NewDiscounts = ({
  title,
  discountsRows,
  setManagementData,
  isSm,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [selectedCell, setSelectedCell] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [newCellText, setNewCellText] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [basePlan, setBasePlan] = useState("");
  const [zeroPriceMsg, setZeroPriceMsg] = useState(false);
  const [currentDuration, setCurrentDuration] = useState("");
  const [newDuration, setNewDuration] = useState("");
  const [extraCountyQty, setExtraCountyQty] = useState("");

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);
  //! max_county
  const handleEdit = async (type) => {
    try {
      setAddLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/signup-discount-management`,
        {
          discount: type === "remove" ? 0 : textFieldValue,
          package: selectedCell,
          newDuration:
            selectedCell?.field === "extraCountyPrice" ? "once" : newDuration,
          extraCountyQty: extraCountyQty,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      setManagementData(res.data.data);

      setErrorText(resp.data.message);

      setAddLoading(false);

      setSelectedCell(null);
      setBasePlan(null);
      setTextFieldValue("");
      setNewCellText("");
      setSelectValue("");
      setNewDuration("");
    } catch (e) {
      setAddLoading(false);
    }
  };

  const handleCellClick = (params) => {
    if (params.field === "packageName") {
      return;
    }

    if (
      params.formattedValue === 0 &&
      !params?.row?.discounts?.[params.field]?.active
    ) {
      setZeroPriceMsg(true);
      setSelectedCell(null);
      setTextFieldValue("");
      setNewDuration("");
      return;
    }

    setSelectedCell(params);
    setTextFieldValue(params?.row?.discounts?.[params.field]?.amount);
    setCurrentDuration(
      params?.row?.discounts?.[params.field]?.duration
        ?.charAt(0)
        .toUpperCase() +
        params?.row?.discounts?.[params.field]?.duration?.slice(1)
    );
    setBasePlan(params.row.packageName);
    setZeroPriceMsg(false);

    setExtraCountyQty(params?.row?.discounts?.[params.field]?.max_county || "");

    if (params?.row?.discounts?.[params.field]?.duration) {
      setNewDuration(
        params?.row?.discounts?.[params.field]?.duration
          ?.charAt(0)
          .toUpperCase() +
          params?.row?.discounts?.[params.field]?.duration?.slice(1)
      );
    } else {
      setNewDuration("");
    }
  };

  const renderSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Define the maximum length you want to display
    const maxLength = 30; // For example, limit to 20 characters

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const discountColumns = [
    {
      field: "packageName",
      headerName: "Base Plan",
      flex: 1,
      headerClassName: "management-datagrid-discount",
    },
    {
      field: "monthlyPrice",
      headerName: "Monthly Price",
      flex: 1,
      headerClassName: "management-datagrid-discount",
      renderCell: (params) => {
        const price = params.row.monthlyPrice;
        const discount = params.row.discounts.monthlyPrice;
        const finalPrice = discount.active ? price - discount.amount : price;
        const discountPercent = discount.active
          ? ((discount.amount / price) * 100).toFixed(2)
          : 0;

        return (
          <>
            <Typography
              variant="body2"
              sx={{ textDecoration: discount.active ? "line-through" : "none" }}
            >
              ${price.toFixed(2)}
            </Typography>
            {discount.active && (
              <Typography variant="body2" color="green">
                ${finalPrice.toFixed(2)} (-{discountPercent}%)
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "notaryRecordPrice",
      headerName: "All Documents Notary & Record",
      flex: 1,
      headerClassName: "management-datagrid-discount",
      renderHeader: () => (
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          All Documents <br />
          Notary & Record
        </Typography>
      ),
      renderCell: (params) => {
        const price = params.row.notaryRecordPrice;
        const discount = params.row.discounts.notaryRecordPrice;
        const finalPrice = discount.active ? price - discount.amount : price;
        const discountPercent = discount.active
          ? ((discount.amount / price) * 100).toFixed(2)
          : 0;

        return (
          <>
            <Typography
              variant="body2"
              sx={{ textDecoration: discount.active ? "line-through" : "none" }}
            >
              ${price.toFixed(2)}
            </Typography>
            {discount.active && (
              <Typography variant="body2" color="green">
                ${finalPrice.toFixed(2)} (-{discountPercent}%)
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "nocNotaryPrice",
      headerName: "NOC Notary",
      flex: 1,
      headerClassName: "management-datagrid-discount",
      renderCell: (params) => {
        const price = params.row.nocNotaryPrice;
        const discount = params.row.discounts.nocNotaryPrice;
        const finalPrice = discount.active ? price - discount.amount : price;
        const discountPercent = discount.active
          ? ((discount.amount / price) * 100).toFixed(2)
          : 0;

        return (
          <>
            <Typography
              variant="body2"
              sx={{ textDecoration: discount.active ? "line-through" : "none" }}
            >
              ${price.toFixed(2)}
            </Typography>
            {discount.active && (
              <Typography variant="body2" color="green">
                ${finalPrice.toFixed(2)} (-{discountPercent}%)
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "extraCountyPrice",
      headerName: "Extra County",
      flex: 1,
      headerClassName: "management-datagrid-discount",
      renderCell: (params) => {
        const price = params.row.extraCountyPrice;
        const discount = params.row.discounts.extraCountyPrice;
        const finalPrice = discount.active ? price - discount.amount : price;
        const discountPercent = discount.active
          ? ((discount.amount / price) * 100).toFixed(2)
          : 0;

        return (
          <>
            <Typography
              variant="body2"
              sx={{ textDecoration: discount.active ? "line-through" : "none" }}
            >
              ${price.toFixed(2)}
            </Typography>
            {discount.active && (
              <Typography variant="body2" color="green">
                ${finalPrice.toFixed(2)} (-{discountPercent}%)
              </Typography>
            )}
          </>
        );
      },
    },
    {
      field: "extraDownloadCost",
      headerName: "PP Download",
      flex: 1,
      headerClassName: "management-datagrid-discount",
      renderCell: (params) => {
        const price = params.row.extraDownloadCost;
        const discount = params.row.discounts.extraDownloadCost;
        const finalPrice = discount.active ? price - discount.amount : price;
        const discountPercent = discount.active
          ? ((discount.amount / price) * 100).toFixed(2)
          : 0;

        return (
          <>
            <Typography
              variant="body2"
              sx={{ textDecoration: discount.active ? "line-through" : "none" }}
            >
              ${price.toFixed(2)}
            </Typography>
            {discount.active && (
              <Typography variant="body2" color="green">
                ${finalPrice.toFixed(2)} (-{discountPercent}%)
              </Typography>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-cell": {
              display: "flex",
              flexDirection: "column", // Align text vertically
              justifyContent: "center", // Center horizontally
            },
            minHeight: "24rem",
          }}
          rows={discountsRows}
          columns={discountColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          rowHeight={60}
          pageSizeOptions={[5, 10, 25, 50, 100]}
          disableRowSelectionOnClick
          onCellClick={handleCellClick}
          classes={{
            cell: "cell", // Apply custom cell class
          }}
        />
        <Grid container spacing={isSm ? 1 : 2} alignItems="center">
          <Grid size={{ xs: 12 }}>
            <Typography
              color={zeroPriceMsg ? "error" : undefined}
              sx={{ fontWeight: "bold" }}
            >
              {selectedCell && !zeroPriceMsg ? (
                <>
                  {basePlan} Plan: {selectedCell.colDef.headerName} - $
                  {selectedCell?.formattedValue.toFixed(2)}
                </>
              ) : zeroPriceMsg ? (
                "Cannot add a discount on a plan with a price of $0"
              ) : (
                <></>
              )}
            </Typography>
            {selectedCell && !zeroPriceMsg && (
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {selectedCell?.field === "extraCountyPrice"
                  ? "Discount duration is limited to a one-time use only"
                  : `Current Discount Duration: ${
                      currentDuration ? currentDuration : "N/A"
                    }`}
              </Typography>
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 4 }} sx={{ minHeight: "120px" }}>
            <TextField
              disabled={!selectedCell}
              label={!selectedCell ? "Select Cell" : "Add/Edit Discount Amount"}
              variant="filled"
              fullWidth
              type="number"
              value={textFieldValue || ""}
              onChange={(e) => setTextFieldValue(e.target.value)}
              InputProps={{
                endAdornment: selectedCell ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setTextFieldValue("");
                        setSelectedCell(null);
                        setNewDuration("");
                        setBasePlan(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
            <Box>
              <FormHelperText
                sx={{
                  color:
                    parseFloat(textFieldValue) > selectedCell?.formattedValue ||
                    parseFloat(textFieldValue) <= 0
                      ? "red"
                      : "blue",
                  ml: 1,
                }}
              >
                {parseFloat(textFieldValue) > selectedCell?.formattedValue
                  ? "Discount cannot be greater than the base plan price"
                  : parseFloat(textFieldValue) <= 0
                  ? "Discount must be greater than 0"
                  : textFieldValue && selectedCell?.field !== "extraCountyPrice"
                  ? "This amount will be deducted from the original price in dollars"
                  : textFieldValue && selectedCell?.field === "extraCountyPrice"
                  ? "This amount will be deducted from the original price per county"
                  : ""}
              </FormHelperText>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 4 }} sx={{ minHeight: "120px" }}>
            {selectedCell?.field === "extraCountyPrice" ? (
              <>
                <TextField
                  disabled={editLoading || !selectedCell || !textFieldValue}
                  label={
                    !selectedCell
                      ? "Select Cell"
                      : "Minimum Quantity to Apply Discount"
                  }
                  variant="filled"
                  fullWidth
                  type="number"
                  value={extraCountyQty || ""}
                  onKeyDown={(e) => {
                    if (
                      e.key === "e" ||
                      e.key === "-" ||
                      e.key === "+" ||
                      e.key === "."
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d+$/.test(value) || value === "") {
                      // Allow only integers
                      setExtraCountyQty(value);
                    }
                  }}
                  InputProps={{
                    inputProps: { min: 1 },
                    endAdornment: selectedCell ? (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            setExtraCountyQty("");
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />

                <FormHelperText
                  sx={{
                    color:
                      parseFloat(extraCountyQty) <= 0 ||
                      parseFloat(extraCountyQty) >
                        selectedCell?.row?.maxAdditionalCounties
                        ? "red"
                        : "blue",
                    ml: 0.5,
                    minHeight: "16px",
                  }}
                >
                  {parseFloat(extraCountyQty) <= 0
                    ? "Minimum quantity must be greater than 0"
                    : parseFloat(extraCountyQty) >
                      selectedCell?.row?.maxAdditionalCounties
                    ? `Minimum quantity can not be more than ${selectedCell?.row?.maxAdditionalCounties}`
                    : extraCountyQty
                    ? "Users must add at least this many counties to qualify for the discounts"
                    : ""}
                </FormHelperText>
              </>
            ) : (
              <FormControl
                fullWidth
                variant="filled"
                disabled={editLoading || !selectedCell || !textFieldValue}
              >
                <Tooltip title="">
                  <InputLabel
                    sx={{
                      whiteSpace: "nowrap", // Prevent label from wrapping
                      overflow: "hidden",
                      textOverflow: "ellipsis", // Add ellipsis for overflow
                    }}
                  >
                    Select Discount Duration
                  </InputLabel>
                </Tooltip>
                <Select
                  value={newDuration}
                  onChange={(e) => setNewDuration(e.target.value)}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        marginTop: "1.5px",
                        maxHeight: 150, // Set the desired max height for the dropdown menu
                        width: "auto",
                        maxWidth: "100%",
                      },
                    },
                  }}
                  renderValue={renderSelectedValues}
                  sx={{
                    maxWidth: "100%", // Set the desired max-width for the input element
                    "& .MuiTypography-body2": {
                      whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                    },
                  }}
                  endAdornment={
                    newDuration !== "" ? ( // Place the clear icon to the right of the Select
                      <IconButton
                        onClick={() => {
                          setNewDuration("");
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ) : undefined
                  }
                  IconComponent={newDuration !== "" ? "div" : undefined} // Remove the default MUI arrow
                >
                  {["Once", "Forever"]?.map(function (item, i) {
                    const truncatedItem = truncateText(item, 30); // Truncate item if too long
                    return (
                      <MenuItem key={i} value={item}>
                        {item.length > 30 ? (
                          <Tooltip title={item}>
                            <Typography variant="body2">
                              {truncatedItem}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body2">{item}</Typography>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 2 }} sx={{ alignSelf: "flex-start" }}>
            <LoadingButton
              loading={addLoading}
              disabled={
                !selectedCell ||
                !textFieldValue ||
                textFieldValue === selectedCell?.formattedValue ||
                removeLoading ||
                addLoading ||
                parseFloat(textFieldValue) <= 0 ||
                parseFloat(textFieldValue) > selectedCell?.formattedValue ||
                (selectedCell?.field !== "extraCountyPrice" && !newDuration) ||
                (selectedCell?.field === "extraCountyPrice" &&
                  (parseFloat(extraCountyQty) <= 0 ||
                    parseFloat(extraCountyQty) >
                      selectedCell?.row?.maxAdditionalCounties ||
                    !extraCountyQty))
              }
              fullWidth
              variant="contained"
              color="success"
              onClick={() => {
                handleEdit("edit");
              }}
            >
              Add/Edit
            </LoadingButton>
          </Grid>
          <Grid size={{ xs: 12, md: 2 }} sx={{ alignSelf: "flex-start" }}>
            <LoadingButton
              loading={addLoading}
              disabled={
                !Boolean(selectedCell) ||
                addLoading ||
                !selectedCell?.row?.discounts?.[selectedCell.field]?.active
              }
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                handleEdit("remove");
              }}
            >
              Remove
            </LoadingButton>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          color="primary"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
