import { Box, Button, Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import EditIcon from "@mui/icons-material/Edit";
import ChangeOwnerProfileDetailsModal from "../../modals/ChangeOwnerProfileDetailsModal";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

const OwnerTab = (props) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);

  const row = props?.selectedRow;
  const { property_owner } = row;
  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [fieldValues, setFieldValues] = useState({
    ownerEmail: property_owner?.email || "",
    ownerMobile: property_owner?.mobile || "",
    ownerEmailError: false,
    ownerMobileError: false,
  });
  const [fieldChangedText, setFieldChangedText] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChangeOwnerDetails = async () => {
    try {
      setLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/update-owner-profile-info`,
        {
          task_id: row?.task_id,
          newEmail: fieldValues?.ownerEmail,
          newMobile: fieldValues?.ownerMobile,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
      if (resp?.data?.success) {
        setFieldChangedText(resp?.data?.message);
        setTimeout(() => {
          setModalOpen(false);
          setFieldValues({
            ownerEmail: fieldValues?.ownerEmail,
            ownerMobile: fieldValues?.ownerMobile,
            ownerEmailError: false,
            ownerMobileError: false,
          });
          setLoading(false);
          setFieldChangedText("");
        }, 3000);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          mt: 1,
        }}
      >
        <img
          src="/img/website_img/house.png"
          style={{
            width: "100%",
            maxWidth: "5rem",
            height: "auto",
            borderRadius: "20px",
          }}
        />
        {!property_owner || isEmptyObject(property_owner) ? (
          <Box mt={6}>
            <Typography align="center" px={1} variant="h6" color="primary">
              To display property owner information, please create a Notice of
              Commencement first
            </Typography>
          </Box>
        ) : (
          <>
            <Typography mt={1} fontWeight="bold">
              {property_owner?.name}
            </Typography>
            {row?.notarized ||
            row?.processing_permit?.notarization?.owner?.notarization
              ?.waiting_to_schedule ? (
              <></>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  onClick={() => setModalOpen(true)}
                  startIcon={<EditIcon />}
                >
                  Edit Details
                </Button>
              </Box>
            )}

            <Box mt={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>
                  {property_owner?.address_parts?.address}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <LocationOnIcon />
                <Typography>
                  {property_owner?.address_parts?.city}{" "}
                  {property_owner?.address_parts?.state}{" "}
                  {property_owner?.address_parts?.zipCode}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <EmailIcon />
                <Typography>{property_owner?.email || "Unknown"}</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  mb: 0.5,
                }}
              >
                <PhoneIcon />
                <Typography>{property_owner?.mobile || "Unknown"}</Typography>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <ChangeOwnerProfileDetailsModal
        open={modalOpen}
        setOpen={setModalOpen}
        onConfirm={handleChangeOwnerDetails}
        fieldValues={fieldValues}
        setFieldValues={setFieldValues}
        fieldChangedText={fieldChangedText}
        loading={loading}
        property_owner={property_owner}
      />
    </>
  );
};

export default OwnerTab;
