const initialState = {
  nocData: null,
  showDemo: false,
  demoShown: false,
  showTourNav: false,
  prevTourStep: 0,
  pdfProgress: [],
  createdNoc: null,
  available_permit_forms: [],
  permit_document: null,
  permitPackages: [],
  pais_templates: [],
  saved_projects: {
    projectContact: [],
    feeSimple: [],
    bonding: [],
    mortgageLender: [],
  },
  permitPackageSigned: null,
  scrapedFormReturned: [],
  newTempPermitData: null,
  emptyCountyFlag: null,
  prevCityCounty: {
    city: "",
    county: "",
  },
  isReadyToFetch: false,
  upgradeRequested: false,
  customers: {
    rows: [],
    pagination: {
      rowsPerPage: 5,
      page: 0,
      totalCount: 0,
    },
  },
};

const nocDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_UPGRADE_REQUESTED":
      return { ...state, upgradeRequested: action.payload };
    case "ON_READY_TO_FETCH":
      return { ...state, isReadyToFetch: true };
    case "OFF_READY_TO_FETCH":
      return { ...state, isReadyToFetch: false };
    case "SET_SHOW_TOUR_NAV":
      return { ...state, showTourNav: action.payload };
    case "ENABLE_DEMO_SHOWN":
      return { ...state, demoShown: true, showDemo: true };
    case "REMOVE_SHOW_DEMO":
      return { ...state, showDemo: false };
    case "CLEAR_SHOW_DEMO":
      return { ...state, demoShown: false, showDemo: false };
    case "SET_PREV_TOUR_STEP":
      return { ...state, prevTourStep: action.payload };
    case "PUSH_CITY_LOG":
      const taskId = action.payload.task_id;
      const logUpdate = action.payload.log;

      // IF EXISTS
      const permit_number = action?.payload?.permit_number;
      const application_id = action?.payload?.application_id;
      const application_url = action?.payload?.application_url;

      const allFieldsAvailable =
        permit_number && application_id && application_url;

      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem?.task_id === taskId) {
            const updatedLogs =
              permitItem?.city_scraper?.logs?.map((log, index, array) => {
                // Set the "completed" field of the previous log to true
                if (index === array.length - 1 && !log.completed) {
                  return {
                    ...log,
                    completed: true,
                    end_time: logUpdate.start_time,
                  };
                }
                return log;
              }) || [];

            return {
              ...permitItem,
              city_scraper: {
                ...permitItem?.city_scraper, // Access the specific permitItem's city_scraper
                logs: [
                  ...updatedLogs, // Update the previous logs with the completed state
                  logUpdate, // Push the new log entry
                ],
                // Conditionally update if the fields exist
                ...(permit_number && { permit_number }),
                ...(application_id && {
                  application_id, // Fixed the path
                }),
                ...(application_url && {
                  application_url, // Fixed the path
                }),
                ...(allFieldsAvailable && { first_done: true }), // Fixed the path
              },
            };
          }
          return permitItem;
        }),
      };
    case "SET_PREV_CITY_COUNTY":
      return {
        ...state,
        prevCityCounty: {
          ...state.prevCityCounty,
          city: action.payload.city,
          county: action.payload.county,
        },
      };
    case "ENABLE_EMPTY_COUNTY_FLAG":
      return { ...state, emptyCountyFlag: action.payload };
    case "DISABLE_EMPTY_COUNTY_FLAG":
      return { ...state, emptyCountyFlag: null };
    case "GET_PERMIT_PACKAGES":
      return { ...state, permitPackages: action.payload };
    case "UPDATE_PERMIT_PACKAGE":
      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem.task_id === action.payload.task_id) {
            // Replace the entire object with action.payload
            return action.payload;
          }
          return permitItem; // Return the original permitItem if no changes are needed
        }),
      };
    case "UPDATE_PERMIT_PACKAGE_NAME":
      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem.task_id === action.payload.task_id) {
            return {
              ...permitItem,
              package_name: action.payload.package_name,
            };
          }
          return permitItem;
        }),
      };
    case "UPDATE_PERMIT_PACKAGE_FROM_RETURN":
      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem.task_id === action.payload.task_id) {
            // Replace the entire object with action.payload
            return action.payload;
          }
          return permitItem; // Return the original permitItem if no changes are needed
        }),
        scrapedFormReturned: (() => {
          const existingIndex = state.scrapedFormReturned.findIndex(
            (item) => item.id === action.payload.task_id
          );

          if (existingIndex !== -1) {
            // Create a new array with the updated object
            return state.scrapedFormReturned.map(
              (item, index) =>
                index === existingIndex
                  ? { ...item, returned: true } // Create a new object with updated `returned`
                  : item // Keep the original item if no change is needed
            );
          } else {
            // Add a new object if it doesn't exist
            return [
              ...state.scrapedFormReturned,
              { id: action.payload.task_id, returned: true },
            ];
          }
        })(),
      };
    case "UPDATE_PERMIT_PACKAGE_ON_FLY":
      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem.task_id === action.payload.task_id) {
            return action.payload;
          }
          return permitItem; // Return the original permitItem if no changes are needed
        }),
      };
    case "RESET_SCRAPED_FORM_RETURN":
      return {
        ...state,
        scrapedFormReturned: state.scrapedFormReturned.filter(
          (item) => item.id !== action.payload // Remove the object with matching id
        ),
      };
    case "ADD_AVAILABLE_PERMIT_FORMS":
      return { ...state, available_permit_forms: action.payload };
    case "UPDATE_PERMIT_NOC_FORM":
      return {
        ...state,
        permitPackages: state.permitPackages.map((permitItem) => {
          if (permitItem.task_id === action.payload.task_id) {
            return {
              ...permitItem,
              documents: {
                ...permitItem.documents,
                noc: action.payload.noc,
              },
            };
          }
          return permitItem;
        }),
      };
    case "GET_NOC_DATA":
      return {
        ...state,
        nocData: {
          ...state.nocData, // Keep existing data
          ...action.payload, // Overwrite with new data
        },
      }; // Update state with new data
    case "GET_PDF_PROGRESS":
      const updatedProgress = [...state.pdfProgress];
      const existingIndex = updatedProgress.findIndex(
        (item) => item.id === action.payload.id
      );

      if (existingIndex !== -1) {
        updatedProgress[existingIndex] = {
          ...updatedProgress[existingIndex], // Create a new object
          message: action.payload.message, // Update the message
          ...(action.payload.queue_data && {
            queue_data: action.payload.queue_data,
          }), // Conditionally update queue_data
          ...(action.payload.status
            ? { status: action.payload.status } // Add or update the status if it's in the payload
            : { status: undefined }), // Remove the status by setting it to undefined if not in the payload
        };
      } else {
        // If not, add the new dictionary
        updatedProgress.push(action.payload);
      }
      return { ...state, pdfProgress: updatedProgress };
    case "CLEAR_PDF_PROGRESS":
      const clearingProgress = state.pdfProgress.filter(
        (item) => item.id !== action.payload
      );
      return { ...state, pdfProgress: clearingProgress };
    case "SET_CREATED_NOC":
      return { ...state, createdNoc: action.payload };
    case "CLEAR_CREATED_NOC":
      return { ...state, createdNoc: null };
    case "UPDATE_PERMIT_DOCUMENT":
      return { ...state, permit_document: action.payload };
    case "REMOVE_PERMIT_DOCUMENT":
      return { ...state, permit_document: null };
    case "ADD_PAIS_TEMPLATE":
      return { ...state, pais_templates: action.payload };
    case "ADD_PERMIT_PACKAGE_SIGNED_URL":
      return { ...state, permitPackageSigned: action.payload };
    case "ADD_SAVED_PROJECTS":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          ...action.payload,
        },
      };
    case "FIRST_ADD_SAVED_PROJECTS":
      return {
        ...state,
        pais_templates: action.payload.paisTemplates,
        saved_projects: {
          ...state.saved_projects,
          ...action.payload.savedProjects,
        },
      };
    case "PUSH_NEW_PROJECT":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          projectContact: [
            ...state.saved_projects.projectContact,
            action.payload,
          ],
        },
      };
    case "PUSH_NEW_FEE_SIMPLE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          feeSimple: [...state.saved_projects.feeSimple, action.payload],
        },
      };
    case "PUSH_NEW_BONDING":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          bonding: [...state.saved_projects.bonding, action.payload],
        },
      };
    case "PUSH_NEW_MORTGAGE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          mortgageLender: [
            ...state.saved_projects.mortgageLender,
            action.payload,
          ],
        },
      };
    case "UPDATE_NEW_PROJECT":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          projectContact: state.saved_projects?.projectContact?.map((project) =>
            project.id === action.payload.id
              ? { ...project, ...action.payload }
              : project
          ),
        },
      };
    case "UPDATE_NEW_FEE_SIMPLE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          feeSimple: state.saved_projects?.feeSimple?.map((project) =>
            project.id === action.payload.id
              ? { ...project, ...action.payload }
              : project
          ),
        },
      };
    case "UPDATE_NEW_BONDING":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          bonding: state.saved_projects?.bonding?.map((project) =>
            project.id === action.payload.id
              ? { ...project, ...action.payload }
              : project
          ),
        },
      };
    case "UPDATE_NEW_MORTGAGE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          mortgageLender: state.saved_projects?.mortgageLender?.map((project) =>
            project.id === action.payload.id
              ? { ...project, ...action.payload }
              : project
          ),
        },
      };
    case "REMOVE_SAVED_PROJECT":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          projectContact: state.saved_projects?.projectContact.filter(
            (pro) => pro.id !== action.payload
          ),
        },
      };
    case "REMOVE_FEE_SIMPLE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          feeSimple: state.saved_projects?.feeSimple.filter(
            (pro) => pro.id !== action.payload
          ),
        },
      };
    case "REMOVE_BONDING":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          bonding: state.saved_projects?.bonding.filter(
            (pro) => pro.id !== action.payload
          ),
        },
      };
    case "REMOVE_MORTGAGE":
      return {
        ...state,
        saved_projects: {
          ...state.saved_projects,
          mortgageLender: state.saved_projects?.mortgageLender.filter(
            (pro) => pro.id !== action.payload
          ),
        },
      };
    case "SET_NEW_TEMP_PERMIT_DATA":
      return { ...state, newTempPermitData: action.payload };
    case "CLEAR_NEW_TEMP_PERMIT_DATA":
      return { ...state, newTempPermitData: null };

    case "SET_CUSTOMERS":
      return {
        ...state,
        customers: {
          ...state.customers,
          rows: action.payload?.rows,
          pagination: {
            ...state.customers.pagination,
            page: action.payload?.page,
            totalCount: action.payload?.totalCount,
          },
        },
      };
    case "UPDATE_CUSTOMER_COUPON":
      return {
        ...state,
        customers: {
          ...state.customers,
          rows: state.customers.rows.map((row) => {
            if (row.id === action.payload.id) {
              // Check if the discount name already exists in user_discounts
              const discountExists = row.user_discounts?.some(
                (discount) => discount.name === action.payload.coupon.name
              );

              return {
                ...row,
                user_discounts: discountExists
                  ? row.user_discounts.map((discount) =>
                      discount.name === action.payload.coupon.name
                        ? action.payload.coupon // Replace existing discount
                        : discount
                    )
                  : [...(row.user_discounts || []), action.payload.coupon], // Append new discount if not found
              };
            }
            return row;
          }),
        },
      };
    default:
      return state;
  }
};

export default nocDataReducer;
