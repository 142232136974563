import {
  CircularProgress,
  Box,
  useMediaQuery,
  Drawer,
  Alert,
  Snackbar,
} from "@mui/material";

import { loadStripe } from "@stripe/stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useCallback } from "react";
import MyPermitGrid from "../../permitComponents/MyPermitGrid";
import {
  clearNewTempPermitData,
  getPermitPackages,
  getSavedProjects,
} from "../../../store/actions/dataActions";
import OpenDocuments from "../../noc/OpenDocuments";
import axios from "axios";
import { DateTime } from "luxon";
import { useTour } from "@reactour/tour";
import { clearForwardNotification } from "../../../store/actions/notificationsActions";
import PromoModal from "../../popover/promoPopover/PromoModal";
import DemoPopPaymentModal from "../../popover/promoPopover/DemoPopPaymentModal";
import { updatePermitPackage } from "../../../store/actions/dataActions";
import AddPaymentCardModal from "../../modals/AddPaymentCardModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { updateAnalytics } from "../../../store/actions/userActions";

const MyPermits = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const savedProjects = useSelector((state) => state.nocData?.saved_projects);
  const permitPackages = useSelector((state) => state.nocData?.permitPackages);
  const newTempPermitData = useSelector(
    (state) => state.nocData?.newTempPermitData
  );
  const nocCreatingStatus = useSelector((state) => state.nocData.pdfProgress);
  const notifications = useSelector((state) => state.notifications);

  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [rowCount, setRowCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState(null);
  const [permitDrawerOpen, setPermitDrawerOpen] = useState(false);
  const [leftValue, setLeftValue] = useState(0);
  const [rightValue, setRightValue] = useState(0);
  const [newPermitOpened, setNewPermitOpened] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [tempLoading, setTempLoading] = useState(false);
  const [ppDownloading, setPPDownloading] = useState(false);
  const [ownerSigning, setOwnerSigning] = useState({
    notarizationNeeded: false,
    signingNeeded: false,
  });
  const [contractorSigning, setContractorSigning] = useState({
    notarizationNeeded: false,
    signingNeeded: false,
  });
  const [activeTab, setActiveTab] = useState(0);
  const [currentPopup, setCurrentPopup] = useState("pop1");
  const [promoModalOpen, setPromoModalOpen] = useState(false);
  const [docNotaryTypeValue, setDocNotaryTypeValue] = useState(0);
  const [activeTabLoading, setActiveTabLoading] = useState(null);
  const [currentNocStatus, setCurrentNocStatus] = useState(null);

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [paymentErrorText, setPaymentErrorText] = useState("");
  const [snackOpen, setSnackOpen] = useState(false);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);
  const [gridKey, setGridKey] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);

  const { isOpen, currentStep, setCurrentStep } = useTour();

  const updateSelectedPackageTypeData = async (index) => {
    try {
      setActiveTabLoading(index);
      setDataLoading(true);
      const result = await dispatch(getPermitPackages(0, pageSize, index));
      setRowCount(result); // Assuming your API returns the total count of documents
    } catch (error) {
    } finally {
      setActiveTabLoading(null);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    if (selectedRow && nocCreatingStatus && nocCreatingStatus.length > 0) {
      const foundStatus = nocCreatingStatus.find(
        (item) => item.id === selectedRow?.task_id
      );

      if (foundStatus) {
        setCurrentNocStatus(foundStatus);
      } else {
        setCurrentNocStatus(null);
      }
    }
  }, [nocCreatingStatus, selectedRow]);

  const handlePackageTypeChange = async (index) => {
    if (activeTab === index) {
      return;
    } else {
      setActiveTab(index);
      setSelectedRow(null);
      setPage(0);
      updateSelectedPackageTypeData(index);
    }
  };

  useEffect(() => {
    if (isOpen && currentSession === "userSession") {
      if (currentStep === 16) {
        setRightValue(1);
      } else if (currentStep === 17) {
        setRightValue(2);
      } else if (currentStep === 18) {
        setRightValue(3);
      } else if (currentStep === 19) {
        setRightValue(4);
      } else if (currentStep === 20) {
        setRightValue(5);
      }
    } else if (isOpen && currentSession === "subUserSession") {
      if (currentStep === 13) {
        setRightValue(0);
      } else if (currentStep === 14) {
        setRightValue(1);
      } else if (currentStep === 15) {
        setRightValue(2);
      } else if (currentStep === 16) {
        setRightValue(3);
      } else if (currentStep === 17) {
        setRightValue(4);
      } else if (currentStep === 18) {
        setRightValue(5);
      }
    }
  }, [currentStep, isOpen, currentSession]);

  //! documents ///
  const [usingTemplate, setUsingTemplate] = useState(false);
  const [creatingDocTitle, setCreatingDocTitle] = useState("");
  const [creatingDoc, setCreatingDoc] = useState("");
  const [viewingDoc, setViewingDoc] = useState("noc");
  const [unavailableRecord, setUnavailableRecord] = useState(false);
  const [documentImage, setDocumentImage] = useState([]);
  const [downloading, setDownloading] = useState("");
  const [instructionDownloading, setInstructionDownloading] = useState("");
  const [numCreated, setNumCreated] = useState(0);
  //! /////////////

  const [createLoading, setCreateLoading] = useState(false);

  const [paymentCards, setPaymentCards] = useState([]);

  //! DOWNLOAD INSTRUCTIONS ////////////////////////////////////////////
  const handleDownloadInstructions = async () => {
    try {
      setInstructionDownloading(
        selectedRow?.selected_documents?.[leftValue]?.name +
          "inst" +
          selectedRow?.task_id
      );
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-instructions`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
        },
        {
          withCredentials: true,
          responseType: "blob",
        }
      );
      // Create a blob URL for the downloaded file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      // Extract the filename from the response headers if available, or default to 'download.zip'
      let fileNameRaw =
        selectedRow?.project_name + "-instructions" || "download-instructions";
      let fileName =
        fileNameRaw
          .toLowerCase()
          .replace(/\s+/g, "_")
          .replace(/[^\w\-]+/g, "") + ".zip";

      link.setAttribute("download", fileName);

      // Append the link to the body
      document.body.appendChild(link);

      // Simulate a click to download the file
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    } catch (error) {
    } finally {
      setInstructionDownloading(false);
    }
  };

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handlePayment = async () => {
    try {
      setPaymentLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/process-addition-payment`,
        {
          paymentCard: paymentCards[activeStep]?.id,
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          addition: currentPopup,
        },
        { withCredentials: true }
      );

      if (resp.data.success === false && resp.data.paid === true) {
        await handleDownloadNoc(
          selectedRow,
          selectedRow?.selected_documents?.[leftValue]?.name
        );
        setPaymentModalOpen(false);
        setPromoModalOpen(false);
        return;
      }

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE); // Replace with your actual publishable key
      const result = await stripe.confirmCardPayment(resp.data.data);
      if (result.error) {
        setPaymentErrorText(result.error.message);
        // Handle the error on the frontend
      } else {
        // The payment has been processed!
        if (
          result.paymentIntent.status === "succeeded" &&
          resp.data.valid === true
        ) {
          const paymentIntent = result.paymentIntent;
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/finish-addition-payment`,
            {
              paymentIntent,
              task_id: selectedRow?.task_id,
              currentSession: currentSession,
              addition: currentPopup,
              currentForm: selectedRow?.selected_documents?.[leftValue]?.name,
            },
            { withCredentials: true }
          );
          if (resp?.data?.success) {
            await dispatch(updatePermitPackage(resp?.data?.data));

            if (currentPopup === "downloadPayment") {
              await dispatch(updateAnalytics(resp?.data?.analytics));
              await handleDownloadNoc(
                selectedRow,
                selectedRow?.selected_documents?.[leftValue]?.name
              );
            }

            setSnackOpen(true);
            setPromoModalOpen(false);
            setPaymentModalOpen(false);
          }
        }
      }
    } catch (e) {
    } finally {
      setPaymentLoading(false);
    }
  };

  const handleDownloadNoc = async (
    selectedRow,
    type,
    selectedFormat,
    selectedSize
  ) => {
    try {
      const downloadLimit = currentUser?.tier?.downloads;
      const totalDownloads = currentUser?.user?.analytics?.ppDownloaded;
      const PPackageDownloaded = selectedRow?.download_info?.downloaded;

      if (
        !selectedRow?.pp_notarized &&
        !selectedRow?.noc_notarized &&
        !selectedRow?.notarized
      ) {
        if (downloadLimit !== "Unlimited" && PPackageDownloaded === false) {
          if (parseInt(totalDownloads) >= parseInt(downloadLimit)) {
            setCurrentPopup("downloadPayment");
            setPromoModalOpen(true);
            return;
          }
        }
      }

      const currentDate = DateTime.now().toFormat("yyyy-MM-dd");

      // Set downloading state based on whether it's "all" or a single document
      if (type === "all") {
        setPPDownloading(true);
      } else {
        setDownloading(
          selectedRow?.selected_documents?.[leftValue]?.name +
            selectedRow?.task_id
        );
      }

      // Determine the download target: either "all" or the specific document's URL
      const downloadPDF =
        type === "all" ? "all" : selectedRow?.documents?.[creatingDoc]?.pdfUrl;
      const pdfName =
        type === "all"
          ? `notarized_permit_package_${currentDate}.zip` // Change to .zip if downloading all documents
          : selectedRow?.documents?.[creatingDoc]?.pdfName ||
            `permit_document_${creatingDoc}_${currentDate}.pdf`;

      //! PRE-SAVE DATA
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/pre-save-downloaded-record`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          currentForm: selectedRow?.selected_documents?.[leftValue]?.name,
        },
        { withCredentials: true }
      );

      if (
        resp?.data?.success === false &&
        resp?.data?.message === "payment_required"
      ) {
        setCurrentPopup("downloadPayment");
        setPromoModalOpen(true);
        return;
      }

      if (resp?.data?.success) {
        if (resp?.data?.data) {
          await dispatch(updatePermitPackage(resp?.data?.data));
        }

        if (resp?.data?.analytics) {
          await dispatch(updateAnalytics(resp?.data?.analytics));
        }
      }

      // !!!!
      // Perform the download request
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/download-created-file`,
        {
          pdf: downloadPDF, // "all" or specific document
          currentSession: currentSession,
          type: type === "all" ? "all" : creatingDoc, // Ensure type is passed correctly
          task_id: selectedRow?.task_id,
          addition: currentPopup,
          currentForm: selectedRow?.selected_documents?.[leftValue]?.name,
          selectedFormat: selectedFormat,
          selectedSize: selectedSize,
        },
        {
          withCredentials: true,
          responseType: "blob", // Expect binary data in response
        }
      );

      const contentType = response.headers["content-type"];

      // Determine file extension based on content type
      let fileExtension = "pdf"; // Default to PDF
      if (contentType === "image/png") {
        fileExtension = "png";
      } else if (contentType === "image/jpeg") {
        fileExtension = "jpg";
      } else if (contentType === "application/zip") {
        fileExtension = "zip";
      }

      const fileName = `${pdfName}.${fileExtension}`;

      // Create a URL for the binary response (PDF or ZIP)
      const fileURL = window.URL.createObjectURL(
        new Blob([response.data], { type: contentType })
      );
      // Create an anchor element to trigger the download
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", fileName); // Set the correct file name (PDF or ZIP)
      document.body.appendChild(fileLink);
      fileLink.click();

      // Clean up after download
      document.body.removeChild(fileLink);
      window.URL.revokeObjectURL(fileURL);
    } catch (e) {
    } finally {
      if (type === "all") {
        setPPDownloading(false); // Reset state after downloading all documents
      } else {
        setDownloading(false); // Reset state after single document download
      }
    }
  };

  useEffect(() => {
    // if (selectedRow?.permit_package_created) {
    let ownerNotarizationNeeded = false;
    let ownerSigningNeeded = false;
    let contractorNotarizationNeeded = false;
    let contractorSigningNeeded = false;

    let selected_documents = [];

    if (docNotaryTypeValue === 0) {
      selected_documents = selectedRow?.selected_documents || [];
    } else if (docNotaryTypeValue === 1) {
      selected_documents =
        selectedRow?.selected_documents?.filter(
          (doc) => doc.name === "noc" || doc.name === "noc_affidavit"
        ) || [];
    } else if (docNotaryTypeValue === 2) {
      selected_documents =
        selectedRow?.selected_documents?.filter((doc) => doc.name !== "noc") ||
        [];
    }

    selected_documents?.forEach((doc) => {
      if (doc.signatures.owner.sign) {
        ownerSigningNeeded = true;
      }
      if (doc.signatures.owner.notary) {
        ownerNotarizationNeeded = true;
      }
      if (doc.signatures.contractor.sign) {
        contractorSigningNeeded = true;
      }
      if (doc.signatures.contractor.notary) {
        contractorNotarizationNeeded = true;
      }
    });

    // Check if the new values differ from the current state before updating
    if (
      ownerSigning.notarizationNeeded !== ownerNotarizationNeeded ||
      ownerSigning.signingNeeded !== ownerSigningNeeded
    ) {
      setOwnerSigning({
        notarizationNeeded: ownerNotarizationNeeded,
        signingNeeded: ownerSigningNeeded,
      });
    }

    if (
      contractorSigning.notarizationNeeded !== contractorNotarizationNeeded ||
      contractorSigning.signingNeeded !== contractorSigningNeeded
    ) {
      setContractorSigning({
        notarizationNeeded: contractorNotarizationNeeded,
        signingNeeded: contractorSigningNeeded,
      });
    }
    // }
  }, [selectedRow, ownerSigning, contractorSigning, docNotaryTypeValue]);

  useEffect(() => {
    if (selectedRow) {
      const currentDoc = selectedRow?.selected_documents?.[leftValue];
      if (creatingDoc !== currentDoc?.name) {
        setCreatingDoc(currentDoc?.name);
      }
    }
  }, [leftValue, selectedRow]);

  useEffect(() => {
    if (selectedRow) {
      const createdCount = selectedRow?.selected_documents?.filter(
        (doc) => doc.created === true
      ).length;

      if (numCreated !== createdCount) {
        setNumCreated(createdCount);
      }
    }
  }, [selectedRow]);

  const handlePermitDrawerClose = () => {
    setPermitDrawerOpen(false);
  };

  const handleCreate = useCallback(() => {
    // Prevent creating if it's open
    if (isOpen) {
      return;
    }
    setPermitDrawerOpen(true);
  }, [isOpen, setPermitDrawerOpen]);

  useEffect(() => {
    if (selectedRow?.documents && permitDrawerOpen) {
      const currentDoc = selectedRow?.selected_documents?.[leftValue];
      const documentsDoc = selectedRow?.documents?.[creatingDoc];

      if (creatingDocTitle !== currentDoc?.label) {
        setCreatingDocTitle(currentDoc?.label);
      }

      if (!documentsDoc?.is_created) {
        if (
          creatingDoc === "pais" &&
          documentsDoc?.signed &&
          documentsDoc?.signed?.length > 0
        ) {
          if (documentImage !== documentsDoc?.signed) {
            setDocumentImage(documentsDoc?.signed);
          }
        } else {
          if (documentImage !== currentDoc?.img) {
            setDocumentImage(currentDoc?.img);
          }
        }
      } else {
        if (documentImage !== documentsDoc?.signed) {
          setDocumentImage(documentsDoc?.signed);
        }
      }
    }
  }, [selectedRow?.documents, permitDrawerOpen, leftValue]);

  useEffect(() => {
    if (currentUser?.payment_cards?.length > 0) {
      setPaymentCards(
        currentUser?.payment_cards?.map((card) => {
          const lastTwo = String(card.exp_year).slice(-2);
          const first = card.exp_month;

          return {
            id: card.id,
            cardNum: "**** **** **** " + card.last4,
            cardName: card.name ? card.name : card.card_holder,
            expires:
              (first < 10 ? "0" + String(first) : String(first)) +
              "/" +
              lastTwo,
            default: card.default,
          };
        })
      );
    } else {
      if (paymentCards.length > 0) {
        setPaymentCards([]);
      }
    }
  }, [currentUser?.payment_cards]);

  useEffect(() => {
    const fetchSpecial = async () => {
      if (notifications?.forwardNotifications?.packageId !== null) {
        setActiveTab(0);
        setDataLoading(true);
        await dispatch(getSavedProjects());
        const result = await dispatch(
          getPermitPackages(
            page,
            pageSize,
            0,
            notifications?.forwardNotifications
          )
        );

        dispatch(clearForwardNotification());

        const selectedPackageId =
          notifications?.forwardNotifications?.packageId;

        const foundRow = result?.documents?.find(
          (doc) => doc.id === selectedPackageId
        );

        setRowCount(result?.total_count);
        setPage(result?.current_page);

        if (foundRow) {
          setSelectionModel([foundRow?.id]);
          setGridKey((prevKey) => prevKey + 1);
          setSelectedRow(foundRow); // Set the matched row
        }

        setLoading(false);
        setCreatingDoc("noc");
        setCreatingDocTitle("Notice of Commencement");
        setDataLoading(false);
      }
    };
    fetchSpecial();
  }, [notifications?.forwardNotifications]);

  useEffect(() => {
    if (newTempPermitData && !newPermitOpened) {
      const newRow = newTempPermitData;

      if (!isOpen) {
        setNewPermitOpened(true);
        setSelectedRow(newRow);
        setCreatingDoc("noc");
        setCreatingDocTitle("Notice of Commencement");
        setDocumentImage(newRow?.selected_documents?.[0]?.img);
        setPermitDrawerOpen(true);
      }

      dispatch(clearNewTempPermitData());
    }

    const fetchData = async () => {
      setDataLoading(true);
      await dispatch(getSavedProjects());
      const result = await dispatch(
        getPermitPackages(page, pageSize, activeTab)
      );

      setRowCount(result);
      setLoading(false);
      setCreatingDoc("noc");
      setCreatingDocTitle("Notice of Commencement");
      setDataLoading(false);
    };

    if (notifications?.forwardNotifications?.packageId === null) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const correctStep = currentSession === "subUserSession" ? 20 : 30;
    const nextStep = currentSession === "subUserSession" ? 10 : 12;
    // 10 / 12
    if (
      isOpen &&
      currentStep === correctStep &&
      permitPackages.length > 0 &&
      !loading &&
      !dataLoading
    ) {
      setTimeout(() => {
        setCurrentStep((s) => nextStep);
      }, 200);
    }
  }, [permitPackages, currentStep, isOpen, loading, dataLoading]);

  useEffect(() => {
    const correctStep = currentSession === "userSession" ? 21 : 21;

    if (
      isOpen &&
      currentStep === correctStep &&
      permitPackages.length > 0 &&
      !loading &&
      !dataLoading
    ) {
      setSelectedRow(permitPackages[0]);
      setTimeout(() => {
        setRightValue(5);
      }, 200);
    }
  }, [permitPackages, currentStep, isOpen, loading, dataLoading]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.expired === true) {
        navigate("/subscription");
      }
    }
  }, [currentUser]);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            px: "1.5rem",
            py: "1.5rem",
          }}
        >
          <Box sx={{ maxWidth: "1920px", width: "100%", height: "100%" }}>
            <Box
              sx={{
                borderRadius: "1.5rem",
                boxShadow: "2px 3px 5px #d3d3d3",
                minHeight: "calc(100vh - 210px)",
                backgroundColor: "#fff",
                // p: 2,
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <MyPermitGrid
                  permitPackages={permitPackages}
                  ppDownloading={ppDownloading}
                  downloading={downloading}
                  page={page}
                  setPage={setPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  rowCount={rowCount}
                  setRowCount={setRowCount}
                  dataLoading={dataLoading}
                  setDataLoading={setDataLoading}
                  currentUser={currentUser}
                  // permitPackageColumn={createdFormColumns}
                  dispatch={dispatch}
                  getPermitPackages={getPermitPackages}
                  selectedRow={selectedRow}
                  setSelectedRow={setSelectedRow}
                  setCreatingDoc={setCreatingDoc}
                  setCreatingDocTitle={setCreatingDocTitle}
                  leftValue={leftValue}
                  setLeftValue={setLeftValue}
                  documentImage={documentImage}
                  setDocumentImage={setDocumentImage}
                  handleCreate={handleCreate}
                  tempLoading={tempLoading}
                  setTempLoading={setTempLoading}
                  handleDownloadNoc={handleDownloadNoc}
                  numCreated={numCreated}
                  permitDrawerOpen={permitDrawerOpen}
                  creatingDoc={creatingDoc}
                  cancelLoading={cancelLoading}
                  setCancelLoading={setCancelLoading}
                  currentSession={currentSession}
                  handleDownloadInstructions={handleDownloadInstructions}
                  instructionDownloading={instructionDownloading}
                  rightValue={rightValue}
                  setRightValue={setRightValue}
                  paymentCards={paymentCards}
                  contractorSigning={contractorSigning}
                  ownerSigning={ownerSigning}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  createLoading={createLoading}
                  setCreateLoading={setCreateLoading}
                  docNotaryTypeValue={docNotaryTypeValue}
                  setDocNotaryTypeValue={setDocNotaryTypeValue}
                  handlePackageTypeChange={handlePackageTypeChange}
                  activeTabLoading={activeTabLoading}
                  currentNocStatus={currentNocStatus}
                  gridKey={gridKey}
                  selectionModel={selectionModel}
                  setSelectionModel={setSelectionModel}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Drawer
        // sx={{ zIndex: 1000 }}
        PaperProps={{
          sx: {
            width: "100vw",
          },
        }}
        anchor="right"
        open={permitDrawerOpen}
        onClose={handlePermitDrawerClose}
      >
        <OpenDocuments
          usingTemplate={usingTemplate}
          setUsingTemplate={setUsingTemplate}
          designProList={currentUser?.design_professionals}
          savedProjects={savedProjects}
          creatingDocTitle={creatingDocTitle}
          setCreatingDocTitle={setCreatingDocTitle}
          creatingDoc={creatingDoc}
          setCreatingDoc={setCreatingDoc}
          setViewingDoc={setViewingDoc}
          viewingDoc={viewingDoc}
          location={location}
          selectedRow={selectedRow}
          unavailableRecord={unavailableRecord}
          permitDrawerOpen={permitDrawerOpen}
          setPermitDrawerOpen={setPermitDrawerOpen}
          serviceTiers={currentUser?.service_tiers}
          plan={currentUser?.tier}
          documentImage={documentImage}
          setDocumentImage={setDocumentImage}
          leftValue={leftValue}
          setLeftValue={setLeftValue}
          setTempLoading={setTempLoading}
          paymentCards={paymentCards}
          rightValue={rightValue}
          setRightValue={setRightValue}
          handleDownloadNoc={handleDownloadNoc}
          currentPopup={currentPopup}
          setCurrentPopup={setCurrentPopup}
          promoModalOpen={promoModalOpen}
          setPromoModalOpen={setPromoModalOpen}
          setCreateLoading={setCreateLoading}
        />
      </Drawer>
      <PromoModal
        open={promoModalOpen}
        setOpen={setPromoModalOpen}
        selectedRow={selectedRow}
        currentSession={currentSession}
        setPaymentModalOpen={setPaymentModalOpen}
        currentPopup={currentPopup}
        setCurrentPopup={setCurrentPopup}
        currentUser={currentUser}
        setPermitDrawerOpen={setPermitDrawerOpen}
      />
      <DemoPopPaymentModal
        open={paymentModalOpen}
        setOpen={setPaymentModalOpen}
        paymentCards={paymentCards}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        handlePayment={handlePayment}
        paymentLoading={paymentLoading}
        title={
          currentPopup === "downloadPayment"
            ? "Purchase Permit Package Download"
            : currentPopup === "vip"
            ? "Purchase VIP Package"
            : currentPopup === "nocPaymentPop"
            ? "Purchase Notice of Commencement Form"
            : "Purchase Permit Package"
        }
        currentPopup={currentPopup}
        setCurrentPopup={setCurrentPopup}
        currentUser={currentUser}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
      />
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Payment Successful. Thank you
        </Alert>
      </Snackbar>

      <AddPaymentCardModal
        addPaymentModalOpen={addPaymentModalOpen}
        setAddPaymentModalOpen={setAddPaymentModalOpen}
        stripe={stripe}
        elements={elements}
        CardElement={CardElement}
      />
    </>
  );
};

export default MyPermits;
