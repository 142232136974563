import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  CardHeader,
  CardContent,
  useMediaQuery,
  Typography,
  Box,
  Grid2 as Grid,
  Button,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { green, grey } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useCallback, useEffect, useState } from "react";
import ContractorTab from "./permitPackageTabs/ContractorTab";
import OwnerTab from "./permitPackageTabs/OwnerTab";
import NotarizationTab from "./permitPackageTabs/NotarizationTab";
import RecordingTab from "./permitPackageTabs/RecordingTab";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import PermitSettings from "./permitPackageTabs/PermitSettings";
import {
  setPrevTourStep,
  updatePermitPackage,
  updatePermitPackageName,
} from "../../store/actions/dataActions";
import CitySiteTab from "./permitPackageTabs/CitySiteTab";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../../data/walkthroughDemo/steps";
import { packageColumns } from "./permitPackageTabs/permitTypeColumns";
import {
  noRecordTitle,
  packageRowDetailsEmptyTitle,
} from "../../data/mappings/permitPackageMappings";
import { updateAllNotifications } from "../../store/actions/notificationsActions";
import SelectDownloadFileSIzeModal from "../modals/SelectDownloadFileSIzeModal";

const MyPermitGrid = (props) => {
  const dispatch = useDispatch();

  const [isFading, setIsFading] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [cancelPermitComment, setCancelPermitComment] = useState("");
  const noc_data = useSelector((state) => state.nocData.nocData);
  const notifications = useSelector((state) => state.notifications);
  const [fileSizeModalOpen, setFileSizeModalOpen] = useState(false);

  const [editObj, setEditObj] = useState({
    nameError: "",
    nameRespText: "",
    packageName: "",
  });
  const [editPNameOpen, setEditPNameOpen] = useState(false);
  const [recordLoading, setRecordLoading] = useState(false);
  const [currentLeftNotarized, setCurrentLeftNotarized] = useState(false);
  const [currentLeftInProgress, setCurrentLeftInProgress] = useState(false);

  const { isOpen, currentStep, setCurrentStep } = useTour();

  const lg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    const notarized =
      props?.selectedRow?.notarized ||
      (props?.leftValue === 0 && props?.selectedRow?.noc_notarized === true) ||
      (props?.leftValue === 1 &&
        props?.selectedRow?.noc_notarized === true &&
        props?.selectedRow?.documents?.noc_affidavit?.is_created === true) ||
      (props?.leftValue >= 1 && props?.selectedRow?.pp_notarized === true)
        ? true
        : false;

    if (notarized !== currentLeftNotarized) {
      setCurrentLeftNotarized(notarized);
    }

    const notarizingDocs =
      props?.selectedRow?.processing_permit?.notarization?.notarizing_docs;
    const finalized =
      props?.selectedRow?.processing_permit?.notarization?.finalized;
    const started =
      props?.selectedRow?.processing_permit?.notarization?.started;

    const inProgress =
      notarizingDocs === 0 && started === true && !finalized
        ? true
        : (notarizingDocs === 1 &&
            props?.leftValue === 0 &&
            started === true &&
            !finalized) ||
          (notarizingDocs === 1 &&
            props?.selectedRow?.selected_documents?.[props?.leftValue]?.name ===
              "noc_affidavit" &&
            started === true &&
            !finalized)
        ? true
        : notarizingDocs === 2 &&
          props?.leftValue !== 0 &&
          started === true &&
          !finalized
        ? true
        : false;

    const currentProgress = !notarized && inProgress ? true : false;

    if (currentLeftInProgress !== currentProgress) {
      setCurrentLeftInProgress(currentProgress);
    }
  }, [props?.leftValue, props?.selectedRow]);

  const snackHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const handleRightChange = (event, newValue) => {
    if (isOpen) {
      return;
    }
    setIsFading(true);
    setTimeout(() => {
      if (props?.rightValue !== newValue) {
        props?.setRightValue(newValue);
        setIsFading(false);
      }
    }, 300); // Duration should match the CSS transition duration
  };

  const handleLeftChange = (event, newValue) => {
    if (props?.leftValue !== newValue) {
      if (props?.selectedRow?.selected_documents?.length === 1) {
        props?.setLeftValue(0);
      } else {
        props?.setLeftValue(newValue);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      const stepForRight = props?.currentSession === "userSession" ? 15 : 13;

      if (currentStep === stepForRight && props?.rightValue !== 0) {
        props?.setRightValue(0);
      }
    }
  }, [props?.rightValue, currentStep]);

  const handleEditPackageName = useCallback(async () => {
    try {
      props?.setCancelLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/edit-package-name`,
        {
          task_id: props?.selectedRow?.task_id,
          packageName: editObj?.packageName,
          currentSession: props?.currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        setEditObj((prev) => ({
          ...prev,
          nameRespText: resp?.data?.message,
        }));
        dispatch(
          updatePermitPackageName({
            task_id: props?.selectedRow?.task_id,
            package_name: editObj?.packageName,
          })
        );
        setEditPNameOpen(false);
        setSnackOpen(true);
      }
    } catch (error) {
    } finally {
      props?.setCancelLoading(false);
    }
  }, [props, editObj, dispatch]);

  const handlePaginationModelChange = (model, details) => {
    const newPage = model.page;
    const newRowsPerPage = model.pageSize;
    props?.setPage(newPage);
    props?.setPageSize(newRowsPerPage);
    fetchData(newPage, newRowsPerPage);
  };

  const handleCancelPermit = useCallback(async () => {
    try {
      props?.setCancelLoading(true);
      const voidPermit = props?.selectedRow?.city_scraper?.permit_id;

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/cancel-permit-package`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
          voidPermit: voidPermit,
          cancelPermitComment: cancelPermitComment,
          notifications: {
            noc: {
              curr_page: notifications?.noc?.pagination?.page,
            },
            package: {
              curr_page: notifications?.package?.pagination?.page,
            },
            requests: {
              curr_page: notifications?.requests?.pagination?.page,
            },
          },
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        props?.setSelectedRow(null);
        handlePaginationModelChange(
          { page: props?.page, pageSize: props?.pageSize },
          "detail"
        );

        props?.setRowCount((prevRowCount) => prevRowCount - 1);

        await dispatch(updateAllNotifications(resp?.data?.notifications));
      }
    } catch (error) {
    } finally {
      props?.setCancelLoading(false);
    }
  }, [
    props.setCancelLoading,
    props.selectedRow,
    props.currentSession,
    cancelPermitComment,
    props.setSelectedRow,
    handlePaginationModelChange,
    props.setRowCount,
    props.page,
    props.pageSize,
  ]);

  const fetchData = async (newPage, newRowsPerPage) => {
    props?.setDataLoading(true);
    await props?.dispatch(
      props?.getPermitPackages(newPage, newRowsPerPage, props?.activeTab)
    );
    props?.setDataLoading(false);
  };

  const handleStartRecord = async () => {
    try {
      setRecordLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/start-manual-recording`,
        {
          task_id: props?.selectedRow?.task_id,
          currentSession: props?.currentSession,
        },
        {
          withCredentials: true,
        }
      );
      if (resp?.data?.success) {
        await dispatch(updatePermitPackage(resp?.data?.data));
      }
    } catch (error) {
    } finally {
      setRecordLoading(false);
    }
  };

  const handleRowClick = useCallback(
    (params, event, details) => {
      if (props?.cancelLoading) {
        return;
      }
      const row = params.row;

      if (isOpen) {
        dispatch(setPrevTourStep(currentStep));
        setTimeout(() => {
          props?.setSelectedRow(params.row);
          setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
        }, 200);
      }

      // Only update selectedRow if it's different from the current one
      if (props?.selectedRow?.id === row?.id) {
        return;
      }

      props?.setTempLoading(false);
      const newLeftValue = row?.selected_documents?.length - 1;

      if (props?.selectedRow?.selected_documents?.length - 1 > newLeftValue) {
        handleLeftChange(null, newLeftValue);
      } else if (
        row?.selected_documents?.length < props?.leftValue + 1 &&
        newLeftValue !== props?.leftValue
      ) {
        handleLeftChange(null, newLeftValue);
      }

      if (isOpen) {
        props?.setRightValue(0);
      } else if (
        props?.activeTab !== 1 &&
        !row?.assigned_package?.is_assigned
      ) {
        const newRightValue =
          (row?.permit_package_created ||
            (row?.noc_created && row?.selected_documents?.length === 1)) &&
          !row?.notarized
            ? 2
            : row?.permit_package_created && row?.notarized && !row?.recorded
            ? 3
            : row?.notarized &&
              !row?.recorded &&
              (!row?.processing_permit?.recording?.is_recorded ||
                row?.processing_permit?.recording?.recording_failed)
            ? 3
            : 0;

        if (newRightValue !== props?.rightValue) {
          props?.setRightValue(newRightValue);
        }
      } else {
        if (props?.rightValue !== 0) {
          props?.setRightValue(0);
        }
      }

      // Only update selectedRow if it's different from the current one
      if (props?.selectedRow?.id !== row?.id && !isOpen) {
        props?.setSelectedRow(row);
      }
    },
    [props, isOpen, currentStep, dispatch, setCurrentStep]
  );

  useEffect(() => {
    if (props?.selectedRow) {
      // Set tempLoading to false if necessary
      if (props?.tempLoading !== false) {
        props?.setTempLoading(false);
      }

      // Update selectedRow only if necessary
      const updatedRow = props?.permitPackages.find(
        (item) => item.task_id === props?.selectedRow.task_id
      );
      if (updatedRow && updatedRow !== props?.selectedRow) {
        props?.setSelectedRow(updatedRow);
      }
    }
  }, [props?.permitPackages, props?.selectedRow, props?.tempLoading]);

  useEffect(() => {
    if (props?.selectedRow && !props?.permitDrawerOpen) {
      // If permit_package_created is true, ensure leftValue is 0
      if (props?.selectedRow?.permit_package_created) {
        if (props?.selectedValue !== 0) {
          if (props?.leftValue !== 0) {
            props?.setLeftValue(0);
          }
        }
      } else {
        const selectedDocs = props?.selectedRow.selected_documents;
        let lastCreatedIndex = 0;

        for (let i = 0; i < selectedDocs.length; i++) {
          const doc = selectedDocs[i];
          if (doc?.created) {
            lastCreatedIndex = i + 1;
          } else {
            break;
          }
        }

        // Only set leftValue if there’s more than one document
        if (selectedDocs.length > 1 && props?.leftValue !== lastCreatedIndex) {
          if (lastCreatedIndex > selectedDocs.length - 1) {
            handleLeftChange(null, lastCreatedIndex - 1);
          } else {
            handleLeftChange(null, lastCreatedIndex);
          }

          // props?.setLeftValue(lastCreatedIndex);
        } else if (selectedDocs.length === 1) {
          props?.setLeftValue(0);
        }
      }
    }
  }, [props.selectedRow]);

  // Function to determine which component to render
  const getRightTabComponent = () => {
    if (
      props?.activeTab === 1 &&
      props?.selectedRow?.assigned_package?.is_assigned
    ) {
      // Render based on rightValue when activeTab is 1 and assigned_package is assigned
      switch (props?.rightValue) {
        case 0:
          return <OwnerTab {...props} />;
        case 1:
          return <ContractorTab {...props} />;
        default:
          return <OwnerTab {...props} />; // Default to OwnerTab if rightValue is unexpected
      }
    } else {
      // Render based on rightValue when activeTab is not 1 or assigned_package is not assigned
      switch (props?.rightValue) {
        case 0:
          return <OwnerTab {...props} />;
        case 1:
          return <ContractorTab {...props} />;
        case 2:
          return <NotarizationTab {...props} />;
        case 3:
          return (
            <RecordingTab
              {...props}
              recordLoading={recordLoading}
              handleStartRecord={handleStartRecord}
            />
          );
        case 4:
          return <CitySiteTab {...props} />;
        case 5:
          return (
            <PermitSettings
              {...props}
              handleCancelPermit={handleCancelPermit}
              handleEditPackageName={handleEditPackageName}
              editObj={editObj}
              setEditObj={setEditObj}
              editPNameOpen={editPNameOpen}
              setEditPNameOpen={setEditPNameOpen}
              cancelPermitComment={cancelPermitComment}
              setCancelPermitComment={setCancelPermitComment}
            />
          );
        default:
          return <OwnerTab {...props} />; // Default to OwnerTab if rightValue is unexpected
      }
    }
  };
  return (
    <>
      <Card sx={{ position: "relative", mb: 0, pb: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            title={`${" \u00A0\u00A0"}P E R M I T${" \u00A0\u00A0"}P A C K A G E S`}
            sx={{ flexGrow: 1, pl: 1, pr: 1 }} // This ensures the title takes up available space
          />
          <Box mr={1}>
            <LoadingButton
              disabled={
                (Boolean(props?.activeTabLoading) &&
                  props?.activeTabLoading === 1) ||
                props?.activeTabLoading === 2
              }
              loading={props?.activeTabLoading === 0}
              variant={props?.activeTab === 0 ? "contained" : "outlined"}
              size="small"
              sx={{ mr: 0.5 }}
              onClick={() => props?.handlePackageTypeChange(0)}
            >
              Active Packages
            </LoadingButton>
            {!props?.currentUser?.tier?.monthly_restart && (
              <LoadingButton
                disabled={
                  (Boolean(props?.activeTabLoading) &&
                    props?.activeTabLoading === 0) ||
                  props?.activeTabLoading === 2
                }
                loading={props?.activeTabLoading === 1}
                size="small"
                variant={props?.activeTab === 1 ? "contained" : "outlined"}
                sx={{ mr: 0.5 }}
                onClick={() => props?.handlePackageTypeChange(1)}
              >
                Packages Sent to Contractors
              </LoadingButton>
            )}

            <LoadingButton
              disabled={
                (Boolean(props?.activeTabLoading) &&
                  props?.activeTabLoading === 0) ||
                props?.activeTabLoading === 1
              }
              loading={props?.activeTabLoading === 2}
              size="small"
              variant={props?.activeTab === 2 ? "contained" : "outlined"}
              onClick={() => props?.handlePackageTypeChange(2)}
            >
              Archived Packages
            </LoadingButton>
          </Box>
        </Box>

        <CardContent className="bottomCard-6" sx={{ p: 0, height: "72vh" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "auto",
            }}
          >
            <Box
              className="step13"
              sx={{
                flex: 0.8,
                minHeight: 0,
                overflow: "auto",
                borderBottom: "5px solid #d3d3d3",
              }}
            >
              {props?.permitPackages?.length === 0 ? (
                <Typography mt={2} align="center" color="primary" variant="h6">
                  {noRecordTitle[props?.activeTab]}
                </Typography>
              ) : (
                <DataGrid
                  key={props?.gridKey}
                  selectionModel={props?.selectionModel}
                  onSelectionModelChange={(newSelectionModel) => {
                    props?.setSelectionModel(newSelectionModel);
                  }}
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      "&:hover": {
                        ".MuiDataGrid-menuIcon, .MuiDataGrid-sortIcon, .MuiDataGrid-columnHeader--sorted":
                          {
                            visibility: "hidden",
                            pointerEvents: "none",
                          },
                        cursor: "default",
                      },
                    },
                    // Additionally target specific sort button
                    "& .MuiDataGrid-columnHeader--sortable": {
                      pointerEvents: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      padding: 0, // Adjust as needed
                      margin: 0, // Adjust as needed
                    },
                    "& .MuiDataGrid-columnHeadersInner": {
                      width: "100%", // Adjust as needed
                      padding: 0,
                    },
                    border: "1px solid #FCF2F0", // Change the border color of the entire DataGrid
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#333333", // Change column header background color
                      color: "white", // Optional: Change text color for contrast
                    },
                    "& .MuiDataGrid-cell": {
                      borderColor: "#333333", // Change cell border color
                    },
                    "& .MuiDataGrid-columnSeparator": {
                      display: "none", // Optional: Hide the column separators
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-columnHeader:focus-within": {
                      outline: "none !important",
                    },
                    "& .MuiDataGrid-row": {
                      cursor: "pointer", // Change cursor to pointer for rows
                      // backgroundColor: "#fff4c0 !important",
                    },
                    // Apply light yellow background for specified rows
                    "& .light-yellow-row": {
                      backgroundColor: "#fff4c0",
                    },
                    "& .selected-row-color": {
                      backgroundColor: "#ebf1f4 !important",
                    },
                    // Apply darker yellow only for selected rows that are light-yellow
                    "& .light-yellow-row.Mui-selected": {
                      backgroundColor: "#ffe499 !important", // Slightly darker yellow
                    },
                  }}
                  rows={props?.permitPackages || []}
                  columns={packageColumns[props?.activeTab]}
                  getRowId={(row) => row?.id}
                  onPaginationModelChange={(model, details) => {
                    handlePaginationModelChange(model, details);
                  }}
                  loading={props?.dataLoading}
                  paginationMode="server"
                  paginationModel={{
                    page: props?.page, // Set the current page value here
                    pageSize: props?.pageSize, // Set the page size value here
                  }}
                  getRowClassName={(params) =>
                    params.row?.assigned_package?.need_assign === true
                      ? "light-yellow-row"
                      : params?.row?.id === props?.selectedRow?.id
                      ? "selected-row-color"
                      : ""
                  }
                  rowCount={props?.rowCount}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  onRowClick={(params, event, details) => {
                    handleRowClick(params, event, details);
                  }}
                  pageSizeOptions={[5]}
                  rowHeight={34}
                />
              )}
            </Box>
            <Box
              sx={{
                flex: 1.05,
                minHeight: 0,
                overflow: "auto",
              }}
            >
              {!props?.selectedRow ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{ textAlign: "center", px: 2 }}
                    variant="h5"
                    color="primary"
                  >
                    {props?.permitPackages?.length > 0
                      ? packageRowDetailsEmptyTitle[props?.activeTab]
                      : ""}
                  </Typography>
                </Box>
              ) : (
                <Grid container sx={{ width: "100%", height: "100%" }}>
                  <Grid size={{ sm: 8 }} sx={{ width: "100%" }}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontStyle: "italic",
                      }}
                      align="center"
                      mt={0.2}
                    >
                      Permit Package Documents
                      <span
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          marginLeft: "6px",
                        }}
                      >
                        Created {props?.numCreated}/
                        {props?.selectedRow?.selected_documents.length}
                      </span>
                    </Typography>

                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      {props?.selectedRow?.notarized ||
                      props?.selectedRow?.pp_notarized ? (
                        <LoadingButton
                          sx={{ p: 0 }}
                          endIcon={<DownloadIcon />}
                          size="small"
                          loading={props?.ppDownloading}
                          onClick={() =>
                            props?.handleDownloadNoc(props?.selectedRow, "all")
                          }
                        >
                          Download Permit Package
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          disabled
                          sx={{ p: 0 }}
                          endIcon={<DownloadIcon />}
                          size="small"
                          loading={props?.ppDownloading}
                          onClick={() => {}}
                        >
                          Download Permit Package
                        </LoadingButton>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        minHeight: "86%",
                        maxHeight: "20rem",
                        mt: 0,
                        borderTop: "4px solid #d3d3d3",
                      }}
                    >
                      <Box
                        className="step14"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Tabs
                          orientation="vertical"
                          value={props?.leftValue}
                          onChange={handleLeftChange}
                          variant="scrollable"
                          scrollButtons="auto"
                          allowScrollButtonsMobile
                          aria-label="scrollable auto tabs example"
                          TabIndicatorProps={{
                            sx: {
                              left: 0, // Position the indicator on the left side
                              width: "3px", // Adjust the width of the indicator
                            },
                          }}
                          sx={{
                            alignItems: "flex-start", // Align the tabs to the left
                          }}
                        >
                          {props?.selectedRow?.selected_documents?.map(
                            (document, index) => {
                              const isEnabled =
                                props?.selectedRow?.permit_package_created ||
                                document?.created ||
                                (props?.selectedRow?.selected_documents[
                                  index - 1
                                ]?.created &&
                                  !document?.created);

                              return (
                                <Tab
                                  disabled={!isEnabled}
                                  key={index}
                                  label={
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "flex-start",
                                        textAlign: "left",
                                      }}
                                    >
                                      {document.label}
                                      {props?.selectedRow?.selected_documents?.[
                                        index
                                      ]?.created &&
                                      props?.selectedRow?.selected_documents?.[
                                        index
                                      ]?.name === "noc" &&
                                      (props?.selectedRow?.notarized === true ||
                                        props?.selectedRow?.noc_notarized ===
                                          true) ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: green[500],
                                            marginLeft: 8,
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : props?.selectedRow
                                          ?.selected_documents?.[index]
                                          ?.name !== "noc" &&
                                        (props?.selectedRow?.notarized ===
                                          true ||
                                          props?.selectedRow?.pp_notarized ===
                                            true) ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: green[500],
                                            marginLeft: 8,
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : props?.selectedRow?.processing_permit?.notarization?.final_documents?.find(
                                          (doc) =>
                                            doc?.name ===
                                            props?.selectedRow
                                              ?.selected_documents?.[index]
                                              ?.name
                                        ) ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: green[500],
                                            marginLeft: 8,
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : props?.selectedRow
                                          ?.selected_documents?.[index]
                                          ?.created ? (
                                        <CheckCircleIcon
                                          style={{
                                            color: grey[500],
                                            marginLeft: 8,
                                            fontSize: "20px",
                                          }}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </span>
                                  }
                                  disableRipple
                                  sx={{
                                    py: 0,
                                    alignItems: "flex-start", // Align the tabs to the left
                                    marginLeft: "24px",
                                    ".MuiTab-root": {
                                      justifyContent: "flex-start", // Align the tab content to the left
                                      textAlign: "left", // Align the text to the left
                                      // Set a consistent left margin for all tabs
                                    },
                                  }}
                                />
                              );
                            }
                          )}
                        </Tabs>
                      </Box>
                      {/* MIDDLE CONTENT */}
                      <Box
                        className="step15"
                        sx={{
                          borderLeft: "4px solid #d3d3d3",
                          ml: 1,
                          flex: 1,
                        }}
                      >
                        <Box sx={{ mt: 1 }}>
                          <Typography
                            variant="h5"
                            align="center"
                            color="primary"
                            px={2}
                            mb={0}
                            sx={{ fontSize: "22px" }}
                          >
                            {
                              props?.selectedRow?.selected_documents[
                                props?.leftValue
                              ]?.label
                            }
                          </Typography>
                          <Typography
                            variant="body2"
                            fontWeight="bold"
                            align="center"
                            color="primary"
                            px={2}
                            mb={0}
                          >
                            {props?.selectedRow?.city
                              ? props?.selectedRow?.city
                                  .replace(/_/g, " ")
                                  .split(" ")
                                  .map(
                                    (word) =>
                                      word.charAt(0).toUpperCase() +
                                      word.slice(1)
                                  )
                                  .join(" ")
                              : "County Specific"}{" "}
                            - {props?.selectedRow?.county}
                          </Typography>
                        </Box>
                        {/* Status Indicators */}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: 4,
                            py: 1,
                            px: 1,
                            borderBottom: "1px solid #e0e0e0", // Add a subtle divider below
                          }}
                        >
                          {/* Created Status */}
                          <Tooltip title="Document Created">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {props?.selectedRow?.selected_documents?.[
                                props?.leftValue
                              ]?.created ? (
                                <CheckCircleIcon
                                  color="success"
                                  sx={{ fontSize: 30 }}
                                />
                              ) : (
                                <CancelIcon
                                  color="error"
                                  sx={{ fontSize: 30 }}
                                />
                              )}
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                fontWeight="bold"
                              >
                                Created
                              </Typography>
                            </Box>
                          </Tooltip>

                          {/* Notarized Status */}
                          <Tooltip title="Document Notarized">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {
                                // Case 1: leftValue === 0 and noc_notarized is true
                                (props?.leftValue === 0 &&
                                  props?.selectedRow?.noc_notarized === true) ||
                                // Case 2: leftValue === 1, noc_notarized is true, and final_documents array includes the selected document's name
                                (props?.leftValue === 1 &&
                                  props?.selectedRow?.noc_notarized === true &&
                                  props?.selectedRow?.processing_permit?.notarization?.final_documents?.some(
                                    (doc) =>
                                      doc?.name ===
                                      props?.selectedRow?.selected_documents?.[
                                        props?.leftValue
                                      ]?.name
                                  )) ||
                                // Case 3: leftValue >= 1 and pp_notarized is true
                                (props?.leftValue >= 1 &&
                                  props?.selectedRow?.pp_notarized === true) ? (
                                  <CheckCircleIcon
                                    color="success"
                                    sx={{ fontSize: 30 }}
                                  />
                                ) : (
                                  // Default case: red cancel icon
                                  <CancelIcon
                                    color="error"
                                    sx={{ fontSize: 30 }}
                                  />
                                )
                              }
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                fontWeight="bold"
                              >
                                Notarized
                              </Typography>
                            </Box>
                          </Tooltip>

                          {/* Recorded Status */}
                          {props?.selectedRow?.selected_documents?.[
                            props?.leftValue
                          ]?.name === "noc" && (
                            <Tooltip title="Document Recorded">
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                {props?.selectedRow?.recorded ? (
                                  <CheckCircleIcon
                                    color="success"
                                    sx={{ fontSize: 30 }}
                                  />
                                ) : (
                                  <CancelIcon
                                    color="error"
                                    sx={{ fontSize: 30 }}
                                  />
                                )}
                                <Typography
                                  variant="caption"
                                  color="textSecondary"
                                  fontWeight="bold"
                                >
                                  Recorded
                                </Typography>
                              </Box>
                            </Tooltip>
                          )}

                          {/* Downloaded Status */}
                          <Tooltip title="Document Downloaded">
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              {props?.selectedRow?.documents[
                                props?.selectedRow?.selected_documents?.[
                                  props?.leftValue
                                ]?.name
                              ]?.download_info?.downloaded === true ? (
                                <CheckCircleIcon
                                  color="success"
                                  sx={{ fontSize: 30 }}
                                />
                              ) : (
                                <CancelIcon
                                  color="error"
                                  sx={{ fontSize: 30 }}
                                />
                              )}
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                fontWeight="bold"
                              >
                                Downloaded
                              </Typography>
                            </Box>
                          </Tooltip>
                        </Box>
                        {/* END OF STATUS INDICATORS */}

                        {props?.activeTab === 1 &&
                        props?.selectedRow?.assigned_package?.is_assigned ? (
                          <Box
                            sx={{
                              // height: "100%",
                              mx: "auto",
                              display: "flex",
                              // alignItems: "center",
                              // flexDirection: "column",
                            }}
                          >
                            <Typography
                              pt={5}
                              sx={{ px: 3 }}
                              variant="h5"
                              align="center"
                              color="warning"
                            >
                              Permit Package has been assigned to the
                              contractor!
                            </Typography>
                          </Box>
                        ) : props?.tempLoading ||
                          props?.selectedRow?.documents?.[props?.creatingDoc]
                            ?.status === "In Progress" ? (
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h5"
                              align="center"
                              color="warning"
                              sx={{ fontSize: "22px" }}
                              pt={2}
                              px={1}
                            >
                              Please wait while the document is being created
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: 2 }}>
                              <img
                                src="/img/gif/HI9M.gif"
                                alt="loading"
                                width={100}
                              />
                            </Box>
                          </Box>
                        ) : props?.currentNocStatus &&
                          !props?.currentNocStatus?.queue_data?.done ? (
                          //? IN QUEUE //////////////////////////////////////////
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h5"
                              align="center"
                              color="warning"
                              sx={{ fontSize: "22px" }}
                              pt={1}
                              px={2}
                            >
                              {props?.currentNocStatus?.queue_data
                                ?.in_processing_window === false
                                ? "Your Notice of Commencement is currently in queue position:"
                                : "Please wait while the document is being created"}
                            </Typography>
                            {props?.currentNocStatus?.queue_data
                              ?.in_processing_window === false && (
                              <Typography
                                variant="h4"
                                align="center"
                                color="primary"
                                pt={1}
                                px={2}
                              >
                                {props?.currentNocStatus?.queue_data?.position}
                              </Typography>
                            )}

                            <Box sx={{ textAlign: "center", mt: 1 }}>
                              <img
                                src="/img/gif/HI9M.gif"
                                alt="loading"
                                width={80}
                              />
                            </Box>
                          </Box>
                        ) : //? ///////////////////////////////////////////////////////////
                        noc_data?.failed_counties.includes(
                            props?.selectedRow?.county
                          ) &&
                          props?.selectedRow?.documents?.noc?.is_created ===
                            false &&
                          props?.selectedRow?.documents?.noc?.status ===
                            "failed" ? (
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              color="error"
                              pt={2}
                              px={2}
                            >
                              {props?.selectedRow?.county} Appraiser's website
                              is temporarily unavailable. Once the website can
                              be accessed, the NOC will be automatically
                              generated.
                            </Typography>
                            <Box sx={{ textAlign: "center", mt: 2 }}>
                              <img
                                src="/img/gif/HI9M.gif"
                                alt="loading"
                                width={80}
                              />
                            </Box>
                          </Box>
                        ) : props?.selectedRow?.documents?.[props?.creatingDoc]
                            ?.status === "failed" ? (
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              pt={2}
                              pb={2}
                              px={1}
                              color="error"
                            >
                              Based on the address you provided, we were unable
                              to locate the property
                            </Typography>
                            <Button
                              onClick={props?.handleCreate}
                              sx={{ mt: 1, minWidth: "80%" }}
                              variant="contained"
                              color="warning"
                              endIcon={<EditIcon />}
                            >
                              UPDATE DETAILS
                            </Button>
                          </Box>
                        ) : props?.selectedRow?.documents?.[props?.creatingDoc]
                            ?.status === "more" ? (
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h6"
                              align="center"
                              pt={2}
                              pb={2}
                              px={1}
                              color="error"
                            >
                              There are more than one property addresses found
                              for the given address
                            </Typography>
                            <Button
                              onClick={props?.handleCreate}
                              sx={{ mt: 1, minWidth: "80%" }}
                              variant="contained"
                              color="warning"
                              endIcon={<EditIcon />}
                            >
                              UPDATE DETAILS
                            </Button>
                          </Box>
                        ) : !props?.selectedRow?.selected_documents[
                            props?.leftValue
                          ]?.created ? (
                          <Box
                            sx={{
                              mx: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="h5"
                              align="center"
                              color="warning"
                              sx={{ fontSize: "22px" }}
                              pt={2}
                              px={2}
                              pb={2}
                            >
                              The document has not yet been created
                            </Typography>
                            <LoadingButton
                              loading={props?.createLoading}
                              disabled={props?.cancelLoading}
                              onClick={() => {
                                if (!isOpen) {
                                  props?.setCreateLoading(true);
                                  props?.handleCreate();
                                } else {
                                  return;
                                }
                              }}
                              sx={{ mt: 1, minWidth: "80%" }}
                              variant="contained"
                              color="success"
                            >
                              CREATE FORM
                            </LoadingButton>
                          </Box>
                        ) : (
                          props?.selectedRow?.selected_documents[
                            props?.leftValue
                          ]?.created && (
                            <>
                              <Box
                                sx={{
                                  mx: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  position: "relative",
                                }}
                              >
                                {currentLeftNotarized &&
                                  currentLeftInProgress && (
                                    <>
                                      <Typography
                                        px={2}
                                        pt={2}
                                        mb={2}
                                        align="center"
                                        variant="h5"
                                        sx={{
                                          color: "#4caf50",
                                          fontSize: "22px",
                                        }}
                                      >
                                        Notarization has been initiated and is
                                        currently in progress...
                                      </Typography>
                                      <Typography
                                        px={2}
                                        mb={0}
                                        align="center"
                                        variant="body1"
                                        color="primary"
                                      ></Typography>
                                    </>
                                  )}

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    gap: 1,
                                    mt: 4,
                                  }}
                                >
                                  {!currentLeftNotarized && (
                                    <Button
                                      disabled={
                                        Boolean(
                                          props?.instructionDownloading
                                        ) ||
                                        Boolean(props?.downloading) ||
                                        currentLeftInProgress
                                      }
                                      onClick={props?.handleCreate}
                                      sx={{ mt: 1, minWidth: "10rem" }}
                                      variant="contained"
                                      color="warning"
                                      endIcon={<EditIcon />}
                                    >
                                      Update Document
                                    </Button>
                                  )}

                                  <LoadingButton
                                    disabled={
                                      Boolean(props?.instructionDownloading) ||
                                      currentLeftInProgress
                                    }
                                    loading={
                                      props?.downloading ===
                                      props?.selectedRow?.selected_documents?.[
                                        props?.leftValue
                                      ]?.name +
                                        props?.selectedRow?.task_id
                                    }
                                    onClick={() => {
                                      setFileSizeModalOpen(true);
                                    }}
                                    sx={{ mt: 1, minWidth: "10rem" }}
                                    variant="contained"
                                    color={
                                      currentLeftNotarized
                                        ? "success"
                                        : "primary"
                                    }
                                    endIcon={<DownloadIcon />}
                                  >
                                    {currentLeftNotarized
                                      ? "Download Notarized Form"
                                      : "Download Form"}
                                  </LoadingButton>
                                </Box>
                                {props?.selectedRow?.selected_documents?.[
                                  props?.leftValue
                                ]?.name === "pais" &&
                                  props?.selectedRow?.selected_documents?.[
                                    props?.leftValue
                                  ]?.created && (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        gap: 1,
                                        mt: 1,
                                      }}
                                    >
                                      <LoadingButton
                                        disabled={Boolean(props?.downloading)}
                                        loading={
                                          props?.instructionDownloading ===
                                          props?.selectedRow
                                            ?.selected_documents?.[
                                            props?.leftValue
                                          ]?.name +
                                            "inst" +
                                            props?.selectedRow?.task_id
                                        }
                                        onClick={
                                          props?.handleDownloadInstructions
                                        }
                                        sx={{ mt: 1, minWidth: "10rem" }}
                                        color="primary"
                                        endIcon={<UploadFileIcon />}
                                      >
                                        Download Instructions
                                      </LoadingButton>
                                    </Box>
                                  )}
                              </Box>
                            </>
                          )
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    className="step16 step17 step18 step19 step20 step21"
                    size={{ sm: 4 }}
                    sx={{ borderLeft: "4px solid #d3d3d3" }}
                  >
                    <Box
                      sx={{
                        maxWidth: "100%",
                        bgcolor: "background.paper",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tabs
                        value={props?.rightValue}
                        onChange={handleRightChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{
                          style: {
                            height: "3px", // Customize the height of the indicator
                            bottom: "10px", // Adjust the position closer to the text
                          },
                        }}
                      >
                        <Tab label="Property Owner" disableRipple />
                        <Tab label="Contractor" disableRipple />
                        <Tab
                          label="Notarization"
                          disableRipple
                          sx={{
                            display:
                              props?.activeTab === 1 &&
                              props?.selectedRow?.assigned_package?.is_assigned
                                ? "none"
                                : undefined,
                          }}
                        />
                        <Tab
                          label="Recording"
                          disableRipple
                          sx={{
                            display:
                              props?.activeTab === 1 &&
                              props?.selectedRow?.assigned_package?.is_assigned
                                ? "none"
                                : undefined,
                          }}
                        />
                        <Tab
                          label="City Permit"
                          disableRipple
                          sx={{
                            display:
                              props?.activeTab === 1 &&
                              props?.selectedRow?.assigned_package?.is_assigned
                                ? "none"
                                : undefined,
                          }}
                        />
                        <Tab
                          label="Settings"
                          disableRipple
                          sx={{
                            display:
                              props?.activeTab === 1 &&
                              props?.selectedRow?.assigned_package?.is_assigned
                                ? "none"
                                : undefined,
                          }}
                        />
                      </Tabs>
                    </Box>

                    <Box
                      sx={{ width: "100%" }}
                      className={
                        isFading
                          ? "fade-exit fade-exit-active"
                          : "fade-enter fade-enter-active"
                      }
                    >
                      {getRightTabComponent()}
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
          </Box>
        </CardContent>
      </Card>
      <SelectDownloadFileSIzeModal
        open={fileSizeModalOpen}
        setOpen={setFileSizeModalOpen}
        handleDownload={props?.handleDownloadNoc}
        selectedRow={props?.selectedRow}
        leftValue={props?.leftValue}
      />
      <Snackbar
        sx={{ zIndex: 9999999999999 }}
        open={snackOpen}
        autoHideDuration={5000}
        onClose={snackHandleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={snackHandleClose}
          severity="success" // Changed severity to success
          sx={{
            width: "100%",
            marginTop: "80px",
            marginLeft: lg ? "280px" : "0px",
            backgroundColor: "#388e3c", // Green shade for success
            color: "#fff",
            fontWeight: "bold",
          }}
          iconMapping={{
            success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
          }}
        >
          The package name has been successfully updated
        </Alert>
      </Snackbar>
    </>
  );
};

export default MyPermitGrid;
