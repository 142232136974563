import axios from "axios";
import { categoryData } from "../data/pais/paisData";
import {
  addPaisTemplate,
  updatePermitPackage,
  updatePermitPackageFromReturn,
} from "../store/actions/dataActions";
import { updateSubAnalytics } from "../store/actions/subUserActions";
import { updateAnalytics } from "../store/actions/userActions";

export const getCategoryLabel = (categoryCode) => {
  const category = categoryData.find(
    (cat) => cat.categoryCode === categoryCode
  );
  return category ? category.categoryLabel : categoryCode;
};

export const getSubCategoryLabel = (categoryCode, subCategoryCode) => {
  const category = categoryData.find(
    (cat) => cat.categoryCode === categoryCode
  );
  if (category) {
    const subCategory = category.subCategory.find(
      (subCat) => subCat.code === subCategoryCode
    );
    return subCategory ? subCategory.label : subCategoryCode;
  }
  return subCategoryCode;
};

export const handleLoadTemplate = async (
  formik,
  paisStates,
  setPaisStates,
  currentSession,
  selectedRow,
  setDocumentImage,
  dispatch,
  setUsingTemplate,
  leftValue,
  creatingDoc,
  initials
) => {
  try {
    setPaisStates((prev) => ({
      ...prev,
      templateLoading: true,
    }));

    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/pais/load-template`,
      {
        currentSession: currentSession,
        template: paisStates?.paisSelectedTemplate,
        savingForm: selectedRow?.documents?.pais,
        values: formik?.values,
        city: selectedRow?.city,
        task_id: selectedRow?.task_id,
        county: selectedRow?.county,
        form: selectedRow?.selected_documents[leftValue],
        creatingDoc: creatingDoc,
        initials: initials,
      },
      { withCredentials: true }
    );

    if (resp?.data?.success) {
      setDocumentImage(resp?.data?.signed);
      await dispatch(updatePermitPackageFromReturn(resp?.data?.data));
      setPaisStates((prev) => ({
        ...prev,
        addNewWaiting: false,
        templateExpanded: false,
        detailsExpanded: true,
      }));
      setUsingTemplate(true);
    }
  } catch (error) {
  } finally {
    setPaisStates((prev) => ({
      ...prev,
      templateLoading: false,
    }));
  }
};

export const handleEditRowClick = async (
  formik,
  product,
  setPaisStates,
  currentSession
) => {
  setPaisStates((prev) => ({
    ...prev,
    editData: product,
    loadingData: true,
    expandedCategory: false,
    editExpanded: false,
    addProductExpanded: true,
  }));
  const categoryCode = product.category + "_" + product.subCategory;
  const resp = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/nocApi/pais/get-products`,
    { currentSession: currentSession, code: categoryCode },
    { withCredentials: true }
  );

  if (resp?.data?.success) {
    setPaisStates((prev) => ({
      ...prev,
      productsData: resp?.data?.data,
      loadingData: false,
      paisSearching: true,
    }));

    formik.setTouched({});
  }
};

export const handleSaveTemplate = async (
  formik,
  paisStates,
  setPaisStates,
  currentSession,
  selectedRow,
  leftValue,
  creatingDoc,
  dispatch,
  pdfSize
) => {
  try {
    setPaisStates((prev) => ({
      ...prev,
      saveTemplateLoading: true,
    }));
    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/pais/save-template`,
      {
        currentSession: currentSession,
        savingForm: selectedRow?.documents?.[creatingDoc],
        city: selectedRow?.city,
        county: selectedRow?.county,
        task_id: selectedRow?.task_id,
        overWrite: paisStates?.savePaisSelectedTemplate?.name !== "",
        saveName: paisStates?.savePaisSelectedTemplate,
        fileName: paisStates?.savePaisName,
        creatingDoc: creatingDoc,
        pdfSize: pdfSize,
      },
      { withCredentials: true }
    );
    if (resp?.data?.success) {
      dispatch(addPaisTemplate(resp?.data?.data));
      setPaisStates((prev) => ({
        ...prev,
        savePaisName: "",
        formAccDisabled: false,
        snackbarOpen: true,
        saveIsReady: false,
        openModal: false,
      }));
    }
  } catch (error) {
    setPaisStates((prev) => ({
      ...prev,
      templateError: error?.response?.data?.message,
    }));
  } finally {
    setPaisStates((prev) => ({
      ...prev,
      saveTemplateLoading: false,
    }));
  }
};

export const handleDeleteProduct = async (
  formik,
  creatingDoc,
  paisStates,
  setPaisStates,
  currentSession,
  selectedRow,
  leftValue,
  setDocumentImage,
  dispatch,
  pdfSize
) => {
  try {
    setPaisStates((prev) => ({
      ...prev,
      loadingData: true,
    }));

    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/pais/add-product-pdf`,
      {
        currentSession: currentSession,
        values: formik.values,
        addedProducts:
          selectedRow?.documents?.[creatingDoc]?.added_products || [],
        task_id: selectedRow?.task_id,
        county: selectedRow?.county,
        city: selectedRow?.city,
        creatingDoc: creatingDoc,
        form: selectedRow?.selected_documents[leftValue],
        deleteData: paisStates?.editData?.id,
        pdfSize: pdfSize,
      },
      { withCredentials: true }
    );
    if (resp?.data?.success) {
      setDocumentImage(resp?.data?.signed);
      await dispatch(updatePermitPackage(resp?.data?.data));

      const isSub = resp?.data?.is_sub;
      const freshAnalytics = resp?.data?.fresh_analytics;

      if (freshAnalytics) {
        if (isSub) {
          await dispatch(updateSubAnalytics(resp?.data?.fresh_analytics));
        } else {
          await dispatch(updateAnalytics(resp?.data?.fresh_analytics));
        }
      }

      setPaisStates((prev) => ({
        ...prev,
        saveIsReady: true,
        resettingData: true,
        descriptionValue: "",
      }));
    }
  } catch (e) {
  } finally {
    setPaisStates((prev) => ({
      ...prev,
      loadingData: false,
    }));
  }
};

export const handleLoadPaisTemplates = async (
  setTemplateLoading,
  currentSession,
  selectedRow,
  setPaisStates,
  dispatch,
  openModal
) => {
  try {
    setTemplateLoading(true);

    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-saved-projects`,
      {
        currentSession: currentSession,
        getPais: true,
        city: selectedRow?.city,
        county: selectedRow?.county,
      },
      { withCredentials: true }
    );

    await dispatch({
      type: "FIRST_ADD_SAVED_PROJECTS",
      payload: resp?.data,
    });

    if (openModal) {
      setPaisStates((prev) => ({
        ...prev,
        openModal: true,
      }));
    } else {
      setPaisStates((prev) => ({
        ...prev,
        useTemplate: true,
        tempContinue: false,
        addNewWaiting: true,
      }));
    }
  } catch (error) {
  } finally {
    setTemplateLoading(false);
  }
};
