import React, { useState } from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import {
  pdf_form_formats,
  pdfSizes,
} from "../../data/buildingPermit/fieldDatas";

const SelectDownloadFileSIzeModal = ({
  open,
  setOpen,
  handleDownload,
  selectedRow,
  leftValue,
}) => {
  const [selectedFormat, setSelectedFormat] = useState("pdf");
  const [selectedSize, setSelectedSize] = useState("letter");

  const handleConfirm = () => {
    handleDownload(
      selectedRow,
      selectedRow?.selected_documents?.[leftValue]?.name,
      selectedFormat,
      selectedSize
    );
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 3,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Select Download Options
        </Typography>

        {/* Format Selection */}
        <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
          <InputLabel>Format</InputLabel>
          <Select
            value={selectedFormat}
            onChange={(e) => setSelectedFormat(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: "1.5px",
                  maxHeight: 150, // Set the desired max height for the dropdown menu
                  width: "auto",
                  maxWidth: "100%",
                },
              },
            }}
          >
            {pdf_form_formats.map((format) => (
              <MenuItem key={format.value} value={format.value}>
                {format.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* PDF Size Selection */}
        <FormControl fullWidth variant="filled" sx={{ mb: 2 }}>
          <InputLabel>PDF Size</InputLabel>
          <Select
            value={selectedSize}
            onChange={(e) => setSelectedSize(e.target.value)}
            MenuProps={{
              PaperProps: {
                style: {
                  marginTop: "1.5px",
                  maxHeight: 150, // Set the desired max height for the dropdown menu
                  width: "auto",
                  maxWidth: "100%",
                },
              },
            }}
          >
            {pdfSizes
              .filter((size) => !size.divider)
              .map((size) => (
                <MenuItem key={size.value} value={size.value}>
                  {size.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button onClick={handleConfirm} variant="contained">
            Download
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SelectDownloadFileSIzeModal;
