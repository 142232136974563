import {
  Grid2 as Grid,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Toolbar,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  FormHelperText,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ReactCardFlip from "react-card-flip";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import Magnifier from "../customComponents/Magnifier";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import React, {
  useState,
  useEffect,
  useRef,
  Suspense,
  useCallback,
} from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForward from "@mui/icons-material/ArrowForward";
import Footer from "../layout/Footer";

import MoreResultsList from "./MoreResultsList";
import { loadComponent } from "../../utils/loadPermitComponent";
import { loadInitials } from "../../utils/loadPermitInitials";
import CityScraperStartModal from "../modals/CityScraperStartModal";
import { handleLoadPaisTemplates } from "../../functions/paisHelperFunctions";
import axios from "axios";
import {
  landscapeImgList,
  pdfSizes,
} from "../../data/buildingPermit/fieldDatas";

const OpenDocuments = ({
  designProList,
  setSelectedRow,
  notaryLoading,
  selectedRow,
  up1177,
  creatingDoc,
  setCreatingDoc,
  creatingDocTitle,
  setCreatingDocTitle,
  usingTemplate,
  setUsingTemplate,
  savedProjects,
  setPermitDrawerOpen,
  documentImage,
  setDocumentImage,
  leftValue,
  setLeftValue,
  permitDrawerOpen,
  setTempLoading,
  serviceTiers,
  plan,
  paymentCards,
  rightValue,
  setRightValue,
  currentPopup,
  setCurrentPopup,
  promoModalOpen,
  setPromoModalOpen,
  setCreateLoading,
}) => {
  const dispatch = useDispatch();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);

  const theme = useTheme();
  const leftGridRef = useRef(null);
  const RightGridRef = useRef(null);
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [createDocLoading, setCreateDocLoading] = useState(false);
  const [consentAlert, setConsentAlert] = useState(false);
  const [templateLoading, setTemplateLoading] = useState(false);

  //! NOC STATES /////////////////////////////////////
  const [nocStates, setNocStates] = useState({
    isNocPreview: false,
    previewLoading: false,
    createNocLoading: false,
    isCreatingNoc: false,
    dynamicValues: {},
    isSuretyRadio: false,
    emptyPermitConfirmed: false,
    pdfProgressModalOpen: false,
    inQueueMessage: "",
    inQueueDrawer: false,
    emptyPermitOpen: false,
    emptyPermitConfirmed: false,
    items: [],
    dotStates: {},
    completed: {},
    intervalIds: {},
    isFinished: false,
    initializeQueue: true,
    isMoreError: false,
    isFailedError: false,
    waitingList: false,
    cardFlip: false,
    moreResultRow: null,
    editNocLoading: false,
    isEditNoc: false,
    isFailed: false,
  });
  //! /////////////////////////////////////////////
  //! ROOFING_PERMIT /////////////////////////////////////
  const [roofingStates, setRoofingStates] = useState({
    isCreatingRoofing: false,
  });
  //! /////////////////////////////////////////////
  //! BUILDING_PERMIT /////////////////////////////////////
  const [buildingStates, setBuildingStates] = useState({
    designProListState: [],
    projectContactListState: [],
    feeSimpleListState: [],
    bondingCompanyListState: [],
    mortgageLenderListState: [],
    addingNewDesignPro: false,
    submitDisabled: false,
    projectSubmitDisabled: false,
    feeSimpleSubmitDisabled: false,
    bondingSubmitDisabled: false,
    mortgageSubmitDisabled: false,
    addingNewProject: false,
    addingNewFeeSimple: false,
    addingNewBonding: false,
    addingNewMortgage: false,
    workDesc: "",
    createBuildingLoading: false,
    isCreatingBuilding: false,
    pdfSize: "legal",
    sizeChangedText: "",
  });
  //! /////////////////////////////////////////////
  //! PAIS STATES ////////////////////////////////
  const [paisStates, setPaisStates] = useState({
    loadingData: false,
    paisSearching: false,
    productsData: null,
    manufacturerOptions: [],
    flNumberOptions: [],
    manDetailsModal: false,
    descriptionOptions: [],
    modelOptions: [],
    addingProduct: false,
    editingProduct: false,
    expandedCategory: false,
    editExpanded: false,
    detailsExpanded: false,
    addProductExpanded: false,
    templateExpanded: true,
    editData: false,
    addNewWaiting: true,
    useTemplate: false,
    tempContinue: true,
    saveIsReady: false,
    saveTemplateLoading: false,
    formAccDisabled: false,
    snackbarOpen: false,
    paisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisSelectedTemplate: {
      id: "",
      name: "",
    },
    savePaisName: "",
    templateError: "",
    templateLoading: false,
    openModal: false,
    descriptionValue: "",
    noProductError: "",
    resettingData: false,
    startDeleteProduct: false,
    category: "",
    subCategory: "",
    isCreatingPais: false,
  });
  //! /////////////////////////////////////////////
  //! OBTAIN_PERMIT STATES ////////////////////////////////
  const [obtainPermitStates, setObtainPermitStates] = useState({
    permissionLoading: false,
    isCreatingObtainPermit: false,
  });
  //! /////////////////////////////////////////////
  //! ENERGY STATES ////////////////////////////////
  const [energyStates, setEnergyStates] = useState({
    isCreatingEnergy: false,
  });
  //! /////////////////////////////////////////////
  //! ASBESTOS STATES ////////////////////////////////
  const [asbestosStates, setAsbestosStates] = useState({
    isCreatingAsbestos: false,
  });
  //! /////////////////////////////////////////////
  //! OWNERSHIP STATES ////////////////////////////////
  const [ownershipStates, setOwnershipStates] = useState({
    isCreatingOwnership: false,
  });
  //! /////////////////////////////////////////////
  //! PERMITFORM STATES ////////////////////////////////
  const [permitFormStates, setPermitFormStates] = useState({
    isCreatingForm: false,
    signersChanged: false,
  });

  //! /////////////////////////////////////////////
  const [descriptionError, setDescriptionError] = useState("");
  const [showSelectedCity, setShowSelectedCity] = useState("");
  const [LoadedComponent, setLoadedComponent] = useState(null);
  const [initials, setInitials] = useState(null);
  const [formError, setFormError] = useState(false);
  const [cityModalOpen, setCityModalOpen] = useState(false);
  const [signedLoading, setSignedLoading] = useState(false);

  useEffect(() => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  const resetCreateLoading = useCallback(() => {
    setCreateLoading(false);
  }, [setCreateLoading]);

  useEffect(() => {
    resetCreateLoading();
  }, [resetCreateLoading]);
  useEffect(() => {
    const complex = selectedRow?.documents?.[creatingDoc]?.sections
      ? true
      : false;

    const load = async () => {
      const { default: Component } = await loadComponent(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city,
        complex
      );
      const Initials = await loadInitials(
        creatingDoc,
        selectedRow?.county,
        selectedRow?.city
      );

      setLoadedComponent(() => Component);
      setInitials(Initials);
    };

    if (creatingDoc && selectedRow?.county) {
      // Only require county, as city may be optional
      load();
    }
  }, [creatingDoc, selectedRow?.county, selectedRow?.city]);

  useEffect(() => {
    if (descriptionError) {
      const interv = setTimeout(() => {
        setDescriptionError(false);
      }, 6000);
      return () => clearTimeout(interv);
    }
  }, [descriptionError]);

  useEffect(() => {
    if (leftGridRef.current) {
      leftGridRef.current.scrollTo(0, 0);
    }
  }, [documentImage]);

  useEffect(() => {
    if (RightGridRef.current) {
      RightGridRef.current.scrollTo(0, 0);
    }
  }, [leftValue]);

  const formatCity = (city) => {
    // Split the string by underscores
    const words = city.split("_");

    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    // Join the words and add 'Creating' and 'Form'
    return `${capitalizedWords.join(" ")}`;
  };

  //! UPDATE CREATED SIGNED IMAGE URL
  const handleImageError = async (index) => {
    try {
      setSignedLoading(true);

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/update-signed-pp-url`,
        {
          task_id: selectedRow?.task_id,
          currentSession: currentSession,
          currentForm: creatingDoc,
        },
        { withCredentials: true }
      );

      setSignedLoading(false);

      if (response?.data?.success) {
        const updatedUrls = response?.data?.data?.[creatingDoc]; // Updated signed URLs

        // Ensure the response has a valid URL at the specified index
        if (updatedUrls && updatedUrls.length > index) {
          return updatedUrls[index]; // Return the URL for the specified index
        }

        console.warn(`Index ${index} is out of bounds in updated URLs.`);
      } else {
        console.error("Failed to update signed URL:", response?.data?.error);
        setSignedLoading(false);
      }

      return null; // Return null if no update is available
    } catch (error) {
      setSignedLoading(false);
      console.error("Error updating signed URL:", error);
      return null; // Return null on failure
    }
  };
  // ! ################################//? ################################

  //? ################################
  const handleIMG = () => {
    const extractOrder = (url) => {
      const match = url.match(/_(\d+|[A-Z])\.jpg/);
      if (match) {
        const value = match[1];
        return isNaN(value) ? value : parseInt(value, 10);
      }
      return null; // No suffix means it's the first image
    };

    const sortedImages = [...documentImage].sort((a, b) => {
      const orderA = extractOrder(a);
      const orderB = extractOrder(b);

      // If either has no suffix, keep it first
      if (orderA === null) return -1;
      if (orderB === null) return 1;

      // Ensure sorting happens within the same type (numbers vs. letters)
      if (typeof orderA === "number" && typeof orderB === "number") {
        return orderA - orderB; // Sort numbers numerically
      }
      if (typeof orderA === "string" && typeof orderB === "string") {
        return orderA.localeCompare(orderB); // Sort letters alphabetically
      }

      return 0; // Should never happen since we ensure separate sorting
    });

    return (
      <>
        {sortedImages.map((item, index) => {
          const src = item.startsWith("https")
            ? item
            : `${process.env.REACT_APP_URL}${item}`;

          return (
            <Magnifier
              key={index}
              src={src}
              lensSize={450}
              zoomFactor={1.5}
              handleImageError={() => handleImageError(index)}
              index={index}
              signedLoading={signedLoading}
              setSignedLoading={setSignedLoading}
            />
          );
        })}
      </>
    );
  };

  const handleIconButton = () => {
    if (buildingStates?.submitDisabled || buildingStates?.addingNewDesignPro) {
      return;
    }

    if (["pais", "pass"].includes(creatingDoc)) {
      setPaisStates((prev) => ({
        ...prev,
        resettingData: true,
      }));
      setUsingTemplate(false);
    }

    if (creatingDoc === "noc") {
      setNocStates((prevState) => ({
        ...prevState,
        cardFlip: false,
      }));
    }

    if (selectedRow?.city_scraper?.is_accepted) {
      setRightValue(4);
    } else if (
      selectedRow?.permit_package_created ||
      (selectedRow?.noc_created &&
        selectedRow?.selected_documents?.length === 1)
    ) {
      if (rightValue !== 2) {
        setRightValue(2);
      }
    }
    setPermitDrawerOpen(false);
  };

  const handleNavigate = useCallback(
    async (direction) => {
      // try {
      let newIndex;
      let nextForm;
      if (direction === "back") {
        newIndex = leftValue - 1;
        // setBackLoading(true);
      } else {
        // setForwardLoading(true);
        newIndex = leftValue + 1;
      }

      if (direction === "back") {
        nextForm = selectedRow?.selected_documents[leftValue - 1];
      } else {
        nextForm = selectedRow?.selected_documents[leftValue + 1];
      }

      setCreatingDoc(nextForm?.name);
      setCreatingDocTitle(nextForm?.label);
      setLeftValue(newIndex);
      setNocStates((prevState) => ({
        ...prevState,
        cardFlip: false,
      }));
    },
    [
      leftValue,
      selectedRow,
      setCreatingDoc,
      setCreatingDocTitle,
      setLeftValue,
      setNocStates,
    ]
  );

  const consentHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setConsentAlert(false);
  };

  useEffect(() => {
    setBuildingStates((prev) => ({
      ...prev,
      sizeChangedText: "",
    }));

    // For "noc", always use legal
    if (creatingDoc === "noc") {
      if (buildingStates?.pdfSize !== "legal") {
        setBuildingStates((prev) => ({
          ...prev,
          pdfSize: "legal",
        }));
      }
      return;
    }

    // First, try to match a document from selected_documents
    if (selectedRow?.selected_documents) {
      const matchedDoc = selectedRow.selected_documents.find(
        (element) => element.name === creatingDoc
      );

      if (matchedDoc) {
        const newSize = matchedDoc.size || "letter"; // Default to "letter" if missing
        // Only update if the size has changed
        if (buildingStates?.pdfSize !== newSize) {
          setBuildingStates((prev) => ({
            ...prev,
            pdfSize: newSize,
          }));
        }
        // Stop here if we found a selected_document
        return;
      }
    }

    // If no matching document, then check the landscape condition
    const isLandscape = landscapeImgList.some(
      (entry) =>
        entry.county === selectedRow?.county &&
        entry.city === selectedRow?.city &&
        entry.doc === creatingDoc
    );

    if (isLandscape) {
      if (buildingStates?.pdfSize !== "landscape_letter") {
        setBuildingStates((prev) => ({
          ...prev,
          pdfSize: "landscape_letter",
        }));
      }
      return;
    }
  }, [creatingDoc, selectedRow]);

  return (
    <>
      <Grid
        container
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {/* LEFT SIDE */}
        <Grid
          ref={leftGridRef}
          size={{ md: !up1177 ? 6 : 6 }}
          sx={{ maxHeight: "90vh", overflow: "auto", position: "relative" }}
        >
          <Box
            sx={{
              flexDirection: "column",
              backgroundColor: "#fff",
              position: "fixed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: 1,

              top: 0,
              width: "49%",
              zIndex: 9999999,
            }}
          >
            <FormControl
              disabled={creatingDoc === "noc"}
              size="small"
              variant="filled"
              sx={{
                minWidth: 290,

                "& .MuiFilledInput-root": {
                  border: "4px solid #004976", // Darker bottom border
                  "&:hover": {
                    border: "4px solid #004976", // Ensures it stays dark on hover
                  },
                  "&.Mui-focused": {
                    border: "4px solid #004976", // Stays dark when focused
                  },
                },
              }}
            >
              <InputLabel sx={{ fontWeight: "bold" }}>DOCUMENT SIZE</InputLabel>
              <Select
                value={buildingStates?.pdfSize}
                MenuProps={{
                  PaperProps: {
                    style: {
                      marginTop: "1.5px",
                      maxHeight: 200, // Set the desired max height for the dropdown menu
                      width: "auto",
                      maxWidth: "100%",
                    },
                  },
                }}
                onChange={(e) => {
                  setBuildingStates((prev) => ({
                    ...prev,
                    pdfSize: e.target.value,
                  }));

                  if (
                    selectedRow?.documents?.[creatingDoc]?.is_created === true
                  ) {
                    setBuildingStates((prev) => ({
                      ...prev,
                      sizeChangedText: ["pais", "pass"].includes(creatingDoc)
                        ? "Click 'Save Project Details/Add or Edit Product' to apply changes"
                        : "Click 'Update Document' to apply changes",
                    }));
                  } else {
                    setBuildingStates((prev) => ({
                      ...prev,
                      sizeChangedText: ["pais", "pass"].includes(creatingDoc)
                        ? "Click 'Save Project Details/Add or Edit Product' to apply changes"
                        : "Click 'Create Document' to apply changes",
                    }));
                  }
                }}
              >
                {pdfSizes.map((size, index) =>
                  size.divider ? (
                    // Render Divider & Label
                    <React.Fragment key={`divider-${index}`}>
                      <Divider />
                      <MenuItem
                        disabled
                        sx={{ fontWeight: "bold", opacity: 1 }}
                      >
                        {size.label}
                      </MenuItem>
                    </React.Fragment>
                  ) : (
                    // Render Normal Menu Items
                    <MenuItem key={size.value} value={size.value}>
                      {size.label}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormHelperText sx={{ color: "#004976", fontWeight: "bold" }}>
              {buildingStates?.sizeChangedText}
            </FormHelperText>
          </Box>
          <Box
            sx={{
              height:
                selectedRow?.county === "Duval" && creatingDoc === "noc"
                  ? "0rem"
                  : "5rem",
            }}
          ></Box>
          {creatingDoc === "noc" && selectedRow?.documents?.noc?.more ? (
            <Box>
              <IconButton
                sx={{
                  display: "block",
                  position: "absolute",
                  left: 20,
                  top: 20,
                  zIndex: 2,
                  backgroundColor: "#fff",
                }}
                onClick={() =>
                  setNocStates((prevState) => ({
                    ...prevState,
                    cardFlip: !prevState.cardFlip,
                  }))
                }
              >
                {nocStates.cardFlip === true ? (
                  <CloseIcon />
                ) : (
                  <FormatListBulletedIcon />
                )}
              </IconButton>
              <ReactCardFlip
                isFlipped={nocStates.cardFlip}
                flipDirection="horizontal"
                flipSpeedBackToFront={1}
                flipSpeedFrontToBack={1}
              >
                <Box>{handleIMG()}</Box>
                <Box sx={{ height: "100vh" }}>
                  <MoreResultsList
                    addressRow={selectedRow?.documents?.noc?.more}
                    handleRowClick={(row) => {
                      setNocStates({ ...nocStates, moreResultRow: row });
                    }}
                  />
                </Box>
              </ReactCardFlip>
            </Box>
          ) : (
            <Box>{handleIMG()}</Box>
          )}
        </Grid>

        {/* RIGHT SIDE */}
        <Grid
          size={{ md: !up1177 ? 6 : 6 }}
          sx={{
            position: "relative",
            mb: 1,
            mt: 2,
            height: "100vh",

            borderLeft: "4px solid #f3f3f3",
          }}
        >
          {/* HEADER */}
          <IconButton
            disabled={
              createDocLoading ||
              paisStates?.loadingData ||
              paisStates?.saveTemplateLoading ||
              nocStates.editNocLoading
            }
            onClick={handleIconButton}
            sx={{ position: "absolute", right: 10 }}
          >
            <CloseIcon disabled={notaryLoading} />
          </IconButton>
          <Snackbar
            open={consentAlert}
            autoHideDuration={26000}
            onClose={consentHandleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={consentHandleClose}
              severity="success"
              sx={{
                width: "100%",
                backgroundColor: "#388e3c", // Green shade for success
                color: "#fff",
                fontWeight: "bold",
              }}
              iconMapping={{
                success: <CheckCircleIcon sx={{ color: "#fff" }} />, // Changed to success icon
              }}
            >
              Consent Accepted! The city permitting process will automatically
              start when a building permit is created.
            </Alert>
          </Snackbar>
          <Box
            sx={{
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <>
              <Typography color="primary" mx={1} align="center" variant="h5">
                Creating {creatingDocTitle}
              </Typography>
              <Typography color="primary" variant="h6">
                {leftValue + 1} / {selectedRow?.selected_documents?.length}
              </Typography>
            </>

            <Typography
              variant="h6"
              sx={{
                mb: 0.5,
                mt: 0,
              }}
              textAlign="center"
            >
              {selectedRow?.city && selectedRow?.city !== "county_specific"
                ? `${formatCity(selectedRow?.city)} - ${
                    selectedRow?.county
                  } County`
                : `${selectedRow?.county} County`}
            </Typography>
            {creatingDoc === "pais" && (
              <Typography align="center" fontWeight="bold" variant="body2">
                Product Approval form is saved when you load a template, add a
                product or edit/remove a product
              </Typography>
            )}
          </Box>
          {/* TEXTFIELD/CARD */}
          <Box
            ref={RightGridRef}
            sx={{
              height:
                creatingDoc === "pais" && paisStates?.paisSearching
                  ? "calc(100% - 365px)"
                  : "calc(100% - 300px)",
              overflow: "auto",
              px: 0.3,
              mt: 0,
              pb: 1.5,
              pt: 0.3,
              borderTop: "4px solid #f3f3f3",
              borderBottom: "4px solid #f3f3f3",
            }}
          >
            {LoadedComponent ? (
              <Suspense fallback={<div>Loading...</div>}>
                <LoadedComponent
                  //! STATES
                  nocStates={nocStates}
                  setNocStates={setNocStates}
                  roofingStates={roofingStates}
                  setRoofingStates={setRoofingStates}
                  buildingStates={buildingStates}
                  setBuildingStates={setBuildingStates}
                  paisStates={paisStates}
                  setPaisStates={setPaisStates}
                  obtainPermitStates={obtainPermitStates}
                  setObtainPermitStates={setObtainPermitStates}
                  energyStates={energyStates}
                  setEnergyStates={setEnergyStates}
                  asbestosStates={asbestosStates}
                  setAsbestosStates={setAsbestosStates}
                  ownershipStates={ownershipStates}
                  setOwnershipStates={setOwnershipStates}
                  permitFormStates={permitFormStates}
                  setPermitFormStates={setPermitFormStates}
                  //! //////////////////////////////////////////
                  leftValue={leftValue}
                  currentSession={currentSession}
                  setDocumentImage={setDocumentImage}
                  permitDrawerOpen={permitDrawerOpen}
                  setPermitDrawerOpen={setPermitDrawerOpen}
                  setSelectedRow={setSelectedRow}
                  selectedRow={selectedRow}
                  setTempLoading={setTempLoading}
                  designProList={designProList}
                  savedProjects={savedProjects}
                  createDocLoading={createDocLoading}
                  setCreateDocLoading={setCreateDocLoading}
                  creatingDoc={creatingDoc}
                  initials={initials}
                  usingTemplate={usingTemplate}
                  setUsingTemplate={setUsingTemplate}
                  setDescriptionError={setDescriptionError}
                  setCityModalOpen={setCityModalOpen}
                  currentUser={currentUser}
                  currentPopup={currentPopup}
                  setCurrentPopup={setCurrentPopup}
                  setPromoModalOpen={setPromoModalOpen}
                />
              </Suspense>
            ) : null}
            {/* ************************************** */}
          </Box>
          {/* {creatingDoc ? ( */}
          <Toolbar
            sx={{
              px: 0,
              py: 0.5,
              position: "fixed",
              bottom: 88.5,
              left: 0,
              right: 0,
              opacity: 1,
            }}
          >
            <Grid container sx={{ width: "100%" }}>
              <Grid size={{ xs: !up1177 ? 6 : 6 }}></Grid>
              <Grid size={{ xs: !up1177 ? 6 : 6 }}>
                {descriptionError && (
                  <Typography
                    sx={{ backgroundColor: "#fff" }}
                    mt={1}
                    ml={1}
                    pt={2}
                    color="error"
                    align="center"
                  >
                    {descriptionError}
                  </Typography>
                )}

                {paisStates?.paisSearching &&
                  ["pais", "pass"].includes(creatingDoc) && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        ml: "24px",
                        alignItems: "center",
                        backgroundColor: "#fff",
                        gap: 1,
                        mb: 1,
                      }}
                    >
                      <LoadingButton
                        onClick={(e) => {
                          e.stopPropagation();
                          if (Boolean(paisStates?.editData)) {
                            setPaisStates((prev) => ({
                              ...prev,
                              startDeleteProduct: true,
                            }));
                          } else {
                            setPaisStates((prev) => ({
                              ...prev,
                              resettingData: true,
                            }));
                          }
                        }}
                        color="error"
                        loading={paisStates?.loadingData}
                        disabled={
                          !paisStates?.category || !paisStates?.subCategory
                        }
                        variant="outlined"
                      >
                        {Boolean(paisStates?.editData)
                          ? "Delete Product"
                          : "New Search"}
                      </LoadingButton>
                      <LoadingButton
                        onClick={(e) => {
                          e.stopPropagation();
                          setPaisStates((prev) => ({
                            ...prev,
                            isCreatingPais: true,
                          }));
                        }}
                        color="success"
                        loading={paisStates?.loadingData}
                        disabled={
                          !paisStates?.category || !paisStates?.subCategory
                        }
                        variant="contained"
                      >
                        {Boolean(paisStates?.editData)
                          ? "Edit Product"
                          : "Add Product"}
                      </LoadingButton>
                      {Boolean(paisStates?.editData) && (
                        <LoadingButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setPaisStates((prev) => ({
                              ...prev,
                              resettingData: true,
                            }));
                          }}
                          color="primary"
                          loading={paisStates?.loadingData}
                          disabled={
                            !paisStates?.category || !paisStates?.subCategory
                          }
                          variant="outlined"
                        >
                          Cancel Edit
                        </LoadingButton>
                      )}
                    </Box>
                  )}
                <Box
                  sx={{
                    borderTop:
                      paisStates?.paisSearching && creatingDoc === "pais"
                        ? "4px solid #f3f3f3"
                        : undefined,
                    display: "flex",
                    justifyContent: "space-between",
                    ml: "24px",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {(leftValue === 1 && selectedRow?.noc_notarized === true) ||
                  (leftValue === 2 &&
                    selectedRow?.noc_notarized === true &&
                    selectedRow?.documents?.noc_affidavit?.is_created) ||
                  selectedRow?.processing_permit?.notarization?.started ? (
                    <>
                      <LoadingButton
                        onClick={() => {}}
                        disabled
                        sx={{
                          visibility: "hidden",
                        }}
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                      >
                        Back
                      </LoadingButton>
                    </>
                  ) : (
                    <LoadingButton
                      onClick={() => handleNavigate("back")}
                      disabled={
                        leftValue === 0 ||
                        createDocLoading ||
                        paisStates?.loadingData ||
                        paisStates?.saveTemplateLoading ||
                        buildingStates?.addingNewDesignPro ||
                        buildingStates?.addingNewProject ||
                        buildingStates?.addingNewFeeSimple ||
                        buildingStates?.addingNewBonding ||
                        buildingStates?.addingNewMortgage
                      }
                      sx={{
                        visibility: leftValue === 0 ? "hidden" : undefined,
                      }}
                      variant="contained"
                      startIcon={<ArrowBackIcon />}
                    >
                      Back
                    </LoadingButton>
                  )}

                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    {creatingDoc === "noc" && !selectedRow?.noc_created && (
                      <LoadingButton
                        onClick={() =>
                          setNocStates({
                            ...nocStates,
                            previewLoading: true,
                            isNocPreview: true,
                            cardFlip: false,
                          })
                        }
                        disabled={nocStates.createNocLoading}
                        loading={nocStates.previewLoading}
                        variant="contained"
                        color="warning"
                        sx={{
                          mt: 2,
                          mb: isMd ? 1 : 2,
                        }}
                        endIcon={<EditIcon />}
                      >
                        Preview
                      </LoadingButton>
                    )}

                    <Button
                      disabled={
                        createDocLoading ||
                        obtainPermitStates?.permissionLoading ||
                        paisStates?.loadingData ||
                        nocStates.previewLoading ||
                        nocStates.editNocLoading
                      }
                      sx={{ mt: 2, mb: isMd ? 1 : 2, minWidth: "10rem" }}
                      color="primary"
                      variant="outlined"
                      onClick={handleIconButton}
                    >
                      Main Menu
                    </Button>

                    {["pais", "pass"].includes(creatingDoc) ? (
                      <LoadingButton
                        disabled={
                          createDocLoading ||
                          obtainPermitStates?.permissionLoading ||
                          paisStates?.loadingData ||
                          (["pais", "pass"].includes(creatingDoc) &&
                            !paisStates?.saveIsReady)
                        }
                        endIcon={
                          up1177 && !["pais", "pass"].includes(creatingDoc) ? (
                            <SendIcon color="#fff" />
                          ) : undefined
                        }
                        loading={
                          paisStates?.saveTemplateLoading || templateLoading
                        }
                        color="warning"
                        // onClick={handleSaveTemplate}
                        onClick={() => {
                          if (!paisStates?.useTemplate) {
                            handleLoadPaisTemplates(
                              setTemplateLoading,
                              currentSession,
                              selectedRow,
                              setPaisStates,
                              dispatch,
                              true
                            );
                          } else {
                            setPaisStates((prev) => ({
                              ...prev,
                              openModal: true,
                            }));
                          }
                        }}
                        sx={{
                          // visibility:
                          //   creatingDoc === "pais" ? "hidden" : undefined,
                          mt: 2,
                          mb: isMd ? 1 : 2,
                          px: 1,
                          minWidth: "100px",
                        }}
                        variant="contained"
                      >
                        SAVE TEMPLATE
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        disabled={
                          buildingStates?.submitDisabled ||
                          buildingStates?.addingNewDesignPro ||
                          buildingStates?.addingNewProject ||
                          buildingStates?.addingNewFeeSimple ||
                          buildingStates?.addingNewBonding ||
                          buildingStates?.addingNewMortgage ||
                          nocStates.previewLoading
                        }
                        // endIcon={up1177 ? <SendIcon color="#fff" /> : undefined}
                        loading={
                          createDocLoading ||
                          obtainPermitStates?.permissionLoading ||
                          nocStates.createNocLoading ||
                          nocStates.editNocLoading
                        }
                        color={
                          selectedRow?.documents?.[creatingDoc]?.is_created
                            ? "warning"
                            : "success"
                        }
                        onClick={() => {
                          if (
                            creatingDoc === "noc" &&
                            !selectedRow?.noc_created
                          ) {
                            setNocStates({
                              ...nocStates,
                              createNocLoading: true,
                              isCreatingNoc: true,
                              cardFlip: false,
                            });
                          } else if (
                            creatingDoc === "noc" &&
                            selectedRow?.noc_created
                          ) {
                            setNocStates({
                              ...nocStates,
                              editNocLoading: true,
                              isEditNoc: true,
                            });
                          } else {
                            setPermitFormStates((prev) => ({
                              ...prev,
                              isCreatingForm: true,
                            }));
                          }
                        }}
                        sx={{
                          mt: 2,
                          mb: isMd ? 1 : 2,
                        }}
                        endIcon={
                          selectedRow?.documents?.[creatingDoc]?.is_created ? (
                            <EditIcon />
                          ) : undefined
                        }
                        variant="contained"
                      >
                        {!selectedRow?.selected_documents[leftValue]?.created
                          ? "Create Document"
                          : "Update Document"}
                      </LoadingButton>
                    )}
                    {selectedRow?.selected_documents?.length === 1 &&
                      selectedRow?.noc_created && (
                        <Button
                          sx={{ width: "10rem" }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setPermitDrawerOpen(false);
                            setRightValue(2);
                          }}
                        >
                          Finish
                        </Button>
                      )}
                  </Box>
                  <LoadingButton
                    sx={{
                      visibility:
                        selectedRow?.selected_documents.length === 1
                          ? "hidden"
                          : undefined,
                      minWidth: "100px",
                    }}
                    color={
                      leftValue ===
                        selectedRow?.selected_documents.length - 1 ||
                      ((selectedRow?.noc_notarized === true ||
                        selectedRow?.processing_permit?.notarization
                          ?.started) &&
                        leftValue === 0)
                        ? "success"
                        : "primary"
                    }
                    onClick={() => {
                      if (
                        leftValue ===
                          selectedRow?.selected_documents.length - 1 ||
                        ((selectedRow?.noc_notarized === true ||
                          selectedRow?.processing_permit?.notarization
                            ?.started) &&
                          leftValue === 0)
                      ) {
                        handleIconButton();
                      } else {
                        handleNavigate("forward");
                      }
                    }}
                    disabled={
                      !selectedRow?.selected_documents[leftValue]?.created ||
                      createDocLoading ||
                      paisStates?.loadingData ||
                      paisStates?.saveTemplateLoading ||
                      buildingStates?.addingNewDesignPro ||
                      buildingStates?.addingNewProject ||
                      buildingStates?.addingNewFeeSimple ||
                      buildingStates?.addingNewBonding ||
                      buildingStates?.addingNewMortgage ||
                      nocStates.previewLoading ||
                      nocStates.editNocLoading
                    }
                    variant="contained"
                    endIcon={
                      leftValue ===
                      selectedRow?.selected_documents.length - 1 ? undefined : (
                        <ArrowForward />
                      )
                    }
                  >
                    {leftValue === selectedRow?.selected_documents.length - 1 ||
                    ((selectedRow?.noc_notarized === true ||
                      selectedRow?.processing_permit?.notarization?.started) &&
                      leftValue === 0)
                      ? "Finish"
                      : "Next"}
                  </LoadingButton>
                  {/* )} */}
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
      <Footer height="60px" />
      <CityScraperStartModal
        open={cityModalOpen}
        setOpen={setCityModalOpen}
        selectedRow={selectedRow}
        setConsentAlert={setConsentAlert}
        setNocStates={setNocStates}
      />
    </>
  );
};

export default OpenDocuments;
