import {
  Box,
  Container,
  Grid2 as Grid,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { disconnectWebsocket } from "../../../store/actions/websocketActions";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { Advertising } from "../../admin/management/Advertising";
import { Registration } from "../../admin/management/Registration";
import { LeavingReasons } from "../../admin/management/LeavingReasons";
import { Staff } from "../../admin/management/Staff";
import { FunFacts } from "../../admin/management/FunFacts";
import { NewDiscounts } from "../../admin/management/NewDiscounts";
import { DemoContractors } from "../../admin/management/DemoContractors";

const Management = () => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [managementData, setManagementData] = useState();
  const [dataLoading, setDataLoading] = useState(true);
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  let count = 0;

  const dispatchCount = useRef(0);

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.user?.role !== "admin" || !currentUser?.user?.admin) {
        if (dispatchCount.current < 1) {
          dispatch(disconnectWebsocket(currentSession));
          navigate("/login");
          dispatchCount.current += 1;
        }
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const getData = async () => {
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
          { currentSession: currentSession },
          { withCredentials: true }
        );

        setManagementData(resp.data.data);

        setDataLoading(false);
      } catch (e) {
        setDataLoading(false);
      }
    };

    if (
      !currentUser ||
      currentUser?.user?.role !== "admin" ||
      !currentUser?.user?.admin
    ) {
      return;
    } else {
      if (count < 1) {
        getData();
        count += 1;
      }
    }
  }, []);

  return (
    <>
      {dataLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 3,
            backgroundColor: "#EEF2F6",
          }}
        >
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <FunFacts
                  title="Fun Facts"
                  funFactsRows={managementData?.funFacts}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <Advertising
                  title="Advertising"
                  advertisingRows={managementData?.advertising}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <Registration
                  title="Registration"
                  registrationRows={managementData?.additional_registration}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>

          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <NewDiscounts
                  title="New Signup Users Discount"
                  discountsRows={managementData?.new_reg_discount}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <LeavingReasons
                  title="Leaving Reasons"
                  leavingRows={managementData?.leaving_reasons}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <Staff
                  title="Admin Registrations"
                  staffRows={managementData?.admin_registration}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth="xl" sx={{ mb: 4 }}>
            <Grid container spacing={5}>
              <Grid size={{ xs: 12, md: 12, lg: 12 }}>
                <DemoContractors
                  title="Demo Contractors"
                  demoLicensesRow={managementData?.demo_contractors}
                  setManagementData={setManagementData}
                  isSm={isSm}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </>
  );
};

export default Management;
