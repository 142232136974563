import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTour } from "@reactour/tour";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import NewRonRequestBox from "../modals/NewRonRequestBox";
import ChatComponent from "../chatbot/ChatComponent";
import DemoMainPage from "../../data/walkthroughDemo/DemoMainPage";
import {
  handleMarkDemoShown,
  removeShowDemo,
  setPrevTourStep,
  setShowTourNav,
} from "../../store/actions/dataActions";
import SessionWillExpireBox from "../modals/SessionWillExpireBox";
import SessionForcedLogoutBox from "../modals/SessionForcedLogoutBox";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled("div")(({ theme, openNav, pathname }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft:
      pathname === "/document-uploader-test"
        ? !openNav
          ? SIDE_NAV_WIDTH // Add padding for "/document-uploader-test" when the sidebar is open
          : 0 // No padding when the sidebar is closed
        : SIDE_NAV_WIDTH, // Default padding for all other paths
  },
}));

// const LayoutRoot = styled("div")(({ theme }) => ({
//   display: "flex",
//   flex: "1 1 auto",
//   maxWidth: "100%",
//   [theme.breakpoints.up("lg")]: {
//     paddingLeft: SIDE_NAV_WIDTH,
//   },
// }));

const LayoutContainer = styled("div")({
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
});

export const Layout = (props) => {
  const { isOpen, setIsOpen, currentStep } = useTour();
  const theme = useTheme();
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const sessionForcedLogout = useSelector(
    (state) => state.sessions.sessionForcedLogout
  );
  const currentUser = useSelector((state) => state[currentSession].user);
  const demoShown = useSelector((state) => state.nocData.demoShown);
  const showDemo = useSelector((state) => state.nocData.showDemo);
  const showTourNav = useSelector((state) => state?.nocData?.showTourNav);
  const prevTourStep = useSelector((state) => state?.nocData?.prevTourStep);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const { children } = props;
  const [openNav, setOpenNav] = useState(false);
  const [tourOpen, setTourOpen] = useState(false);
  const [willExpireSoon, setWillExpireSoon] = useState(false);

  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  useEffect(() => {
    const checkWillExpire = () => {
      const isExpiring = sessionStorage.getItem("willExpire") === "true";
      setWillExpireSoon(isExpiring);
    };

    const handleExpireUpdate = () => {
      checkWillExpire();
    };

    // Add listener
    window.addEventListener("expireUpdate", handleExpireUpdate);

    // Initial check
    checkWillExpire();

    return () => {
      window.removeEventListener("expireUpdate", handleExpireUpdate);
    };
  }, []);

  useEffect(() => {
    const showNavList =
      currentSession === "subUserSession"
        ? [1, 8, 9, 11, 12, 13, 14, 15, 16, 17, 18, 19]
        : // : [1, 6, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 27, 28];
          [1, 6, 10, 11, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24, 26, 27, 28];

    if (
      prevTourStep !== currentStep &&
      showTourNav &&
      !showNavList.includes(currentStep)
    ) {
      dispatch(setShowTourNav(false));
      dispatch(setPrevTourStep(currentStep));
    }
  }, [showTourNav, currentStep]);

  const handlePathnameChange = useCallback(() => {
    if (openNav) {
      setOpenNav(false);
    }
  }, [openNav]);

  useEffect(() => {
    if (isOpen && !lgUp) {
      setIsOpen(false);
    }
  }, [isOpen, lgUp]);

  useEffect(() => {
    if (isOpen && !openNav && currentStep === 20) {
      setOpenNav(true);
    }
  }, [openNav, isOpen, currentStep]);

  useEffect(() => {
    const demoTimeout = setTimeout(() => {
      if (showDemo) {
        setTourOpen(true);
      }
      dispatch(removeShowDemo());
    }, 2000);

    return () => clearTimeout(demoTimeout);
  }, [showDemo]);

  useEffect(() => {
    if (!demoShown) {
      if (currentSession === "userSession" && currentUser?.user?.firstLogin) {
        // setTourOpen(true);
        dispatch(handleMarkDemoShown());
      } else if (
        currentSession === "subUserSession" &&
        currentUser?.subuser?.firstLogin
      ) {
        // setTourOpen(true);
        dispatch(handleMarkDemoShown());
      }
    }
  }, [currentSession, currentUser]);

  useEffect(
    () => {
      // signOut();
      handlePathnameChange();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  useEffect(() => {
    if (pathname === "/document-uploader-test") {
      setTimeout(() => {
        setOpenNav(true);
      }, 3000);
    }
  }, [pathname]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh", // Set the minimum height of the layout to the full viewport height
        }}
      >
        {currentSession && (
          <SessionWillExpireBox
            willExpireSoon={willExpireSoon}
            setWillExpireSoon={setWillExpireSoon}
          />
        )}
        {sessionForcedLogout && (
          <SessionForcedLogoutBox
            onNavigateToLogin={() => {
              navigate("/login");
            }}
          />
        )}

        <Box
          component="header"
          sx={{
            backdropFilter: "blur(6px)",
            backgroundColor: (theme) =>
              alpha(theme.palette.background.default, 0.7),
            position: "sticky", // Make the header sticky
            top: 0, // Stick it at the top
            left: {
              lg: `${SIDE_NAV_WIDTH}px`,
            },
            width: {
              lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
            },
            zIndex: (theme) => theme.zIndex.appBar,
          }}
        >
          {currentSession === "notarySession" && <NewRonRequestBox />}
          <TopNav
            onNavOpen={() => setOpenNav(true)}
            togleNav={() => setOpenNav(!openNav)}
            openNav={openNav}
            setTourOpen={setTourOpen}
          />
        </Box>

        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <SideNav onClose={() => setOpenNav(false)} open={openNav} />

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              overflowY: "hidden",
              backgroundColor: "#edf1f5",
            }}
          >
            <LayoutRoot openNav={openNav} pathname={pathname}>
              <LayoutContainer sx={{ maxWidth: "100%", zIndex: 0 }}>
                {children}
              </LayoutContainer>
            </LayoutRoot>
            {currentUser?.role !== "notary" &&
            ![
              "/login",
              "/register",
              "/resetpassword",
              "/signee/manage",
              "/signee/auth-login",
              "/record/signee/register",
            ].includes(pathname) ? (
              <ChatComponent />
            ) : null}
          </Box>
        </Box>

        {currentSession !== "notarySession" &&
          !tabletSize &&
          pathname !== "/document-uploader-test" && (
            <Box
              component="footer"
              sx={{
                position: "sticky",
                bottom: 0,
                backgroundColor: "#1c2536",
                left: {
                  lg: `${SIDE_NAV_WIDTH}px`,
                },

                width: {
                  lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`,
                },
                zIndex: (theme) => theme.zIndex.appBar,
              }}
            >
              <Footer />
            </Box>
          )}
        <DemoMainPage
          open={tourOpen}
          setOpen={setTourOpen}
          openNav={openNav}
          setOpenNav={setOpenNav}
        />
      </Box>
    </>
  );
};
