import { combineReducers } from "@reduxjs/toolkit";
import sessionsReducer from "./reducers/sessionsReducer";

import userSessionReducer from "./reducers/userSessionReducer";
import subUserSessionReducer from "./reducers/subUserSessionReducer";
import guestSessionReducer from "./reducers/guestSessionReducer";
import notarySessionReducer from "./reducers/notarySessionReducer";

import nocDataReducer from "./reducers/nocDataReducer";
import notificationsReducer from "./reducers/notificationsReducer";
import userPackagesReducer from "./reducers/userPackagesReducer";

export const createDynamicReducer = (sessionType) => {
  const dynamicReducers = {
    sessions: sessionsReducer, // always include the sessions reducer
    // global: globalReducer
  };

  // Add reducers based on session type
  if (sessionType === "userSession") {
    dynamicReducers.userSession = userSessionReducer;
  } else if (sessionType === "subUserSession") {
    dynamicReducers.subUserSession = subUserSessionReducer;
  } else if (sessionType === "notarySession") {
    dynamicReducers.notarySession = notarySessionReducer;
  } else if (sessionType === "guestSession") {
    dynamicReducers.guestSession = guestSessionReducer;
  }

  // Additional static reducers can be added here if needed
  dynamicReducers.nocData = nocDataReducer;
  dynamicReducers.notifications = notificationsReducer;
  dynamicReducers.userPackages = userPackagesReducer;

  return combineReducers(dynamicReducers);
};
