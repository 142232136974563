import {
  Box,
  Button,
  Grid2 as Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LockIcon from "@mui/icons-material/Lock";

import CreatePackageBanner from "./CreatePackageBanner";
import AddingPackageStepper from "./AddingPackageStepper";
import { useDispatch, useSelector } from "react-redux";
import SelectedPackage from "./SelectedPackage";
import AddingDocumentStepper from "./AddingDocumentStepper";
import { useState } from "react";
import {
  updateDocName,
  updatePackageName,
} from "../../store/actions/userPackagesActions";
import { currentProcessingFileBase } from "../../data/userPackage/userPackageBase";

const PackagePanelComponent = ({
  setPackagePanelOpen,
  currentProcessingFile,
  setCurrentProcessingFile,
  uploadError,
  setUploadError,
  selectedPackage,
  setSelectedPackage,
  addingNewDocument,
  setAddingNewDocument,
  addingNewPackage,
  setAddingNewPackage,
  currentFormsActive,
  setCurrentFormsActive,
  savedPackagesActive,
  setSavedPackagesActive,
  selectedPackageForm,
  setSelectedPackageForm,
  setExpandToolBox,
}) => {
  const userPackages = useSelector((state) => state.userPackages);
  const [updatingPackageName, setUpdatingPackageName] = useState(null);
  const [newPackageName, setNewPackageName] = useState("");
  const [updatingDocName, setUpdatingDocName] = useState(null);
  const [newDocName, setNewDocName] = useState("");

  const dispatch = useDispatch();

  const handleSelectPackage = (packageItem) => {
    if (packageItem?.documents?.length > 0) {
      setSelectedPackageForm(packageItem?.documents[0]);
      setCurrentProcessingFile(currentProcessingFileBase);
      setExpandToolBox(false);
    } else {
      setSelectedPackageForm(null);
    }

    setSelectedPackage(packageItem);
  };

  const generateRandomName = (nameType) => {
    const timestamp = Date.now().toString(); // Get current timestamp as string
    const lastFour = timestamp.slice(-4); // Extract last 4 digits
    return `${nameType} ${lastFour}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "90%",
        maxWidth: "1100px",
        height: "80vh",
        bgcolor: "white",
        boxShadow: 16,
        borderRadius: 2,
        overflow: "hidden",
        mx: "auto",
        my: 4,
      }}
    >
      <Grid container spacing={1} sx={{ p: 2, height: "100%" }}>
        {/* Left Panel */}
        <Grid
          size={{ xs: 3 }}
          sx={{
            borderRight: "3px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Fixed Header */}
          <Box
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 2,
              bgcolor: "white",
              p: 2,
              borderBottom: "3px solid #e0e0e0",
            }}
          >
            <Typography variant="h6" color="primary">
              Package Panel
            </Typography>
            <Button
              disabled={currentFormsActive}
              onClick={() => {
                setAddingNewPackage(true);
                setAddingNewDocument(false);

                if (selectedPackage) {
                  setSelectedPackage(null);
                  setSelectedPackageForm(null);
                  setCurrentProcessingFile(currentProcessingFileBase);
                  setExpandToolBox(false);
                }
                setCurrentFormsActive(false);
                setSavedPackagesActive(true);
              }}
              sx={{ mt: 1 }}
              size="small"
              variant={addingNewPackage ? "contained" : "outlined"}
              startIcon={<AddIcon />}
            >
              New Package
            </Button>
            <Button
              disabled={savedPackagesActive}
              onClick={() => {
                if (!addingNewDocument) {
                  setAddingNewDocument(true);
                }

                if (selectedPackageForm) {
                  setSelectedPackageForm(null);
                  setCurrentProcessingFile((prevFile) => ({
                    ...prevFile,
                    document: {
                      ...prevFile.document,
                      ...currentProcessingFileBase.document,
                    },
                  }));
                  setExpandToolBox(false);
                }
              }}
              sx={{ mt: 1 }}
              size="small"
              variant={addingNewDocument ? "contained" : "outlined"}
              startIcon={<AddIcon />}
            >
              Add Document
            </Button>
          </Box>

          {/* Scrollable Content */}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              mt: 1,
              borderBottom: "3px solid #e0e0e0",
            }}
          >
            {/* Handle empty package list */}
            {!userPackages?.userPackages ||
            userPackages?.userPackages.length === 0 ? (
              <>
                <Typography
                  fontWeight="bold"
                  variant="subtitle2"
                  align="center"
                >
                  Your Package is Empty
                </Typography>
                <Typography
                  fontWeight="bold"
                  variant="subtitle2"
                  align="center"
                >
                  Add Forms to Get Started
                </Typography>
              </>
            ) : savedPackagesActive ? (
              <Box sx={{ p: 0 }}>
                <List sx={{ p: 0 }}>
                  {userPackages?.userPackages?.map((packageItem, index) => {
                    const isSelected =
                      selectedPackage?.packageID === packageItem?.packageID; // ✅ Check if selected
                    const allLocked = packageItem.documents.every(
                      (doc) => doc.locked
                    );
                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          className={
                            isSelected
                              ? "selected-list-item"
                              : "unselected-list-item"
                          }
                          onClick={() => {
                            if (
                              updatingPackageName &&
                              packageItem?.packageID !== updatingPackageName
                            ) {
                              setUpdatingPackageName(null);
                              setNewPackageName("");
                            }
                            if (isSelected) {
                              return;
                            } else {
                              handleSelectPackage(packageItem);
                              if (!currentFormsActive) {
                                setCurrentFormsActive(true);
                                setSavedPackagesActive(false);
                              }
                            }
                          }}
                          onDoubleClick={() => {
                            setUpdatingPackageName(packageItem?.packageID);
                            setNewPackageName(packageItem?.packageName);
                          }}
                          disabled={addingNewPackage}
                        >
                          {packageItem?.packageID === updatingPackageName ? (
                            <input
                              type="text"
                              value={newPackageName}
                              onChange={(e) =>
                                setNewPackageName(e.target.value)
                              }
                              autoFocus
                              onBlur={() => {
                                if (
                                  newPackageName.trim() !==
                                  packageItem?.packageName
                                ) {
                                  let randomName = null;

                                  if (!newPackageName.trim()) {
                                    randomName = generateRandomName("Package");
                                  } else {
                                    randomName = newPackageName.trim();
                                  }

                                  dispatch(
                                    updatePackageName(
                                      packageItem?.packageID,
                                      randomName
                                    )
                                  );

                                  setCurrentProcessingFile((prevFile) => ({
                                    ...prevFile,
                                    packageName: randomName,
                                  }));
                                  setSelectedPackage((prevPackage) => ({
                                    ...prevPackage,
                                    packageName: randomName,
                                  }));
                                  setNewPackageName(null);
                                }
                                setUpdatingPackageName(null);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setUpdatingPackageName(null);
                                }
                              }}
                              style={{
                                font: "inherit", // ✅ Matches ListItemText font
                                border: "none", // ✅ Removes borders
                                background: "transparent", // ✅ Keeps background same
                                color: "inherit", // ✅ Keeps color same
                                fontWeight: "bold", // ✅ Matches ListItemText font weight
                                width: "100%", // ✅ Ensures full width
                                padding: 0,
                                paddingTop: "6.5px",
                                paddingBottom: "6.5px",
                                outline: "none", // ✅ Prevents focus outline
                                cursor: "text", // ✅ Matches normal text cursor
                              }}
                            />
                          ) : (
                            <>
                              <ListItemText
                                primary={
                                  packageItem?.documents?.length === 0
                                    ? `${packageItem.packageName} (Empty)`
                                    : packageItem.packageName
                                }
                              />
                              {allLocked && (
                                <LockIcon sx={{ fontSize: "16px" }} />
                              )}
                            </>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            ) : selectedPackage ? (
              <Box sx={{ p: 0 }}>
                <List sx={{ p: 0 }}>
                  {selectedPackage?.documents?.map((doc, index) => {
                    const isSelected =
                      selectedPackageForm?.formID === doc?.formID; // ✅ Check if selected

                    return (
                      <ListItem key={index} disablePadding>
                        <ListItemButton
                          className={
                            isSelected
                              ? "selected-list-item"
                              : "unselected-list-item"
                          }
                          onClick={() => {
                            if (
                              updatingDocName &&
                              doc?.formID !== updatingDocName
                            ) {
                              setUpdatingDocName(null);
                              setNewDocName("");
                            }
                            if (isSelected) {
                              return;
                            } else {
                              setSelectedPackageForm(doc);
                            }
                          }}
                          onDoubleClick={() => {
                            setUpdatingDocName(doc?.formID);
                            setNewDocName(doc?.document_name);
                          }}
                          disabled={addingNewPackage || addingNewDocument}
                        >
                          {doc?.formID === updatingDocName ? (
                            <input
                              type="text"
                              value={newDocName}
                              onChange={(e) => setNewDocName(e.target.value)}
                              autoFocus
                              onBlur={() => {
                                if (newDocName.trim() !== doc?.document_name) {
                                  let randomName = null;

                                  if (!newDocName.trim()) {
                                    randomName = generateRandomName("Document");
                                  } else {
                                    randomName = newDocName.trim();
                                  }

                                  dispatch(
                                    updateDocName(
                                      selectedPackage?.packageID,
                                      doc?.formID,
                                      randomName
                                    )
                                  );

                                  setCurrentProcessingFile((prevFile) => ({
                                    ...prevFile,
                                    document: {
                                      ...prevFile.document,
                                      document_name: randomName,
                                    },
                                  }));
                                  setSelectedPackage((prevPackage) => ({
                                    ...prevPackage,
                                    documents: [
                                      ...prevPackage.documents.map((item) =>
                                        item.formID === doc.formID
                                          ? {
                                              ...item,
                                              document_name: randomName,
                                            }
                                          : item
                                      ),
                                    ],
                                  }));
                                  setSelectedPackageForm((prevForm) => ({
                                    ...prevForm,
                                    document_name: randomName,
                                  }));
                                  setNewDocName(null);
                                }
                                setUpdatingDocName(null);
                              }}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  setUpdatingDocName(null);
                                }
                              }}
                              style={{
                                font: "inherit", // ✅ Matches ListItemText font
                                border: "none", // ✅ Removes borders
                                background: "transparent", // ✅ Keeps background same
                                color: "inherit", // ✅ Keeps color same
                                fontWeight: "bold", // ✅ Matches ListItemText font weight
                                width: "100%", // ✅ Ensures full width
                                padding: 0,
                                paddingTop: "6.5px",
                                paddingBottom: "6.5px",
                                outline: "none", // ✅ Prevents focus outline
                                cursor: "text", // ✅ Matches normal text cursor
                              }}
                            />
                          ) : (
                            <>
                              <ListItemText primary={doc.document_name} />
                              {doc?.locked && (
                                <LockIcon sx={{ fontSize: "16px" }} />
                              )}
                            </>
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            ) : (
              <Typography fontWeight="bold" variant="subtitle2" align="center">
                Select a Package to View Forms
              </Typography>
            )}
          </Box>

          {/* Fixed Pagination */}
          <Box
            sx={{
              mx: "auto",
              position: "sticky",
              bottom: 0,
              zIndex: 2,
              bgcolor: "white",
              pt: 1,

              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                pb: 1,
                width: "100%",
              }}
              gap={1}
            >
              <Button
                sx={{ mr: 1.5 }}
                size="small"
                disabled={addingNewPackage || !selectedPackage}
                variant={currentFormsActive ? "contained" : "outlined"}
                onClick={() => {
                  if (!currentFormsActive) {
                    setCurrentFormsActive(true);
                    setSavedPackagesActive(false);
                  }

                  if (
                    !selectedPackageForm &&
                    selectedPackage &&
                    !addingNewDocument
                  ) {
                    setSelectedPackageForm(selectedPackage?.documents[0]);
                  }
                }}
              >
                Documents
              </Button>
              <Button
                sx={{ mr: 1.5 }}
                disabled={addingNewPackage || addingNewDocument}
                size="small"
                variant={savedPackagesActive ? "contained" : "outlined"}
                onClick={() => {
                  if (!savedPackagesActive) {
                    setCurrentFormsActive(false);
                    setSavedPackagesActive(true);
                  }
                }}
              >
                Packages
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Pagination
                disabled={addingNewPackage}
                count={1}
                siblingCount={0}
                boundaryCount={0}
                shape="rounded"
                color="primary"
                sx={{
                  "& .MuiPaginationItem-root": { borderRadius: 0.5 },
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Right Panel (Scrollable) */}
        <Grid
          size={{ xs: 9 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          {/* Fixed Title */}
          {!selectedPackage && !addingNewPackage ? (
            <CreatePackageBanner setAddingNewPackage={setAddingNewPackage} />
          ) : addingNewPackage ? (
            <AddingPackageStepper
              currentProcessingFile={currentProcessingFile}
              setCurrentProcessingFile={setCurrentProcessingFile}
              setSelectedPackage={setSelectedPackage}
              setSelectedPackageForm={setSelectedPackageForm}
              setAddingNewPackage={setAddingNewPackage}
              uploadError={uploadError}
              setUploadError={setUploadError}
              setPackagePanelOpen={setPackagePanelOpen}
              selectedPackage={selectedPackage}
              selectedPackageForm={selectedPackageForm}
            />
          ) : addingNewDocument ? (
            <AddingDocumentStepper
              currentProcessingFile={currentProcessingFile}
              setCurrentProcessingFile={setCurrentProcessingFile}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              selectedPackageForm={selectedPackageForm}
              setSelectedPackageForm={setSelectedPackageForm}
              setAddingNewDocument={setAddingNewDocument}
              uploadError={uploadError}
              setUploadError={setUploadError}
              setPackagePanelOpen={setPackagePanelOpen}
            />
          ) : (
            <SelectedPackage
              currentProcessingFile={currentProcessingFile}
              setCurrentProcessingFile={setCurrentProcessingFile}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              setAddingNewPackage={setAddingNewPackage}
              setPackagePanelOpen={setPackagePanelOpen}
              selectedPackageForm={selectedPackageForm}
              setSelectedPackageForm={setSelectedPackageForm}
              savedPackagesActive={savedPackagesActive}
              currentFormsActive={currentFormsActive}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PackagePanelComponent;
