import axios from "axios";
import { loadNotifications } from "./notificationsActions";
import { addUserPackages } from "./userPackagesActions";
import { currentProcessingFileBase } from "../../data/userPackage/userPackageBase";

export const getFirstNocData = (sessionName) => async (dispatch, getState) => {
  try {
    // Perform both API requests concurrently
    const [res, notificationRes, userPackagesResp] = await Promise.all([
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-first-noc-data`,
        { sessionName: sessionName },
        { withCredentials: true }
      ),
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/notification/load-notifications`,
        { sessionName: sessionName },
        { withCredentials: true }
      ),
      axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/initial-load`,
        {
          sessionName: sessionName,
          pagination: {
            package: {
              packageLimit: 5,
              packagePage: 1,
              totalPackageRecord: 0,
            },
            form: {
              formLimit: 5,
              formPage: 1,
              totalFormRecord: 0,
            },
          },
        },
        { withCredentials: true }
      ),
    ]);

    const { totalPackageRecord, userPackages } = userPackagesResp.data;

    const updatedUserPackages = userPackages.map((packageItem) => ({
      ...packageItem,
      documents: packageItem.documents.map((doc) => ({
        ...doc, // Keep original document properties
        tempFields: currentProcessingFileBase.document.tempFields,
      })),
    }));

    dispatch(addUserPackages(updatedUserPackages, totalPackageRecord));

    dispatch(loadNotifications(notificationRes?.data?.data));

    await dispatch({
      type: "GET_NOC_DATA",
      payload: res?.data?.data,
    });

    return;
  } catch (error) {}
};

export const setUpgradeRequested = (upgrading) => {
  return {
    type: "SET_UPGRADE_REQUESTED",
    payload: upgrading,
  };
};

export const updateCustomerCoupon = (id, coupon) => {
  return {
    type: "UPDATE_CUSTOMER_COUPON",
    payload: { id, coupon },
  };
};

export const getPermitPackages =
  (gridIndex, rowSize, activeTab, forwardNotification) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const currentSession = state.sessions?.currentSession;

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/building/get-permit-package`,
        {
          gridIndex: gridIndex,
          rowSize: rowSize,
          activeTab: activeTab,
          currentSession: currentSession,
          forwardNotification: forwardNotification,
        },
        { withCredentials: true }
      );

      if (resp?.data?.data) {
        await dispatch({
          type: "GET_PERMIT_PACKAGES",
          payload: resp?.data?.data?.documents,
        });

        if (forwardNotification && forwardNotification?.packageId) {
          return resp?.data?.data;
        } else {
          return resp?.data?.data?.total_count;
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

export const getCustomers = (newPage) => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentSession = state.sessions?.currentSession;
    const customers = state.nocData?.customers;
    const page =
      newPage || newPage === 0 ? newPage : customers?.pagination?.page;
    const rowsPerPage = customers?.pagination?.rowsPerPage;

    const resp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/admin/getUsers`,
      {
        page: page,
        rowsPerPage: rowsPerPage,
        currentSession: currentSession,
      },
      { withCredentials: true }
    );

    if (resp?.data?.success) {
      await dispatch({
        type: "SET_CUSTOMERS",
        payload: resp?.data?.data,
      });

      return resp?.data?.data?.rows;
    }
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const getSavedProjects = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    if (currentSession) {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/get-saved-projects`,
        {
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      await dispatch({
        type: "FIRST_ADD_SAVED_PROJECTS",
        payload: resp?.data,
      });

      return;
    }
  } catch (error) {
    console.error(error);
  }
};

export const handleSaveProjects = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentSession = state.sessions?.currentSession;
    const saved_projects = state.nocData?.saved_projects;
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/data/save-projects`,
      { sessionName: currentSession, saved_projects: saved_projects },
      { withCredentials: true }
    );
  } catch (error) {
    console.error(error);
  }
};

export const clearNotificationCount = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const currentSession = state.sessions?.currentSession;
    await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/noc/clear-notification-count`,
      { currentSession: currentSession },
      { withCredentials: true }
    );
  } catch (error) {
    console.error(error);
  }

  await dispatch({
    type: "UPDATE_NOTIFICATION_COUNT",
    payload: 0,
  });

  return;
};

export const handleMarkDemoShown = () => {
  return {
    type: "ENABLE_DEMO_SHOWN",
  };
};

export const removeShowDemo = () => {
  return {
    type: "REMOVE_SHOW_DEMO",
  };
};

export const clearShowDemo = () => {
  return {
    type: "CLEAR_SHOW_DEMO",
  };
};

export const setPrevTourStep = (step) => {
  return {
    type: "SET_PREV_TOUR_STEP",
    payload: step,
  };
};

export const setShowTourNav = (bool) => {
  return {
    type: "SET_SHOW_TOUR_NAV",
    payload: bool,
  };
};

export const pushCityLog = (data) => {
  return {
    type: "PUSH_CITY_LOG",
    payload: data,
  };
};

export const addNewNotification = (data) => {
  return {
    type: "ADD_NEW_NOTIFICATION",
    payload: data,
  };
};

export const updateNotification = (data) => {
  return {
    type: "UPDATE_NOTIFICATION",
    payload: data,
  };
};

export const updateNotificationCount = (data) => {
  return {
    type: "UPDATE_NOTIFICATION_COUNT",
    payload: data,
  };
};

export const updatePermitNocForm = (noc, id) => {
  return {
    type: "UPDATE_PERMIT_NOC_FORM",
    payload: { noc: noc, task_id: id },
  };
};

export const updatePermitPackage = (obj) => {
  return {
    type: "UPDATE_PERMIT_PACKAGE",
    payload: obj,
  };
};

export const updatePermitPackageName = (data) => {
  return {
    type: "UPDATE_PERMIT_PACKAGE_NAME",
    payload: data,
  };
};

export const updatePermitPackageFromReturn = (obj) => {
  return {
    type: "UPDATE_PERMIT_PACKAGE_FROM_RETURN",
    payload: obj,
  };
};
export const updatePermitPackageOnFly = (obj) => {
  return {
    type: "UPDATE_PERMIT_PACKAGE_ON_FLY",
    payload: obj,
  };
};

export const resetScrapedFormState = (id) => {
  return {
    type: "RESET_SCRAPED_FORM_RETURN",
    payload: id,
  };
};

export const addPermitPackageSignedUrl = (document) => {
  return {
    type: "ADD_PERMIT_PACKAGE_SIGNED_URL",
    payload: document,
  };
};

export const addAvailablePermitForms = (forms) => {
  return {
    type: "ADD_AVAILABLE_PERMIT_FORMS",
    payload: forms,
  };
};
export const setPrevCityCounty = (data) => {
  return {
    type: "SET_PREV_CITY_COUNTY",
    payload: data,
  };
};

export const addCurrentPermitDocument = (document) => {
  return {
    type: "UPDATE_PERMIT_DOCUMENT",
    payload: document,
  };
};

export const removePermitDocument = () => {
  return {
    type: "REMOVE_PERMIT_DOCUMENT",
  };
};

export const addPaisTemplate = (data) => {
  return {
    type: "ADD_PAIS_TEMPLATE",
    payload: data,
  };
};

export const addSavedProjects = (data) => {
  return {
    type: "ADD_SAVED_PROJECTS",
    payload: data,
  };
};

export const pushNewProject = (data) => {
  return {
    type: "PUSH_NEW_PROJECT",
    payload: data,
  };
};

export const updateNewProject = (data) => {
  return {
    type: "UPDATE_NEW_PROJECT",
    payload: data,
  };
};

export const pushNewFeeSimple = (data) => {
  return {
    type: "PUSH_NEW_FEE_SIMPLE",
    payload: data,
  };
};

export const updateNewFeeSimple = (data) => {
  return {
    type: "UPDATE_NEW_FEE_SIMPLE",
    payload: data,
  };
};

export const pushNewBonding = (data) => {
  return {
    type: "PUSH_NEW_BONDING",
    payload: data,
  };
};

export const updateNewBonding = (data) => {
  return {
    type: "UPDATE_NEW_BONDING",
    payload: data,
  };
};

export const pushNewMortgage = (data) => {
  return {
    type: "PUSH_NEW_MORTGAGE",
    payload: data,
  };
};

export const updateNewMortgage = (data) => {
  return {
    type: "UPDATE_NEW_MORTGAGE",
    payload: data,
  };
};

export const removeSavedProject = (id) => {
  return {
    type: "REMOVE_SAVED_PROJECT",
    payload: id,
  };
};

export const enableEmptyCountyFlag = (path) => {
  return {
    type: "ENABLE_EMPTY_COUNTY_FLAG",
    payload: path,
  };
};

export const disableEmptyCountyFlag = () => {
  return {
    type: "DISABLE_EMPTY_COUNTY_FLAG",
  };
};

export const setNewTempPermitData = (data) => {
  return {
    type: "SET_NEW_TEMP_PERMIT_DATA",
    payload: data,
  };
};

export const clearNewTempPermitData = () => {
  return {
    type: "CLEAR_NEW_TEMP_PERMIT_DATA",
  };
};

export const removeFeeSimple = (id) => {
  return {
    type: "REMOVE_FEE_SIMPLE",
    payload: id,
  };
};

export const removeBonding = (id) => {
  return {
    type: "REMOVE_BONDING",
    payload: id,
  };
};

export const removeMortgage = (id) => {
  return {
    type: "REMOVE_MORTGAGE",
    payload: id,
  };
};

export const getLiveNocData = (nocData) => {
  return {
    type: "GET_NOC_DATA",
    payload: nocData,
  };
};

export const getPdfProgress = (nocData) => {
  return {
    type: "GET_PDF_PROGRESS",
    payload: nocData,
  };
};

export const clearPdfProgress = (id) => {
  return {
    type: "CLEAR_PDF_PROGRESS",
    payload: id,
  };
};

export const setCreatedNOC = (nocData) => {
  return {
    type: "SET_CREATED_NOC",
    payload: nocData,
  };
};

export const clearCreatedNOC = () => {
  return {
    type: "CLEAR_CREATED_NOC",
  };
};

export const onReadyToFetch = () => {
  return {
    type: "ON_READY_TO_FETCH",
  };
};

export const offReadyToFetch = () => {
  return {
    type: "OFF_READY_TO_FETCH",
  };
};
