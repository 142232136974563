import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { pdfjs, Document, Page } from "react-pdf";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import EditIcon from "@mui/icons-material/Edit";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Pagination,
  Slider,
  Typography,
} from "@mui/material";
import SigningsOverlay from "../uploaderComponents/SigningsOverlay";
import FormFieldToolsComponent from "../uploaderComponents/FormFieldToolsComponent";
import { extractFormFields } from "../uploaderComponents/helpers/processPDF";
import { handleDynamicFontResize } from "../uploaderComponents/helpers/processFont";
import { handleMouseDown } from "../uploaderComponents/helpers/mouseEvents.js/mouseDown";
import { handleMouseMove } from "../uploaderComponents/helpers/mouseEvents.js/mouseMove";
import { handleMouseUp } from "../uploaderComponents/helpers/mouseEvents.js/mouseUp";
import { handleKeyDown } from "../uploaderComponents/helpers/keyEvents.js/keyDown";
import { handleDisableFormToolMenus } from "../uploaderComponents/helpers/FormFieldMenus/disableToolMenus";
import PackagePanelComponent from "../uploaderComponents/PackagePanelComponent";
import { currentProcessingFileBase } from "../../data/userPackage/userPackageBase";
import { useDispatch, useSelector } from "react-redux";
import { updateEditingForm } from "../../store/actions/userPackagesActions";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const TestUploader = () => {
  const { userPackages } = useSelector((state) => state.userPackages);
  const [uploadError, setUploadError] = useState("");
  const [currentProcessingFile, setCurrentProcessingFile] = useState(
    currentProcessingFileBase
  );
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const childInputRef = useRef(null);
  const pdfContainerRef = useRef(null);

  const [expandToolBox, setExpandToolBox] = useState(false);
  const [highlightFields, setHighlightFields] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const [selectToolActive, setSelectToolActive] = useState(true);
  const [typingToolActive, setTypingToolActive] = useState(false);

  const [resizing, setResizing] = useState(false);
  const [moving, setMoving] = useState(false);
  const [currCursor, setCurrCursor] = useState("default");
  const [resizeDirection, setResizeDirection] = useState(null);
  const [initialMousePosition, setInitialMousePosition] = useState({
    x: 0,
    y: 0,
  });
  const [initialInputSize, setInitialInputSize] = useState({
    width: 0,
    height: 0,
  });
  const [inputPosition, setInputPosition] = useState({ x: 0, y: 0 });
  const [isDrawing, setIsDrawing] = useState(false);
  const [rect, setRect] = useState(null);
  const [startPoint, setStartPoint] = useState(null);
  const [reverseDeleted, setReverseDeleted] = useState([]);

  const [isSelecting, setIsSelecting] = useState(false);
  const [freeSelectStartPoint, setFreeSelectStartPoint] = useState(null);
  const [selectionRect, setSelectionRect] = useState(null);

  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedPackageForm, setSelectedPackageForm] = useState(null);

  const [packagePanelOpen, setPackagePanelOpen] = useState(true);

  const [addingNewPackage, setAddingNewPackage] = useState(false);
  const [addingNewDocument, setAddingNewDocument] = useState(false);

  const [currentFormsActive, setCurrentFormsActive] = useState(false);
  const [savedPackagesActive, setSavedPackagesActive] = useState(true);

  const fixedInputs = [
    "notary_seal_a",
    "notary_sign_a",
    "notary_seal_b",
    "notary_sign_b",
    "sign_a",
    "sign_b",
    "sign_c",
  ];

  useEffect(() => {
    if (currentProcessingFile?.document?.tempFields?.formFields) {
      handleDisableFormToolMenus(
        currentProcessingFile,
        setCurrentProcessingFile
      );
    }
  }, [currentProcessingFile?.document?.tempFields?.formFields]);

  const handlePackagePanelOpen = async () => {
    if (
      currentProcessingFile?.document?.tempFields?.currentDocument?.actualHeight
    ) {
      const { document, ...packageBase } = currentProcessingFile;

      await dispatch(updateEditingForm(currentProcessingFile));

      // Find the correct package from Redux
      const prevOldPackage = userPackages.find(
        (pkg) => pkg.packageID === packageBase.packageID
      );

      // Ensure we have the correct package
      if (!prevOldPackage) {
        console.error("Package not found in Redux!");
        return;
      }

      // ✅ Update only the matching document while keeping all other documents
      const updatedDocuments = prevOldPackage.documents.map((doc) =>
        doc.formID === document.formID
          ? {
              ...doc,
              tempFields: {
                ...doc.tempFields,
                currentDocument: {
                  ...doc.tempFields.currentDocument,
                  ...document.tempFields.currentDocument, // ✅ Ensure currentDocument is updated properly
                },
              },
            }
          : doc
      );

      setSelectedPackage({
        ...prevOldPackage, // ✅ Use previous package data
        ...packageBase, // ✅ Update package-level data
        documents: updatedDocuments, // ✅ Replace only the correct document
      });

      // ✅ Update selected form inside the package
      setSelectedPackageForm(document);
    }

    setPackagePanelOpen(true);
    setCurrentFormsActive(true);
    setSavedPackagesActive(false);
  };

  const handleClickOutside = (event) => {
    if (resizing) return;
    // Check if the click is inside the PDF viewer container
    const isInsidePdfViewer =
      pdfContainerRef.current && pdfContainerRef.current.contains(event.target);

    // Check if the click is inside the input field
    const isInsideInputField =
      inputRef.current && inputRef.current.contains(event.target);

    const isInsideChildInputField =
      childInputRef.current && childInputRef.current.contains(event.target);

    // If the click is inside the PDF viewer but outside of the input field, clear the selected form field
    if (isInsidePdfViewer && !isInsideInputField && !isInsideChildInputField) {
      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            selectedFormField: null,
            selectedExtraFields: [],
          },
        },
      }));
    }
  };

  useEffect(() => {
    const handleWheel = (e) => {
      if (
        !currentProcessingFile?.document?.tempFields?.currentDocument.base64File
      )
        return;

      if (e.ctrlKey) {
        e.preventDefault(); // Prevent default zooming behavior

        if (e.deltaY < 0) {
          if (
            currentProcessingFile?.document?.tempFields?.currentDocument
              .pdfSize > 1500
          )
            return;
          setCurrentProcessingFile((prevFile) => ({
            ...prevFile,
            document: {
              ...prevFile.document,
              tempFields: {
                ...prevFile.document.tempFields,
                currentDocument: {
                  ...prevFile.document.tempFields.currentDocument,
                  pdfSize:
                    prevFile.document.tempFields.currentDocument?.pdfSize + 50,
                },
              },
            },
          }));
        } else {
          if (
            currentProcessingFile?.document?.tempFields?.currentDocument
              .pdfSize < 500
          )
            return;
          setCurrentProcessingFile((prevFile) => ({
            ...prevFile,
            document: {
              ...prevFile.document,
              tempFields: {
                ...prevFile.document.tempFields,
                currentDocument: {
                  ...prevFile.document.tempFields.currentDocument,
                  pdfSize:
                    prevFile.document.tempFields.currentDocument?.pdfSize - 50,
                },
              },
            },
          }));
        }
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, [currentProcessingFile?.document?.tempFields?.currentDocument]);

  const handleKeyDownWrapper = (e) => {
    handleKeyDown(
      e,
      currentProcessingFile,
      setCurrentProcessingFile,
      typingToolActive,
      setReverseDeleted,
      setCurrCursor,
      selectToolActive,
      focusedField,
      setTypingToolActive,
      setSelectToolActive,
      currCursor,
      setFocusedField,
      setHighlightFields,
      expandToolBox,
      setExpandToolBox
    );
  };

  useEffect(() => {
    // Attach mousemove and mouseup events to the document
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("keydown", handleKeyDownWrapper);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("keydown", handleKeyDownWrapper);
    };
  }, [
    resizing,
    moving,
    isDrawing,
    isSelecting,
    currentProcessingFile?.document?.tempFields?.selectedFormField,
    typingToolActive,
    focusedField,
    selectToolActive,
    currentProcessingFile?.document?.tempFields?.formFieldTools,
    reverseDeleted,
  ]);

  // Attach the event listener to the document
  useEffect(() => {
    document.addEventListener("dblclick", handleClickOutside);

    return () => {
      document.removeEventListener("dblclick", handleClickOutside);
    };
  }, []);

  const onDocumentLoadSuccess = (pdf) => {
    const { numPages } = pdf;

    // Fetch the dimensions of the first page
    pdf.getPage(1).then((page) => {
      const viewport = page.getViewport({ scale: 1 }); // Scale of 1 gives actual dimensions

      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            currentDocument: {
              ...prevFile.document.tempFields.currentDocument,
              numPages,
              actualWidth: viewport.width,
              actualHeight: viewport.height,
            },
          },
        },
      }));
    });
  };

  const handleFieldChange = (e, field) => {
    const newValue = e.target.value;

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          formFields: prevFile.document.tempFields.formFields.map((f) => {
            if (f.name !== field.name) return f;

            // If autoFontSize is false, only update the value
            if (!f.autoFontSize) {
              return { ...f, value: newValue };
            }

            const { newFontSize, fontSizes } = handleDynamicFontResize(
              field.width,
              field.height,
              f.fontSize,
              newValue,
              f.fontSizes || [],
              f.value
            );

            return {
              ...f,
              value: newValue,
              fontSize: newFontSize, // Apply correct converted font size
              fontSizes: fontSizes, // Store history in correct format
            };
          }),
        },
      },
    }));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box
        sx={{
          position: "relative",
          height: "calc(100vh - 64px)", // Full height
          display: "flex",
          overflow: "auto", // Prevent global scrolling
          backgroundColor: "#e4e4e4",
        }}
      >
        {/* Left Toolbar */}
        <Box
          sx={{
            width: "20%",
            maxWidth: "20rem",
            minWidth: "15rem",
            px: "1rem",
            pt: "1rem",
            background: "#f4f4f4",
            display: "flex",
            flexDirection: "column", // Ensure child elements are stacked vertically
            position: "sticky", // Make the sidebar sticky
            top: 0, // Stick it to the top of the viewport
            height: "calc(100vh - 84px)", // Full height minus header/footer
            overflowY: "auto", // Scrollable content for overflow
            overflowX: "hidden", // Hide horizontal overflow
          }}
        >
          {/* Toolbar Header */}
          <Box>
            <Typography
              color="primary"
              align="center"
              variant={"h5"}
              mb={1}
              sx={{ zIndex: 200 }}
            >
              Document Uploader
            </Typography>
            <Box sx={{ borderBottom: "3px solid #d3d3d3" }}></Box>

            {/* Toolbox */}
            <Box
              sx={{
                height: "calc(100vh - 390px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Typography variant="h5" align="center" mt={1}>
                Tools
              </Typography>
              <Box
                gap={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ my: 1 }}>
                  <Button
                    size="small"
                    startIcon={<SelectAllIcon />}
                    onClick={() => {
                      setCurrCursor("default");
                      setTypingToolActive(false);
                      setSelectToolActive(true);
                      setCurrentProcessingFile((prevFile) => ({
                        ...prevFile,
                        document: {
                          ...prevFile.document,
                          tempFields: {
                            ...prevFile.document.tempFields,
                            selectedFormField: null,
                            selectedExtraFields: [],
                            formFieldTools: Object.keys(
                              prevFile.document.tempFields.formFieldTools
                            ).reduce((acc, key) => {
                              acc[key] = {
                                ...prevFile.document.tempFields.formFieldTools[
                                  key
                                ],
                                active: false,
                                values: [],
                              };
                              return acc;
                            }, {}),
                          },
                        },
                      }));
                    }}
                    variant={selectToolActive ? "contained" : "outlined"}
                    sx={{ justifyContent: "flex-start" }}
                    fullWidth
                  >
                    Select
                  </Button>
                </Box>
                <Box sx={{ my: 1 }}>
                  <Button
                    size="small"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setCurrCursor("default");
                      setTypingToolActive(true);
                      setSelectToolActive(false);
                      setCurrentProcessingFile((prevFile) => ({
                        ...prevFile,
                        document: {
                          ...prevFile.document,
                          tempFields: {
                            ...prevFile.document.tempFields,
                            selectedFormField: null,
                            selectedExtraFields: [],
                          },
                        },
                      }));
                    }}
                    variant={typingToolActive ? "contained" : "outlined"}
                    sx={{ justifyContent: "flex-start" }}
                    fullWidth
                  >
                    Typing
                  </Button>
                </Box>
              </Box>

              <FormFieldToolsComponent
                currentProcessingFile={currentProcessingFile}
                setCurrentProcessingFile={setCurrentProcessingFile}
                expandToolBox={expandToolBox}
                setExpandToolBox={setExpandToolBox}
                setSelectToolActive={setSelectToolActive}
                typingToolActive={typingToolActive}
                setTypingToolActive={setTypingToolActive}
                setCurrCursor={setCurrCursor}
                fixedInputs={fixedInputs}
              />
            </Box>
          </Box>

          {!currentProcessingFile?.document?.tempFields?.currentDocument
            .base64File && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "94%",
                backgroundColor: "rgba(0, 0, 0, 0.1)", // Slightly transparent overlay
                zIndex: 2, // Ensure overlay is on top
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></Box>
          )}

          {/* Bottom Content */}
          <Box sx={{ mt: "auto", py: 0.5, mb: 1 }}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={highlightFields}
                    onChange={(e) => setHighlightFields(e.target.checked)}
                  />
                }
                label={
                  <Typography variant="body2" fontWeight="bold">
                    Highlight Fields
                  </Typography>
                }
              />
            </Box>
            <Box sx={{ borderBottom: "3px solid #d3d3d3", mt: 0 }}></Box>
            {/* PDF Scaling Slider */}
            <Box sx={{ mt: 1 }}>
              <h3 style={{ marginBottom: 0 }}>Scale PDF</h3>
              <Slider
                value={
                  currentProcessingFile?.document?.tempFields?.currentDocument
                    .pdfSize || 1000
                }
                min={500}
                max={1500}
                step={10} // Adjust step as needed
                valueLabelDisplay="auto" // Display the current value as a tooltip
                onChange={(e, newValue) =>
                  setCurrentProcessingFile((prevFile) => ({
                    ...prevFile,
                    document: {
                      ...prevFile.document,
                      tempFields: {
                        ...prevFile.document.tempFields,
                        currentDocument: {
                          ...prevFile.document.tempFields.currentDocument,
                          pdfSize: newValue,
                        },
                      },
                    },
                  }))
                }
                sx={{
                  color: "primary.main", // Customize the slider color (optional)
                }}
              />
            </Box>

            <Box sx={{ borderBottom: "3px solid #d3d3d3", mt: 2 }}></Box>
            {/* Pagination */}
            <h3>Navigate Pages</h3>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                size="small"
                variant="outlined"
                shape="rounded"
                count={
                  currentProcessingFile?.document?.tempFields?.currentDocument
                    ?.numPages
                } // Number of pages
                page={
                  currentProcessingFile?.document?.tempFields?.currentDocument
                    ?.pageNumber
                } // Current page
                color="primary"
                onChange={(e, page) => {
                  setCurrentProcessingFile((prevFile) => ({
                    ...prevFile,
                    document: {
                      ...prevFile.document,
                      tempFields: {
                        ...prevFile.document.tempFields,
                        currentDocument: {
                          ...prevFile.document.tempFields.currentDocument,
                          pageNumber: page,
                        },
                      },
                    },
                  }));
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* Main Content Area */}
        <Box
          sx={{
            my: "auto",
            flex: 1, // Take remaining space
            display: "flex",
            flexDirection: "column", // Arrange content vertically
            overflowY: "auto", // Allow scrolling for the main content
          }}
        >
          {currentProcessingFile?.document?.tempFields?.currentDocument
            .base64File &&
            !packagePanelOpen && (
              <>
                {/* Action Buttons */}
                <Box
                  sx={{
                    mt: 1,
                    mr: 3,
                    display: "flex",
                    justifyContent: "flex-end",
                    right: 0,
                    position: "fixed",
                    zIndex: 100,
                  }}
                >
                  {/* <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      if (
                        window.confirm(
                          "Are you sure you want to discard this PDF?"
                        )
                      ) {
                        setCurrentProcessingFile(currentProcessingFileBase);
                        setCurrCursor("default");
                        setExpandToolBox(false);
                        setHighlightFields(false);
                        setFocusedField(null);
                        setSelectToolActive(true);
                        setTypingToolActive(false);
                        setResizing(false);
                        setMoving(false);
                        setResizeDirection(false);
                        setIsDrawing(false);
                        setReverseDeleted([]);
                        setIsSelecting(false);
                      }
                    }}
                    sx={{ backgroundColor: "#fff" }}
                  >
                    Discard PDF
                  </Button> */}
                  {/* <Button
                    onClick={() => {
                      setPackagePanelOpen(true);
                    }}
                    variant="contained"
                    color="success"
                    sx={{ ml: 2 }}
                  >
                    Package Panel
                  </Button> */}
                </Box>

                {/* PDF Viewer */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#e4e4e4",
                  }}
                >
                  <Box
                    ref={pdfContainerRef}
                    sx={{
                      position: "relative", // Ensure fields are placed relative to this container
                      display: "inline-block", // Align tightly to the PDF content
                      my: 2,
                      width: `${currentProcessingFile?.document?.tempFields?.currentDocument.pdfSize}px`, // Match PDF width dynamically
                      maxHeight: "100%", // Ensure height scales
                      border: "2px solid #d3d3d3",
                      borderRadius: "18px",
                      boxShadow: "3px 4px 12px rgba(0, 0, 0, 0.3)",
                      background: "#fff",
                      cursor: selectToolActive ? currCursor : "default",
                      userSelect: "none",
                    }}
                    onMouseDown={(e) => {
                      const isToolActive = Object.values(
                        currentProcessingFile?.document?.tempFields
                          ?.formFieldTools
                      ).some((field) => field.active);
                      if (isToolActive) {
                        handleMouseDown(
                          e,
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          null,
                          null,
                          "fieldTools",
                          "crosshair",
                          pdfContainerRef,
                          setStartPoint,
                          setRect,
                          setIsDrawing,
                          setCurrCursor,
                          setFreeSelectStartPoint,
                          setMoving,
                          setIsSelecting,
                          setInitialMousePosition,
                          setInitialInputSize,
                          setInputPosition,
                          setSelectionRect,
                          setResizing,
                          setResizeDirection
                        );
                      } else if (selectToolActive && !isToolActive) {
                        handleMouseDown(
                          e,
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          null,
                          null,
                          "freeSelect",
                          "default",
                          pdfContainerRef,
                          setStartPoint,
                          setRect,
                          setIsDrawing,
                          setCurrCursor,
                          setFreeSelectStartPoint,
                          setMoving,
                          setIsSelecting,
                          setInitialMousePosition,
                          setInitialInputSize,
                          setInputPosition,
                          setSelectionRect,
                          setResizing,
                          setResizeDirection
                        );
                      } else return;
                    }}
                    onMouseMove={(e) => {
                      const isToolActive = Object.values(
                        currentProcessingFile?.document?.tempFields
                          ?.formFieldTools
                      ).some((field) => field.active);

                      if (isToolActive) {
                        handleMouseMove(
                          e,
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          pdfContainerRef,
                          resizing,
                          initialMousePosition,
                          initialInputSize,
                          inputPosition,
                          resizeDirection,
                          moving,
                          isDrawing,
                          startPoint,
                          setRect,
                          isSelecting,
                          freeSelectStartPoint,
                          setSelectionRect
                        );
                      } else if (selectToolActive && !isToolActive) {
                        handleMouseMove(
                          e,
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          pdfContainerRef,
                          resizing,
                          initialMousePosition,
                          initialInputSize,
                          inputPosition,
                          resizeDirection,
                          moving,
                          isDrawing,
                          startPoint,
                          setRect,
                          isSelecting,
                          freeSelectStartPoint,
                          setSelectionRect
                        );
                      } else return;
                    }}
                    onMouseUp={() => {
                      const isToolActive = Object.values(
                        currentProcessingFile?.document?.tempFields
                          ?.formFieldTools
                      ).some((field) => field.active);
                      if (isToolActive) {
                        handleMouseUp(
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          resizing,
                          setResizing,
                          setCurrCursor,
                          moving,
                          setMoving,
                          isDrawing,
                          rect,
                          setIsDrawing,
                          setRect,
                          fixedInputs,
                          isSelecting,
                          setIsSelecting,
                          setSelectionRect,
                          setFreeSelectStartPoint
                        );
                      } else if (selectToolActive && !isToolActive) {
                        handleMouseUp(
                          currentProcessingFile,
                          setCurrentProcessingFile,
                          resizing,
                          setResizing,
                          setCurrCursor,
                          moving,
                          setMoving,
                          isDrawing,
                          rect,
                          setIsDrawing,
                          setRect,
                          fixedInputs,
                          isSelecting,
                          setIsSelecting,
                          setSelectionRect,
                          setFreeSelectStartPoint
                        );
                      } else return;
                    }}
                  >
                    <Document
                      file={
                        currentProcessingFile?.document?.tempFields
                          ?.currentDocument.base64File
                      } // Pass Data URL directly
                      onLoadSuccess={onDocumentLoadSuccess}
                      onLoadError={(error) =>
                        console.error("Error rendering PDF:", error)
                      }
                    >
                      <Page
                        pageNumber={
                          currentProcessingFile?.document?.tempFields
                            ?.currentDocument?.pageNumber
                        }
                        renderAnnotationLayer={true}
                        renderTextLayer={true}
                        width={
                          currentProcessingFile?.document?.tempFields
                            ?.currentDocument?.pdfSize
                        }
                      />
                    </Document>

                    {isDrawing && (
                      <Box
                        sx={{
                          position: "absolute",
                          left: `${rect.x}px`,
                          top: `${rect.y}px`,
                          width: `${rect.width}px`,
                          height: `${rect.height}px`,
                          border: "2px dotted blue",
                        }}
                      />
                    )}

                    {isSelecting && (
                      <Box
                        sx={{
                          position: "absolute",
                          left: `${selectionRect.x}px`,
                          top: `${selectionRect.y}px`,
                          width: `${selectionRect.width}px`,
                          height: `${selectionRect.height}px`,
                          border: "2px dotted blue",
                        }}
                      />
                    )}

                    {currentProcessingFile?.document?.tempFields?.formFieldTools.textField.values?.map(
                      (field, index) => (
                        <Box
                          key={index}
                          sx={{
                            position: "absolute",
                            left: `${field.x}px`,
                            top: `${field.y}px`,
                            width: `${field.width}px`,
                            height: `${field.height}px`,
                            border: "1px solid #000",
                          }}
                        />
                      )
                    )}

                    {/* Overlay Form Fields */}
                    {currentProcessingFile?.document?.tempFields?.formFields
                      .filter(
                        (field) =>
                          field.page ===
                          currentProcessingFile?.document?.tempFields
                            ?.currentDocument?.pageNumber
                      ) // Only fields for the current page
                      .map((field, index) => {
                        const scaleFactor =
                          currentProcessingFile?.document?.tempFields
                            ?.currentDocument.pdfSize /
                          currentProcessingFile?.document?.tempFields
                            ?.currentDocument.actualWidth;

                        // For text fields or other field types
                        return (
                          <React.Fragment key={index}>
                            {currentProcessingFile?.document?.tempFields
                              ?.selectedFormField &&
                              currentProcessingFile?.document?.tempFields
                                ?.selectedFormField?.name === field?.name &&
                              selectToolActive && (
                                <Box
                                  ref={inputRef}
                                  sx={{
                                    position: "absolute",
                                    left:
                                      field.type === "PDFCheckBox" ||
                                      field.type === "PDFRadioGroup"
                                        ? `${field.x * scaleFactor + 2}px`
                                        : `${field.x * scaleFactor}px`,
                                    top:
                                      field.type === "PDFCheckBox" ||
                                      field.type === "PDFRadioGroup"
                                        ? `${
                                            (currentProcessingFile?.document
                                              ?.tempFields?.currentDocument
                                              .actualHeight -
                                              field.y -
                                              field.height) *
                                              scaleFactor +
                                            1
                                          }px`
                                        : `${
                                            (currentProcessingFile?.document
                                              ?.tempFields?.currentDocument
                                              .actualHeight -
                                              field.y -
                                              field.height) *
                                            scaleFactor
                                          }px`,
                                    width:
                                      currentProcessingFile?.document
                                        ?.tempFields?.selectedFormField &&
                                      field.name ===
                                        currentProcessingFile?.document
                                          ?.tempFields?.selectedFormField.name
                                        ? `${
                                            currentProcessingFile?.document
                                              ?.tempFields?.selectedFormField
                                              .width * scaleFactor
                                          }px`
                                        : `${field.width * scaleFactor}px`,
                                    height:
                                      currentProcessingFile?.document
                                        ?.tempFields?.selectedFormField &&
                                      field.name ===
                                        currentProcessingFile?.document
                                          ?.tempFields?.selectedFormField.name
                                        ? `${
                                            currentProcessingFile?.document
                                              ?.tempFields?.selectedFormField
                                              .height * scaleFactor
                                          }px`
                                        : `${field.height * scaleFactor}px`,
                                    border: "2px dotted blue",
                                    background: "transparent",
                                    zIndex: 100,
                                  }}
                                  onMouseDown={(e) =>
                                    handleMouseDown(
                                      e,
                                      currentProcessingFile,
                                      setCurrentProcessingFile,
                                      field,
                                      scaleFactor,
                                      "move",
                                      "default",
                                      pdfContainerRef,
                                      setStartPoint,
                                      setRect,
                                      setIsDrawing,
                                      setCurrCursor,
                                      setFreeSelectStartPoint,
                                      setMoving,
                                      setIsSelecting,
                                      setInitialMousePosition,
                                      setInitialInputSize,
                                      setInputPosition,
                                      setSelectionRect,
                                      setResizing,
                                      setResizeDirection
                                    )
                                  }
                                  onDoubleClick={() => {
                                    if (
                                      currentProcessingFile?.document
                                        ?.tempFields?.selectedExtraFields
                                        .length > 1
                                    ) {
                                      setCurrentProcessingFile((prevFile) => ({
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedExtraFields:
                                              prevFile.document.tempFields.selectedExtraFields.filter(
                                                (f) => f.name !== field.name
                                              ),
                                          },
                                        },
                                      }));
                                    }
                                  }}
                                >
                                  {/* Small Squares at Corners and Midpoints */}
                                  {[
                                    {
                                      top: 0,
                                      left: -1,
                                      cursor: "nwse-resize",
                                      direction: "nw",
                                    }, // Top Left
                                    {
                                      top: -1,
                                      left: "50%",
                                      cursor: "ns-resize",
                                      direction: "n",
                                    }, // Top Middle
                                    {
                                      top: 0,
                                      right: -12,
                                      cursor: "nesw-resize",
                                      direction: "ne",
                                    }, // Top Right
                                    {
                                      bottom: -11,
                                      left: -1,
                                      cursor: "nesw-resize",
                                      direction: "sw",
                                    }, // Bottom Left
                                    {
                                      bottom: -11,
                                      left: "50%",
                                      cursor: "ns-resize",
                                      direction: "s",
                                    }, // Bottom Middle
                                    {
                                      bottom: -11,
                                      right: -12,
                                      cursor: "nwse-resize",
                                      direction: "se",
                                    }, // Bottom Right
                                    {
                                      top: "50%",
                                      left: -1,
                                      cursor: "ew-resize",
                                      direction: "w",
                                    }, // Middle Left
                                    {
                                      top: "50%",
                                      right: -12,
                                      cursor: "ew-resize",
                                      direction: "e",
                                    }, // Middle Right
                                  ].map((pos, idx) => (
                                    <Box
                                      key={idx}
                                      sx={{
                                        position: "absolute",
                                        width: "8px", // Size of small squares
                                        height: "8px",
                                        border: "1px solid blue",
                                        borderRadius: "2px",
                                        transform: "translate(-50%, -50%)", // Center the squares
                                        ...pos, // Apply position dynamically
                                      }}
                                      onMouseDown={(e) => {
                                        e.stopPropagation();
                                        handleMouseDown(
                                          e,
                                          currentProcessingFile,
                                          setCurrentProcessingFile,
                                          field,
                                          scaleFactor,
                                          pos.direction,
                                          pos.cursor,
                                          pdfContainerRef,
                                          setStartPoint,
                                          setRect,
                                          setIsDrawing,
                                          setCurrCursor,
                                          setFreeSelectStartPoint,
                                          setMoving,
                                          setIsSelecting,
                                          setInitialMousePosition,
                                          setInitialInputSize,
                                          setInputPosition,
                                          setSelectionRect,
                                          setResizing,
                                          setResizeDirection
                                        );
                                      }}
                                    />
                                  ))}
                                </Box>
                              )}
                            {currentProcessingFile?.document?.tempFields
                              ?.selectedExtraFields.length > 0 &&
                              currentProcessingFile?.document?.tempFields?.selectedExtraFields.some(
                                (f) =>
                                  f.name === field.name &&
                                  (!currentProcessingFile?.document?.tempFields
                                    ?.selectedFormField ||
                                    currentProcessingFile?.document?.tempFields
                                      ?.selectedFormField?.name !== f.name)
                              ) && (
                                <Box
                                  ref={childInputRef}
                                  onClick={(e) => {
                                    if (currCursor === "crosshair") return;
                                    if (e.ctrlKey || e.metaKey) {
                                      setCurrentProcessingFile((prevFile) => ({
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedExtraFields:
                                              prevFile.document.tempFields.selectedExtraFields.filter(
                                                (f) => f.name !== field?.name
                                              ),
                                          },
                                        },
                                      }));
                                    } else {
                                      setCurrentProcessingFile((prevFile) => ({
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedFormField: field,
                                          },
                                        },
                                      }));
                                    }
                                  }}
                                  sx={{
                                    position: "absolute",
                                    left:
                                      field.type === "PDFCheckBox" ||
                                      field.type === "PDFRadioGroup"
                                        ? `${field.x * scaleFactor + 2}px`
                                        : `${field.x * scaleFactor}px`,
                                    top:
                                      field.type === "PDFCheckBox" ||
                                      field.type === "PDFRadioGroup"
                                        ? `${
                                            (currentProcessingFile?.document
                                              ?.tempFields?.currentDocument
                                              .actualHeight -
                                              field.y -
                                              field.height) *
                                              scaleFactor +
                                            1
                                          }px`
                                        : `${
                                            (currentProcessingFile?.document
                                              ?.tempFields?.currentDocument
                                              .actualHeight -
                                              field.y -
                                              field.height) *
                                            scaleFactor
                                          }px`,
                                    width: `${field.width * scaleFactor}px`,
                                    height: `${field.height * scaleFactor}px`,
                                    border: "2px dotted blue",
                                    background: "transparent",
                                    zIndex: 20,
                                  }}
                                ></Box>
                              )}
                            {field.type === "PDFCheckBox" ||
                            field.type === "PDFRadioGroup" ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  left: `${field.x * scaleFactor + 5}px`,
                                  top: `${
                                    (currentProcessingFile?.document?.tempFields
                                      ?.currentDocument.actualHeight -
                                      field.y -
                                      field.height) *
                                      scaleFactor +
                                    3
                                  }px`,
                                  width: `${field.width * scaleFactor}px`,
                                  height: `${field.height * scaleFactor}px`,

                                  background:
                                    highlightFields ||
                                    focusedField === field.name
                                      ? "RGBA(255, 211, 0, 0.3)"
                                      : "transparent",
                                  zIndex: 20,
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (currCursor === "crosshair") return;

                                  if (typingToolActive) {
                                    setCurrentProcessingFile((prevFile) => ({
                                      ...prevFile,
                                      document: {
                                        ...prevFile.document,
                                        tempFields: {
                                          ...prevFile.document.tempFields,
                                          formFields:
                                            prevFile.document.tempFields.formFields.map(
                                              (f) =>
                                                f.name === field.name
                                                  ? { ...f, value: "" }
                                                  : f // Clear checkbox value
                                            ),
                                        },
                                      },
                                    }));
                                  }
                                  if (
                                    currentProcessingFile?.document?.tempFields
                                      ?.selectedExtraFields.length > 0 &&
                                    currentProcessingFile?.document?.tempFields?.selectedExtraFields.some(
                                      (f) =>
                                        f.name === field.name &&
                                        (!currentProcessingFile?.document
                                          ?.tempFields?.selectedFormField ||
                                          currentProcessingFile?.document
                                            ?.tempFields?.selectedFormField
                                            ?.name !== f.name)
                                    )
                                  ) {
                                    if (e.ctrlKey || e.metaKey) {
                                      setCurrentProcessingFile((prevFile) => ({
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedExtraFields:
                                              prevFile.document.tempFields.selectedExtraFields.filter(
                                                (f) => f.name !== field?.name
                                              ),
                                          },
                                        },
                                      }));
                                    } else {
                                      setCurrentProcessingFile((prevFile) => ({
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedFormField: field,
                                          },
                                        },
                                      }));
                                    }
                                  } else {
                                    setCurrentProcessingFile((prevFile) => {
                                      const isAlreadySelected =
                                        prevFile.document.tempFields.selectedExtraFields.some(
                                          (f) => f.name === field.name
                                        );

                                      return {
                                        ...prevFile,
                                        document: {
                                          ...prevFile.document,
                                          tempFields: {
                                            ...prevFile.document.tempFields,
                                            selectedExtraFields:
                                              isAlreadySelected
                                                ? prevFile.document.tempFields
                                                    .selectedExtraFields
                                                : e.ctrlKey || e.metaKey
                                                ? [
                                                    ...prevFile.document
                                                      .tempFields
                                                      .selectedExtraFields,
                                                    field,
                                                  ]
                                                : [field],

                                            selectedFormField: { ...field },
                                          },
                                        },
                                      };
                                    });
                                  }
                                }}
                              ></Box>
                            ) : (
                              <></>
                            )}

                            {fixedInputs.some((prefix) =>
                              field.name.startsWith(prefix)
                            ) && (
                              <SigningsOverlay
                                field={field}
                                scaleFactor={scaleFactor}
                                currentDocument={
                                  currentProcessingFile?.document?.tempFields
                                    ?.currentDocument
                                }
                                currentProcessingFile={currentProcessingFile}
                              />
                            )}

                            <input
                              type={
                                field.type === "PDFCheckBox"
                                  ? "checkbox"
                                  : field.type === "PDFRadioGroup"
                                  ? "radio"
                                  : field.name.startsWith("date_field")
                                  ? "date"
                                  : "text"
                              }
                              value={
                                field.type === "PDFCheckBox" ||
                                field.type === "PDFRadioGroup"
                                  ? undefined
                                  : field.value || ""
                              }
                              checked={
                                field.type === "PDFCheckBox" ||
                                field.type === "PDFRadioGroup"
                                  ? field.value
                                  : undefined
                              }
                              onFocus={(e) => {
                                if (
                                  selectToolActive ||
                                  fixedInputs.some((prefix) =>
                                    field.name.startsWith(prefix)
                                  )
                                ) {
                                  e.preventDefault();
                                  return;
                                } else {
                                  setFocusedField(field.name);
                                }
                              }}
                              onMouseDown={(e) => {
                                if (
                                  (selectToolActive &&
                                    field.name.startsWith("date_field")) ||
                                  fixedInputs.some((prefix) =>
                                    field.name.startsWith(prefix)
                                  )
                                ) {
                                  e.preventDefault(); // Prevent selecting "mm/dd/yyyy"
                                }
                              }}
                              onBlur={() => setFocusedField(null)}
                              readOnly={
                                selectToolActive ||
                                fixedInputs.some((prefix) =>
                                  field.name.startsWith(prefix)
                                )
                              }
                              onChange={(e) => {
                                if (
                                  fixedInputs.some((prefix) =>
                                    field.name.startsWith(prefix)
                                  )
                                ) {
                                  return;
                                }
                                if (
                                  field.type === "PDFCheckBox" ||
                                  field.type === "PDFRadioGroup"
                                ) {
                                  if (selectToolActive) return;
                                  const isChecked = e.target.checked;
                                  setCurrentProcessingFile((prevFile) => ({
                                    ...prevFile,
                                    document: {
                                      ...prevFile.document,
                                      tempFields: {
                                        ...prevFile.document.tempFields,
                                        formFields:
                                          prevFile.document.tempFields.formFields.map(
                                            (f) =>
                                              f.name === field.name
                                                ? { ...f, value: isChecked }
                                                : f // Update checkbox value
                                          ),
                                      },
                                    },
                                  }));
                                } else {
                                  handleFieldChange(e, field);
                                }
                              }}
                              onClick={(e) => {
                                e.stopPropagation();

                                if (currCursor === "crosshair") return;

                                setCurrentProcessingFile((prevFile) => {
                                  const isAlreadySelected =
                                    prevFile.document.tempFields.selectedExtraFields.some(
                                      (f) => f.name === field.name
                                    );

                                  return {
                                    ...prevFile,
                                    document: {
                                      ...prevFile.document,
                                      tempFields: {
                                        ...prevFile.document.tempFields,
                                        selectedExtraFields: isAlreadySelected
                                          ? prevFile.document.tempFields
                                              .selectedExtraFields
                                          : e.ctrlKey || e.metaKey
                                          ? [
                                              ...prevFile.document.tempFields
                                                .selectedExtraFields,
                                              field,
                                            ]
                                          : [field],

                                        selectedFormField: { ...field },
                                      },
                                    },
                                  };
                                });
                              }}
                              style={{
                                position: "absolute",
                                fontSize: `${field.fontSize}px`,
                                left: `${field.x * scaleFactor}px`,
                                top: `${
                                  (currentProcessingFile?.document?.tempFields
                                    ?.currentDocument.actualHeight -
                                    field.y -
                                    field.height) *
                                  scaleFactor
                                }px`,
                                width:
                                  currentProcessingFile?.document?.tempFields
                                    ?.selectedFormField &&
                                  field.name ===
                                    currentProcessingFile?.document?.tempFields
                                      ?.selectedFormField.name
                                    ? `${
                                        currentProcessingFile?.document
                                          ?.tempFields?.selectedFormField
                                          .width * scaleFactor
                                      }px`
                                    : `${field.width * scaleFactor}px`,
                                height:
                                  currentProcessingFile?.document?.tempFields
                                    ?.selectedFormField &&
                                  field.name ===
                                    currentProcessingFile?.document?.tempFields
                                      ?.selectedFormField.name
                                    ? `${
                                        currentProcessingFile?.document
                                          ?.tempFields?.selectedFormField
                                          .height * scaleFactor
                                      }px`
                                    : `${field.height * scaleFactor}px`,
                                border:
                                  typingToolActive ||
                                  fixedInputs.some((prefix) =>
                                    field.name.startsWith(prefix)
                                  )
                                    ? "none"
                                    : "1px solid #000",
                                outline: "none",
                                background:
                                  focusedField === field.name
                                    ? "transparent" // Normal background when focused
                                    : highlightFields &&
                                      fixedInputs.some((prefix) =>
                                        field.name.startsWith(prefix)
                                      )
                                    ? "transparent"
                                    : highlightFields
                                    ? "RGBA(255, 211, 0, 0.3)" // Highlighted when not focused
                                    : "transparent",
                                zIndex:
                                  field.type === "PDFCheckBox" ||
                                  field.type === "PDFRadioGroup"
                                    ? 1
                                    : 10,
                                cursor: selectToolActive
                                  ? currCursor
                                  : fixedInputs.some((prefix) =>
                                      field.name.startsWith(prefix)
                                    )
                                  ? "default"
                                  : "text",
                                pointerEvents: isDrawing ? "none" : "auto",
                              }}
                            />
                          </React.Fragment>
                        );
                      })}
                  </Box>
                </Box>
              </>
            )}

          {packagePanelOpen ? (
            <PackagePanelComponent
              setPackagePanelOpen={setPackagePanelOpen}
              currentProcessingFile={currentProcessingFile}
              setCurrentProcessingFile={setCurrentProcessingFile}
              uploadError={uploadError}
              setUploadError={setUploadError}
              selectedPackage={selectedPackage}
              setSelectedPackage={setSelectedPackage}
              addingNewDocument={addingNewDocument}
              setAddingNewDocument={setAddingNewDocument}
              addingNewPackage={addingNewPackage}
              setAddingNewPackage={setAddingNewPackage}
              currentFormsActive={currentFormsActive}
              setCurrentFormsActive={setCurrentFormsActive}
              savedPackagesActive={savedPackagesActive}
              setSavedPackagesActive={setSavedPackagesActive}
              selectedPackageForm={selectedPackageForm}
              setSelectedPackageForm={setSelectedPackageForm}
              setExpandToolBox={setExpandToolBox}
            />
          ) : (
            <Box sx={{ zIndex: 1000 }}>
              <Box sx={{ position: "fixed", top: 100, right: 30 }}>
                <Button
                  onClick={handlePackagePanelOpen}
                  variant="contained"
                  color="success"
                  endIcon={<ViewSidebarIcon />}
                  sx={{ ml: 2 }}
                >
                  Package Panel
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </DndProvider>
  );
};

export default TestUploader;
