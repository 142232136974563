import { Box, Button, Collapse, Typography } from "@mui/material";
import { useEffect } from "react";

const Toolbox = ({
  currentProcessingFile,
  setCurrentProcessingFile,
  expandToolBox,
  setSelectToolActive,
  typingToolActive,
  setTypingToolActive,
  setCurrCursor,
  fixedInputs,
}) => {
  useEffect(() => {
    if (
      typingToolActive ||
      currentProcessingFile?.document?.tempFields?.selectedFormField
    ) {
      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFieldTools: Object.keys(
              prevFile.document.tempFields.formFieldTools
            ).reduce((acc, key) => {
              acc[key] = {
                ...prevFile.document.tempFields.formFieldTools[key],
                active: false, // ✅ Set active to false
              };
              return acc;
            }, {}),
          },
        },
      }));
    }
  }, [
    typingToolActive,
    currentProcessingFile?.document?.tempFields?.selectedFormField,
  ]);

  const handleSelectTools = (tool, defCursor) => {
    setCurrentProcessingFile((prevFile) => {
      const isToolActive =
        prevFile.document.tempFields.formFieldTools[tool]?.active;

      // Clear selections
      const updatedFile = {
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            selectedFormField: null,
            selectedExtraFields: [],
          },
        },
      };

      if (typingToolActive) {
        setTypingToolActive(false);
        setSelectToolActive(true);
      }

      // If the tool is already active, reset all tools
      if (isToolActive) {
        setCurrCursor("default");
        return {
          ...updatedFile,
          document: {
            ...updatedFile.document,
            tempFields: {
              ...updatedFile.document.tempFields,
              formFieldTools: Object.keys(
                prevFile.document.tempFields.formFieldTools
              ).reduce((acc, key) => {
                acc[key] = {
                  ...prevFile.document.tempFields.formFieldTools[key],
                  active: false,
                  values: [],
                };
                return acc;
              }, {}),
            },
          },
        };
      }

      // Otherwise, set the selected tool to active and others to false
      if (!defCursor) {
        setCurrCursor("crosshair");
      }

      return {
        ...updatedFile,
        document: {
          ...updatedFile.document,
          tempFields: {
            ...updatedFile.document.tempFields,
            formFieldTools: Object.keys(
              prevFile.document.tempFields.formFieldTools
            ).reduce((acc, key) => {
              acc[key] = {
                ...prevFile.document.tempFields.formFieldTools[key],
                active: !defCursor ? key === tool : false,
              };
              return acc;
            }, {}),
          },
        },
      };
    });
  };

  const editName = (rawName) => {
    if (
      currentProcessingFile?.document?.tempFields?.selectedExtraFields
        .length === 1 &&
      currentProcessingFile?.document?.tempFields?.selectedFormField &&
      !currentProcessingFile?.document?.tempFields?.selectedFormField.name.startsWith(
        "date_field"
      ) &&
      !fixedInputs.some((prefix) =>
        currentProcessingFile?.document?.tempFields?.selectedFormField.name.startsWith(
          prefix
        )
      )
    ) {
      const newName = `${rawName}_${Date.now().toString().slice(-4)}`;

      setCurrentProcessingFile((prevFile) => {
        const updatedFormFields = prevFile.document.tempFields.formFields.map(
          (field) =>
            field.name === prevFile?.document.tempFields.selectedFormField?.name
              ? { ...field, name: newName }
              : field
        );

        const updatedSelectedExtraFields =
          prevFile.document.tempFields.selectedExtraFields.map((field) =>
            field.name === prevFile?.document.tempFields.selectedFormField?.name
              ? { ...field, name: newName }
              : field
          );

        // Find the matching tool key in formFieldTools based on the name property
        const matchingKey = Object.keys(
          prevFile.document.tempFields.formFieldTools
        ).find(
          (key) =>
            prevFile.document.tempFields.formFieldTools[key]?.name === rawName
        );

        return {
          ...prevFile,
          document: {
            ...prevFile.document,
            tempFields: {
              ...prevFile.document.tempFields,
              formFields: updatedFormFields,
              selectedExtraFields: updatedSelectedExtraFields,
              selectedFormField: prevFile.document.tempFields.selectedFormField
                ? {
                    ...prevFile.document.tempFields.selectedFormField,
                    name: newName,
                  }
                : null,
              formFieldTools: matchingKey
                ? {
                    ...prevFile.document.tempFields.formFieldTools,
                    [matchingKey]: {
                      ...prevFile.document.tempFields.formFieldTools[
                        matchingKey
                      ],
                      active: false, // Deactivate only this tool
                      disabled: true, // Disable only this tool
                    },
                  }
                : prevFile.document.tempFields.formFieldTools, // If no match, keep as is
            },
          },
        };
      });

      // Update cursor state outside to avoid unnecessary re-renders
      setCurrCursor("default");

      return true;
    } else {
      return false;
    }
  };

  return (
    <Collapse in={expandToolBox}>
      <Box mt={1}>
        <Typography variant="h6">General</Typography>
        <Box
          gap={1}
          sx={{ display: "flex", flexDirection: "column" }}
          ml={1}
          mt={0.5}
        >
          <Button
            onClick={() => handleSelectTools("textField", false)}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .textField.active
                ? "contained"
                : "outlined"
            }
            style={{ marginTop: 0 }}
          >
            📝 Text Field
          </Button>
          <Button
            onClick={() => handleSelectTools("checkbox", false)}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .checkbox.active
                ? "contained"
                : "outlined"
            }
          >
            ☑️ Checkbox
          </Button>
          <Button
            onClick={() => handleSelectTools("radio", false)}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools.radio
                .active
                ? "contained"
                : "outlined"
            }
          >
            🔘 Radio
          </Button>
          <Button
            onClick={() => handleSelectTools("date", false)}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools.date
                .active
                ? "contained"
                : "outlined"
            }
          >
            📅 Date
          </Button>
        </Box>
      </Box>

      <Box mt={1}>
        <Typography variant="h6">Notary</Typography>
        <Box
          gap={1}
          sx={{ display: "flex", flexDirection: "column" }}
          ml={1}
          mt={0.5}
        >
          <Button
            onClick={() => {
              const defCursor = editName("notary_seal_a");

              handleSelectTools("notarySeal_a", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySeal_a.active
                ? "contained"
                : "outlined"
            }
            style={{ marginTop: 0 }}
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySeal_a.disabled
            }
          >
            🖋️ Notary Seal 1
          </Button>
          <Button
            onClick={() => {
              const defCursor = editName("notary_sign_a");
              handleSelectTools("notarySignature_a", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySignature_a.active
                ? "contained"
                : "outlined"
            }
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySignature_a.disabled
            }
          >
            ✍️ Notary Signature 1
          </Button>
          <Button
            onClick={() => {
              const defCursor = editName("notary_seal_b");

              handleSelectTools("notarySeal_b", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySeal_b.active
                ? "contained"
                : "outlined"
            }
            style={{ marginTop: 0 }}
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySeal_b.disabled ||
              !(
                currentProcessingFile?.document?.tempFields?.formFields.some(
                  (field) => field.name.startsWith("notary_seal_a")
                ) &&
                currentProcessingFile?.document?.tempFields?.formFields.some(
                  (field) => field.name.startsWith("notary_sign_a")
                )
              )
            }
          >
            🖋️ Notary Seal 2
          </Button>
          <Button
            onClick={() => {
              const defCursor = editName("notary_sign_b");
              handleSelectTools("notarySignature_b", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySignature_b.active
                ? "contained"
                : "outlined"
            }
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .notarySignature_b.disabled ||
              !(
                currentProcessingFile?.document?.tempFields?.formFields.some(
                  (field) => field.name.startsWith("notary_seal_a")
                ) &&
                currentProcessingFile?.document?.tempFields?.formFields.some(
                  (field) => field.name.startsWith("notary_sign_a")
                )
              )
            }
          >
            ✍️ Notary Signature 2
          </Button>
        </Box>
      </Box>

      <Box mt={1}>
        <Typography variant="h6">Signatures</Typography>
        <Box
          gap={1}
          sx={{ display: "flex", flexDirection: "column" }}
          ml={1}
          mt={0.5}
        >
          <Button
            onClick={() => {
              const defCursor = editName("sign_a");
              handleSelectTools("signatureA", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureA.active
                ? "contained"
                : "outlined"
            }
            style={{ marginTop: 0 }}
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureA.disabled
            }
          >
            ✍️ Signer 1
          </Button>
          <Button
            onClick={() => {
              const defCursor = editName("sign_b");
              handleSelectTools("signatureB", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureB.active
                ? "contained"
                : "outlined"
            }
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureB.disabled ||
              !currentProcessingFile?.document?.tempFields?.formFields.some(
                (field) => field.name.startsWith("sign_a")
              )
            }
          >
            ✍️ Signer 2
          </Button>
          <Button
            onClick={() => {
              const defCursor = editName("sign_c");
              handleSelectTools("signatureC", defCursor);
            }}
            fullWidth
            size="small"
            variant={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureC.active
                ? "contained"
                : "outlined"
            }
            disabled={
              currentProcessingFile?.document?.tempFields?.formFieldTools
                .signatureC.disabled ||
              !currentProcessingFile?.document?.tempFields?.formFields.some(
                (field) => field.name.startsWith("sign_b")
              )
            }
          >
            ✍️ Signer 3
          </Button>
        </Box>
      </Box>
    </Collapse>
  );
};

export default Toolbox;
