import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import * as pdfjsLib from "pdfjs-dist/webpack";
import { extractFormFields } from "../uploaderComponents/helpers/processPDF";

const UploadBox = ({
  setCurrentProcessingFile,
  uploadError,
  setUploadError,
  setUploaderLoading,
}) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const file = event.dataTransfer.files[0]; // Single file upload
    validateAndProcessFile(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    validateAndProcessFile(file);
  };

  const validateAndProcessFile = (file) => {
    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 2 * 1024 * 1024
    ) {
      const reader = new FileReader();

      reader.onload = function () {
        const base64String = reader.result;

        pdfjsLib
          .getDocument({ data: base64ToUint8Array(base64String) })
          .promise.then(async () => {
            try {
              // Successfully loaded the PDF
              const extractedFields = await extractFormFields(base64String);

              setCurrentProcessingFile((prevFile) => ({
                ...prevFile,
                document: {
                  ...prevFile.document,
                  tempFields: {
                    ...prevFile.document.tempFields,
                    formFields: extractedFields,
                    currentDocument: {
                      ...prevFile.document.tempFields.currentDocument,
                      base64File: base64String, // Store Data URL
                      fileName: file.name,
                      files: "",
                    },
                  },
                },
              }));

              if (setUploaderLoading) {
                setUploaderLoading(true);
                setUploadError("");
              }
            } catch (error) {
              setUploadError("Failed to load the PDF.");
              console.error("Error loading PDF:", error);
            }
          });
      };

      reader.readAsDataURL(file); // Convert file to Base64 Data URL
    } else {
      setUploadError("Only PDF files under 2MB are supported.");
    }
  };

  const base64ToUint8Array = (base64) => {
    const binaryString = atob(base64.split(",")[1]);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  };

  const openFileDialog = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <Box
      sx={{
        mx: "auto",
        maxWidth: "50rem",
        height: "100%",
        border: "2px dashed gray",
        padding: 2,
        backgroundColor: dragOver ? "#e0e0e0" : "#fafafa",
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "20px",
        "&:hover": {
          backgroundColor: "#e0e0e0",
        },
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      onClick={openFileDialog}
    >
      <input
        type="file"
        id="fileInput"
        onChange={handleFileChange}
        accept="application/pdf"
        style={{ display: "none" }}
      />
      <Box
        sx={{
          mt: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
      >
        <UploadFileIcon sx={{ fontSize: 80, color: "gray" }} />
        <Typography variant="h5" sx={{ mb: 3 }}>
          Drag and drop files here, or click to select one
        </Typography>
        {uploadError && (
          <Typography color="error" variant="body2" sx={{ fontWeight: "bold" }}>
            {uploadError}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default UploadBox;
