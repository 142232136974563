import ReactGA from "react-ga4";

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

export const initGA = () => {
  ReactGA.initialize(GA_TRACKING_ID);
};

export const trackPageView = (page_path) => {
  ReactGA.send({ hitType: "pageview", page: page_path });
};

export const trackEvent = (category, action, label) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
