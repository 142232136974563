import {
  Grid2 as Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Card,
  CardContent,
  Divider,
  Autocomplete,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import { FaSignature } from "react-icons/fa";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewOptions,
  addNewSigners,
  adjustDocumentLock,
  fetchUserPkgFileBase64,
  loadFormOptions,
  loadFormSigners,
  updateProcessOptions,
  updateSigners,
} from "../../store/actions/userPackagesActions";
import CloseIcon from "@mui/icons-material/Close";
import { countiesForLicense } from "../../data/dbpr";
import { handlePhoneInputChange } from "../../functions/permitFormHelpers";
import LoadingButton from "@mui/lab/LoadingButton";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const RenderedPackageFormFields = ({
  selectedPackage,
  setSelectedPackage,
  selectedPackageForm,
  setSelectedPackageForm,
  currentProcessingFile,
  setCurrentProcessingFile,
}) => {
  const { userPackages } = useSelector((state) => state.userPackages);
  const reduxFormOptions = useSelector(
    (state) => state.userPackages.processFormOptions
  );
  const reduxSigners = useSelector(
    (state) => state.userPackages.processSigners
  );
  const dispatch = useDispatch();
  const [signers, setSigners] = useState([
    {
      signer: "signer_1",
      companyName: "",
      ownerNameType: "personal",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      county: "",
    },
    {
      signer: "signer_2",
      companyName: "",
      ownerNameType: "organization",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      county: "",
    },
    {
      signer: "signer_3",
      companyName: "",
      ownerNameType: "organization",
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      phone: "",
      county: "",
    },
  ]);
  const [options, setOptions] = useState({
    upload: false,
    notarize: false,
    sign: false,
    record: false,
  });

  const [disableOptions, setDisableOptions] = useState({
    upload: false,
    notarize: false,
    sign: false,
    record: false,
  });
  const [lockLoading, setLockLoading] = useState(false);
  const [lockErrorText, setLockErrorText] = useState("");

  const clearSignerFields = (signerType) => {
    setSigners((prevSigners) => {
      // Create updated signers list
      const updatedSigners = prevSigners.map((signer) =>
        signer.signer === signerType
          ? {
              ...signer,
              companyName: "",
              ownerNameType: signer.ownerNameType,
              firstName: "",
              middleName: "",
              lastName: "",
              email: "",
              phone: "",
              county: "",
            }
          : signer
      );

      // Dispatch immediately with updated list
      dispatch(updateSigners(selectedPackage.packageID, updatedSigners));

      return updatedSigners; // Ensure state update happens correctly
    });
  };

  useEffect(() => {
    if (!selectedPackageForm || !selectedPackage) return;

    const existingOptions = reduxFormOptions?.find(
      (item) =>
        item.formID === selectedPackageForm.formID &&
        item.packageID === selectedPackage.packageID
    );

    if (existingOptions) {
      setOptions(existingOptions?.options);
      return;
    }

    const currentUserPackage = userPackages?.find(
      (item) => item.packageID === selectedPackage.packageID
    );
    const savedExistingOptions =
      currentUserPackage?.process_form_options &&
      Array.isArray(currentUserPackage.process_form_options)
        ? currentUserPackage.process_form_options.find(
            (item) =>
              item.formID === selectedPackageForm.formID &&
              item.packageID === selectedPackage.packageID
          )
        : undefined;

    if (savedExistingOptions) {
      dispatch(loadFormOptions(savedExistingOptions));
      setOptions(savedExistingOptions?.options);
    } else {
      const defOptions = {
        upload: false,
        notarize: false,
        sign: false,
        record: false,
      };
      dispatch(
        addNewOptions(
          selectedPackage.packageID,
          selectedPackageForm.formID,
          defOptions
        )
      );
      setOptions(defOptions);
    }
  }, [selectedPackageForm, selectedPackage]);

  useEffect(() => {
    if (!selectedPackageForm || !selectedPackage) return;

    const existingSigners = reduxSigners?.find(
      (item) => item.packageID === selectedPackage.packageID
    );

    if (existingSigners) {
      setSigners(existingSigners?.signers);
      return;
    }

    const currentUserPackage = userPackages?.find(
      (item) => item.packageID === selectedPackage.packageID
    );

    const savedExistingSigners =
      currentUserPackage?.process_signers &&
      Array.isArray(currentUserPackage.process_signers)
        ? currentUserPackage.process_signers.find(
            (item) => item.packageID === selectedPackage.packageID
          )
        : undefined;

    if (savedExistingSigners) {
      dispatch(loadFormSigners(savedExistingSigners));
      setSigners(savedExistingSigners.signers || []);
      return;
    }

    const oOrg =
      selectedPackageForm?.property_owner?.ORGANIZATION?.nameUnparsed;
    const oFirst = selectedPackageForm?.property_owner?.PERSON?.first_name;
    const oMiddle = selectedPackageForm?.property_owner?.PERSON?.middle_name;
    const oLast = selectedPackageForm?.property_owner?.PERSON?.last_name;
    const oCounty = selectedPackageForm?.document_location?.county;
    const oPhone = selectedPackageForm?.property_owner?.phone_number;

    const cOrg =
      selectedPackageForm?.contractor?.ORGANIZATION?.nameUnparsed ||
      selectedPackageForm?.contractor?.PERSON?.business_name;
    const cFirst = selectedPackageForm?.contractor?.PERSON?.first_name;
    const cMiddle = selectedPackageForm?.contractor?.PERSON?.middle_name;
    const cLast = selectedPackageForm?.contractor?.PERSON?.last_name;
    const cPhone = selectedPackageForm?.contractor?.phone_number;

    const defaultSigners = signers.map((signer) => {
      // Extract relevant conditions
      const isOwner = signer.signer === "signer_1";
      const isContractor = signer.signer === "signer_2";

      return {
        ...signer,
        companyName: isContractor ? cOrg : isOwner ? oOrg : "",
        ownerNameType:
          isOwner && oOrg ? "organization" : isOwner && !cOrg ? "personal" : "",
        firstName: isContractor ? cFirst : isOwner ? oFirst : "",
        middleName: isContractor ? cMiddle : isOwner ? oMiddle : "",
        lastName: isContractor ? cLast : isOwner ? oLast : "",
        email: "",
        phone: isContractor ? cPhone : isOwner ? oPhone : "",
        county: isOwner ? oCounty.toUpperCase() : signer.county,
      };
    });

    dispatch(addNewSigners(selectedPackage.packageID, defaultSigners));
    setSigners(defaultSigners);
  }, [selectedPackage]); // ✅ Add dependencies

  useEffect(() => {
    setDisableOptions((prev) => {
      const newDisableOptions = {
        upload: false,
        notarize: false,
        sign: false,
        record: false,
      };

      if (options.upload) {
        newDisableOptions.notarize = true;
        newDisableOptions.sign = true;
        newDisableOptions.record = true;
      }

      if (options.notarize) {
        newDisableOptions.upload = true;
        newDisableOptions.sign = true;
      }

      if (options.sign) {
        newDisableOptions.upload = true;
        newDisableOptions.notarize = true;
        newDisableOptions.record = true;
      }

      if (options.record) {
        newDisableOptions.upload = true;
        newDisableOptions.sign = true;
      }

      // ✅ Only update state if there is an actual change
      if (JSON.stringify(prev) !== JSON.stringify(newDisableOptions)) {
        setDisableOptions(newDisableOptions);
      }

      return prev;
    });
  }, [options]); // ✅ Ensure this only runs when `options` change

  const handleOptionChange = (event) => {
    const { name, checked } = event.target;

    setOptions((prevOptions) => {
      const updatedOptions = {
        ...prevOptions,
        [name]: checked,
        record:
          name === "record"
            ? selectedPackageForm?.is_notice_of_commencement
              ? checked
              : false
            : prevOptions.record,
      };

      // ✅ Update Redux Immediately
      dispatch(
        updateProcessOptions(
          selectedPackage?.packageID,
          selectedPackageForm?.formID,
          updatedOptions
        )
      );

      return updatedOptions;
    });
  };

  const handleBlurSave = () => {
    dispatch(updateSigners(selectedPackage.packageID, signers));
  };

  const handleFieldsChange = (event, field, index) => {
    let value;

    if (field === "county") {
      value = event;
    } else {
      value = event.target.value;
    }

    if (field === "phone") {
      value = handlePhoneInputChange(event);
    }

    setSigners((prevSigners) =>
      prevSigners.map((signer, i) =>
        i === index ? { ...signer, [field]: value || "" } : signer
      )
    );
  };

  useEffect(() => {
    if (lockErrorText) {
      const timeout = setTimeout(() => {
        setLockErrorText("");
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [lockErrorText]);

  const handleLoadDocument = async () => {
    try {
      if (
        (selectedPackage && selectedPackage?.documents?.length === 0) ||
        (selectedPackageForm && !selectedPackageForm?.formID) ||
        !selectedPackageForm
      ) {
        return;
      }
      let base64PDF = null;
      let extractedFields = [];

      const selectedPackageCopy = { ...selectedPackage };
      delete selectedPackageCopy.documents;

      const formattedData = {
        ...selectedPackageCopy,
        document: selectedPackageForm,
      };

      if (!formattedData?.document?.tempFields?.currentDocument?.base64File) {
        const fieldResp = await dispatch(
          fetchUserPkgFileBase64(
            formattedData.document.pdf_url,
            formattedData.packageID,
            formattedData.document.formID
          )
        );

        base64PDF = fieldResp.base64;
        extractedFields = fieldResp.extractedFields;
      } else {
        base64PDF =
          formattedData.document.tempFields.currentDocument.base64File;
      }

      /// ✅ Ensure formattedData is up-to-date before updating state
      const updatedFormattedData = {
        ...formattedData,
        document: {
          ...formattedData.document,
          tempFields: {
            ...formattedData.document.tempFields,
            formFields:
              Array.isArray(extractedFields) && extractedFields.length > 0
                ? extractedFields
                : formattedData.document.tempFields?.formFields || [],
            currentDocument: {
              ...formattedData.document.tempFields.currentDocument,
              base64File: base64PDF,
            },
          },
        },
      };

      const updatedPackageData = {
        ...selectedPackage, // ✅ Keep all package-level data the same
        documents: selectedPackage.documents.map((doc) =>
          doc.formID === formattedData.document.formID
            ? {
                ...doc,
                tempFields: {
                  ...doc.tempFields,
                  formFields:
                    Array.isArray(extractedFields) && extractedFields.length > 0
                      ? extractedFields
                      : doc.tempFields?.formFields || [],
                  currentDocument: {
                    ...doc.tempFields.currentDocument,
                    base64File: base64PDF, // ✅ Preserve existing base64 if not new
                  },
                },
              } // ✅ Only update the matching document
            : doc
        ),
      };
      delete updatedPackageData.document;

      const updatedSelectedForm = updatedPackageData.documents.find(
        (doc) => doc.formID === formattedData.document.formID
      );

      await setCurrentProcessingFile(updatedFormattedData);
      await setSelectedPackage(updatedPackageData);
      await setSelectedPackageForm(updatedSelectedForm);

      return true;
    } catch (error) {
      console.error("Error loading document:", error);
    }
  };

  const fieldMap = [
    "notary_seal_a",
    "notary_sign_a",
    "notary_seal_b",
    "notary_sign_b",
    "sign_a",
    "sign_b",
    "sign_c",
  ];

  const normalizeName = (name) => {
    const parts = name.split("_");
    const result =
      parts.length === 3
        ? parts.slice(0, 2).join("_")
        : parts.length === 4
        ? parts.slice(0, 3).join("_")
        : name;

    return result;
  };

  const areAllOptionsFalse = (options) =>
    Object.values(options).every((value) => value === false);

  const filterDefBoxes = (formFields, fieldMap) => {
    const filtered = formFields
      .map((field) => normalizeName(field.name)) // Normalize field names
      .filter((normalizedName) => fieldMap.includes(normalizedName)); // Keep only matching ones

    return filtered.length > 0 ? filtered : []; // Return empty array if no matches
  };

  const validateNotaryPairs = (addedDefaultBoxes) => {
    let requiredNotaryA = ["notary_seal_a", "notary_sign_a"];
    let requiredNotaryB = ["notary_seal_b", "notary_sign_b"];
    // Check if all items in requiredNotaryA are present
    const hasFullNotaryA = requiredNotaryA.every((value) =>
      addedDefaultBoxes.includes(value)
    );

    // Check if all items in requiredNotaryB are present
    const hasFullNotaryB = requiredNotaryB.every((value) =>
      addedDefaultBoxes.includes(value)
    );

    // Check if some but not all of requiredNotaryB are present (partial)
    const hasPartialNotaryB =
      requiredNotaryB.some((value) => addedDefaultBoxes.includes(value)) &&
      !hasFullNotaryB;

    // Validation conditions
    if (!hasFullNotaryA) {
      return { valid: false, message: "Notary 1 is required." };
    }

    if (hasPartialNotaryB) {
      return {
        valid: false,
        message: "Notary 2 must have both fields (seal & sign) or be absent.",
      };
    }

    return {
      valid: true,
      message: "Valid notary setup.",
      hasFullNotaryA: hasFullNotaryA,
      hasFullNotaryB: hasFullNotaryB,
    };
  };

  const handleLockUnlock = async () => {
    try {
      setLockLoading(true);

      let loaded = false;

      // PRELOAD IF NEEDED
      if (
        !currentProcessingFile?.document?.formID ||
        selectedPackageForm?.formID !== currentProcessingFile?.document?.formID
      ) {
        loaded = await handleLoadDocument();
      } else {
        loaded = true;
      }
      //

      if (!loaded) {
        return;
      }

      if (!selectedPackageForm?.locked) {
        const addedDefaultBoxes = filterDefBoxes(
          currentProcessingFile?.document?.tempFields?.formFields,
          fieldMap
        );

        //? VALIDATION
        if (areAllOptionsFalse(options)) {
          setLockErrorText("Please select at least one option to continue");
          return;
        }
        if (options.upload) {
          if (addedDefaultBoxes?.length > 0) {
            setLockErrorText(
              "Please remove all signature fields from the document. You have selected 'Upload Only' without any further processing."
            );
            return;
          }
        }
        if (options.notarize) {
          const notaryValidationResp = validateNotaryPairs(addedDefaultBoxes);

          let notaryScore = 0;
          let signatureScore = 0;

          const notaryA = notaryValidationResp?.hasFullNotaryA;
          const notaryB = notaryValidationResp?.hasFullNotaryB;

          const hasSignerA = addedDefaultBoxes.includes("sign_a");
          const hasSignerB = addedDefaultBoxes.includes("sign_b");
          const hasSignerC = addedDefaultBoxes.includes("sign_c");

          if (notaryA) {
            notaryScore += 1;
          }
          if (notaryB) {
            notaryScore += 1;
          }
          if (hasSignerA) {
            signatureScore += 1;
          }
          if (hasSignerB) {
            signatureScore += 1;
          }
          if (hasSignerC) {
            signatureScore += 1;
          }

          if (!notaryValidationResp?.valid) {
            setLockErrorText(notaryValidationResp?.message);
            return;
          }
          if (!hasSignerA && !hasSignerB) {
            setLockErrorText(
              "Please add at least one signature field to the document."
            );
            return;
          }
          if (
            signatureScore < notaryScore || // More notaries than signers = Invalid
            (signatureScore > notaryScore && notaryScore < 2) // More signers than notaries but not maxed out = Invalid
          ) {
            setLockErrorText(
              "Please ensure that each notary has a corresponding signer."
            );
            return;
          }
        }
        //?
      }

      const locked = await dispatch(
        adjustDocumentLock(
          selectedPackage?.packageID,
          selectedPackageForm?.formID
        )
      );

      await setSelectedPackage((prevPackage) => ({
        ...prevPackage,
        documents: prevPackage.documents.map((doc) =>
          doc.formID === selectedPackageForm?.formID ? { ...doc, locked } : doc
        ),
      }));
      await setSelectedPackageForm((prevForm) => ({
        ...prevForm,
        locked,
      }));
      await setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          locked,
        },
      }));
    } catch (e) {
    } finally {
      setLockLoading(false);
    }
  };

  return (
    <Box>
      {/* Action Selection */}
      <Card
        sx={{
          mb: 3,
          boxShadow: "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
        }}
      >
        <CardContent sx={{ pt: 3 }}>
          <Typography color="primary" variant="h6" gutterBottom>
            Select What You Want to Do with This Form
          </Typography>
          <Grid container spacing={2}>
            {["upload", "notarize", "sign", "record"].map((option) => (
              <Grid size={{ xs: 6, sm: 3 }} key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        option === "record" &&
                        !selectedPackageForm?.is_notice_of_commencement
                          ? false
                          : options?.[option] ?? false // ✅ Ensure `false` if undefined
                      }
                      onChange={handleOptionChange}
                      name={option}
                      disabled={
                        selectedPackageForm?.locked ||
                        disableOptions?.[option] ||
                        (option === "record" &&
                          !selectedPackageForm?.is_notice_of_commencement)
                      }
                    />
                  }
                  label={option.charAt(0).toUpperCase() + option.slice(1)}
                />
              </Grid>
            ))}
          </Grid>
          <Box
            mt={2}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Text Box - Allow it to take available space but not shrink the button */}
            <Box sx={{ maxWidth: "80%", flexGrow: 1, overflow: "hidden" }}>
              <Typography
                fontWeight="bold"
                variant="body2"
                color="error"
                sx={{ wordWrap: "break-word" }}
              >
                {lockErrorText}
              </Typography>
            </Box>

            {/* Button Box - Prevent shrinking */}
            <Box sx={{ flexShrink: 0, ml: 2 }}>
              <LoadingButton
                loading={lockLoading}
                variant={selectedPackageForm?.locked ? "contained" : "outlined"}
                size="small"
                color={selectedPackageForm?.locked ? "secondary" : "primary"}
                onClick={handleLockUnlock}
                startIcon={
                  selectedPackageForm?.locked ? <LockIcon /> : <LockOpenIcon />
                }
              >
                {selectedPackageForm?.locked
                  ? "Unlock & Edit"
                  : "Validate & Lock"}
              </LoadingButton>
            </Box>
          </Box>
        </CardContent>
      </Card>

      {options.record && (
        <Card
          sx={{
            mb: 3,
            boxShadow: "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          <CardContent sx={{ pt: 3 }}>
            <Typography color="primary" variant="h6">
              Recording Details
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              These names will be sent to the recording office to record the
              Notice of Commencement.
            </Typography>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12 }}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    boxShadow: "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        Grantor (Property Owner)
                      </Typography>
                      {selectedPackageForm?.locked ? (
                        <IconButton disabled onClick={() => {}}>
                          <CloseIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      ) : (
                        <Tooltip title="Clear Fields">
                          <IconButton
                            onClick={() => {
                              clearSignerFields("signer_1");
                            }}
                          >
                            <CloseIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    {options?.notarize && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "RGBA(60, 145, 80, 0.6)",
                          borderRadius: 1,
                          p: 1,
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          Signer 1
                        </Typography>{" "}
                        <FaSignature size={18} color="white" />
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{ mt: options?.notarize ? -2 : -0.5 }}
                    display="flex"
                    alignItems="center"
                    gap={1}
                  >
                    <Typography variant="body2">Personal</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={selectedPackageForm?.locked}
                          checked={signers[0].ownerNameType === "organization"}
                          onChange={() =>
                            setSigners((prev) =>
                              prev.map((signer) =>
                                signer.signer === "signer_1"
                                  ? {
                                      ...signer,
                                      ownerNameType:
                                        signer.ownerNameType === "personal"
                                          ? "organization"
                                          : "personal",
                                    }
                                  : signer
                              )
                            )
                          }
                          sx={{
                            "& .MuiSwitch-switchBase": {
                              color: "primary.main", // ✅ Ensures primary color when unchecked
                            },
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "primary.main", // ✅ Ensures primary color when checked
                            },
                            "& .MuiSwitch-track": {
                              backgroundColor: "primary.main", // ✅ Ensures primary color track
                            },
                          }}
                          color="primary"
                        />
                      }
                      label={
                        <Typography variant="body2">Organization</Typography>
                      }
                    />
                  </Box>

                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={2}>
                    {options?.notarize ||
                    (!options?.notarize &&
                      signers[0].ownerNameType === "personal") ? (
                      <>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="First Name"
                            value={signers[0].firstName}
                            onChange={(e) => {
                              handleFieldsChange(e, "firstName", 0);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Middle Name"
                            value={signers[0].middleName}
                            onChange={(e) => {
                              handleFieldsChange(e, "middleName", 0);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Last Name"
                            value={signers[0].lastName}
                            onChange={(e) => {
                              handleFieldsChange(e, "lastName", 0);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {signers[0].ownerNameType === "organization" && (
                      <>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Company Name"
                            value={signers[0].companyName}
                            onChange={(e) => {
                              handleFieldsChange(e, "companyName", 0);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        disabled={selectedPackageForm?.locked}
                        fullWidth
                        label="Email"
                        value={signers[0].email}
                        onChange={(e) => {
                          handleFieldsChange(e, "email", 0);
                        }}
                        onBlur={handleBlurSave}
                        variant="filled"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        disabled={selectedPackageForm?.locked}
                        fullWidth
                        label="Phone Number"
                        value={signers[0].phone}
                        onChange={(e) => {
                          handleFieldsChange(e, "phone", 0);
                        }}
                        onBlur={handleBlurSave}
                        variant="filled"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Autocomplete
                        disabled={selectedPackageForm?.locked}
                        sx={{
                          width: "100%",
                          userSelect: "none",
                          WebkitUserSelect: "none",
                          MozUserSelect: "none",
                          MsUserSelect: "none",
                        }}
                        options={countiesForLicense}
                        value={signers[0].county || ""}
                        onChange={(e, value) => {
                          handleFieldsChange(value, "county", 0);
                        }}
                        onBlur={handleBlurSave}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="County"
                            autoComplete="off"
                            slotProps={{
                              htmlInput: {
                                ...params.inputProps,
                                autoComplete: "new-password",
                              },
                            }}
                            sx={{
                              userSelect: "none",
                              WebkitUserSelect: "none",
                              MozUserSelect: "none",
                              MsUserSelect: "none",
                            }}
                          />
                        )}
                        slotProps={{
                          listbox: {
                            style: {
                              maxHeight: "150px",
                              overflowY: "auto",
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid size={{ xs: 12 }}>
                <Card
                  variant="outlined"
                  sx={{
                    p: 1.5,
                    borderRadius: 2,
                    boxShadow: "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
                  }}
                >
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box
                      sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                    >
                      <Typography variant="subtitle1" fontWeight="bold">
                        Grantee (Contractor)
                      </Typography>
                      {selectedPackageForm?.locked ? (
                        <IconButton disabled onClick={() => {}}>
                          <CloseIcon sx={{ fontSize: "20px" }} />
                        </IconButton>
                      ) : (
                        <Tooltip title="Clear Fields">
                          <IconButton
                            onClick={() => {
                              clearSignerFields("signer_2");
                            }}
                          >
                            <CloseIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>
                    {options?.notarize && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          backgroundColor: "RGBA(175, 60, 60, 0.6)",
                          borderRadius: 1,
                          p: 1,
                          gap: 2,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            textAlign: "center",
                            textTransform: "uppercase",
                          }}
                        >
                          Signer 2
                        </Typography>
                        <FaSignature size={18} color="white" />
                      </Box>
                    )}
                  </Box>

                  <Divider sx={{ my: 1 }} />
                  <Grid container spacing={2}>
                    {options?.notarize && (
                      <>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="First Name"
                            value={signers[1].firstName}
                            onChange={(e) => {
                              handleFieldsChange(e, "firstName", 1);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Middle Name"
                            value={signers[1].middleName}
                            onChange={(e) => {
                              handleFieldsChange(e, "middleName", 1);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Last Name"
                            value={signers[1].lastName}
                            onChange={(e) => {
                              handleFieldsChange(e, "lastName", 1);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        disabled={selectedPackageForm?.locked}
                        fullWidth
                        label="Company Name"
                        value={signers[1].companyName}
                        onChange={(e) => {
                          handleFieldsChange(e, "companyName", 1);
                        }}
                        onBlur={handleBlurSave}
                        variant="filled"
                      />
                    </Grid>

                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        disabled={selectedPackageForm?.locked}
                        fullWidth
                        label="Email"
                        value={signers[1].email}
                        onChange={(e) => {
                          handleFieldsChange(e, "email", 1);
                        }}
                        onBlur={handleBlurSave}
                        variant="filled"
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <TextField
                        disabled={selectedPackageForm?.locked}
                        fullWidth
                        label="Phone Number"
                        value={signers[1].phone}
                        onChange={(e) => {
                          handleFieldsChange(e, "phone", 1);
                        }}
                        onBlur={handleBlurSave}
                        variant="filled"
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {/* Signer Fields */}
      {options.notarize || options.sign ? (
        <Card
          sx={{
            mb: 3,
            boxShadow: "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
          }}
        >
          <CardContent sx={{ pt: 3 }}>
            <Typography color="primary" variant="h6">
              {options?.record ? "Additional Signer" : "Signer Details"}
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
              These names will be assigned to the signature fields.
            </Typography>
            <Grid container spacing={2}>
              {signers.map((signer, index) => {
                if (options?.record && index !== 2) return;
                return (
                  <Grid size={{ xs: 12 }} key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        p: 2,
                        borderRadius: 2,
                        mb: 1,
                        boxShadow:
                          "3px 5px 6px 4px rgba(0, 0, 0, 0.2) !important",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          backgroundColor:
                            index === 0
                              ? "RGBA(60, 145, 80, 0.6)"
                              : index === 1
                              ? "RGBA(175, 60, 60, 0.6)"
                              : "RGBA(140, 80, 180, 0.6)",
                          borderRadius: 1,
                          p: 1,
                          gap: 2,
                        }}
                      >
                        <Box sx={{ display: "flex", gap: 2 }}>
                          <Typography
                            sx={{
                              color: "white",
                              fontSize: "14px",
                              fontWeight: "bold",
                              textAlign: "center",
                              textTransform: "uppercase",
                            }}
                          >
                            Signer {index + 1}
                          </Typography>
                          <FaSignature size={18} color="white" />
                        </Box>
                        {selectedPackageForm?.locked ? (
                          <IconButton disabled onClick={() => {}}>
                            <CloseIcon
                              sx={{ fontSize: "20px", color: "#fff" }}
                            />
                          </IconButton>
                        ) : (
                          <Tooltip title="Clear Fields">
                            <IconButton
                              onClick={() => {
                                clearSignerFields(`signer_${index + 1}`);
                              }}
                            >
                              <CloseIcon
                                sx={{ fontSize: "20px", color: "#fff" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Grid container spacing={2}>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="First Name"
                            value={signer.firstName}
                            onChange={(e) => {
                              handleFieldsChange(e, "firstName", index);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Last Name"
                            value={signer.lastName}
                            onChange={(e) => {
                              handleFieldsChange(e, "lastName", index);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Email"
                            value={signer.email}
                            onChange={(e) => {
                              handleFieldsChange(e, "email", index);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                        <Grid size={{ xs: 12, sm: 6 }}>
                          <TextField
                            disabled={selectedPackageForm?.locked}
                            fullWidth
                            label="Phone Number"
                            value={signer.phone}
                            onChange={(e) => {
                              handleFieldsChange(e, "phone", index);
                            }}
                            onBlur={handleBlurSave}
                            variant="filled"
                          />
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        </Card>
      ) : null}
    </Box>
  );
};

export default RenderedPackageFormFields;
