import { Add } from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import ChangePlanMultistepForm, { FormStep } from "../ChangePlanMultistepForm";
import { DateTime } from "luxon";
import {
  Box,
  Grid2 as Grid,
  Card,
  Button,
  Typography,
  Stack,
  CircularProgress,
  Drawer,
  useMediaQuery,
  useTheme,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LottieAnimation from "../../animation/LottieAnimation";
import axios from "axios";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ChangePlan from "../ChangePlan";
import SubscribeExpiredPlan from "../SubscribeExpiredPlan";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import { CloudQueue } from "@mui/icons-material";
import { Download } from "@mui/icons-material";
import {
  fetchUserData,
  refreshDiscountData,
  updateExtraCountyData,
  updateSelectedCounties,
} from "../../store/actions/userActions";
import { useDispatch } from "react-redux";
import ChangePlanHeader from "../ChangePlanHeader";
import ChangePlanContractor from "../auth/registerSteps/ChangePlanContractor";
import {
  fetchSubUserData,
  refreshSubDiscountData,
} from "../../store/actions/subUserActions";
import MobileFooter from "../layout/MobileFooter";
import { useTour } from "@reactour/tour";
import { tourSteps } from "../../data/walkthroughDemo/steps";
import { setUpgradeRequested } from "../../store/actions/dataActions";
import AddPaymentCardModal from "../modals/AddPaymentCardModal";
import AddDefaultCardModal from "../popover/promoPopover/AddDefaultCardModal";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PurchaseExtraCounty from "../popover/promoPopover/PurchaseExtraCounty";
import RemoveExtraCounty from "../popover/promoPopover/removeExtraCounty";
import InfoRemoveCountyModal from "../popover/promoPopover/InfoRemoveCountyModal";
import UpdateCountiesWarnModal from "../auth/registerSteps/UpdateCountiesWarnModal";

const Subscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const stripe = useStripe();
  const elements = useElements();

  //? SCREEN SIZES ##################################################################
  const below1450 = useMediaQuery("(max-width:1450px)");
  const iphone12Height = useMediaQuery("(min-height: 840px)");
  const smallHeight = useMediaQuery("(max-height: 735px)");
  const mobileSize = useMediaQuery(theme.breakpoints.down("sm"));
  const mobileSizeUp = useMediaQuery(theme.breakpoints.up("md"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const largePC = useMediaQuery(theme.breakpoints.up("xl"));
  //? ###############################################################################

  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const upgradeRequested = useSelector(
    (state) => state.nocData.upgradeRequested
  );
  const [oldPlan, setOldPlan] = useState("");
  const [currentPlan, setCurrentPlan] = useState();

  const [selectedName, setSelectedName] = useState(null);
  const [checked, setChecked] = useState(true);

  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const [stepNumber, setStepNumber] = useState(0);

  const [tiers, setTiers] = useState([]);
  const [terms, setTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newSubscriptionDrawer, setNewSubscriptionDrawer] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [defPlan, setDefPlan] = useState({
    expired: false,
    canceled: false,
    package_type: "",
  });
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [isLoad, setIsload] = useState(false);
  const [disableInputs, setDisableInputs] = useState(true);
  const [duplicatedLicenses, setDuplicatedLicenses] = useState([]);
  const [validLicense, setValidLicense] = useState(false);
  const [additionalData, setAdditionalData] = useState();
  const [oldChecked, setOldChecked] = useState(false);
  const [oldPlanType, setOldPlanType] = useState("");
  const [lastDisabled, setLastDisabled] = useState(true);
  const [contractorList, setContractorList] = useState([]);
  const [timezone, setTimezone] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [renewDate, setRenewDate] = useState(null);
  const [countyRenewDate, setCountyRenewDate] = useState(null);

  const [paymentCards, setPaymentCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [addPaymentModalOpen, setAddPaymentModalOpen] = useState(false);

  const { isOpen, setCurrentStep, currentStep } = useTour();

  const [selectedPlan, setSelectedPlan] = useState();
  const [plans, setPlans] = useState();
  const [promoCodeName, setPromoCodeName] = useState("");

  const [contractorIndex, setContractorIndex] = useState(0);
  const [deleteLicenses, setDeleteLicenses] = useState([]);

  //? ADD NEW COUNTIES ///////////////////////////////////////////
  const [addCountyModalOpen, setAddCountyModalOpen] = useState(false);
  const [extraCountyPaymentModalOpen, setExtraCountyPaymentModalOpen] =
    useState(false);
  const [addCountyPaymentModalOpen, setAddCountyPaymentModalOpen] =
    useState(false);
  const [addCountyLoading, setAddCountyLoading] = useState(false);
  const [extraCountyTitle, setExtraCountyTitle] = useState("");
  const [countyValue, setCountyValue] = useState(0);
  const [removeCountyModalOpen, setRemoveCountyModalOpen] = useState(false);
  const [removeCountyLoading, setRemoveCountyLoading] = useState(false);
  const [removeCountyValue, setRemoveCountyValue] = useState(0);
  const [removeCounties, setRemoveCounties] = useState([]);
  const [infoRemoveCounty, setInfoRemoveCounty] = useState(false);
  const [needRemoveExtraCountyQty, setNeedRemoveExtraCountyQty] = useState(0);
  const [needRemoveFreeCountyQty, setNeedRemoveFreeCountyQty] = useState(0);
  const [updateCountiesLoading, setUpdateCountiesLoading] = useState(false);
  const [removeAdjustedCountiesList, setRemoveAdjustedCountiesList] = useState(
    []
  );
  //? /////////////////////////////////////////////////////////////

  const [addCardType, setAddCardType] = useState("add");

  //? NEED COUNTY UPDATE WARNING MODAL ////////////////////////////
  const [countyWarnModalOpen, setCountyWarnModalOpen] = useState(false);

  useEffect(() => {
    if (stepNumber === 1 && countyWarnModalOpen === false) {
      //? FUTURE PLAN //////////////////////////////////////////////////
      const newFreeCountyQty = parseInt(selectedPlan?.county); // Free counties in the new plan
      const newMaxCountyQty =
        newFreeCountyQty === 67 ? 0 : parseInt(selectedPlan?.max_county); // Max counties allowed in the new plan
      const newMaxExtraCountyQty = Math.max(
        newMaxCountyQty - newFreeCountyQty,
        0
      ); // Max extra paid counties allowed in the new plan
      //? ///////////////////////////////////////////////////////////////
      //? CURRENT PLAN //////////////////////////////////////////////////
      const selectedCardsQty = currentUser?.user?.selectedCountiesNOC.length; // Total selected counties
      const selectedExtraQty = currentUser?.tier?.additions?.county?.qty || 0; // Paid extra counties in the current plan
      //? ///////////////////////////////////////////////////////////////

      // Calculate how many extra paid counties need to be removed
      const needRemoveExtraCountyQty = Math.max(
        selectedExtraQty - newMaxExtraCountyQty,
        0
      );

      // Calculate the total counties (free + paid) to remove
      const totalAllowedCounties =
        newFreeCountyQty + Math.min(selectedExtraQty, newMaxExtraCountyQty); // Total allowed counties
      const needRemoveFreeCountyQty = Math.max(
        selectedCardsQty - totalAllowedCounties,
        0
      );

      setNeedRemoveExtraCountyQty(needRemoveExtraCountyQty);
      setNeedRemoveFreeCountyQty(needRemoveFreeCountyQty);

      // Check if limits are exceeded to trigger the warning modal
      const extraCountyInLimit = newMaxExtraCountyQty >= selectedExtraQty; // Check if extra paid counties are within limit
      const allowedCountyInLimit = totalAllowedCounties >= selectedCardsQty; // Check if total selected counties are within limit

      if (!extraCountyInLimit || !allowedCountyInLimit) {
        setCountyWarnModalOpen(true);
        return;
      }
    }
  }, [stepNumber, selectedPlan, currentUser]);
  //? /////////////////////////////////////////////////////////////

  useEffect(() => {
    if (stepNumber === 1 && openDrawer === false) {
      setStepNumber(0);
    }
  }, [stepNumber, openDrawer]);

  useEffect(() => {
    const transformedCards =
      currentUser?.payment_cards?.map((card) => {
        const lastTwo = String(card.exp_year).slice(-2);
        const first = card.exp_month;

        return {
          id: card.id,
          cardNum: "**** **** **** " + card.last4,
          cardName: card.name ? card.name : card.card_holder,
          expires:
            (first < 10 ? "0" + String(first) : String(first)) + "/" + lastTwo,
          default: card.default,
        };
      }) || []; // Ensure we default to an empty array if no cards exist

    setPaymentCards(transformedCards);

    // Find the default card from the transformed cards
    const foundDefaultCard = transformedCards.find((card) => card.default);

    // Save the default card in the state, if it exists
    setDefaultCard(foundDefaultCard || null); // Set null if no default card is found
  }, [currentUser?.payment_cards]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isChange = queryParams.get("change");

    if (isChange === "true") {
      setOpenDrawer(true);
    }
  }, []);

  let count = 0;

  useEffect(() => {
    if (upgradeRequested) {
      setOpenDrawer(true);
      dispatch(setUpgradeRequested(false));
    }
  }, [upgradeRequested]);

  useEffect(() => {
    // Check if the current step is related to the subscription page
    if (isOpen && currentStep === 23 && !loading) {
      // When loading is complete, move to the next tour step
      setCurrentStep((s) => (s === tourSteps?.length - 1 ? 0 : s + 1));
    }
  }, [isOpen, currentStep, loading]);

  useEffect(() => {
    if (currentUser?.user?.expired === true) {
      setDefPlan({
        expired: true,
        canceled: false,
        package_type: "Expired Subscription",
      });
    }
  }, [currentUser]);

  const handleRemoveCounty = async () => {
    try {
      setRemoveCountyLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/remove-subscription-county`,
        {
          currentSession: currentSession,
          removeCountyValue: removeCountyValue,
          removeCounties: removeCounties,
        },
        { withCredentials: true }
      );
      if (resp.data.success) {
        if (resp?.data?.data) {
          await dispatch(updateExtraCountyData(resp?.data?.data));
        }

        if (resp?.data?.user_counties) {
          await dispatch(updateSelectedCounties(resp?.data?.user_counties));
        }

        setRemoveCountyModalOpen(false);
        setRemoveCountyValue(0);
        setRemoveCounties([]);
      }
    } catch (error) {
    } finally {
      setRemoveCountyLoading(false);
    }
  };

  const handleModifyCounty = async () => {
    try {
      setUpdateCountiesLoading(true);

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/adjust-subscription-county`,
        {
          currentSession: currentSession,
          needRemoveExtraCountyQty: needRemoveExtraCountyQty,
          removeAdjustedCountiesList: removeAdjustedCountiesList,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        if (resp?.data?.data) {
          await dispatch(updateExtraCountyData(resp?.data?.data));
        }

        if (resp?.data?.user_counties) {
          await dispatch(updateSelectedCounties(resp?.data?.user_counties));
        }

        setCountyWarnModalOpen(false);
        setNeedRemoveExtraCountyQty(0);
        setRemoveAdjustedCountiesList([]);
      }
    } catch (error) {
    } finally {
      setUpdateCountiesLoading(false);
    }
  };

  const handleAddCounty = async () => {
    try {
      setAddCountyLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/add-subscription-county`,
        {
          currentSession: currentSession,
          num_county: countyValue,
        },
        { withCredentials: true }
      );

      if (resp.data.success) {
        if (resp?.data?.data) {
          await dispatch(updateExtraCountyData(resp?.data?.data));
        }

        if (resp?.data?.discount) {
          if (currentSession === "subUserSession") {
            await dispatch(refreshSubDiscountData(resp?.data?.discount));
          } else {
            await dispatch(refreshDiscountData(resp?.data?.discount));
          }
        }

        setAddCountyModalOpen(false);
        setCountyValue(0);
      }
    } catch (err) {
    } finally {
      setAddCountyLoading(false);
    }
  };

  const handleRemoveContractor = () => {
    const selectedCon =
      currentUser?.user?.contractors[contractorIndex]?.licenseNumber;

    setDeleteLicenses((prevSelected) => {
      if (prevSelected.includes(selectedCon)) {
        // Remove the license number if it's already included
        return prevSelected.filter((license) => license !== selectedCon);
      } else {
        // Add the license number if it's not included
        return [...prevSelected, selectedCon];
      }
    });
  };

  const handleReactivatePlan = async () => {
    setIsLoading(true);

    try {
      const changePlan = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/resume-canceled-subscription`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      if (changePlan.data.success) {
        setDefPlan((prevDefPlan) => ({
          ...prevDefPlan,
          expired: false,
          canceled: false,
          package_type: "Paid Package",
        }));
        setErrorText(changePlan.data.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setErrorText(changePlan.data.message);
      }
      if (currentSession === "subUserSession") {
        await dispatch(fetchSubUserData(currentSession));
      } else {
        await dispatch(fetchUserData(currentSession));
      }
    } catch (err) {
      setErrorText(err.message);
      setIsLoading(false);
    }
  };

  const handleCreateExpiredPlan = async () => {
    const priceId = checked
      ? selectedPlan.price_id_year
      : selectedPlan.price_id_month;
    if (!terms) {
      setErrorText("You must accept the terms and conditions.");
    } else {
      setIsLoading(true);

      try {
        const changePlan = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/expired-create-plan`,
          {
            priceId,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );

        if (changePlan.data.success) {
          window.location.href = changePlan.data.data;
        } else {
          setIsLoading(false);
          setErrorText(changePlan.data.message);
        }
      } catch (err) {
        setErrorText(err.message);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      let startDateObj = DateTime.fromMillis(
        currentUser?.user?.current_period_start * 1000
      ).setZone(timezone);

      let renewDateObj = DateTime.fromMillis(
        currentUser?.user?.current_period_end * 1000
      ).setZone(timezone);

      const countyStamp = currentUser?.tier?.additions?.county?.renewal_date;
      if (countyStamp) {
        let countyRenewDateObj = DateTime.fromMillis(
          countyStamp * 1000
        ).setZone(timezone);
        countyRenewDateObj = countyRenewDateObj.toFormat("LL/dd/yyyy");
        setCountyRenewDate(countyRenewDateObj);
      } else {
        setCountyRenewDate("N/A");
      }

      startDateObj = startDateObj.toFormat("LL/dd/yyyy");
      renewDateObj = renewDateObj.toFormat("LL/dd/yyyy");

      setStartDate(startDateObj);
      setRenewDate(renewDateObj);
    }
    // }
  }, [timezone, currentUser]);

  useEffect(() => {
    const getTiers = async () => {
      if (!currentUser || !currentUser.user) {
        if (currentUser) {
          if (currentUser?.role === "notary") {
            navigate("/notary/dashboard");
          }
        }
        return;
      }

      if (currentUser?.subuser) {
        setTimezone(currentUser?.subuser?.timezone);
      } else {
        setTimezone(currentUser?.user?.personal[0]?.timezone);
      }

      if (
        currentUser?.subuser &&
        currentUser?.subuser.role === "Basic User" &&
        !currentUser?.user?.expired
      ) {
        navigate("/dashboard");
      }

      try {
        const url1 = `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/get-current-tier`;
        const url2 = `${process.env.REACT_APP_BASE_URL}/nocApi/data/additional`;

        // Start both requests concurrently
        const [resp, res] = await Promise.all([
          axios.post(
            url1,
            { currentSession: currentSession },
            { withCredentials: true }
          ),
          axios.get(url2),
        ]);

        if (res.data.success) {
          setAdditionalData(res.data.data);
        }

        if (resp?.data?.success) {
          setTiers(resp?.data?.data);
          setPlans(res?.data?.data?.plans);
          setDefPlan(resp?.data?.myPlan);

          setOldPlanType(resp?.data?.myPlan?.license_type);

          setDisableInputs(false);
          if (!res?.data?.data?.plans.includes(resp?.data?.myPlan?.title)) {
            setPromoCodeName(resp?.data?.myPlan?.title);
          } else {
            setPromoCodeName("");
          }
          setChecked(resp?.data?.myPlan?.interval === "month" ? false : true);
          setOldChecked(
            resp?.data?.myPlan?.interval === "month" ? false : true
          );
        }
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };

    if (count < 1) {
      getTiers();
      count += 1;
    }
  }, [currentUser]);

  useEffect(() => {
    if (errorText !== "") {
      const timer = setTimeout(() => setErrorText(""), 5000);
      return () => clearTimeout(timer);
    }
  }, [errorText]);

  return (
    <>
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {tabletSize ? (
              <>
                <Box sx={{ mb: 2 }}>
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
                <LottieAnimation />
              </>
            ) : (
              <CircularProgress color="warning" size="10rem" />
            )}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              mx: mobileSizeUp ? "auto" : 0,
              maxWidth: "1500px",
              py: 1,
              backgroundColor: "#EEF2F6",
              mb: tabletSize ? "5rem" : undefined,
            }}
          >
            <Grid
              container
              sx={{
                px: mobileSize ? 1 : tabletSize ? 2 : 3,
              }}
            >
              <Card
                className="step25"
                mt={1}
                py={3}
                px={mobileSize ? 0 : 8}
                component={Grid}
                container
              >
                <Grid container>
                  <Grid size={{ sm: 12 }}>
                    {currentUser?.subuser &&
                    currentUser?.subuser?.role === "Basic User" &&
                    currentUser?.user?.expired ? (
                      <></>
                    ) : (
                      <Typography
                        color="primary"
                        sx={{
                          textAlign: isOpen ? "center" : "left",
                          mb: 2,
                          pl: mobileSize ? 2 : undefined,
                        }}
                        variant={mobileSize ? "h6" : "h5"}
                      >
                        Current Subscription
                      </Typography>
                    )}
                  </Grid>

                  <Grid size={{ sm: 12 }}>
                    <Stack mt={2}>
                      <Paper
                        sx={
                          !defPlan?.expired
                            ? {
                                pt: 1,
                                width: "100%",
                                margin: "auto",
                                borderRadius: "18px",
                                boxShadow: "2px 3px 10px 5px rgba(0,0,0,0.2)",
                              }
                            : {
                                pt: 2,
                                margin: "auto",
                                backgroundColor: "#FFD9DC",
                                border: "4px solid #FF0000",
                                borderRadius: "18px",
                              }
                        }
                      >
                        <Box sx={{ px: 2 }}>
                          <Typography
                            align="center"
                            variant={mobileSize ? "h5" : "h4"}
                            gutterBottom
                            style={
                              defPlan?.expired || defPlan?.canceled
                                ? { color: "#FF0000" }
                                : { color: "#004976" }
                            }
                          >
                            {currentUser?.tier?.title === "Free"
                              ? "Free Plan"
                              : defPlan?.package_type}
                          </Typography>
                          {defPlan?.expired && (
                            <>
                              <Typography
                                align="center"
                                variant="body1"
                                gutterBottom
                                style={{ color: "#FF0000" }}
                              >
                                {currentUser?.subuser
                                  ? "Your subscription is expired. Please contact your Administrator for further assistance."
                                  : "Your payment has failed. Please create a new subscription to continue using our services."}
                              </Typography>
                            </>
                          )}
                          {defPlan?.canceled && (
                            <>
                              <Typography
                                align="center"
                                variant="body1"
                                gutterBottom
                                style={{ color: "#FF0000" }}
                              >
                                {`Your subscription is scheduled to be canceled on ${renewDate}. To continue using Permit Rockstar, please consider reactivating your subscription.`}
                              </Typography>
                            </>
                          )}
                          <List>
                            <ListItem>
                              <ListItemIcon>
                                <MonetizationOnIcon
                                  style={{ color: "#2196F3" }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                primary="Plan Name"
                                secondary={
                                  defPlan?.title &&
                                  currentUser?.tier?.discount?.monthly_price
                                    ?.duration === "forever"
                                    ? `${defPlan?.title} - Includes an indefinite discount. Subject to change or removal at any time`
                                    : defPlan?.title &&
                                      currentUser?.tier?.discount?.monthly_price
                                        ?.duration === "once"
                                    ? `${defPlan?.title} - Includes a 1-month discount`
                                    : defPlan?.title
                                    ? defPlan?.title
                                    : "N/A"
                                }
                              />
                            </ListItem>

                            <Box
                              sx={{ borderBottom: "3px solid #d3d3d3", mx: 2 }}
                            ></Box>

                            <Box sx={{ display: "flex" }}>
                              <ListItem>
                                <ListItemIcon>
                                  <MonetizationOnIcon
                                    style={{ color: "#2196F3" }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    parseInt(
                                      currentUser?.tier?.additions?.county?.qty
                                    ) > 0
                                      ? "Base Plan + Additional Counties Cost"
                                      : "Base Plan Cost"
                                  }
                                  secondary={(() => {
                                    let basePriceElement = "N/A"; // Default value

                                    // ✅ Handle Base Plan Price + Monthly Discount
                                    if (currentUser?.trial?.has_trial) {
                                      basePriceElement = "Free Trial";
                                    } else if (
                                      currentUser?.subuser &&
                                      currentUser?.subuser?.role ===
                                        "Basic User" &&
                                      currentUser?.user?.expired
                                    ) {
                                      basePriceElement = "-------";
                                    } else if (defPlan?.price !== undefined) {
                                      const basePrice = parseFloat(
                                        defPlan?.price || 0
                                      );
                                      const monthlyDiscount = parseFloat(
                                        currentUser?.tier?.discount
                                          ?.monthly_price?.amount || 0
                                      );
                                      const discountedBasePrice =
                                        basePrice - monthlyDiscount;

                                      basePriceElement =
                                        monthlyDiscount &&
                                        currentUser?.tier?.discount
                                          ?.monthly_price?.duration ===
                                          "forever" ? (
                                          <>
                                            <span
                                              style={{
                                                textDecoration: "line-through",
                                              }}
                                            >
                                              {`$${basePrice.toFixed(2)}`}
                                            </span>
                                            {` $${discountedBasePrice.toFixed(
                                              2
                                            )}`}
                                          </>
                                        ) : (
                                          `$${basePrice.toFixed(2)}`
                                        );
                                    }

                                    // ✅ Handle Extra County Cost (No Discount Logic)
                                    const countyQty =
                                      parseInt(
                                        currentUser?.tier?.additions?.county
                                          ?.qty
                                      ) || 0;
                                    const extraCountyCost = parseFloat(
                                      currentUser?.service_tiers
                                        ?.extra_county_cost || 0
                                    );
                                    const extraCountyElement =
                                      countyQty > 0
                                        ? ` + $${(
                                            countyQty * extraCountyCost
                                          ).toFixed(2)}`
                                        : "";

                                    return (
                                      <>
                                        {basePriceElement}
                                        {extraCountyElement}
                                      </>
                                    );
                                  })()}
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <ScheduleIcon style={{ color: "#FFC107" }} />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Interval"
                                  secondary={
                                    defPlan?.interval
                                      ? defPlan.interval
                                          .charAt(0)
                                          .toUpperCase() +
                                        defPlan.interval.slice(1) +
                                        "ly"
                                      : "N/A"
                                  }
                                />
                              </ListItem>
                            </Box>

                            <Box
                              sx={{ borderBottom: "3px solid #d3d3d3", mx: 2 }}
                            ></Box>

                            <Box sx={{ display: "flex" }}>
                              <ListItem>
                                <ListItemIcon>
                                  <EventIcon style={{ color: "#9C27B0" }} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    currentUser?.trial?.has_trial
                                      ? "Trial Subscription Start Date"
                                      : "Subscription Start Date"
                                  }
                                  secondary={
                                    currentUser?.subuser &&
                                    currentUser?.subuser?.role ===
                                      "Basic User" &&
                                    currentUser?.user?.expired
                                      ? "-------"
                                      : startDate && startDate !== "12/31/1969"
                                      ? startDate
                                      : "N/A"
                                  }
                                />
                              </ListItem>
                              <ListItem>
                                <ListItemIcon>
                                  <EventIcon style={{ color: "#9C27B0" }} />
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    currentUser?.trial?.has_trial
                                      ? "Trial Subscription End Date"
                                      : "Subscription Renew Date"
                                  }
                                  secondary={
                                    currentUser?.subuser &&
                                    currentUser?.subuser?.role ===
                                      "Basic User" &&
                                    currentUser?.user?.expired
                                      ? "-------"
                                      : defPlan?.canceled
                                      ? "-"
                                      : renewDate && renewDate !== "12/31/1969"
                                      ? renewDate
                                      : "N/A"
                                  }
                                />
                              </ListItem>
                            </Box>

                            <Box
                              sx={{ borderBottom: "3px solid #d3d3d3", mx: 2 }}
                            ></Box>

                            <Box sx={{ display: "flex" }}>
                              <ListItem>
                                <ListItemIcon>
                                  <Download style={{ color: "#2196F3" }} />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Included Free Downloads"
                                  secondary={
                                    currentUser?.tier?.downloads === "Unlimited"
                                      ? "Unlimited"
                                      : `${currentUser?.user?.analytics?.availableDownloads} Permit Packages`
                                  }
                                />
                              </ListItem>
                              <ListItem>
                                <Box sx={{ position: "relative" }}>
                                  <ListItemIcon>
                                    <CloudQueue style={{ color: "#2196F3" }} />
                                  </ListItemIcon>

                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 12,
                                      left: 18,
                                    }}
                                  >
                                    <Download
                                      sx={{
                                        color: "#2196F3",
                                        fontWeight: "bold",
                                        fontSize: "1.4rem",
                                      }}
                                    />
                                  </Box>
                                </Box>

                                <ListItemText
                                  primary="Remaining Free Downloads"
                                  secondary={
                                    currentUser?.tier?.downloads === "Unlimited"
                                      ? "Unlimited"
                                      : (() => {
                                          const availableDownloads = parseInt(
                                            currentUser?.user?.analytics
                                              ?.availableDownloads || "0"
                                          );
                                          const ppDownloaded = parseInt(
                                            currentUser?.user?.analytics
                                              ?.ppDownloaded || "0"
                                          );

                                          // Ensure the remaining value is not negative
                                          const remaining = Math.max(
                                            availableDownloads - ppDownloaded,
                                            0
                                          );

                                          // Singular or plural
                                          const packageText =
                                            remaining === 1 || remaining === 0
                                              ? "Permit Package"
                                              : "Permit Packages";

                                          return `${remaining} ${packageText}`;
                                        })()
                                  }
                                />
                              </ListItem>
                            </Box>

                            <Box
                              sx={{ borderBottom: "3px solid #d3d3d3", mx: 2 }}
                            ></Box>
                            {currentUser?.tier?.discount?.extra_county_cost
                              ?.active &&
                              !currentUser?.tier?.discount?.extra_county_cost
                                ?.used && (
                                <Typography
                                  variant="body2"
                                  align="center"
                                  mt={1}
                                  fontWeight="bold"
                                  color="primary"
                                >
                                  You are eligible for a one-time discount when
                                  adding at least{" "}
                                  {
                                    currentUser?.tier?.discount
                                      ?.extra_county_cost?.max_county
                                  }{" "}
                                  additional{" "}
                                  {currentUser?.tier?.discount
                                    ?.extra_county_cost?.max_county === 1
                                    ? "county"
                                    : "counties"}
                                </Typography>
                              )}
                            <Box sx={{ display: "flex" }}>
                              <ListItem>
                                <ListItemIcon>
                                  <LocationOnIcon
                                    style={{ color: "#E91E63" }}
                                  />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Counties"
                                  secondary={
                                    defPlan?.counties &&
                                    defPlan?.counties === "1"
                                      ? `${defPlan.counties} county`
                                      : `${defPlan.counties} counties`
                                  }
                                />
                              </ListItem>
                              <ListItem>
                                <Box sx={{ position: "relative" }}>
                                  <ListItemIcon>
                                    <LocationOnIcon
                                      style={{ color: "#E91E63" }}
                                    />
                                  </ListItemIcon>

                                  <Box
                                    sx={{
                                      position: "absolute",
                                      bottom: 10,
                                      left: 15,
                                    }}
                                  >
                                    <Add
                                      sx={{
                                        color: "green",
                                        fontWeight: "bold",
                                        fontSize: "1.6rem",
                                      }}
                                    />
                                  </Box>
                                </Box>

                                <ListItemText
                                  primary="Additional Counties Plan"
                                  secondary={`${
                                    currentUser?.tier?.additions?.county?.qty
                                  } ${
                                    parseInt(
                                      currentUser?.tier?.additions?.county?.qty
                                    ) <= 1
                                      ? "county"
                                      : "counties"
                                  }`}
                                />
                              </ListItem>
                            </Box>

                            {!defPlan?.canceled && !defPlan?.expired && (
                              <Box
                                sx={{
                                  display: currentUser?.subuser
                                    ? "none"
                                    : "flex",
                                  gap: 2,
                                  justifyContent: "center",
                                  alignItems: "center",
                                  my: 2,
                                }}
                              >
                                <Button
                                  onClick={() => setRemoveCountyModalOpen(true)}
                                  disabled={
                                    parseInt(
                                      currentUser?.tier?.additions?.county?.qty
                                    ) <= 0
                                  }
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                >
                                  Remove Additional Counties
                                </Button>
                                <Button
                                  onClick={() => setAddCountyModalOpen(true)}
                                  disabled={
                                    parseInt(currentUser?.tier?.county) ===
                                      67 ||
                                    parseInt(currentUser?.tier?.county) +
                                      parseInt(
                                        currentUser?.tier?.additions?.county
                                          ?.qty
                                      ) >=
                                      parseInt(currentUser?.tier?.maxCounty)
                                  }
                                  variant="contained"
                                  color="success"
                                  size="small"
                                >
                                  Add Additional Counties
                                </Button>
                              </Box>
                            )}
                          </List>
                        </Box>
                      </Paper>
                      <Typography
                        variant="body1"
                        color="#F2CD00"
                        fontWeight="bold"
                        textAlign="center"
                        sx={{ height: "1rem", mt: 1 }}
                      >
                        {errorText}
                      </Typography>
                      {currentUser.subuser ? (
                        <></>
                      ) : defPlan?.canceled ? (
                        <>
                          <LoadingButton
                            loading={isLoading}
                            size={mobileSize ? "small" : "large"}
                            onClick={handleReactivatePlan}
                            sx={{
                              mt: mobileSize ? 0 : 2,
                              mx: mobileSize ? 1 : undefined,
                            }}
                            variant="contained"
                          >
                            Re-Activate Plan
                          </LoadingButton>
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            size={
                              defPlan?.expired || defPlan?.canceled
                                ? "large"
                                : "medium"
                            }
                            onClick={() => {
                              if (isOpen) {
                                return;
                              }
                              if (defPlan?.expired) {
                                setAddCardType("updateExpired");
                                setAddCountyPaymentModalOpen(true);
                                return;
                              }
                              setSelectedPlan();
                              setOpenDrawer(true);
                            }}
                            color={defPlan?.expired ? "success" : "warning"}
                            sx={{
                              mt: mobileSize ? 0 : 0,
                              mx: mobileSize ? 1 : undefined,
                              width: "100%",
                              maxWidth:
                                defPlan?.expired || defPlan?.canceled
                                  ? "60%"
                                  : "80%",
                            }}
                            variant={
                              defPlan?.expired || defPlan?.canceled
                                ? "contained"
                                : "outlined"
                            }
                          >
                            {defPlan?.expired
                              ? "UPDATE CARD DETAILS"
                              : "CHANGE PLAN"}
                          </Button>
                        </Box>
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Card>
              {/* FIRST MODAL --> INFO PLUS CLICK BUTTON TO ADD EXTRA COUNTY WHICH OPEN A DEF PAYMENT MODAL -->  */}
              <PurchaseExtraCounty
                setPaymentModalOpen={setExtraCountyPaymentModalOpen} // <----- THIS IS THE SELECT BANK CARD MODAL
                open={addCountyModalOpen}
                setOpen={setAddCountyModalOpen}
                setExtraCountyTitle={setExtraCountyTitle}
                defaultCard={defaultCard}
                handleAddCounty={handleAddCounty}
                addCountyLoading={addCountyLoading}
                countyValue={countyValue}
                setCountyValue={setCountyValue}
              />
              {/* REMOVE COUNTY MODAL */}
              <RemoveExtraCounty
                open={removeCountyModalOpen}
                setOpen={setRemoveCountyModalOpen}
                handleRemoveCounty={handleRemoveCounty}
                removeCountyLoading={removeCountyLoading}
                removeCountyValue={removeCountyValue}
                setRemoveCountyValue={setRemoveCountyValue}
                removeCounties={removeCounties}
                setRemoveCounties={setRemoveCounties}
              />
              <InfoRemoveCountyModal
                open={infoRemoveCounty}
                setOpen={setInfoRemoveCounty}
                setIsload={setIsload}
                setIsLoading={setIsLoading}
                setErrorText={setErrorText}
              />
              {/* ********************************************************************************** */}
              <AddDefaultCardModal
                open={extraCountyPaymentModalOpen}
                setOpen={setExtraCountyPaymentModalOpen}
                paymentCards={paymentCards}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                title={extraCountyTitle}
                currentUser={currentUser}
                setAddPaymentModalOpen={setAddPaymentModalOpen}
                setIsload={setIsload}
                setIsLoading={setIsLoading}
                setErrorText={setErrorText}
                defaultCard={defaultCard}
                checkDefault={true}
              />
              <AddPaymentCardModal
                addPaymentModalOpen={addCountyPaymentModalOpen}
                setAddPaymentModalOpen={setAddCountyPaymentModalOpen}
                stripe={stripe}
                elements={elements}
                CardElement={CardElement}
                addCardType={addCardType}
                setAddCardType={setAddCardType}
              />
            </Grid>
            {tabletSize && <MobileFooter pos="bottom" pl="0.5rem" />}
          </Box>
        </>
      )}

      <Drawer
        PaperProps={{
          sx: {
            height: "100vh",
            position: "relative",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            maxWidth: "1200px",
            width: "100%",
            mx: "auto",
          }}
        >
          <ChangePlanHeader
            isLoad={isLoad}
            tabletSize={tabletSize}
            mobileSize={mobileSize}
            title={defPlan?.expired ? "Create Subscription" : "Change Plan"}
            p1=""
            p2=""
            setOpenDrawer={setOpenDrawer}
          />
          <ChangePlanMultistepForm
            stepNumber={stepNumber}
            setStepNumber={setStepNumber}
            setIsload={setIsload}
            currentUser={currentUser}
            type={defPlan?.expired ? "subscribe" : "change"}
            isLoad={isLoad}
            errorText={errorText}
            setErrorText={setErrorText}
            lastDisabled={lastDisabled}
            setLastDisabled={setLastDisabled}
            validLicense={validLicense}
            setContractorList={setContractorList}
            contractorList={contractorList}
            defPlan={defPlan}
            terms={terms}
            selectedName={selectedName}
            oldChecked={oldChecked}
            checked={checked}
            oldPlan={oldPlan}
            setPage={setPage}
            page={page}
            setDuplicatedLicenses={setDuplicatedLicenses}
            selectedPlan={selectedPlan}
            mobileSize={mobileSize}
            tabletSize={tabletSize}
            initialValues={{
              licenseType: contractorList[page]?.licenseType || "",
              licenseNumber: contractorList[page]?.licenseNumber || "",
              contractorName: contractorList[page]?.contractorName || "",
              contractorAddress: contractorList[page]?.contractorAddress || "",
              contractorPhone: contractorList[page]?.contractorPhone || "",
              contractorFax: contractorList[page]?.contractorFax || "",
              contractorEmail: contractorList[page]?.contractorEmail || "",
            }}
            deleteLicenses={deleteLicenses}
            currLicense={
              currentUser?.user?.contractors[contractorIndex]?.licenseNumber
            }
            onSubmit={async (values) => {
              setIsload(true);
              setIsLoading(true);
              setErrorText("Please Wait...");

              const priceId = selectedPlan?.monthly_price_id;

              const plan_obj = {
                title: selectedPlan?.title,
                product_id: selectedPlan?.product_id,
                price_id: priceId,
                monthly_restart: selectedPlan?.monthly_restart,
                license: parseInt(selectedPlan?.license),
                subuser: parseInt(selectedPlan?.subuser),
                noc: selectedPlan?.noc,
                county: selectedPlan?.county,
                interval: checked === true ? "year" : "month",
              };

              values["deleteLicenses"] = deleteLicenses;
              values["plan"] = plan_obj;

              try {
                if (paymentCards?.length === 0 || !defaultCard) {
                  setPaymentModalOpen(true);
                  return;
                }

                const changePlanResponse = await axios.post(
                  `${process.env.REACT_APP_BASE_URL}/nocApi/stripe/change-plan`,
                  {
                    values,
                    currentSession: currentSession,
                  },
                  { withCredentials: true }
                );

                if (changePlanResponse.data.success) {
                  window.location.href = "/dashboard";
                } else {
                  setIsLoading(false);
                  setIsload(false);
                  setErrorText(changePlanResponse.data.message);
                }
              } catch (err) {
                setErrorText(err.message);
                setIsLoading(false);
                setIsload(false);
              }
            }}
          >
            <FormStep stepName="Choose a Plan" onSubmit={(e) => {}}>
              <ChangePlan
                type={defPlan?.expired ? "subscribe" : "change"}
                isMd={tabletSize}
                setOpenDrawer={setOpenDrawer}
                checked={checked}
                setChecked={setChecked}
                promoCodeName={promoCodeName}
                selectedName={selectedName}
                currentPlan={currentPlan}
                currentUser={currentUser}
                setSelectedName={setSelectedName}
                tiers={tiers}
                trial={currentUser?.trial}
                plans={plans}
                defPlan={defPlan}
                terms={terms}
                setTerms={setTerms}
                setOpen={setOpen}
                errorText={errorText}
                isLoading={isLoading}
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                contractorList={contractorList}
                mobileSize={mobileSize}
                tabletSize={tabletSize}
                loading={loading}
              />
            </FormStep>
            <FormStep stepName="Contractor Details" onSubmit={(e) => {}}>
              <Grid container spacing={2}>
                <Grid size={{ sm: 12 }}>
                  <Typography
                    mt={0}
                    color="primary"
                    align="center"
                    variant={mobileSize ? "h6" : tabletSize ? "h5" : "h3"}
                  >
                    Manage Contractors
                  </Typography>
                  <Typography
                    mt={0}
                    color="primary"
                    align="center"
                    variant="h6"
                    px={1}
                  >
                    The extra contractors should be removed or removed all if
                    the new selected plan allows fewer contractors; then you
                    will be able to add a new contractor in the contractors area
                    if required
                  </Typography>
                </Grid>
              </Grid>
              <Box sx={{ mb: 2 }}></Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                <Typography variant="h6">
                  Allowed Contractors:{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      color:
                        currentUser?.user?.contractors.length -
                          deleteLicenses.length >
                        parseInt(selectedPlan?.license)
                          ? red[500]
                          : green[500],
                    }}
                  >
                    {parseInt(selectedPlan?.license)}
                  </span>{" "}
                  / Selected:{" "}
                  <span
                    style={{
                      fontSize: "25px",
                      color:
                        currentUser?.user?.contractors.length -
                          deleteLicenses.length >
                        parseInt(selectedPlan?.license)
                          ? red[500]
                          : green[500],
                    }}
                  >
                    {currentUser?.user?.contractors.length -
                      deleteLicenses.length}
                  </span>
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center", mb: 1 }}>
                {currentUser?.user?.contractors?.length > 0 && (
                  <Button
                    disabled={isLoad}
                    onClick={handleRemoveContractor}
                    variant="contained"
                    size="small"
                    color={
                      deleteLicenses?.includes(
                        currentUser?.user?.contractors[contractorIndex]
                          ?.licenseNumber
                      )
                        ? "warning"
                        : "error"
                    }
                  >
                    {deleteLicenses?.includes(
                      currentUser?.user?.contractors[contractorIndex]
                        ?.licenseNumber
                    )
                      ? "Undo"
                      : "Remove"}
                  </Button>
                )}
              </Box>
              <ChangePlanContractor
                contractorIndex={contractorIndex}
                setContractorIndex={setContractorIndex}
                currentUser={currentUser}
                deleteLicenses={deleteLicenses}
                type="subscription"
              />
            </FormStep>
          </ChangePlanMultistepForm>
        </Box>
        {/* ************************************************ */}
        <UpdateCountiesWarnModal
          open={countyWarnModalOpen}
          setOpen={setCountyWarnModalOpen}
          handleModifyCounty={handleModifyCounty}
          updateCountiesLoading={updateCountiesLoading}
          removeAdjustedCountiesList={removeAdjustedCountiesList}
          setRemoveAdjustedCountiesList={setRemoveAdjustedCountiesList}
          setOpenDrawer={setOpenDrawer}
          setStepNumber={setStepNumber}
          needRemoveExtraCountyQty={needRemoveExtraCountyQty}
          needRemoveFreeCountyQty={needRemoveFreeCountyQty}
        />
        <AddDefaultCardModal
          open={paymentModalOpen}
          setOpen={setPaymentModalOpen}
          paymentCards={paymentCards}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          title="Change Subscription Plan"
          currentUser={currentUser}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          setIsload={setIsload}
          setIsLoading={setIsLoading}
          setErrorText={setErrorText}
          defaultCard={defaultCard}
          checkDefault={true}
        />
        <AddPaymentCardModal
          addPaymentModalOpen={addPaymentModalOpen}
          setAddPaymentModalOpen={setAddPaymentModalOpen}
          stripe={stripe}
          elements={elements}
          CardElement={CardElement}
        />
      </Drawer>

      {/* NEW SUBSCRIPTION DRAWER */}
      <Drawer
        PaperProps={{
          sx: {
            width: tabletSize ? "100vw" : "50vw",
          },
        }}
        anchor="right"
        open={newSubscriptionDrawer}
        onClose={() => setNewSubscriptionDrawer(false)}
      >
        <SubscribeExpiredPlan
          isMd={tabletSize}
          setOpenDrawer={setNewSubscriptionDrawer}
          checked={checked}
          setChecked={setChecked}
          selectedName={selectedName}
          currentPlan={currentPlan}
          tiers={tiers}
          currentUser={currentUser}
          setSelectedName={setSelectedName}
          defPlan={defPlan}
          terms={terms}
          setTerms={setTerms}
          setOpen={setOpen}
          errorText={errorText}
          handleCreateExpiredPlan={handleCreateExpiredPlan}
          isLoading={isLoading}
        />
      </Drawer>
    </>
  );
};

export default Subscription;
