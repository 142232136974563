import { Box, Typography } from "@mui/material";
import ProcessPDFAnimation from "../../animation/ProcessPDFAnimation";

const UploaderLoadingComponent = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
      className="uploader-loading"
    >
      <Box
        sx={{
          backgroundColor: "#0f3b66",
          borderRadius: "2rem",
          width: "15rem",
          p: 2,
        }}
        className="uploader-loading__spinner"
      >
        <ProcessPDFAnimation />
      </Box>
      <Box mt={1} className="uploader-loading__text">
        <Typography align="center" variant="h6" color="primary">
          Processing Document...
        </Typography>
        <Typography align="center" variant="h6" color="primary">
          Please Wait
        </Typography>
      </Box>
    </Box>
  );
};

export default UploaderLoadingComponent;
