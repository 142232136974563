import {
  Card,
  CardContent,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useState } from "react";
import SigneeRegisterInfo from "../popover/SigneeRegisterInfo";

const TotalCounties = ({
  tabletSize,
  mobileSize,
  miniSize,
  between905and1125,
  between1200and1405,
  value = 0,
  isFadingOut,
  availableCountyTitle,
  setAvailableCountyTitle,
}) => {
  const [title, setTitle] = useState(
    "This indicate a number of counties accessible to all users within your organization."
  );
  return (
    <Card
      sx={{
        minHeight: mobileSize ? undefined : tabletSize ? "171px" : "203px",
        position: "relative",
      }}
    >
      {tabletSize ? (
        <IconButton
          onClick={() => setAvailableCountyTitle(!availableCountyTitle)}
          sx={{ position: "absolute", right: 0, top: 0 }}
        >
          <HelpOutlineIcon color="primary" />
        </IconButton>
      ) : (
        <Tooltip title={title} placement="top">
          <HelpOutlineIcon
            color="primary"
            sx={{ position: "absolute", right: 10, top: 10 }}
          />
        </Tooltip>
      )}

      {availableCountyTitle && (
        <SigneeRegisterInfo
          infoText={title}
          isFadingOut={isFadingOut}
          top={35}
        />
      )}

      <CardContent
        sx={{
          px: tabletSize ? 1.5 : between1200and1405 ? 1 : undefined,
          pt: mobileSize ? 1.5 : tabletSize ? 3 : undefined,
          pb: tabletSize ? 1.5 : undefined,
        }}
      >
        <Stack
          alignItems="flex-start"
          direction="row"
          justifyContent="space-between"
          spacing={3}
        >
          <Stack spacing={1}>
            <Typography
              color="text.secondary"
              variant={tabletSize ? "h6" : "overline"}
            >
              Total Counties in Your Subscription
            </Typography>
            <Typography variant="h5">{value}</Typography>
          </Stack>
          {!between905and1125 && !miniSize && (
            <Stack>
              <img
                alt="counties"
                src="/img/dashboard/countiesIcon.png"
                style={{
                  maxWidth: mobileSize
                    ? "5.5rem"
                    : between1200and1405
                    ? "5.5rem"
                    : "6.5rem",
                  height: "auto",
                  margin: 0,
                }}
              />
            </Stack>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TotalCounties;
