import { Box, Typography } from "@mui/material";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const CreatePackageBanner = ({ setAddingNewPackage }) => {
  return (
    <>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          bgcolor: "white",
          p: 2,
        }}
      >
        <Typography align="center" variant="h4" color="primary">
          Build Your Document Package
        </Typography>
      </Box>

      {/* Scrollable Content */}
      <Box sx={{ flexGrow: 1, overflowY: "auto", px: 2 }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "13rem", height: "auto", zIndex: 2 }}
          />
        </Box>

        <Typography mb={1} align="center" variant="body2" fontWeight="bold">
          Easily create your document package by uploading any forms you
          need—all in one place. Add as many documents as required and
          seamlessly process them for missing text, notarization, or official
          recording.
        </Typography>
        <Typography mb={1} align="center" variant="body2" fontWeight="bold">
          Once uploaded, simply mark where the notary seal, signatures, and
          signer fields should go. Our streamlined system ensures accuracy and
          compliance, making the entire process faster and hassle-free.
        </Typography>

        <Typography
          color="warning"
          align="center"
          variant="body2"
          fontWeight="bold"
        >
          Currently, the Notice of Commencement is eligible for direct recording
          with the county office.
        </Typography>
      </Box>

      {/* Fixed Add Button at the Bottom */}
      {/* <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 2,
          bgcolor: "white",
          textAlign: "center",
          p: 2,
        }}
      >
        <IconButton
          onClick={() => {
            setAddingNewPackage(true);
          }}
          sx={{
            width: 80,
            height: 80,
            bgcolor: "success.main",
            color: "white",
            borderRadius: "50%",
            boxShadow: 3,
            "&:hover": { bgcolor: "success.dark" },
          }}
        >
          <AddIcon sx={{ fontSize: 60 }} />
        </IconButton>
      </Box> */}
    </>
  );
};

export default CreatePackageBanner;
