import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers";
import validator from "validator";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import YupPassword from "yup-password";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Stack,
  TextField,
  Typography,
  Grid2 as Grid,
  useMediaQuery,
  useTheme,
  CircularProgress,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

YupPassword(Yup);

const NotaryRegister = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenQuery = queryParams.get("token");
  const firstNameQuery = queryParams.get("first");
  const lastNameQuery = queryParams.get("last");
  const [pageLoading, setPageLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [notaryError, setNotaryError] = useState("");
  const [memorableSelections, setMemorableSelection] = useState([]);
  const [userTimezone, setUserTimeZone] = useState("America/New_York");

  let count = 0;

  useEffect(() => {
    if (!tokenQuery || !firstNameQuery || !lastNameQuery) {
      navigate("/login");
      return;
    }
    const validate = async () => {
      try {
        const tokenResponse = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/validate-registration`,
          {
            token: tokenQuery,
            first: firstNameQuery,
            last: lastNameQuery,
            timezone: userTimezone,
          },
          {
            withCredentials: true,
          }
        );
        if (tokenResponse.data.success) {
          setMemorableSelection(tokenResponse.data.memorableList);
          setPageLoading(false);
        } else {
          navigate("/login");
          throw new Error("Network response was not ok");
        }
        //}
      } catch (error) {
        console.error("Error fetching Email:", error);
        navigate("/login");
      }
    };
    if (count < 1) {
      count += 1;
      validate();
    }
  }, []);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePhoneInputChange = (event) => {
    const { value } = event.target;
    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }

    formik.setFieldValue("phone", formattedPhoneNumber);
  };

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      password: "",
      confirmPassword: "",
      address: "",
      phone: "",
      commissionNumber: "",
      commissionExpiry: null,
      reminderQuestion: "",
      reminderAnswer: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must contain 8 or more characters")
        .max(255)
        .minLowercase(1, "Password must contain at least 1 lower case letter")
        .minUppercase(1, "Password must contain at least 1 upper case letter")
        .minNumbers(1, "Password must contain at least 1 number")
        .minSymbols(1, "Password must contain at least 1 special character")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .required("Password confirmation is required")
        .test("Passwords-match", "Passwords must match", function (value) {
          return this.parent.password === value;
        }),
      address: Yup.string()
        .min(5, "Address is too short")
        .required("Address is required"),
      phone: Yup.string()
        .required("Phone number is required")
        .test("PhoneTest", "Phone number is invalid", function (value) {
          const isValidPhoneNumber = validator.isMobilePhone(value);
          if (isValidPhoneNumber) {
            return true;
          } else {
            return false;
          }
        }),
      commissionNumber: Yup.string()
        .min(5, "Commission Number is too short")
        .required("Commission Number is required"),
      commissionExpiry: Yup.string().required("Commission Expiry is required"),
      reminderQuestion: Yup.string().required("Security Question is required"),
      reminderAnswer: Yup.string()
        .required("Security question is required")
        .min(4, "Your answer must contain 4 or more characters"),
    }),
    onSubmit: async (values, helpers) => {
      setSubmitLoading(true);
      try {
        const selectedDate = Math.floor(
          values["commissionExpiry"]["$d"].getTime() / 1000
        );
        const payload = {
          firstName: firstNameQuery,
          lastName: lastNameQuery,
          token: tokenQuery,
          commissionNumber: values["commissionNumber"],
          commissionExpiry: selectedDate,
          address: values["address"],
          phone: values["phone"],
          pwd: values["password"],
          pwdConf: values["confirmPassword"],
          memorable: values["reminderQuestion"],
          memorableText: values["reminderAnswer"],
          timezone: userTimezone,
        };

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/notary/finalize-notary`,
          {
            payload,
          },
          { withCredentials: true }
        );

        if (response.data.success === true) {
          //! CREATE SESSION LOGIN FOR NOTARY
          navigate("/notary/finalize");
        } else if (response.data.success === false) {
          setNotaryError(response.data.message);
          setSubmitLoading(false);
        }
      } catch (e) {
        setNotaryError(e.response.data.message);
        setSubmitLoading(false);
      }
    },
  });

  return (
    <>
      {pageLoading ? (
        <>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              height: "100vh",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <CircularProgress color="warning" size="10rem" />
          </Box>
        </>
      ) : (
        <Box
          sx={
            isSmallScreen
              ? {
                  justifyContent: "center",
                  overflow: "auto",
                  width: "100%",
                  height: "100vh",
                }
              : {
                  backgroundImage: "url('/img/website_img/homeImg.jpeg')",
                  backgroundRepeat: "repeat",
                  width: "100%",
                  flex: "1 1 auto",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  height: "100vh",
                }
          }
        >
          <Box
            sx={
              isSmallScreen
                ? {
                    display: "flex",
                    alignItems: "center",
                    flex: "1 1 auto",
                    justifyContent: "center",
                    backgroundColor: "#fff",
                    py: "10px",
                    width: "100%",
                  }
                : {
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    maxWidth: 900,
                    maxHeight: "95vh",
                    borderRadius: "25px",
                    px: 3,
                    py: "10px",
                    width: "100%",
                    pt: "2rem",
                  }
            }
          >
            <div
              style={
                isSmallScreen
                  ? { width: "100%", padding: "1rem" }
                  : { padding: "1rem" }
              }
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Box
                  sx={{ cursor: "pointer" }}
                  display="flex"
                  justifyContent="center"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    style={{
                      width: "10rem",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "-9px",
                    }}
                    alt="Permit Rockstar Logo"
                    src="/img/website_img/PermitRockstar.png"
                  />
                </Box>
              </Stack>
              <Typography color="primary" variant="h4" align="center">
                Notary Registration
              </Typography>

              <Grid container justifyContent="center" spacing={2}>
                <>
                  <Grid container justifyContent="center" mb={2} mt={5}>
                    <Grid size={{ xs: 12 }} sx={{ width: "100%" }}>
                      <Typography
                        color="primary"
                        variant="body1"
                        align="center"
                        sx={{ mb: 5 }}
                      >
                        Welcome to Permit Rockstar, the Notary Platform. We
                        kindly request that you complete your Notary
                        registration by providing the necessary information in
                        the fields below.
                      </Typography>
                    </Grid>
                  </Grid>
                  <form noValidate onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(
                              formik.touched.commissionNumber &&
                              formik.errors.commissionNumber
                            )
                          }
                          helperText={
                            formik.touched.commissionNumber &&
                            formik.errors.commissionNumber
                          }
                          name="commissionNumber"
                          label="Commission Number"
                          variant="filled"
                          fullWidth
                          onBlur={formik.handleBlur}
                          value={formik.values.commissionNumber}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                      </Grid>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <DatePicker
                          disablePast
                          sx={{ width: "100%" }}
                          label="Commission Expiration Date"
                          value={formik.values.commissionExpiry}
                          onChange={(value) =>
                            formik.setFieldValue(
                              "commissionExpiry",
                              value,
                              true
                            )
                          }
                          slotProps={{
                            textField: {
                              variant: "filled",
                              error:
                                formik.touched.commissionExpiry &&
                                formik.errors.commissionExpiry,
                              helperText:
                                formik.touched.commissionExpiry &&
                                formik.errors.commissionExpiry,
                            },
                          }}
                        />
                      </Grid>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(formik.touched.address && formik.errors.address)
                          }
                          helperText={
                            formik.touched.address && formik.errors.address
                          }
                          name="address"
                          label="Address"
                          variant="filled"
                          fullWidth
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                      </Grid>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(formik.touched.phone && formik.errors.phone)
                          }
                          helperText={
                            formik.touched.phone && formik.errors.phone
                          }
                          name="phone"
                          label="Phone Number"
                          variant="filled"
                          fullWidth
                          onBlur={formik.handleBlur}
                          value={formik.values.phone}
                          onKeyUp={handlePhoneInputChange}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                      </Grid>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(
                              formik.touched.password && formik.errors.password
                            )
                          }
                          helperText={
                            formik.touched.password && formik.errors.password
                          }
                          type={showPassword ? "text" : "password"}
                          name="password"
                          label="Password"
                          variant="filled"
                          fullWidth
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          slotProps={{
                            input: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                    onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            },
                          }}
                        />
                      </Grid>
                      <Grid size={{ sm: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(
                              formik.touched.confirmPassword &&
                              formik.errors.confirmPassword
                            )
                          }
                          helperText={
                            formik.touched.confirmPassword &&
                            formik.errors.confirmPassword
                          }
                          type="password"
                          name="confirmPassword"
                          label="Confirm Password"
                          variant="filled"
                          fullWidth
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <FormControl fullWidth variant="filled">
                          <InputLabel
                            sx={
                              formik.touched.reminderQuestion &&
                              Boolean(formik.errors.reminderQuestion)
                                ? { color: "red" }
                                : {}
                            }
                          >
                            Select a security question
                          </InputLabel>
                          <Select
                            error={
                              !!(
                                formik.touched.reminderQuestion &&
                                Boolean(formik.errors.reminderQuestion)
                              )
                            }
                            label="Select"
                            name="reminderQuestion"
                            onBlur={formik.handleBlur}
                            value={formik.values.reminderQuestion}
                            onChange={formik.handleChange}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  marginTop: "1.5px",
                                  maxHeight: 150, // Set the desired max height for the dropdown menu
                                  width: "auto",
                                  maxWidth: "100%",
                                },
                              },
                            }}
                            sx={{
                              maxWidth: "100%", // Set the desired max-width for the input element
                              "& .MuiTypography-body2": {
                                whiteSpace: "wrap", // Prevent text from wrapping or shrinking
                              },
                            }}
                          >
                            {memorableSelections?.map(function (item, i) {
                              return (
                                <MenuItem key={i} value={item}>
                                  {item}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText sx={{ color: "red" }}>
                            {formik.touched.reminderQuestion &&
                              formik.errors.reminderQuestion}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <TextField
                          error={
                            !!(
                              formik.touched.reminderAnswer &&
                              formik.errors.reminderAnswer
                            )
                          }
                          helperText={
                            formik.touched.reminderAnswer &&
                            formik.errors.reminderAnswer
                          }
                          fullWidth
                          name="reminderAnswer"
                          label="Please enter the answer to your security question"
                          type="text"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.reminderAnswer}
                        />
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <Typography
                          sx={{ minHeight: "1.38rem" }}
                          fontWeight="bold"
                          align="center"
                          variant="body2"
                          color="error"
                        >
                          {notaryError}
                        </Typography>
                      </Grid>
                      <Grid size={{ xs: 12 }}>
                        <LoadingButton
                          type="submit"
                          loading={submitLoading}
                          size="large"
                          variant="contained"
                          fullWidth
                        >
                          Submit
                        </LoadingButton>
                      </Grid>
                    </Grid>
                  </form>
                </>
              </Grid>
            </div>
          </Box>
        </Box>
      )}
    </>
  );
};

export default NotaryRegister;
