import React, { useState, useRef, useEffect } from "react";
import { Box, Button, CircularProgress } from "@mui/material";

const Magnifier = ({
  src,
  lensSize = 400,
  zoomFactor = 2,
  handleImageError,
  index,
  signedLoading,
  setSignedLoading,
  selectedPackage,
  second,
  customStyle,
}) => {
  const [isZooming, setIsZooming] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [imageSrc, setImageSrc] = useState(src);
  const imageRef = useRef(null);

  useEffect(() => {
    setImageSrc(src);
  }, [src]);

  const handleMouseMove = (e) => {
    if (!isZooming || !imageRef.current) return;

    const imageRect = imageRef.current.getBoundingClientRect();

    // Calculate mouse position relative to the image
    const x =
      (e.clientX - imageRect.left) /
      (imageRect.width / imageRef.current.naturalWidth);
    const y =
      (e.clientY - imageRect.top) /
      (imageRect.height / imageRef.current.naturalHeight);

    setMousePosition({ x, y });
  };

  const handleMouseDown = (e) => {
    if (e.target.tagName === "BUTTON") return; // Prevent zooming when clicking buttons
    if (!imageRef.current) return;

    setIsZooming(true);

    const imageRect = imageRef.current.getBoundingClientRect();

    // Normalize click position to actual image scale
    const x =
      ((e.clientX - imageRect.left) / imageRect.width) *
      imageRef.current.naturalWidth;
    const y =
      ((e.clientY - imageRect.top) / imageRect.height) *
      imageRef.current.naturalHeight;

    setMousePosition({ x, y });
  };

  const handleMouseUp = () => {
    setIsZooming(false);
  };

  const handleImageLoadError = async () => {
    // Show CircularProgress while loading a new URL
    try {
      // Call the backend to get a new signed URL
      const updatedUrl = await handleImageError();

      if (updatedUrl) {
        setImageSrc(updatedUrl); // Update the image source with the new URL
      }
    } catch (error) {
      console.error("Failed to refresh the signed URL:", error);
    }
  };

  return (
    <Box
      className="magnifierImg"
      sx={{
        position: "relative",
        "&:hover": {
          cursor: "crosshair",
        },
      }}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      {/* CHANGE FORM BUTTONS */}
      {!second &&
        selectedPackage?.building === true &&
        selectedPackage?.noc === true && (
          <Box sx={{ position: "absolute", top: 10, left: 30, zIndex: 9999 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); // Prevent default behavior
                  // Add other button click functionality here
                }}
                variant="contained"
                size="small"
                sx={{
                  padding: "10px 20px",
                  backgroundColor: "#f2eadf",
                  border: "2px solid",
                  borderColor: "#715e60",
                  color: "#715e60",
                  "&:hover": {
                    backgroundColor: "#715e60",
                    color: "#f2eadf",
                  },
                }}
              >
                Notice of Commencement
              </Button>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault(); // Prevent default behavior
                  // Add other button click functionality here
                }}
                variant="outlined"
                size="small"
                sx={{
                  zIndex: 9999,
                  padding: "10px 20px",
                  backgroundColor: "#f2eadf",
                  border: "2px solid",
                  borderColor: "#715e60",
                  color: "#715e60",
                  "&:hover": {
                    backgroundColor: "#715e60",
                    color: "#f2eadf",
                  },
                }}
              >
                Building Permit
              </Button>
            </Box>
          </Box>
        )}

      {/* Display CircularProgress while loading */}
      {signedLoading ? (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <CircularProgress color="warning" size="5rem" />
        </Box>
      ) : (
        <>
          {/* Main Image */}
          <img
            ref={imageRef}
            src={imageSrc}
            style={
              customStyle ? customStyle : { width: "100%", height: "auto" }
            }
            draggable="false"
            onError={handleImageError ? handleImageLoadError : null}
          />
          {isZooming && imageRef.current && (
            <Box
              sx={{
                position: "absolute",
                left: `${
                  mousePosition.x *
                    (imageRef.current.width / imageRef.current.naturalWidth) -
                  lensSize / 2
                }px`,
                top: `${
                  mousePosition.y *
                    (imageRef.current.height / imageRef.current.naturalHeight) -
                  lensSize / 2
                }px`,
                width: `${lensSize}px`,
                height: `${lensSize}px`,
                borderRadius: "50%",
                overflow: "hidden",
                border: "2px solid white",
                boxShadow: "0 0 15px 8px rgba(0, 0, 0, 0.5)",
                background: `url(${imageSrc}) no-repeat`,
                backgroundSize: `${
                  imageRef.current.naturalWidth * zoomFactor
                }px ${imageRef.current.naturalHeight * zoomFactor}px`,
                backgroundPosition: `${
                  -mousePosition.x * zoomFactor + lensSize / 2
                }px ${-mousePosition.y * zoomFactor + lensSize / 2}px`,
                pointerEvents: "none",
              }}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default Magnifier;
