import React from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function ChangeEmailModal({
  open,
  setOpen,
  onConfirm,
  newEmail,
  setNewEmail,
  newEmailError,
  setNewEmailError,
  newEmailLoading,
  sendReminder,
  setSendReminder,
  emailChangeText,
}) {
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? "" : "Invalid email format";
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setNewEmail(value);
    setNewEmailError(validateEmail(value));
  };

  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h4"
          align="center"
          component="h2"
          color="primary"
          mb={2}
        >
          Change Owner's Invitation Email
        </Typography>

        <TextField
          error={Boolean(newEmailError)}
          onChange={handleChange}
          fullWidth
          label="New Email"
          value={newEmail}
        />
        {newEmailError && (
          <FormHelperText sx={{ ml: 0.5, color: "red" }}>
            {newEmailError}
          </FormHelperText>
        )}

        {/* Checkbox for sending a reminder email */}
        <FormControlLabel
          control={
            <Checkbox
              checked={sendReminder}
              onChange={(e) => setSendReminder(e.target.checked)}
              color="primary"
            />
          }
          label="Send reminder email"
          sx={{ mt: 2 }}
        />

        <Typography align="center" variant="h6" color="success">
          {emailChangeText}
        </Typography>

        <Box sx={{ mt: 5, display: "flex", justifyContent: "space-between" }}>
          <Button
            disabled={newEmailLoading}
            sx={{ mr: 1 }}
            fullWidth
            color="error"
            onClick={() => {
              setOpen(false);
              setNewEmail("");
              setNewEmailError("");
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={newEmailLoading}
            disabled={
              newEmailLoading || newEmail === "" || Boolean(newEmailError)
            }
            sx={{ ml: 1 }}
            fullWidth
            variant="contained"
            color="success"
            onClick={onConfirm}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Modal>
  );
}

export default ChangeEmailModal;
