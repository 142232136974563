import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid2 as Grid,
  Box,
  Avatar,
  Button,
} from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount"; // Coupon Icon
import PriceCheckIcon from "@mui/icons-material/PriceCheck"; // Original Price Icon
import PercentIcon from "@mui/icons-material/Percent"; // Discount Percentage Icon
import EventIcon from "@mui/icons-material/Event"; // Duration Icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Active Badge
import CancelIcon from "@mui/icons-material/Cancel"; // Inactive Badge
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Added At Icon
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { formatMoneyValue } from "../../../functions/permitFormHelpers";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { updateCustomerCoupon } from "../../../store/actions/dataActions";

const DiscountCard = ({
  data,
  selectedRow,
  setSelectedRow,
  colorIndex,
  btnText,
  addBtn,
  assignCouponLoading,
  setAssignCouponLoading,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession]?.user);
  const [formattedTime, setFormattedTime] = useState("N/A");
  const dispatch = useDispatch();

  const categoryMapping = {
    monthly_price: "Base Plan",
    notary_record: "Permit Package Notarization & Recording",
    noc_notary: "Notice of Commencement Notarization & Recording",
    extra_county_cost: "Additional Counties",
    extra_download_cost: "Additional Downloads",
  };

  const handleAssignCouponToCustomer = async () => {
    try {
      setAssignCouponLoading(true);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/assign-coupon-to-user`,
        {
          currentSession: currentSession,
          coupon: data,
          user_email: selectedRow?.email,
        },
        { withCredentials: true }
      );

      if (resp?.data?.coupon) {
        await dispatch(updateCustomerCoupon(selectedRow.id, resp.data.coupon));

        setSelectedRow((prev) => {
          if (!prev) return prev; // Ensure prev is defined

          const discountExists = prev.user_discounts?.some(
            (discount) => discount.name === resp.data.coupon.name
          );

          return {
            ...prev,
            user_discounts: discountExists
              ? prev.user_discounts.map((discount) =>
                  discount.name === resp.data.coupon.name
                    ? resp.data.coupon // Replace the existing discount
                    : discount
                )
              : [...(prev.user_discounts || []), resp.data.coupon], // Append new discount if not found
          };
        });
      }
    } catch (error) {
    } finally {
      setAssignCouponLoading(false);
    }
  };

  useEffect(() => {
    if (data.added_at && currentUser?.user?.personal?.[0]?.timezone) {
      const userTimezone = currentUser.user.personal[0].timezone;

      // Convert ISO date to US format with user's timezone
      const convertedTime = DateTime.fromISO(data.added_at, { zone: "utc" })
        .setZone(userTimezone)
        .toFormat("MM/dd/yyyy hh:mm a");

      setFormattedTime(convertedTime);
    }
  }, [data.added_at, currentUser?.user?.personal?.[0]?.timezone]);

  const colors = [
    "#3f51b5",
    "#f44336",
    "#4caf50",
    "#ff9800",
    "#9c27b0",
    "#673ab7",
    "#009688",
    "#795548",
    "#1e88e5",
    "#d32f2f",
  ];

  const getRandomColor = () => colors[colorIndex % colors.length] || colors[0];

  return (
    <Card
      sx={{
        maxWidth: 420,
        margin: "auto",
        mb: 2,
        boxShadow: 6,
        borderRadius: 3,
        border: "4px solid",
        borderColor: data.active ? "rgba(0, 128, 0, 0.5)" : "red",
        position: "relative",
      }}
    >
      <CardContent sx={{ position: "relative" }}>
        <Grid container spacing={2} alignItems="center">
          {/* Left Section - Coupon Icon */}
          <Grid size={{ xs: 3 }} sx={{ textAlign: "center" }}>
            <Avatar
              sx={{
                bgcolor: getRandomColor(),
                width: 60,
                height: 60,
                margin: "auto",
              }}
            >
              <DiscountIcon fontSize="large" />
            </Avatar>
          </Grid>

          {/* Right Section - Title & Status */}
          <Grid size={{ xs: 9 }}>
            <Typography variant="h6" fontWeight="bold">
              {data.title}
            </Typography>

            <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
              {data.active ? (
                <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
              ) : (
                <CancelIcon sx={{ color: "red", mr: 1 }} />
              )}
              <Typography variant="body2" fontWeight="bold">
                {data.active ? "Active" : "Inactive"}
              </Typography>
            </Box>
          </Grid>

          {/* Highlighted Base Plan & Category */}
          <Grid size={{ xs: 12 }}>
            <Box
              sx={{
                backgroundColor: "rgba(0, 128, 0, 0.1)",
                padding: "8px",
                borderRadius: "6px",
                mb: 1,
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                📌 Assigned Base Plan: <strong>{data.base_plan}</strong>
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "rgba(33, 150, 243, 0.1)", // Light blue background
                padding: "8px",
                borderRadius: "6px",
                mb: 1,
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                🎯 Coupon Category:{" "}
                <strong>{categoryMapping[data?.name] || "N/A"}</strong>
              </Typography>
            </Box>
          </Grid>

          {/* Coupon Amount & Discount */}
          <Grid size={{ xs: 12 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <PriceCheckIcon sx={{ mr: 1, color: "gray" }} />
              <Typography variant="body2">
                Original Price:{" "}
                <strong>
                  {formatMoneyValue(data.original_price) || "N/A"}
                </strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <PercentIcon sx={{ mr: 1, color: "gray" }} />
              <Typography variant="body2">
                Discount:{" "}
                <strong>
                  -{formatMoneyValue(data.amount)} ({data.amount_percent}%)
                </strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <MonetizationOnIcon sx={{ mr: 1, color: "gray" }} />
              <Typography variant="body2">
                New Total Price:{" "}
                <strong>{formatMoneyValue(data.new_total) || "N/A"}</strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <EventIcon sx={{ mr: 1, color: "gray" }} />
              <Typography variant="body2">
                Duration:{" "}
                <strong>
                  {data.duration
                    ? data.duration.charAt(0).toUpperCase() +
                      data.duration.slice(1)
                    : "N/A"}
                </strong>
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <AccessTimeIcon sx={{ mr: 1, color: "gray" }} />
              <Typography variant="body2">
                Added At: <strong>{formattedTime || "N/A"}</strong>
              </Typography>
            </Box>

            {/* Step - Only for extra_county_cost */}
            {data.name === "extra_county_cost" && (
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <AddLocationIcon sx={{ mr: 1, color: "gray" }} />
                <Typography variant="body2">
                  Minimum Counties Required:{" "}
                  <strong>{data.step || "N/A"}</strong>
                </Typography>
              </Box>
            )}

            {/* Remove Coupon Button */}
            <Box
              sx={{
                display: "flex",
                justifyContent: addBtn ? "center" : undefined,
                alignItems: "center",
                mt: 2,
                gap: addBtn ? 2 : 0,
              }}
            >
              <Button
                disabled={assignCouponLoading}
                variant="contained"
                color="error"
                size="small"
              >
                {btnText}
              </Button>
              {addBtn && (
                <LoadingButton
                  loading={assignCouponLoading}
                  onClick={handleAssignCouponToCustomer}
                  variant="contained"
                  color="success"
                  size="small"
                >
                  Add Coupon to Customer
                </LoadingButton>
              )}
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DiscountCard;
