import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";
import "./theme/global.css";
import { createTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "@fontsource/dancing-script"; // A stylish cursive font
import "@fontsource/patrick-hand"; // A casual handwritten font
import "@fontsource/caveat"; // Another good handwritten style
import "@fontsource/gloria-hallelujah"; // Playful but readable

const theme = createTheme();

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
