import axios from "axios";
import { extractFormFields } from "../../components/uploaderComponents/helpers/processPDF";

export const addUserPackages = (userPackages, totalPackageRecord) => {
  return {
    type: "ADD_USER_PACKAGES",
    payload: {
      newPackages: userPackages,
      packagePage: 1,
      totalPackageRecord: totalPackageRecord,
    },
  };
};

export const uploadUserPackagePDF =
  (currentProcessingFile, setUploaderLoading, packageName, packageID) =>
  async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    try {
      const formData = new FormData();

      formData.append("currentSession", currentSession);
      formData.append("packageID", packageID ?? "");
      formData.append("packageName", packageName ?? "");

      const document =
        currentProcessingFile.document.tempFields.currentDocument;

      if (document?.base64File) {
        // Send Base64 data as a normal field
        formData.append("base64File", document.base64File);
      }

      if (document?.fileName) {
        formData.append("fileName", document.fileName);
      }

      if (document?.files?.length > 0) {
        document.files.forEach((file) => {
          formData.append("files", file); // Automatically sends as multipart
        });
      }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/upload-pdf`,
        formData,
        {
          withCredentials: true,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (packageID) {
        const newDocument = {
          ...resp.data.data, // Use new document data from response
          tempFields: {
            ...currentProcessingFile.document.tempFields,
            currentDocument: {
              ...currentProcessingFile.document.tempFields.currentDocument,
              files: undefined, // Exclude `files`
            },
          },
        };

        const returnDoc = {
          ...resp.data.data,
          tempFields: currentProcessingFile.document.tempFields,
        };

        await dispatch({
          type: "ADD_DOCUMENTS_TO_PACKAGE",
          payload: {
            packageID: packageID,
            documents: [newDocument], // ✅ Append new document to existing package
            formPage: 1, // Assuming we start with page 1
            totalFormRecord: 1, // Can be updated dynamically
          },
        });

        return returnDoc;
      } else {
        const formattedData = {
          packageID: resp.data.data.packageID,
          packageName: resp.data.data.packageName,
          email: resp.data.data.email,
          is_subuser: resp.data.data.is_subuser,
          user_document_token: resp.data.data.user_document_token,
          signers: resp.data.data.signers,
          project_address: resp.data.data.project_address,
          property_owner: resp.data.data.property_owner,
          existing_notaries: resp.data.data.existing_notaries,
          contractor: resp.data.data.contractor,
          processing_permit: resp.data.data.processing_permit,
          documents: [
            {
              ...resp.data.data.documents[0], // Keep document properties
              tempFields: currentProcessingFile.document.tempFields,
            },
          ],
        };

        const modifiedData = {
          ...formattedData,
          document: formattedData.documents[0], // Extract first (only) item
        };
        delete modifiedData.documents;

        const reduxData = {
          ...formattedData,
          documents: [
            {
              ...resp.data.data.documents[0], // Keep document properties
              tempFields: {
                ...currentProcessingFile.document.tempFields,
                currentDocument: {
                  ...currentProcessingFile.document.tempFields.currentDocument,
                  files: undefined, // Exclude `files`
                },
              },
            },
          ],
        };

        await dispatch({
          type: "ADD_NEW_USER_PACKAGE",
          payload: reduxData,
        });

        return modifiedData;
      }
    } catch (error) {
      console.error("Upload failed:", error);
    } finally {
      setUploaderLoading(false);
    }
  };

export const fetchMoreUserPackages = async (
  dispatch,
  sessionName,
  currentPage,
  totalPackages
) => {
  if (totalPackages && currentPage * 5 >= totalPackages) return; // ✅ Prevent extra fetch if no more packages

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/initial-load`,
      {
        sessionName: sessionName,
        pagination: {
          package: {
            packageLimit: 5,
            packagePage: currentPage + 1,
            totalPackageRecord: totalPackages,
          },
          form: { formLimit: 5, formPage: 1, totalFormRecord: 0 },
        },
      },
      { withCredentials: true }
    );

    const { totalPackageRecord, userPackages } = response.data;

    dispatch({
      type: "ADD_USER_PACKAGES",
      payload: {
        newPackages: userPackages,
        packagePage: currentPage + 1,
        totalPackageRecord: totalPackageRecord,
      },
    });
  } catch (error) {
    console.error("Error fetching more user packages:", error);
  }
};

export const fetchMoreUserPackageDocuments = async (
  dispatch,
  packageID,
  currentPage,
  totalForms
) => {
  if (totalForms && currentPage * 5 >= totalForms) return; // ✅ Prevent extra fetch if no more documents

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/fetch-documents`,
      {
        packageID: packageID,
        pagination: {
          formLimit: 5,
          formPage: currentPage + 1,
          totalFormRecord: totalForms,
        },
      },
      { withCredentials: true }
    );

    const { totalFormRecord, documents } = response.data;

    dispatch({
      type: "ADD_DOCUMENTS_TO_PACKAGE",
      payload: {
        packageID: packageID,
        documents: documents,
        formPage: currentPage + 1,
        totalFormRecord: totalFormRecord,
      },
    });
  } catch (error) {
    console.error("Error fetching more documents:", error);
  }
};

export const fetchUserPkgFileBase64 =
  (pdfURL, packageID, formID) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/fetch-pdf`,
        {
          pdfURL: pdfURL,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      if (response?.data?.pdf_base64) {
        const extractedFields = await extractFormFields(
          response?.data?.pdf_base64
        );

        await dispatch({
          type: "ADD_BASE64_TO_PACKAGE",
          payload: {
            BASE64: response?.data?.pdf_base64,
            packageID: packageID,
            formID: formID,
            formFields: extractedFields,
          },
        });

        return {
          base64: response?.data?.pdf_base64,
          extractedFields: extractedFields,
        };
      } else return { base64: "", extractedFields: [] };
    } catch (error) {
      console.error("Error fetching PDF as Base64:", error);
      return null;
    }
  };

export const updateEditingForm =
  (currentProcessingFile) => async (dispatch, getState) => {
    const state = getState();
    const userP = state.userPackages?.userPackages;

    await dispatch({
      type: "UPDATE_EDITING_FORM",
      payload: currentProcessingFile,
    });

    return true;
  };

export const updateProcessOptions = (packageID, formID, options) => {
  return {
    type: "UPDATE_PROCESS_OPTIONS",
    payload: {
      packageID: packageID,
      formID: formID,
      options: options,
    },
  };
};

export const addNewOptions = (packageID, formID, options) => {
  return {
    type: "ADD_NEW_PROCESS_OPTIONS",
    payload: {
      packageID: packageID,
      formID: formID,
      options: options,
    },
  };
};

export const addNewSigners = (packageID, signers) => {
  return {
    type: "ADD_NEW_SIGNERS",
    payload: {
      packageID: packageID,
      signers: signers,
    },
  };
};

export const updateSigners = (packageID, signers) => {
  return {
    type: "UPDATE_SIGNERS",
    payload: {
      packageID: packageID,
      signers: signers,
    },
  };
};

export const updatePackageName =
  (packageID, newName) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/edit-package-name`,
        {
          currentSession: currentSession,
          packageID: packageID,
          newName: newName,
        },
        { withCredentials: true }
      );

      await dispatch({
        type: "UPDATE_PACKAGE_NAME",
        payload: {
          packageID: packageID,
          value: newName,
        },
      });
    } catch (e) {}
  };

export const updateDocName =
  (packageID, formID, newName) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/edit-doc-name`,
        {
          currentSession: currentSession,
          packageID: packageID,
          docID: formID,
          newName: newName,
        },
        { withCredentials: true }
      );

      await dispatch({
        type: "UPDATE_DOC_NAME",
        payload: {
          packageID: packageID,
          docID: formID,
          value: newName,
        },
      });
    } catch (e) {}
  };

export const updateNewFileName =
  (packageID, formID, newName) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;

    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/edit-doc-filename`,
        {
          currentSession: currentSession,
          packageID: packageID,
          docID: formID,
          newName: newName,
        },
        { withCredentials: true }
      );

      await dispatch({
        type: "UPDATE_DOC_FILENAME",
        payload: {
          packageID: packageID,
          docID: formID,
          value: newName,
        },
      });
    } catch (e) {}
  };

export const deleteUserPackageForm =
  (packageID, formID, savedPackagesActive) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;
    const userPackages = state.userPackages?.userPackages;

    try {
      const hasDocuments = userPackages?.some(
        (pkg) => pkg.packageID === packageID && pkg.documents?.length > 0
      );

      if (savedPackagesActive && hasDocuments) {
        return false;
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/delete-doc`,
        {
          currentSession: currentSession,
          packageID: packageID,
          docID: formID,
          remove_package: savedPackagesActive,
        },
        { withCredentials: true }
      );

      if (savedPackagesActive) {
        await dispatch({
          type: "DELETE_USER_PACKAGE",
          payload: {
            packageID: packageID,
          },
        });
      } else {
        await dispatch({
          type: "DELETE_USER_PACKAGE_DOCUMENT",
          payload: {
            packageID: packageID,
            docID: formID,
            removePackage: savedPackagesActive,
          },
        });
      }

      return true;
    } catch (e) {}
  };

export const loadFormOptions = (savedExistingOptions) => {
  return {
    type: "LOAD_EXISTING_OPTIONS",
    payload: savedExistingOptions,
  };
};

export const loadFormSigners = (savedExistingSigners) => {
  return {
    type: "LOAD_EXISTING_SIGNERS",
    payload: savedExistingSigners,
  };
};

export const adjustDocumentLock =
  (packageID, formID) => async (dispatch, getState) => {
    const state = getState();
    const currentSession = state.sessions?.currentSession;
    const processFormOptions = state.userPackages?.processFormOptions;
    const processSigners = state.userPackages?.processSigners;

    try {
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/user-packages/adjust-document-lock`,
        {
          currentSession: currentSession,
          packageID: packageID,
          docID: formID,
          processFormOptions: processFormOptions,
          processSigners: processSigners,
        },
        { withCredentials: true }
      );

      if (resp?.data?.success) {
        await dispatch({
          type: "TOGGLE_DOCUMENT_LOCK",
          payload: {
            packageID: packageID,
            docID: formID,
            locked: resp.data.locked,
          },
        });

        return resp.data.locked;
      }

      return false;
    } catch (e) {}
  };
