export const workTypes = {
  Duval: [
    {
      label: "Accessory Dwelling Unit",
      noc_name: "accessory_dwelling_unit",
      permit_name: "type_imp_mobile_home",
    },
    {
      label: "Accessory Dwelling Unit - Attached",
      noc_name: "accessory_dwelling_unit_attached",
      permit_name: "type_imp_rv",
    },
    {
      label: "Accessory Structure",
      noc_name: "accessory_structure",
      permit_name: "type_imp_accessory",
    },
    {
      label: "Addition",
      noc_name: "addition",
      permit_name: "type_imp_addition",
    },
    {
      label: "Alterations & Repairs",
      noc_name: "alterations_repairs",
      permit_name: "type_imp_alteration",
    },
    {
      label: "Roofing Improvements",
      noc_name: "roofing_improvements",
      permit_name: "type_imp_roofing_imp",
    },
    {
      label: "Borrow Pit",
      noc_name: "borrow_pit",
      permit_name: "type_imp_o_checkbox",
    },
    {
      label: "Converting Use",
      noc_name: "converting_use",
      permit_name: "type_imp_converting",
    },
    {
      label: "Demolition",
      noc_name: "demolition",
      permit_name: "type_imp_demolition",
    },
    {
      label: "Foundation Only",
      noc_name: "foundation_only",
      permit_name: "type_imp_fundation",
    },
    {
      label: "Move Bldg Into Duval",
      noc_name: "move_bldg_into_duval",
      permit_name: "type_imp_move_into",
    },
    {
      label: "Move Bldg Out Of Duval",
      noc_name: "move_bldg_out_of_duval",
      permit_name: "type_imp_move_out",
    },
    {
      label: "Move Bldg Within Duval",
      noc_name: "move_bldg_within_duval",
      permit_name: "type_imp_move_within",
    },
    {
      label: "New Building",
      noc_name: "new_building",
      permit_name: "type_imp_new_building",
    },
    {
      label: "Shell Building",
      noc_name: "shell_building",
      permit_name: "type_imp_shell",
    },
    {
      label: "Siding Installation/Repair",
      noc_name: "siding_installation_repair",
      permit_name: "type_imp_non_structural",
    },
    {
      label: "Small Cell",
      noc_name: "small_cell",
      permit_name: "type_imp_o_checkbox",
    },
    {
      label: "Swimming Pool",
      noc_name: "swimming_pool",
      permit_name: "type_imp_pool_in_ground",
    },
    {
      label: "Swimming Pool Demolition",
      noc_name: "swimming_pool_demolition",
      permit_name: "type_imp_pool_above_ground",
    },
    {
      label: "Tenant Build-out",
      noc_name: "tenant_build_out",
      permit_name: "type_imp_tenant",
    },
    {
      label: "Window/Door Replacement",
      noc_name: "window_door_replacement",
      permit_name: "type_imp_windows",
    },
  ],
  others: [
    {
      label: "Accessory Dwelling Unit",
      noc_name: "accessory_dwelling_unit",
      permit_name: "type_imp_mobile_home",
    },
    {
      label: "Accessory Dwelling Unit - Attached",
      noc_name: "accessory_dwelling_unit_attached",
      permit_name: "type_imp_rv",
    },
    {
      label: "Accessory Structure",
      noc_name: "accessory_structure",
      permit_name: "type_imp_accessory",
    },
    {
      label: "Addition",
      noc_name: "addition",
      permit_name: "type_imp_addition",
    },
    {
      label: "Alterations & Repairs",
      noc_name: "alterations_repairs",
      permit_name: "type_imp_alteration",
    },
    {
      label: "Roofing Improvements",
      noc_name: "roofing_improvements",
      permit_name: "type_imp_roofing_imp",
    },
    {
      label: "Borrow Pit",
      noc_name: "borrow_pit",
      permit_name: "type_imp_o_checkbox",
    },
    {
      label: "Converting Use",
      noc_name: "converting_use",
      permit_name: "type_imp_converting",
    },
    {
      label: "Demolition",
      noc_name: "demolition",
      permit_name: "type_imp_demolition",
    },
    {
      label: "Foundation Only",
      noc_name: "foundation_only",
      permit_name: "type_imp_fundation",
    },
    {
      label: "New Building",
      noc_name: "new_building",
      permit_name: "type_imp_new_building",
    },
    {
      label: "Shell Building",
      noc_name: "shell_building",
      permit_name: "type_imp_shell",
    },
    {
      label: "Siding Installation/Repair",
      noc_name: "siding_installation_repair",
      permit_name: "type_imp_non_structural",
    },
    {
      label: "Small Cell",
      noc_name: "small_cell",
      permit_name: "type_imp_o_checkbox",
    },
    {
      label: "Swimming Pool",
      noc_name: "swimming_pool",
      permit_name: "type_imp_pool_in_ground",
    },
    {
      label: "Swimming Pool Demolition",
      noc_name: "swimming_pool_demolition",
      permit_name: "type_imp_pool_above_ground",
    },
    {
      label: "Tenant Build-out",
      noc_name: "tenant_build_out",
      permit_name: "type_imp_tenant",
    },
    {
      label: "Window/Door Replacement",
      noc_name: "window_door_replacement",
      permit_name: "type_imp_windows",
    },
  ],
};

export const ownerInterest = [
  {
    label: "Fee Simple",
    name: "fee_simple",
  },
  {
    label: "Lessor",
    name: "lessor",
  },
  {
    label: "Lessee",
    name: "lessee",
  },
  {
    label: "Easement Holder",
    name: "easement_holder",
  },
  {
    label: "Life Estate Holder",
    name: "life_estate_holder",
  },
  {
    label: "Mortgagee in Possession",
    name: "mortgagee_in_possession",
  },
  {
    label: "Contract for Deed Purchaser",
    name: "contract_for_deed_purchaser",
  },
  {
    label: "Trustee",
    name: "trustee",
  },
  {
    label: "Beneficiary",
    name: "beneficiary",
  },
  {
    label: "Developer",
    name: "developer",
  },
  {
    label: "Partnership or Joint Venture",
    name: "partnership_joint_venture",
  },
  {
    label: "Owner's Representative",
    name: "owners_representative",
  },
  {
    label: "Tenant in Common",
    name: "tenant_in_common",
  },
  {
    label: "Joint Tenant with Right of Survivorship",
    name: "joint_tenant_survivorship",
  },
  {
    label: "Co-Owner",
    name: "co_owner",
  },
  {
    label: "Corporation or LLC",
    name: "corporation_llc",
  },
  {
    label: "Heir or Successor in Interest",
    name: "heir_successor",
  },
  {
    label: "Foreclosure Buyer",
    name: "foreclosure_buyer",
  },
  {
    label: "Government Entity",
    name: "government_entity",
  },
  {
    label: "Condominium Association",
    name: "condominium_association",
  },
  {
    label: "Homeowners Association (HOA)",
    name: "homeowners_association",
  },
  {
    label: "Ground Leaseholder",
    name: "ground_leaseholder",
  },
  {
    label: "Builder/General Contractor",
    name: "builder_general_contractor",
  },
  {
    label: "Agricultural Lessee",
    name: "agricultural_lessee",
  },
  {
    label: "Industrial Tenant",
    name: "industrial_tenant",
  },
  {
    label: "Residential Owner-Occupant",
    name: "residential_owner_occupant",
  },
  {
    label: "Investor",
    name: "investor",
  },
  {
    label: "Property Manager",
    name: "property_manager",
  },
  {
    label: "Estate Executor/Administrator",
    name: "estate_executor",
  },
  {
    label: "Timeshare Owner",
    name: "timeshare_owner",
  },
  {
    label: "Trust Beneficiary",
    name: "trust_beneficiary",
  },
  {
    label: "Tax Deed Holder",
    name: "tax_deed_holder",
  },
  {
    label: "Bank-Owned Property (REO)",
    name: "bank_owned_property",
  },
];
