import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Dialog,
  useMediaQuery,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const PurchaseExtraCounty = ({
  setPaymentModalOpen,
  open,
  setOpen,
  setExtraCountyTitle,
  defaultCard,
  handleAddCounty,
  addCountyLoading,
  countyValue,
  setCountyValue,
}) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const currentUser = useSelector((state) => state[currentSession].user);
  const navigate = useNavigate();
  const tabletSize = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [maxCounties, setMaxCounties] = useState(0);
  const [discountapplied, setDiscountapplied] = useState(false);
  const [totalOff, setTotalOff] = useState(0);
  const [totalFinal, setTotalFinal] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const maxTierCounty =
        parseInt(currentUser?.tier?.maxCounty) -
        parseInt(currentUser?.tier?.county);
      const addedCounties = currentUser?.tier?.additions?.county?.qty;

      setMaxCounties(maxTierCounty - addedCounties);
    }
  }, [
    countyValue,
    currentUser?.tier?.maxCounty,
    currentUser?.tier?.additions?.county?.qty,
  ]);

  useEffect(() => {
    const extraCounty = currentUser?.tier?.discount?.extra_county_cost;
    if (extraCounty?.active && !extraCounty?.used) {
      if (countyValue >= extraCounty?.max_county) {
        const basePrice = parseInt(
          currentUser?.service_tiers?.extra_county_cost
        );
        const discount = extraCounty?.max_county * extraCounty?.amount;
        setTotalOff(discount);
        setTotalFinal(basePrice * countyValue - discount);
        setDiscountapplied(true);
      } else {
        setDiscountapplied(false);
      }
    } else {
      setDiscountapplied(false);
    }
  }, [countyValue, currentUser]);

  const handleIncrement = () => {
    if (countyValue < maxCounties) {
      setCountyValue(countyValue + 1);
    }
  };

  const handleDecrement = () => {
    if (countyValue > 0) {
      setCountyValue(countyValue - 1);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= maxCounties) {
      setCountyValue(parseInt(inputValue, 10));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        fullWidth
        maxWidth={tabletSize ? false : undefined}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          className: "popupGrow", // Apply the animation class dynamically
          style: tabletSize
            ? {
                width: "100%", // Set Paper width to 100%
                maxWidth: "100%", // Ensure it does not exceed 100%
                height: "auto", // Optional: in case you also want full height
                margin: 0, // Remove margins to ensure it fits to the edge
              }
            : {},
        }}
      >
        <DialogTitle
          color="primary"
          variant="h4"
          id="responsive-dialog-title"
          sx={{ textAlign: "center", mt: 1 }}
        >
          Add Additional Counties to Your Subscription
        </DialogTitle>
        <Box sx={{ display: "flex", justifyContent: "center", mt: -2 }}>
          <img
            alt="avatar_circle"
            src="/img/website_img/pp_avatar_circle.png"
            style={{ width: "10rem", height: "auto", zIndex: 2 }}
          />
        </Box>
        {discountapplied && (
          <Typography px={2} color="success" align="center" variant="h5" mb={1}>
            Your one-time discount of ${totalOff} has been applied. Today's
            final one-time payment is ${totalFinal}
          </Typography>
        )}
        <DialogContent sx={{ px: 2 }}>
          <Box>
            <DialogContentText sx={{ mb: 1, mt: -3, textAlign: "center" }}>
              Add additional counties to your subscription for only{" "}
              <span style={{ fontWeight: "bold", fontSize: "20px" }}>
                ${currentUser?.service_tiers?.extra_county_cost}/month
              </span>{" "}
              per county. This is a monthly subscription, and you can cancel
              anytime.
            </DialogContentText>

            <DialogContentText
              sx={{ mb: 3, textAlign: "center" }}
              color="primary"
            >
              You can add up to{" "}
              <span
                style={{
                  fontSize: "24px",
                  color:
                    parseInt(currentUser?.tier?.maxCounty) -
                      parseInt(currentUser?.tier?.county) -
                      currentUser?.tier?.additions?.county?.qty -
                      (isNaN(countyValue) ? 0 : countyValue) >
                    0
                      ? "#0a885e"
                      : "#7a211b",
                  fontWeight: "bold",
                }}
              >
                {parseInt(currentUser?.tier?.maxCounty) -
                  parseInt(currentUser?.tier?.county) -
                  currentUser?.tier?.additions?.county?.qty -
                  (isNaN(countyValue) ? 0 : countyValue)}
              </span>{" "}
              additional counties to your plan.
            </DialogContentText>
            <Typography
              align="center"
              variant="body2"
              fontWeight="bold"
              color="primary"
              mb={0.5}
            >
              Number of Counties:
            </Typography>
            <Box
              sx={{ display: "flex", gap: 1, justifyContent: "center", mb: 2 }}
            >
              {/* Decrement Button */}
              <Button
                variant="contained"
                size="small"
                onClick={handleDecrement}
                disabled={countyValue <= 0 || addCountyLoading} // Disable when value is 0
                sx={{
                  minWidth: "40px",
                  height: "40px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "#004976",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#003456",
                  },
                }}
              >
                -
              </Button>
              <TextField
                onClick={() => {}}
                variant="outlined"
                type="number"
                value={countyValue}
                onChange={handleChange}
                slotProps={{
                  input: {
                    readOnly: true,
                    tabIndex: -1,
                    min: 0,
                    max: maxCounties,
                    step: 1,
                  },
                }}
                sx={{
                  width: "58px",
                  textAlign: "center",
                  pointerEvents: "none",
                  "& .MuiInputBase-input": {
                    fontWeight: "bold",
                    fontSize: "28px",
                    textAlign: "center",
                    cursor: "default",
                    backgroundColor: "transparent",
                  },
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "transparent", // Prevent hover background change
                    "&:hover": {
                      backgroundColor: "transparent", // Ensure hover background is transparent
                    },
                    "& fieldset": {
                      border: "4px solid rgba(0, 73, 118, 1)",
                    },
                    "&:hover fieldset": {
                      border: "4px solid rgba(0, 73, 118, 1)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "2px solid rgba(0, 73, 118, 1) !important", // Prevent focus border change
                    },
                  },
                }}
              />
              {/* Increment Button */}
              <Button
                variant="contained"
                size="small"
                onClick={handleIncrement}
                disabled={countyValue >= maxCounties || addCountyLoading} // Disable when max reached
                sx={{
                  minWidth: "40px",
                  height: "40px",
                  fontSize: "18px",
                  fontWeight: "bold",
                  backgroundColor: "#004976",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#003456",
                  },
                }}
              >
                +
              </Button>
            </Box>

            <DialogContentText
              variant={"h5"}
              sx={{
                mb: 3,
                px: 1,
                textAlign: "center",
                fontWeight: "bold",
                color: "#004976",
              }}
            >
              Looking for better value? Switch to a larger subscription package
              and enjoy more counties for a lower price per county!
            </DialogContentText>
          </Box>

          <Box
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 1,
            }}
          >
            <LoadingButton
              loading={addCountyLoading}
              disabled={countyValue <= 0}
              color="success"
              onClick={() => {
                setExtraCountyTitle(
                  countyValue > 1
                    ? `Add ${countyValue} Additional Counties - $${parseInt(
                        currentUser?.service_tiers?.extra_county_cost *
                          countyValue
                      )}/Month`
                    : `Add ${countyValue} Additional County - $${parseInt(
                        currentUser?.service_tiers?.extra_county_cost *
                          countyValue
                      )}/Month`
                );
                if (!defaultCard) {
                  setPaymentModalOpen(true);
                } else {
                  handleAddCounty();
                }
              }}
              fullWidth
              variant="contained"
            >
              {countyValue > 1
                ? "ADD ADDITIONAL COUNTIES FOR $"
                : "ADD ADDITIONAL COUNTY FOR $"}
              {parseInt(
                currentUser?.service_tiers?.extra_county_cost * countyValue
              )}
              /MONTH
            </LoadingButton>
            <Button
              disabled={addCountyLoading}
              onClick={() => {
                navigate("/subscription?change=true");
              }}
              color="primary"
              fullWidth
              variant="contained"
            >
              SWITCH TO BETTER PLAN
            </Button>
            <Button
              disabled={addCountyLoading}
              onClick={() => {
                setOpen(false);
                setCountyValue(0);
              }}
              color="error"
              fullWidth
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PurchaseExtraCounty;
