import { Box, IconButton, Typography } from "@mui/material";
import ContractorCard from "../../permitComponents/ContractorCard";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DiscountCard from "./DiscountCard";

const RenderCustomerDiscounts = ({ discountPage, setDiscountPage, table }) => {
  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = discountPage + 1;
    } else if (direction === "back") {
      newPage = discountPage - 1;
    }

    setDiscountPage(newPage);
  };

  return (
    <>
      {table?.data.length > 0 ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              gap: 1,
            }}
            my={1}
          >
            <IconButton
              onClick={() => handleNavigate("back")}
              sx={{
                visibility: discountPage === 1 ? "hidden" : "visible",
              }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
            </IconButton>

            <Typography align="center" variant="h5">
              {table?.data.length === 0
                ? "0/0"
                : discountPage + "/" + table?.data.length}
            </Typography>
            <IconButton
              onClick={() => handleNavigate("forward")}
              sx={{
                visibility:
                  table?.data.length === 0 ||
                  discountPage === table?.data.length
                    ? "hidden"
                    : "visible",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>
          <DiscountCard
            data={table?.data?.[discountPage - 1]}
            colorIndex={discountPage}
            btnText="Remove Customer's Coupon"
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            gap: 1,
          }}
          mb={2}
        >
          <Typography variant="body2" fontWeight="bold" pt={2}>
            No Active Discounts Applied
          </Typography>
        </Box>
      )}
    </>
  );
};

export default RenderCustomerDiscounts;
