import * as Yup from "yup";

export const handlePhoneInputChange = (event, formik) => {
  const { value } = event.target;
  let digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters

  // If the number of digits is greater than 10, slice to 10 digits
  if (digitsOnly.length > 10) {
    digitsOnly = digitsOnly.slice(0, 10);
  }

  let formattedPhoneNumber = "";

  // Add dashes after 3rd and 6th digits
  for (let i = 0; i < digitsOnly.length; i++) {
    if (i === 3 || i === 6) {
      formattedPhoneNumber += "-";
    }
    formattedPhoneNumber += digitsOnly[i];
  }
  if (formik) {
    formik.setFieldValue(event.target.name, formattedPhoneNumber);
  } else {
    return formattedPhoneNumber;
  }
};

export const validateDate = (dateStr) => {
  const [month, day, year] = dateStr.split("/").map(Number);
  const date = new Date(year, month - 1, day);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  // Validate month, day, and year ranges
  if (
    month < 1 ||
    month > 12 ||
    day < 1 ||
    day > 31 ||
    year < 1000 ||
    year > 9999 ||
    isNaN(date.getTime())
  ) {
    return false;
  }

  // Check if the date is in the past
  if (dateStr.length >= 10 && date < today) {
    return false;
  }
  return true;
};

export const handleDateChange = (event, formik, name) => {
  let { value } = event.target;
  value = value.replace(/\D/g, ""); // Remove all non-digit characters

  // Ensure we do not proceed if the value is too long
  if (value.length > 8) {
    formik.setFieldError(name, "Invalid date format");
    return;
  }

  // Format the value as mm/dd/yyyy
  let formattedValue = "";
  if (value.length > 4) {
    formattedValue = `${value.slice(0, 2)}/${value.slice(2, 4)}/${value.slice(
      4
    )}`;
  } else if (value.length > 2) {
    formattedValue = `${value.slice(0, 2)}/${value.slice(2)}`;
  } else {
    formattedValue = value;
  }

  // Validate and set the formatted value
  formik.setFieldValue(name, formattedValue);
  if (formattedValue.length === 10 && !validateDate(formattedValue)) {
    formik.setFieldError(
      name,
      "Invalid date or expiration date cannot be in the past"
    );
  } else {
    formik.setFieldError(name, "");
  }
};

export const handleMoneyChange = (event, formik) => {
  // Allow only numeric input and the decimal point during typing
  let { value } = event.target;
  value = value.replace(/[^\d.]/g, "");

  formik.setFieldValue(event.target.name, value);
};

export const formatMoneyValue = (value) => {
  if (!value) {
    return "";
  }
  value = String(value);
  // Remove any non-numeric characters except the decimal point
  value = value.replace(/[^\d.]/g, "");

  // Split the input into integer and decimal parts
  let [integer, decimal] = value.split(".");

  // Limit the integer part to 10 digits
  integer = integer ? integer.slice(0, 10) : "";

  // Format the integer part with commas
  let formattedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Handle cases for decimal parts
  if (decimal === undefined) {
    // If there's no decimal part, ensure it ends with ".00"
    formattedInteger += ".00";
  } else if (decimal.length === 0) {
    // If the decimal part is empty, assume ".00"
    formattedInteger += ".00";
  } else if (decimal.length === 1) {
    // If there's only one decimal digit, add a trailing "0"
    formattedInteger += `.${decimal}0`;
  } else {
    // Otherwise, limit the decimal part to two digits
    formattedInteger += `.${decimal.slice(0, 2)}`;
  }

  // Add the dollar sign
  return `$${formattedInteger}`;
};

// export const parseLegalString = (legalString) => {
//   const regex = /^(\S+)?\s+(\S+)?\s+\S+\s+([\w\s\/]+)?\s+RECD\s+O\/R\s+(\S+)?/;
//   const matches = legalString.match(regex);

//   return {
//     lot: matches ? matches[1] || "" : "",
//     block: matches ? matches[2] || "" : "",
//     subdivision: matches ? matches[3] || "" : "",
//     platted: matches ? matches[4] || "" : "",
//   };
// };
// export const parseLegalString = (legalString) => {
//   const regex =
//     /^([\d\-]+)?\s+([\d\-\w]+)?\s+([\d\w\-]+)?\s+([\w\s\/]+)?\s+LOT\s+(\d+)?\s+BLK\s+(\d+)?/;
//   const matches = legalString.match(regex);

//   return {
//     lot: matches ? matches[5] || "" : "",
//     block: matches ? matches[6] || "" : "",
//     subdivision: matches ? matches[4] || "" : "",
//     platted: matches ? matches[3] || "" : "",
//   };
// };
export const parseLegalString = (legalString) => {
  let lot = "";
  let block = "";
  let subdivision = "";
  let platted = "";
  let record = "";

  // Multiple patterns to capture different parts of legal description
  const patterns = [
    // Pattern 1: Captures subdivisions, RECD O/R, and platted info
    /^([\d\-]+)?\s+([\d\-\w]+)?\s+([\d\.]+)?\s+([\w\s\/]+)\s+([\w\s\/]+)?\s+RECD\s+O\/R\s+(\S+)/,
  ];

  for (const pattern of patterns) {
    const matches = legalString.match(pattern);
    if (matches) {
      // Handle different parts of the legal string
      lot = matches[1] || lot; // Lot number (if present)
      block = matches[2] || block; // Block (if present)
      subdivision = matches[4] || subdivision; // Subdivision name
      platted = matches[5] || platted; // Platted information
      record = matches[6] || record; // RECD O/R (record)
      break;
    }
  }

  return {
    lot: lot || "",
    block: block || "",
    subdivision: subdivision || "",
    platted: platted || "",
    record: record || "",
  };
};

export const updateBuildingState = (setBuildingStates, key, list) => {
  setBuildingStates((prevState) => ({
    ...prevState,
    [key]: list?.length > 0 ? [...list] : [],
  }));
};

// formValidationUtils.js
export const checkForDuplicates = ({
  fieldsToCheck,
  fieldMapping,
  formik,
  listState,
  entityName,
  setBuildingStates,
  buildingStates,
}) => {
  let hasDuplicate = false;
  fieldsToCheck.forEach((field) => {
    const formikField = fieldMapping[field] || field;
    const formikValue = formik?.values?.[formikField]?.trim().toLowerCase();

    if (formikValue) {
      const isDuplicate =
        formikValue !== "" &&
        listState.some((item) => {
          const itemValue = item[field]?.trim().toLowerCase();
          return (
            itemValue === formikValue && item.id !== formik.values[entityName]
          );
        });

      if (formik.values[entityName]) {
        if (
          isDuplicate ||
          (formik.values[fieldMapping.nameField] === "" &&
            formik.values[entityName]) ||
          (formik.values[fieldMapping.addressField] === "" &&
            formik.values[entityName])
        ) {
          hasDuplicate = true;
          formik.setErrors((prevErrors) => ({
            ...prevErrors,
            [formikField]: `${fieldMapping.labels[field]} already exists`,
          }));
        } else {
          formik.setErrors((prevErrors) => ({
            ...prevErrors,
            [formikField]:
              prevErrors[formikField] ===
              `${fieldMapping.labels[field]} already exists`
                ? ""
                : prevErrors[formikField],
          }));
        }
      }
    }
  });

  setBuildingStates((prevState) => ({
    ...prevState,
    [`${entityName}SubmitDisabled`]: hasDuplicate,
  }));
};

export const getValueFromPath = (object, pathString) => {
  try {
    if (pathString.includes("+")) {
      // Handle concatenation case
      const parts = pathString.split("+").map((part) => part.trim());

      const values = parts.map((part) => {
        // Check if part is optional (indicated by ? after +)
        const isOptional = part.startsWith("?");
        if (isOptional) {
          part = part.replace("?", "").trim(); // Remove the optional marker
        }

        const value = part
          .split("?.")
          .reduce((o, key) => (o ? o[key] : undefined), object);

        // Return the value (or empty string for optional ones that are missing)
        return isOptional
          ? value !== undefined && value !== null && value !== ""
            ? value
            : ""
          : value;
      });
      // Concatenate valid values, including optional ones if they have valid values
      return (
        values
          .filter((val) => val !== undefined && val !== null && val !== "")
          .join(" ") || undefined
      );
    } else {
      // Handle normal path
      return pathString
        .split("?.")
        .reduce((o, key) => (o ? o[key] : undefined), object);
    }
  } catch (error) {
    return undefined;
  }
};

// Helper function to get initials from a path
export const getInitialsFromPath = (object, pathString) => {
  try {
    const value = pathString
      .split("?.")
      .reduce((o, key) => (o ? o[key] : undefined), object);

    if (value && typeof value === "string") {
      // Get the initials from the name (first letters of words)
      const initials = value
        .split(" ")
        .map((word) => word[0].toUpperCase())
        .join("");
      return initials;
    }
    return undefined; // Return undefined if no valid name is found
  } catch (error) {
    return undefined;
  }
};

export const generateValidationConfig = (commonFields) => {
  const validationConfig = {};

  commonFields.forEach((field) => {
    // Skip if the field doesn't have a 'name' property
    if (!field.name) return;

    // Dynamically create the rules based on the field name and specific rules in the commonFields object
    validationConfig[field.name] = {
      fieldNames: field.fields || [], // Use the fields list, default to empty if not provided
      rules: {
        // Assign dynamic rules, skipping the rule if it's not defined for the field
        min: field.min
          ? {
              value: field.min,
              message: `${field.name.replace(
                /([A-Z])/g,
                " $1"
              )} must be at least ${
                field?.specialMin ? field?.specialMin : field.min
              } characters`,
            }
          : null,
        max: field.max
          ? {
              value: field.max,
              message: `${field.name.replace(
                /([A-Z])/g,
                " $1"
              )} cannot exceed ${field.max} characters`,
            }
          : null,
        required: field.required
          ? {
              value: true,
              message: `${field.name.replace(/([A-Z])/g, " $1")} is required`,
            }
          : null,
        regex: field.regex
          ? {
              value: field.regex,
              message: `${field.name.replace(
                /([A-Z])/g,
                " $1"
              )} format is invalid`,
            }
          : null,
        email: field.email
          ? { value: true, message: "Invalid email format" }
          : null,
        number: field.number
          ? {
              value: true,
              message: `${field.name.replace(
                /([A-Z])/g,
                " $1"
              )} must be a number`,
            }
          : null,
        // Add more rules as needed
      },
    };

    // Remove any null rules (those that were not provided for the field)
    validationConfig[field.name].rules = Object.fromEntries(
      Object.entries(validationConfig[field.name].rules).filter(
        ([_, rule]) => rule !== null
      )
    );
  });

  return validationConfig;
};

export const buildValidationSchema = (initialValues, validationConfig) => {
  const schemaShape = {};

  Object.keys(validationConfig).forEach((configKey) => {
    const config = validationConfig[configKey];
    const matchingField = config.fieldNames.find((fieldName) =>
      Object.keys(initialValues).includes(fieldName)
    );

    if (matchingField) {
      let fieldSchema = Yup.string();

      // Apply the dynamic rules (min, max, regex, email, number, required, etc.)
      if (config.rules.min) {
        fieldSchema = fieldSchema.min(
          config.rules.min.value,
          config.rules.min.message
        );
      }
      if (config.rules.max) {
        fieldSchema = fieldSchema.max(
          config.rules.max.value,
          config.rules.max.message
        );
      }
      if (config.rules.required?.value) {
        fieldSchema = fieldSchema.required(config.rules.required.message);
      }
      if (config.rules.regex) {
        fieldSchema = fieldSchema.matches(
          config.rules.regex.value,
          config.rules.regex.message
        );
      }
      if (config.rules.email) {
        fieldSchema = fieldSchema.email(config.rules.email.message);
      }
      if (config.rules.number) {
        fieldSchema = Yup.number().typeError(config.rules.number.message);
      }

      // Add the field schema to the Yup schema
      schemaShape[matchingField] = fieldSchema;
    }
  });

  return Yup.object().shape(schemaShape);
};
