import { useState, useEffect } from "react";
import {
  Box,
  IconButton,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid2 as Grid,
  Button,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DiscountCard from "./DiscountCard";
import CreateCouponForm from "./CreateCouponForm";

const RenderAvailableCoupons = ({
  table,
  selectedBasePlan,
  limits,
  rowID,
  selectedRow,
  setSelectedRow,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  const [createCouponLoading, setCreateCouponLoading] = useState(false);
  const [couponPage, setCouponPage] = useState(1);
  const [assignCouponLoading, setAssignCouponLoading] = useState(false);

  const freeCategoryMapping = [
    { name: "notary_record", label: "Permit Package Notarization & Recording" },
    {
      name: "noc_notary",
      label: "Notice of Commencement Notarization & Recording",
    },
    { name: "extra_county_cost", label: "Additional Counties" },
    { name: "extra_download_cost", label: "Additional Downloads" },
  ];

  const premiumCategoryMapping = [
    {
      name: "monthly_price",
      label: "Base Plan",
    },
    { name: "notary_record", label: "Permit Package Notarization & Recording" },
    {
      name: "noc_notary",
      label: "Notice of Commencement Notarization & Recording",
    },
    { name: "extra_county_cost", label: "Additional Counties" },
  ];

  const rockstarCategoryMapping = [
    {
      name: "monthly_price",
      label: "Base Plan",
    },
    { name: "notary_record", label: "Permit Package Notarization & Recording" },
    {
      name: "noc_notary",
      label: "Notice of Commencement Notarization & Recording",
    },
  ];

  const categoryMapping = {
    Free: freeCategoryMapping,
    Premium: premiumCategoryMapping,
    Rockstar: rockstarCategoryMapping,
  };

  useEffect(() => {
    if (!table?.coupons || table?.coupons.length === 0) return;

    // Filter coupons based on base plan and category selection
    const filtered = table?.coupons
      .filter(
        (item) =>
          item.base_plan === selectedBasePlan && // Only matching base_plan
          item.coupons.length > 0 && // Must have available coupons
          (selectedCategory === "all" || item.name === selectedCategory) // Show all or selected category
      )
      .flatMap((item) => item.coupons); // Extract the coupon arrays

    setFilteredCoupons(filtered);
    setCouponPage(1); // Reset pagination on filter change
  }, [selectedBasePlan, selectedCategory, table?.coupons]);

  const handleNavigate = (direction) => {
    let newPage;
    if (direction === "forward") {
      newPage = couponPage + 1;
    } else if (direction === "back") {
      newPage = couponPage - 1;
    }
    setCouponPage(newPage);
  };

  return (
    <Box
      sx={{
        m: 2,
        p: 2,
        border: "3px solid #1976d2",
        borderRadius: "8px",
        boxShadow: 3,
      }}
    >
      {/* Dropdown for Selecting Coupon Category */}
      <FormControl
        fullWidth
        sx={{ mb: 2 }}
        variant="filled"
        disabled={createCouponLoading}
      >
        <InputLabel>Select Category</InputLabel>
        <Select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: "1.5px",
                maxHeight: 350, // Set the desired max height for the dropdown menu
                width: "auto",
                maxWidth: "100%",
              },
            },
          }}
        >
          {categoryMapping?.[selectedBasePlan].map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {isCreating ? (
        <CreateCouponForm
          selectedCategory={selectedCategory}
          selectedBasePlan={selectedBasePlan}
          onCancel={() => setIsCreating(false)}
          limits={limits}
          createCouponLoading={createCouponLoading}
          setCreateCouponLoading={setCreateCouponLoading}
          rowID={rowID}
          setSelectedRow={setSelectedRow}
        />
      ) : filteredCoupons.length > 0 ? (
        <>
          {/* Pagination Controls */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
            }}
            my={1}
          >
            <IconButton
              onClick={() => handleNavigate("back")}
              sx={{ visibility: couponPage === 1 ? "hidden" : "visible" }}
            >
              <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
            </IconButton>

            <Typography align="center" variant="h6">
              {filteredCoupons.length === 0
                ? "0/0"
                : `${couponPage}/${Math.ceil(filteredCoupons.length)}`}
            </Typography>

            <IconButton
              onClick={() => handleNavigate("forward")}
              sx={{
                visibility:
                  couponPage === filteredCoupons.length ? "hidden" : "visible",
              }}
            >
              <ArrowForwardIosIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Box>

          {/* Display Coupons in Two Columns */}
          <Grid container spacing={2}>
            {filteredCoupons
              .slice(couponPage - 1, couponPage) // ✅ Selects only 1 item
              .map((coupon, index) => (
                <Grid size={{ xs: 12 }} key={index}>
                  <DiscountCard
                    data={coupon}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    colorIndex={couponPage}
                    btnText="Remove Coupon"
                    addBtn={true}
                    assignCouponLoading={assignCouponLoading}
                    setAssignCouponLoading={setAssignCouponLoading}
                  />
                </Grid>
              ))}
          </Grid>
        </>
      ) : (
        <Box>
          <Typography
            variant="body2"
            fontWeight="bold"
            textAlign="center"
            mt={2}
          >
            {selectedCategory && filteredCoupons.length === 0
              ? "No Available Coupons for Selected Category"
              : !selectedCategory
              ? "Select a Category to View Coupons"
              : ""}
          </Typography>
        </Box>
      )}

      {!isCreating && selectedCategory && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Button
            disabled={assignCouponLoading}
            variant="contained"
            color="primary"
            onClick={() => setIsCreating(true)}
          >
            Create Coupon
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default RenderAvailableCoupons;
