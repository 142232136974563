export const license_types_suffix = {
  "Certified AC Contractor": ["CAC", "CACA", "CACB"],
  "Certified Building Contractor": ["CBC", "CBCA", "CBCB", "TTT"],
  "Certified General Contractor": ["CGC", "CGCA", "CGCB"],
  "Certified Mechanical Contractor": ["CMC", "CMCA"],
  "Certified Plumbing Contractor": ["CFC", "CFCA"],
  "Certified Pollutant Storage Contractor": ["PCC", "PCCA"],
  "Certified Pool/Spa Contractor": ["CPC", "CPCA"],
  "Certified Residential Contractor": ["CRC", "CRCA", "CRCB"],
  "Certified Roofing Contractor": ["CCC", "CCCA"],
  "Certified Sheet Metal Contractor": ["CSC"],
  "Certified Solar Contractor": ["CVC"],
  "Certified Specialty Contractor": ["SCC", "CKC", "CWC", "CYC", "CZC"],
  "Certified Utility and Excavation": ["CUC", "CUCA"],
  "Registered Air Conditioning Contractor": ["RA"],
  "Registered Building Contractor": ["RB"],
  "Registered General Contractor": ["RG", "RGB", "RGA"],
  "Registered Mechanical Contractor": ["RM", "RMA"],
  "Registered Plumbing Contractor": ["RF"],
  "Registered Pool/Spa Contractor": ["RP", "RPA"],
  "Registered Precision Tank Tester": ["RQ"],
  "Registered Residential Contractor": ["RR", "RRA"],
  "Registered Roofing Contractor": ["RC"],
  "Registered Sheet Metal Contractor": ["RS"],
  "Registered Specialty Contractor": ["RX"],
  "Registered Tank Lining Applicator": ["RL"],
  "Registered Utility and Excavation Contractor": ["RU"],
};

export const license_statuses = [
  "All",
  "None",
  "Application Withdrawn",
  "Application in Progress",
  "Close to open Certified License",
  "Close to open Certified License,Active",
  "Close to open Certified License,Inactive",
  "Current",
  "Current Conditional,Active",
  "Current with Probation,Active",
  "Current with Probation,Inactive",
  "Current,Active",
  "Current,Inactive",
  "Deceased",
  "Delinquent",
  "Delinquent,Active",
  "Delinquent,Inactive",
  "Discipline Relinquishment",
  "Eligible for Exam",
  "Eligible for Exam,Active",
  "Eligible for Exam,Inactive",
  "License Authority Voided",
  "License Authority Voided,Active",
  "Military,Active",
  "Military,Inactive",
  "Null and Void",
  "Null and Void,Inactive",
  "Revoked",
  "Revoked,Active",
  "Suspended",
  "Suspended,Active",
  "Suspended,Inactive",
  "Voluntary Null & Void",
  "Voluntary Relinquishment",
];

export const license_types = [
  "All",
  "None",
  "Certified AC Contractor",
  "Certified Building Contractor",
  "Certified General Contractor",
  "Certified Mechanical Contractor",
  "Certified Plumbing Contractor",
  "Certified Pollutant Storage Contractor",
  "Certified Pool/Spa Contractor",
  "Certified Residential Contractor",
  "Certified Roofing Contractor",
  "Certified Sheet Metal Contractor",
  "Certified Solar Contractor",
  "Certified Specialty Contractor",
  "Certified Utility and Excavation",
  "Registered Air Conditioning Contractor",
  "Registered Building Contractor",
  "Registered General Contractor",
  "Registered Mechanical Contractor",
  "Registered Plumbing Contractor",
  "Registered Pool/Spa Contractor",
  "Registered Precision Tank Tester",
  "Registered Residential Contractor",
  "Registered Roofing Contractor",
  "Registered Sheet Metal Contractor",
  "Registered Specialty Contractor",
  "Registered Tank Lining Applicator",
  "Registered Utility and Excavation Contractor",
];

export const edit_pivot_license_types = [
  "All",
  "None",
  "Total",
  "Certified Air Conditioning Contractor",
  "Certified Building Contractor",
  "Certified General Contractor",
  "Certified Mechanical Contractor",
  "Certified Plumbing Contractor",
  "Certified Pollutant Storage System Contractor",
  "Certified Pool/Spa Contractor",
  "Certified Residential Contractor",
  "Certified Roofing Contractor",
  "Certified Sheet Metal Contractor",
  "Certified Solar Contractor",
  "Certified Specialty Contractor",
  "Certified Underground Utility and Excavation Contractor",
  "Registered Air Conditioning Contractor",
  "Registered Building Contractor",
  "Registered General Contractor",
  "Registered Mechanical Contractor",
  "Registered Plumbing Contractor",
  "Registered Pool/Spa Contractor",
  "Registered Precision Tank Tester",
  "Registered Residential Contractor",
  "Registered Roofing Contractor",
  "Registered Sheet Metal Contractor",
  "Registered Specialty Contractor",
  "Registered Tank Lining Applicator",
  "Registered Underground Utility and Excavation Contractor",
];

export const counties = [
  "All",
  "None",
  "UNKNOWN",
  "OUT OF STATE",
  "ALACHUA",
  "BAKER",
  "BAY",
  "BRADFORD",
  "BREVARD",
  "BROWARD",
  "CALHOUN",
  "CHARLOTTE",
  "CITRUS",
  "CLAY",
  "COLLIER",
  "COLUMBIA",
  "DADE",
  "DESOTO",
  "DIXIE",
  "DUVAL",
  "ESCAMBIA",
  "FLAGLER",
  "FRANKLIN",
  "GADSDEN",
  "GILCHRIST",
  "GLADES",
  "GULF",
  "HAMILTON",
  "HARDEE",
  "HENDRY",
  "HERNANDO",
  "HIGHLANDS",
  "HILLSBOROUGH",
  "HOLMES",
  "INDIAN RIVER",
  "JACKSON",
  "JEFFERSON",
  "LAFAYETTE",
  "LAKE",
  "LEE",
  "LEON",
  "LEVY",
  "LIBERTY",
  "MADISON",
  "MANATEE",
  "MARION",
  "MARTIN",
  "MONROE",
  "NASSAU",
  "OKALOOSA",
  "OKEECHOBEE",
  "ORANGE",
  "OSCEOLA",
  "PALM BEACH",
  "PASCO",
  "PINELLAS",
  "POLK",
  "PUTNAM",
  "SANTA ROSA",
  "SARASOTA",
  "SEMINOLE",
  "ST. JOHNS",
  "ST. LUCIE",
  "SUMTER",
  "SUWANNEE",
  "TAYLOR",
  "UNION",
  "VOLUSIA",
  "WAKULLA",
  "WALTON",
  "WASHINGTON",
];

export const countiesForLicense = [
  "ALACHUA",
  "BAKER",
  "BAY",
  "BRADFORD",
  "BREVARD",
  "BROWARD",
  "CALHOUN",
  "CHARLOTTE",
  "CITRUS",
  "CLAY",
  "COLLIER",
  "COLUMBIA",
  "DADE",
  "DESOTO",
  "DIXIE",
  "DUVAL",
  "ESCAMBIA",
  "FLAGLER",
  "FRANKLIN",
  "GADSDEN",
  "GILCHRIST",
  "GLADES",
  "GULF",
  "HAMILTON",
  "HARDEE",
  "HENDRY",
  "HERNANDO",
  "HIGHLANDS",
  "HILLSBOROUGH",
  "HOLMES",
  "INDIAN RIVER",
  "JACKSON",
  "JEFFERSON",
  "LAFAYETTE",
  "LAKE",
  "LEE",
  "LEON",
  "LEVY",
  "LIBERTY",
  "MADISON",
  "MANATEE",
  "MARION",
  "MARTIN",
  "MONROE",
  "NASSAU",
  "OKALOOSA",
  "OKEECHOBEE",
  "ORANGE",
  "OSCEOLA",
  "PALM BEACH",
  "PASCO",
  "PINELLAS",
  "POLK",
  "PUTNAM",
  "SANTA ROSA",
  "SARASOTA",
  "SEMINOLE",
  "ST. JOHNS",
  "ST. LUCIE",
  "SUMTER",
  "SUWANNEE",
  "TAYLOR",
  "UNION",
  "VOLUSIA",
  "WAKULLA",
  "WALTON",
  "WASHINGTON",
];

export const duplicated_selection = ["Primary Name", "Main Address"];
