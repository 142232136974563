export const handleDisableFormToolMenus = async (
  currentProcessingFile,
  setCurrentProcessingFile
) => {
  if (!currentProcessingFile || typeof currentProcessingFile !== "object") {
    return;
  }

  //? SIGNATURES ///////////////////////////////////////////////////
  const notaryTargetNames = [
    "notary_seal_a",
    "notary_sign_a",
    "notary_seal_b",
    "notary_sign_b",
  ];
  const signTargetNames = ["sign_a", "sign_b", "sign_c"];

  const foundNotaryNames = [];
  const foundSignNames = [];

  let filterNotarySealB = false;
  let filterNotarySignB = false;

  let notarySeal_a = false;
  let notarySignature_a = false;
  let notarySeal_b = false;
  let notarySignature_b = false;

  let signatureA = false;
  let signatureB = false;
  let signatureC = false;

  if (Array.isArray(currentProcessingFile?.document?.tempFields?.formFields)) {
    currentProcessingFile?.document?.tempFields?.formFields?.forEach((obj) => {
      if (
        [...notaryTargetNames, ...signTargetNames].some((prefix) =>
          obj.name.startsWith(prefix)
        )
      ) {
        const splitName = obj.name.split("_");
        if (splitName.length === 3) {
          const shortenedSignName = splitName.slice(0, 2).join("_");
          foundSignNames.push(shortenedSignName);
        } else {
          const shortenedNotaryName = splitName.slice(0, 3).join("_");
          foundNotaryNames.push(shortenedNotaryName);
        }
      }
    });
  } else {
    console.error(
      "handleDisableFormToolMenus: formFields is not an array",
      currentProcessingFile?.document?.tempFields?.formFields
    );
    return;
  }

  //? NOTARY ///////////////////////////////////////////////////
  if (
    foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_seal_b") &&
    !foundNotaryNames.includes("notary_sign_a") &&
    !foundNotaryNames.includes("notary_sign_b")
  ) {
    filterNotarySealB = true;
    notarySeal_a = true;
    notarySeal_b = true;
    notarySignature_b = true;
  } else if (
    !foundNotaryNames.includes("notary_seal_a") &&
    !foundNotaryNames.includes("notary_seal_b") &&
    foundNotaryNames.includes("notary_sign_a") &&
    foundNotaryNames.includes("notary_sign_b")
  ) {
    filterNotarySignB = true;
    notarySignature_a = true;
    notarySeal_b = true;
    notarySignature_b = true;
  } else if (
    !foundNotaryNames.includes("notary_seal_a") &&
    !foundNotaryNames.includes("notary_sign_a")
  ) {
    notarySeal_b = true;
    notarySignature_b = true;
  } else if (
    foundNotaryNames.includes("notary_seal_a") &&
    !foundNotaryNames.includes("notary_sign_a")
  ) {
    notarySeal_a = true;
    notarySeal_b = true;
    notarySignature_b = true;
  } else if (
    !foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_sign_a")
  ) {
    notarySignature_a = true;
    notarySeal_b = true;
    notarySignature_b = true;
  } else if (
    foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_sign_a") &&
    !foundNotaryNames.includes("notary_seal_b") &&
    !foundNotaryNames.includes("notary_sign_b")
  ) {
    notarySeal_a = true;
    notarySignature_a = true;
  } else if (
    foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_sign_a") &&
    !foundNotaryNames.includes("notary_seal_b") &&
    foundNotaryNames.includes("notary_sign_b")
  ) {
    notarySeal_a = true;
    notarySignature_a = true;
    notarySignature_b = true;
  } else if (
    foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_sign_a") &&
    foundNotaryNames.includes("notary_seal_b") &&
    !foundNotaryNames.includes("notary_sign_b")
  ) {
    notarySeal_a = true;
    notarySignature_a = true;
    notarySeal_b = true;
  } else if (
    foundNotaryNames.includes("notary_seal_a") &&
    foundNotaryNames.includes("notary_sign_a") &&
    foundNotaryNames.includes("notary_seal_b") &&
    foundNotaryNames.includes("notary_sign_b")
  ) {
    notarySeal_a = true;
    notarySignature_a = true;
    notarySeal_b = true;
    notarySignature_b = true;
  }
  //? //////////////////////////////////////////////////////////////

  //? SIGNATURES ///////////////////////////////////////////////////
  if (
    foundSignNames.includes("sign_a") &&
    !foundSignNames.includes("sign_b") &&
    !foundSignNames.includes("sign_c")
  ) {
    signatureA = true;
    signatureC = true;
  } else if (
    foundSignNames.includes("sign_a") &&
    foundSignNames.includes("sign_b") &&
    !foundSignNames.includes("sign_c")
  ) {
    signatureA = true;
    signatureB = true;
  } else if (
    foundSignNames.includes("sign_a") &&
    foundSignNames.includes("sign_b") &&
    foundSignNames.includes("sign_c")
  ) {
    signatureA = true;
    signatureB = true;
    signatureC = true;
  } else if (
    !foundSignNames.includes("sign_a") &&
    !foundSignNames.includes("sign_b") &&
    !foundSignNames.includes("sign_c")
  ) {
    signatureB = true;
    signatureC = true;
  }
  //? //////////////////////////////////////////////////////////////

  //? SAVE TO STATE ///////////////////////////////////////////////////
  setCurrentProcessingFile((prevFile) => ({
    ...prevFile,
    document: {
      ...prevFile.document,
      tempFields: {
        ...prevFile.document.tempFields,
        formFields: filterNotarySealB
          ? prevFile.document.tempFields.formFields.filter(
              (field) => !field.name.startsWith("notary_seal_b")
            )
          : filterNotarySignB
          ? prevFile.document.tempFields.formFields.filter(
              (field) => !field.name.startsWith("notary_sign_b")
            )
          : prevFile.document.tempFields.formFields,
        formFieldTools: {
          ...prevFile.document.tempFields.formFieldTools,
          notarySeal_a: {
            ...prevFile.document.tempFields.formFieldTools.notarySeal_a,
            disabled: notarySeal_a,
          },
          notarySignature_a: {
            ...prevFile.document.tempFields.formFieldTools.notarySignature_a,
            disabled: notarySignature_a,
          },
          notarySeal_b: {
            ...prevFile.document.tempFields.formFieldTools.notarySeal_b,
            disabled: notarySeal_b,
          },
          notarySignature_b: {
            ...prevFile.document.tempFields.formFieldTools.notarySignature_b,
            disabled: notarySignature_b,
          },
          signatureA: {
            ...prevFile.document.tempFields.formFieldTools.signatureA,
            disabled: signatureA,
          },
          signatureB: {
            ...prevFile.document.tempFields.formFieldTools.signatureB,
            disabled: signatureB,
          },
          signatureC: {
            ...prevFile.document.tempFields.formFieldTools.signatureC,
            disabled: signatureC,
          },
        },
      },
    },
  }));
  //? /////////////////////////////////////////////////////////////////
};
