export const handleMouseDown = (
  e,
  currentProcessingFile,
  setCurrentProcessingFile,
  field,
  scaleFactor,
  direction,
  cursor,
  pdfContainerRef,
  setStartPoint,
  setRect,
  setIsDrawing,
  setCurrCursor,
  setFreeSelectStartPoint,
  setMoving,
  setIsSelecting,
  setInitialMousePosition,
  setInitialInputSize,
  setInputPosition,
  setSelectionRect,
  setResizing,
  setResizeDirection
) => {
  if (direction === "fieldTools") {
    // Get the mouse position relative to the container
    const containerRect = pdfContainerRef?.current.getBoundingClientRect();
    const startX = e.clientX - containerRect.left;
    const startY = e.clientY - containerRect.top;

    // Convert to points (1px = 1 / scaleFactor pt)
    setStartPoint({
      x:
        startX /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
      y:
        startY /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
    });

    const returnRect = {
      x:
        startX /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
      y:
        startY /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
      width: 0,
      height: 0,
    };

    setRect(returnRect);
    setIsDrawing(true);
    setCurrCursor("crosshair");
  } else if (direction === "freeSelect") {
    const containerRect = pdfContainerRef?.current.getBoundingClientRect();
    const startX = e.clientX - containerRect.left;
    const startY = e.clientY - containerRect.top;

    // Convert to points (1px = 1 / scaleFactor pt)
    const startPoint = {
      x:
        startX /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
      y:
        startY /
        currentProcessingFile?.document?.tempFields?.currentDocument
          .scaleFactor,
    };

    setFreeSelectStartPoint(startPoint);
    const scaleFactor =
      currentProcessingFile?.document?.tempFields?.currentDocument?.pdfSize /
      currentProcessingFile?.document?.tempFields?.currentDocument?.actualWidth;

    const startPointScaled = {
      x: startPoint.x / scaleFactor,
      y:
        currentProcessingFile?.document?.tempFields?.currentDocument
          .actualHeight -
        startPoint.y / scaleFactor,
    };

    const clickedField = [
      currentProcessingFile?.document?.tempFields?.selectedFormField,
      ...currentProcessingFile?.document?.tempFields?.selectedExtraFields,
    ].find((field) => {
      if (!field) return false; // Skip if `selectedFormField` is null
      return (
        startPointScaled.x >= field.x &&
        startPointScaled.x <= field.x + field.width &&
        startPointScaled.y >= field.y &&
        startPointScaled.y <= field.y + field.height
      );
    });

    if (clickedField) {
      if (e.ctrlKey || e.metaKey) {
        return;
      }

      const currentX = e.clientX;
      const currentY = e.clientY;

      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            selectedFormField: clickedField, // ✅ Wrap in array to keep consistency
          },
        },
      }));

      setMoving(true);
      setIsSelecting(false);

      setInitialMousePosition({ x: currentX, y: currentY });

      // Use the raw field position without multiplying by scaleFactor here
      setInitialInputSize({
        width: clickedField.width,
        height: clickedField.height,
      });

      setInputPosition({
        x: clickedField.x,
        y: clickedField.y,
      });
    } else {
      const isStartingInsideField =
        currentProcessingFile?.document?.tempFields?.formFields.some(
          (field) => {
            if (!field) return false; // Skip null fields
            return (
              startPointScaled.x >= field.x &&
              startPointScaled.x <= field.x + field.width &&
              startPointScaled.y >= field.y &&
              startPointScaled.y <= field.y + field.height
            );
          }
        );

      if (isStartingInsideField) {
        return; // Prevent selection if starting inside a field
      }
      const returnRect = {
        x:
          startX /
          currentProcessingFile?.document?.tempFields?.currentDocument
            .scaleFactor,
        y:
          startY /
          currentProcessingFile?.document?.tempFields?.currentDocument
            .scaleFactor,
        width: 0,
        height: 0,
      };
      setSelectionRect(returnRect);
      setIsSelecting(true);
    }
  } else if (["nw", "n", "ne", "sw", "s", "se", "w", "e"].includes(direction)) {
    setResizing(true);
    setCurrCursor(cursor);
    setResizeDirection(direction);
    setInitialMousePosition({ x: e.clientX, y: e.clientY });

    if (
      currentProcessingFile?.document?.tempFields?.selectedFormField &&
      currentProcessingFile?.document?.tempFields?.selectedFormField?.name ===
        field?.name
    ) {
      setInitialInputSize({
        width:
          currentProcessingFile?.document?.tempFields?.selectedFormField.width *
          scaleFactor,
        height:
          currentProcessingFile?.document?.tempFields?.selectedFormField
            .height * scaleFactor,
      });
      setInputPosition({
        x:
          currentProcessingFile?.document?.tempFields?.selectedFormField.x *
          scaleFactor,
        y:
          currentProcessingFile?.document?.tempFields?.selectedFormField.y *
          scaleFactor,
      });
    } else {
      setInitialInputSize({
        width: field.width * scaleFactor,
        height: field.height * scaleFactor,
      });

      setInputPosition({
        x: field.x * scaleFactor,
        y: field.y * scaleFactor,
      });
    }
  } else if (direction === "move") {
    setMoving(true);
    setCurrCursor(cursor);
    setResizeDirection(direction);
    setInitialMousePosition({ x: e.clientX, y: e.clientY });

    // Use the raw field position without multiplying by scaleFactor here
    setInitialInputSize({
      width: field.width,
      height: field.height,
    });

    setInputPosition({
      x: field.x,
      y: field.y,
    });
  }
};
