export const handleKeyDown = (
  e,
  currentProcessingFile,
  setCurrentProcessingFile,
  typingToolActive,
  setReverseDeleted,
  setCurrCursor,
  selectToolActive,
  focusedField,
  setTypingToolActive,
  setSelectToolActive,
  currCursor,
  setFocusedField,
  setHighlightFields,
  expandToolBox,
  setExpandToolBox
) => {
  if (!currentProcessingFile?.document?.tempFields?.currentDocument.base64File)
    return;

  if (
    document.activeElement.tagName === "INPUT" &&
    !currentProcessingFile?.document?.tempFields?.selectedFormField
  ) {
    const isToolActive = Object.values(
      currentProcessingFile?.document?.tempFields?.formFieldTools
    ).some((field) => field.active);
    if (isToolActive) {
      if (document.activeElement && document.activeElement !== document.body) {
        document.activeElement.blur();
      }
    } else {
      return; // Do nothing if an input field is focused
    }
  }
  if (e.key === "Delete" && !typingToolActive) {
    let fieldsToRemove = [];

    if (currentProcessingFile?.document?.tempFields?.selectedFormField) {
      fieldsToRemove = [
        currentProcessingFile?.document?.tempFields?.selectedFormField,
        ...currentProcessingFile?.document?.tempFields?.selectedExtraFields.filter(
          (field) =>
            field.name !==
            currentProcessingFile?.document?.tempFields?.selectedFormField.name
        ),
      ];
    } else {
      fieldsToRemove =
        currentProcessingFile?.document?.tempFields?.selectedExtraFields;
    }

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          formFieldTools: (() => {
            const updatedTools = {
              ...prevFile.document.tempFields.formFieldTools,
            };

            fieldsToRemove.forEach((field) => {
              const matchingKey = Object.keys(
                prevFile.document.tempFields.formFieldTools
              ).find((key) =>
                field.name.startsWith(
                  prevFile.document.tempFields.formFieldTools[key].name
                )
              );

              if (
                matchingKey &&
                prevFile.document.tempFields.formFieldTools[matchingKey]
                  .disabled
              ) {
                updatedTools[matchingKey] = {
                  ...updatedTools[matchingKey],
                  disabled: false, // ✅ Re-enable tool
                };
              }
            });

            return updatedTools;
          })(),
        },
      },
    }));

    //? MAINTAIN SIGNING ORDERS
    let replacements = [];
    let fieldsToRemoveWithB = [...fieldsToRemove];

    fieldsToRemove.forEach((field) => {
      if (field.name.startsWith("notary_seal_a")) {
        // Replace notary_seal_a with notary_seal_b (if exists)
        const sealB =
          currentProcessingFile?.document?.tempFields?.formFields.find((f) =>
            f.name.startsWith("notary_seal_b")
          );
        if (sealB) {
          replacements.push({
            ...sealB,
            name: `notary_seal_a_${Date.now().toString().slice(-4)}`,
          });
          fieldsToRemoveWithB.push(sealB);
        }
      }

      if (field.name.startsWith("notary_sign_a")) {
        // Replace notary_sign_a with notary_sign_b (if exists)
        const signB =
          currentProcessingFile?.document?.tempFields?.formFields.find((f) =>
            f.name.startsWith("notary_sign_b")
          );
        if (signB) {
          replacements.push({
            ...signB,
            name: `notary_sign_a_${Date.now().toString().slice(-4)}`,
          });
          fieldsToRemoveWithB.push(signB);
        }
      }

      //? HANDLE SIGNING FIELDS
      if (field.name.startsWith("sign_a")) {
        const signB =
          currentProcessingFile?.document?.tempFields?.formFields.find((f) =>
            f.name.startsWith("sign_b")
          );
        const signC =
          currentProcessingFile?.document?.tempFields?.formFields.find((f) =>
            f.name.startsWith("sign_c")
          );

        if (signB) {
          replacements.push({
            ...signB,
            name: `sign_a_${Date.now().toString().slice(-4)}`,
          });
          fieldsToRemoveWithB.push(signB);
        }

        if (signC && signB) {
          replacements.push({
            ...signC,
            name: `sign_b_${Date.now().toString().slice(-4)}`,
          });
          fieldsToRemoveWithB.push(signC);
        }
      }

      if (field.name.startsWith("sign_b")) {
        const signC =
          currentProcessingFile?.document?.tempFields?.formFields.find((f) =>
            f.name.startsWith("sign_c")
          );

        if (signC) {
          replacements.push({
            ...signC,
            name: `sign_b_${Date.now().toString().slice(-4)}`,
          });
          fieldsToRemoveWithB.push(signC);
        }
      }
    });

    //? Save deleted fields for undo
    setReverseDeleted(fieldsToRemove);

    //? Single State Update (Merging setFormFieldTools, setFormFields, and setCurrentProcessingFile)
    setCurrentProcessingFile((prevFile) => {
      // ✅ Remove fields but add replacements if needed
      let updatedFields = prevFile.document.tempFields.formFields.filter(
        (field) =>
          !fieldsToRemoveWithB.some((removed) => removed.name === field.name)
      );

      // ✅ Add replacements if available
      updatedFields = [...updatedFields, ...replacements];

      return {
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFieldTools: {
              ...prevFile.document.tempFields.formFieldTools,
              ...(prevFile.document.tempFields.formFields.some((f) =>
                f.name.startsWith("notary_seal_b")
              ) && {
                notarySeal_a: {
                  ...prevFile.document.tempFields.formFieldTools.notarySeal_a,
                  disabled: true,
                },
                notarySeal_b: {
                  ...prevFile.document.tempFields.formFieldTools.notarySeal_b,
                  disabled: false,
                  values: [],
                },
              }),
              ...(prevFile.document.tempFields.formFields.some((f) =>
                f.name.startsWith("notary_sign_b")
              ) && {
                notarySignature_a: {
                  ...prevFile.document.tempFields.formFieldTools
                    .notarySignature_a,
                  disabled: true,
                },
                notarySignature_b: {
                  ...prevFile.document.tempFields.formFieldTools
                    .notarySignature_b,
                  disabled: false,
                  values: [],
                },
              }),
            },
            formFields: updatedFields,
            selectedFormField: null,
            selectedExtraFields: [],
          },
        },
      };
    });

    //? Remove focus from active element
    if (document.activeElement && document.activeElement !== document.body) {
      document.activeElement.blur();
    }
  }

  if (e.key === "Escape") {
    const isToolActive = Object.values(
      currentProcessingFile?.document?.tempFields?.formFieldTools
    ).some((field) => field.active);

    if (
      isToolActive ||
      (currentProcessingFile?.document?.tempFields?.selectedFormField &&
        selectToolActive)
    ) {
      setCurrCursor("default");

      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFieldTools: isToolActive
              ? Object.keys(prevFile.document.tempFields.formFieldTools).reduce(
                  (acc, key) => {
                    acc[key] = {
                      ...prevFile.document.tempFields.formFieldTools[key],
                      active: false,
                      values: [],
                    };
                    return acc;
                  },
                  {}
                )
              : prevFile.document.tempFields.formFieldTools,
            selectedFormField:
              currentProcessingFile?.document?.tempFields?.selectedFormField &&
              selectToolActive
                ? null
                : prevFile.document.tempFields.selectedFormField,
            selectedExtraFields:
              currentProcessingFile?.document?.tempFields?.selectedFormField &&
              selectToolActive
                ? []
                : prevFile.document.tempFields.selectedExtraFields,
          },
        },
      }));
    }

    if (document.activeElement && document.activeElement !== document.body) {
      document.activeElement.blur();
    }
  }
  if (e.key === "t" && !focusedField) {
    e.preventDefault();

    if (typingToolActive) {
      setTypingToolActive(false);
      setSelectToolActive(true);
    } else {
      setTypingToolActive(true);
      setSelectToolActive(false);
    }

    const activeTool = Object.values(
      currentProcessingFile?.document?.tempFields?.formFieldTools
    ).find((field) => field.active);

    if (
      activeTool ||
      currentProcessingFile?.document?.tempFields?.selectedFormField ||
      currentProcessingFile?.document?.tempFields?.selectedExtraFields?.length >
        0
    ) {
      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            selectedFormField: prevFile.document.tempFields.selectedFormField
              ? null
              : prevFile.document.tempFields.selectedFormField,
            selectedExtraFields:
              prevFile.document.tempFields.selectedExtraFields?.length > 0
                ? []
                : prevFile.document.tempFields.selectedExtraFields,

            // ✅ Reset formFieldTools if activeTool exists
            formFieldTools: activeTool
              ? Object.keys(prevFile.document.tempFields.formFieldTools).reduce(
                  (acc, key) => {
                    acc[key] = {
                      ...prevFile.document.tempFields.formFieldTools[key],
                      active: false,
                      values: [],
                    };
                    return acc;
                  },
                  {}
                )
              : prevFile.document.tempFields.formFieldTools, // Keep unchanged if no activeTool
          },
        },
      }));
    }

    if (currCursor !== "default") {
      setCurrCursor("default");
    }

    if (focusedField) {
      setFocusedField(null);
    }

    if (document.activeElement && document.activeElement !== document.body) {
      document.activeElement.blur();
    }
  }

  if (e.key === "h" && !focusedField) {
    setHighlightFields((prev) => !prev);
  }

  if (["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(e.key)) {
    if (!currentProcessingFile?.document?.tempFields?.selectedFormField) return;
    const movementStep = e.ctrlKey ? 1 : 5;

    let dx = 0;
    let dy = 0;

    if (e.key === "ArrowUp") dy = movementStep;
    if (e.key === "ArrowDown") dy = -movementStep;
    if (e.key === "ArrowLeft") dx = -movementStep;
    if (e.key === "ArrowRight") dx = movementStep;

    if (dx !== 0 || dy !== 0) {
      e.preventDefault();
    }

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          formFields: prevFile.document.tempFields.formFields.map((f) =>
            prevFile.document.tempFields.selectedExtraFields.some(
              (sf) => sf.name === f.name
            )
              ? { ...f, x: f.x + dx, y: f.y + dy }
              : f
          ),

          // ✅ Update selectedFormField if it exists
          selectedFormField: prevFile.document.tempFields.selectedFormField
            ? {
                ...prevFile.document.tempFields.selectedFormField,
                x: prevFile.document.tempFields.selectedFormField.x + dx,
                y: prevFile.document.tempFields.selectedFormField.y + dy,
              }
            : prevFile.document.tempFields.selectedFormField, // Keep as is if null/undefined

          // ✅ Update selectedExtraFields if they exist
          selectedExtraFields: prevFile.document.tempFields.selectedExtraFields
            ? prevFile.document.tempFields.selectedExtraFields.map((field) => ({
                ...field,
                x: field.x + dx,
                y: field.y + dy,
              }))
            : prevFile.document.tempFields.selectedExtraFields, // Keep as is if null/undefined
        },
      },
    }));
  }

  //? ///////////////////////////////////////////////////////////////
  const field_tool_keys = ["g", "n", "s"];
  if (field_tool_keys.includes(e.key) && !focusedField) {
    e.preventDefault();

    if (typingToolActive) {
      setTypingToolActive(false);
      setSelectToolActive(true);
    }

    if (!expandToolBox) {
      setExpandToolBox(true);
    }

    if (
      currentProcessingFile?.document?.tempFields?.selectedFormField ||
      currentProcessingFile?.document?.tempFields?.selectedExtraFields?.length >
        0 ||
      currentProcessingFile?.document?.tempFields?.formFieldTools
    ) {
      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            selectedFormField: prevFile.document.tempFields.selectedFormField
              ? null
              : prevFile.document.tempFields.selectedFormField,
            selectedExtraFields:
              prevFile.document.tempFields.selectedExtraFields?.length > 0
                ? []
                : prevFile.document.tempFields.selectedExtraFields,

            // ✅ Update formFieldTools logic
            formFieldTools: ((prevTools) => {
              // Get all tools that match the current group based on e.key
              const toolsInGroup = Object.entries(
                prevTools.document.tempFields.formFieldTools
              )
                .filter(([_, tool]) => tool.group === e.key)
                .map(([key, tool]) => ({ key, ...tool }));

              if (toolsInGroup.length === 0) {
                if (currCursor !== "default") {
                  setCurrCursor("default");
                }
                return prevTools.document.tempFields.formFieldTools;
              } // No matching group, do nothing

              // ✅ If group is "n" (notary) or "s" (signatures), check if any tool is enabled
              const enabledTools = toolsInGroup.filter(
                (tool) => !tool.disabled
              );

              if (enabledTools.length === 0) {
                if (currCursor !== "default") {
                  setCurrCursor("default");
                }
                return prevTools.document.tempFields.formFieldTools; // If all tools are disabled, do nothing
              }

              // Find the currently active tool within the enabled group
              const activeIndex = enabledTools.findIndex((tool) => tool.active);
              let nextActiveIndex = 0; // Default: If no active tool, pick the first enabled one

              if (activeIndex !== -1) {
                // If there's an active tool, move to the next one in the enabled list
                nextActiveIndex = (activeIndex + 1) % enabledTools.length;
              }

              // Get the key of the next tool that should be activated
              const nextActiveKey = enabledTools[nextActiveIndex].key;

              // Create the updated tools object
              const updatedTools = {};
              for (const key in prevTools.document.tempFields.formFieldTools) {
                updatedTools[key] = {
                  ...prevTools.document.tempFields.formFieldTools[key],
                  active: key === nextActiveKey, // Activate the selected tool
                };
              }
              if (currCursor !== "crosshair") {
                setCurrCursor("crosshair");
              }

              return updatedTools;
            })(prevFile.document.tempFields.formFieldTools), // Apply the function to prevFile.formFieldTools
          },
        },
      }));
    }
  }
};
