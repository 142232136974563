import { Grid2 as Grid } from "@mui/material";
import UserNOCUsage from "../UserNOCUsage";
import { useEffect, useState } from "react";

const RenderUsageChart = ({ table, selectedRow }) => {
  const [nocYear, setNocYear] = useState(String(new Date().getFullYear()));
  const [nocYears, setNocYears] = useState([nocYear]);
  const [ppYear, setPpYear] = useState(String(new Date().getFullYear()));
  const [ppYears, setPpYears] = useState([nocYear]);

  const handleNocChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    table?.data?.map((item) => {
      if (item.year === nocYear) {
        chartList[item.month] = item.noc;
      }
      return null;
    });
    return chartList;
  };

  const handlePpChartValues = () => {
    let chartList = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

    table?.data?.map((item) => {
      if (item.year === ppYear) {
        chartList[item.month] = item?.package;
      }
      return null;
    });
    return chartList;
  };

  useEffect(() => {
    if (table?.name === "nocUsage") {
      const yearsSet = new Set();

      table?.data?.forEach((item) => {
        yearsSet.add(item.year);
      });

      // Convert Set to array, sort it numerically, and update state
      setNocYears([...yearsSet].sort((a, b) => a - b));
    } else if (table?.name === "ppUsage") {
      const yearsSet = new Set();

      table?.data?.forEach((item) => {
        yearsSet.add(item.year);
      });

      // Convert Set to array, sort it numerically, and update state
      setPpYears([...yearsSet].sort((a, b) => a - b));
    }
  }, [selectedRow]);

  return (
    <>
      <Grid container>
        <Grid size={{ xs: 12 }}>
          {table?.name === "nocUsage" ? (
            <UserNOCUsage
              title="Total Created NOC's"
              role={selectedRow?.role}
              year={nocYear}
              setYear={setNocYear}
              nocYears={nocYears}
              handleChartValues={handleNocChartValues}
              chartSeries={[
                {
                  name: "Created NOC's",
                  data: handleNocChartValues(),
                },
              ]}
              sx={{ height: "100%" }}
            />
          ) : (
            <UserNOCUsage
              title="Total Created Permit Packages"
              role={selectedRow?.role}
              year={ppYear}
              setYear={setPpYear}
              nocYears={ppYears}
              handleChartValues={handlePpChartValues}
              chartSeries={[
                {
                  name: "Created Packages",
                  data: handlePpChartValues(),
                },
              ]}
              sx={{ height: "100%" }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default RenderUsageChart;
