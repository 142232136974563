import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  FormHelperText,
} from "@mui/material";
import UploadBox from "../fileUploader/UploadBox";
import UploaderLoadingComponent from "./UploaderLoadingComponent";
import axios from "axios";
import { useDispatch } from "react-redux";
import { uploadUserPackagePDF } from "../../store/actions/userPackagesActions";
import RenderedPackageFormFields from "./RenderedPackageFormFields";

const steps = ["Upload Document", "Document Details"];

const AddingPackageStepper = ({
  currentProcessingFile,
  setCurrentProcessingFile,
  setSelectedPackage,
  setSelectedPackageForm,
  setAddingNewPackage,
  uploadError,
  setUploadError,
  setPackagePanelOpen,
  selectedPackage,
  selectedPackageForm,
}) => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [packageName, setPackageName] = useState("");
  const [uploaderLoading, setUploaderLoading] = useState(false);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setPackagePanelOpen(false);
      setAddingNewPackage(false);
    }

    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      setAddingNewPackage(false);
    } else {
      return;
    }
  };

  const handleExtractPDF = async () => {
    const response = await dispatch(
      uploadUserPackagePDF(
        currentProcessingFile,
        setUploaderLoading,
        packageName,
        null // ✅ packageID <-- New Package
      )
    );

    if (response) {
      const packageCopy = { ...response, documents: [response?.document] };
      delete packageCopy?.document;

      setCurrentProcessingFile(response);
      setSelectedPackage(packageCopy);
      setSelectedPackageForm(response?.document);
      handleNext();
    }
  };

  useEffect(() => {
    if (uploaderLoading) {
      handleExtractPDF();
    }
  }, [uploaderLoading]);

  return (
    <>
      {/* Fixed Header with Stepper */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          bgcolor: "white",
          p: 2,
          borderBottom: "3px solid #e0e0e0",
        }}
      >
        <Typography mb={1} align="center" variant="h4" color="primary">
          Create New Package
        </Typography>

        {/* MUI Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Scrollable Content */}
      <Box sx={{ flexGrow: 1, overflowY: "auto", px: 2, py: 2 }}>
        {activeStep === 0 && (
          <>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {uploaderLoading ? (
                <Typography variant="h6" color="primary">
                  {!packageName ? "Unnamed Package" : packageName}
                </Typography>
              ) : (
                <TextField
                  disabled={uploaderLoading}
                  value={packageName}
                  label="Package Name"
                  onChange={(e) => {
                    setPackageName(e.target.value);
                  }}
                />
              )}
            </Box>
            <FormHelperText sx={{ textAlign: "center" }}>
              {uploaderLoading
                ? "Your Package Name"
                : "Enter Your Package Name"}
            </FormHelperText>

            <Box mt={2}>
              {uploaderLoading ? (
                <UploaderLoadingComponent />
              ) : (
                <UploadBox
                  setCurrentProcessingFile={setCurrentProcessingFile}
                  uploadError={uploadError}
                  setUploadError={setUploadError}
                  setUploaderLoading={setUploaderLoading}
                />
              )}
            </Box>
          </>
        )}
        {activeStep === 1 && (
          <>
            <Typography mb={2} variant="h5" color="primary" align="center">
              You can edit these fields later
            </Typography>
            <RenderedPackageFormFields
              selectedPackage={selectedPackage}
              selectedPackageForm={selectedPackageForm}
            />
          </>
        )}
      </Box>

      {/* Fixed Back-Next Buttons at the Bottom */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 2,
          bgcolor: "white",
          p: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          disabled={uploaderLoading}
          variant="contained"
          color={activeStep === 0 ? "error" : "secondary"}
          onClick={handleBack}
          sx={{ visibility: activeStep > 0 && "hidden" }}
        >
          {activeStep === 0 ? "Cancel" : "Back"}
        </Button>
        <Button
          variant="contained"
          color={activeStep === steps.length - 1 ? "success" : "primary"}
          onClick={handleNext}
          disabled={activeStep === 0 || uploaderLoading}
          sx={{
            visibility: activeStep === 0 && "hidden",
            width: activeStep === steps.length - 1 ? "15rem" : undefined,
          }}
        >
          {activeStep === steps.length - 1 ? "Complete" : "Next"}
        </Button>
      </Box>
    </>
  );
};

export default AddingPackageStepper;
