const initialState = {
  userPackages: [],
  pagination: {
    package: {
      packageLimit: 5,
      packagePage: 1,
      totalPackageRecord: 0,
    },
    form: {
      formLimit: 5,
      formPage: {}, // Tracks document pages per package (dynamic per packageID)
      totalFormRecord: {},
    },
  },
  processFormOptions: [],
  processSigners: [],
};

const userPackagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_NEW_USER_PACKAGE":
      return {
        ...state,
        userPackages: [...state.userPackages, action.payload],
      };

    case "ADD_USER_PACKAGES":
      return {
        ...state,
        userPackages: [...state.userPackages, ...action.payload.newPackages], // ✅ Append new packages
        pagination: {
          ...state.pagination,
          package: {
            ...state.pagination.package,
            packagePage: action.payload.packagePage, // ✅ Update pagination page
            totalPackageRecord: action.payload.totalPackageRecord, // ✅ Store total packages
          },
        },
      };

    case "ADD_DOCUMENTS_TO_PACKAGE":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? {
                ...packageItem,
                documents: [
                  ...packageItem.documents,
                  ...action.payload.documents,
                ], // ✅ Append new documents
              }
            : packageItem
        ),
        pagination: {
          ...state.pagination,
          form: {
            ...state.pagination.form,
            formPage: {
              ...state.pagination.form.formPage,
              [action.payload.packageID]: action.payload.formPage, // ✅ Track document page per package
            },
            totalFormRecord: {
              ...state.pagination.form.totalFormRecord,
              [action.payload.packageID]: action.payload.totalFormRecord, // ✅ Track total docs per package
            },
          },
        },
      };
    case "ADD_BASE64_TO_PACKAGE":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? {
                ...packageItem,
                documents: packageItem.documents.map((document) =>
                  document.formID === action.payload.formID
                    ? {
                        ...document,
                        tempFields: {
                          ...document.tempFields,
                          formFields: action.payload.formFields,
                          currentDocument: {
                            ...document.tempFields.currentDocument,
                            base64File: action.payload.BASE64,
                          },
                        },
                      }
                    : document
                ),
              }
            : packageItem
        ),
      };
    case "UPDATE_EDITING_FORM":
      const { document, ...packageBase } = action.payload;

      if (
        !Array.isArray(state.userPackages) ||
        state.userPackages.length === 0
      ) {
        console.warn(
          "⚠️ [UPDATE_EDITING_FORM] No userPackages found! Returning state."
        );
        return state;
      }

      const updatedPackages = state.userPackages.map((packageItem) => {
        if (packageItem.packageID === packageBase.packageID) {
          const updatedDocuments = packageItem.documents.map((doc) => {
            if (doc.formID === document.formID) {
              return {
                ...doc,
                tempFields: {
                  ...doc.tempFields, // Keep existing fields
                  ...document.tempFields, // ✅ Merge latest tempFields
                  currentDocument: {
                    ...doc.tempFields.currentDocument, // Keep previous values
                    ...document.tempFields.currentDocument, // ✅ Merge new currentDocument updates
                  },
                },
              };
            }
            return doc;
          });

          return {
            ...packageItem,
            ...packageBase,
            documents: updatedDocuments, // ✅ Replace only updated documents
          };
        }
        return packageItem;
      });

      return {
        ...state,
        userPackages: updatedPackages,
      };

    case "UPDATE_PROCESS_OPTIONS":
      return {
        ...state,
        processFormOptions: state.processFormOptions.map((item) =>
          item.packageID === action.payload.packageID &&
          item.formID === action.payload.formID
            ? { ...item, options: action.payload.options }
            : item
        ),
      };

    case "ADD_NEW_PROCESS_OPTIONS":
      return {
        ...state,
        processFormOptions: state.processFormOptions.some(
          (item) =>
            item.packageID === action.payload.packageID &&
            item.formID === action.payload.formID
        )
          ? state.processFormOptions.map((item) =>
              item.packageID === action.payload.packageID &&
              item.formID === action.payload.formID
                ? { ...item, options: action.payload.options }
                : item
            )
          : [
              ...state.processFormOptions,
              {
                packageID: action.payload.packageID,
                formID: action.payload.formID,
                options: action.payload.options,
              },
            ],
      };

    case "UPDATE_SIGNERS":
      return {
        ...state,
        processSigners: state.processSigners.map((item) =>
          item.packageID === action.payload.packageID
            ? { ...item, signers: action.payload.signers }
            : item
        ),
      };

    case "ADD_NEW_SIGNERS":
      return {
        ...state,
        processSigners: state.processSigners.some(
          (item) => item.packageID === action.payload.packageID
        )
          ? state.processSigners.map((item) =>
              item.packageID === action.payload.packageID
                ? { ...item, signers: action.payload.signers }
                : item
            )
          : [
              ...state.processSigners,
              {
                packageID: action.payload.packageID,
                signers: action.payload.signers,
              },
            ],
      };

    case "UPDATE_PACKAGE_NAME":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? { ...packageItem, packageName: action.payload.value }
            : packageItem
        ),
      };
    case "UPDATE_DOC_NAME":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? {
                ...packageItem,
                documents: packageItem.documents.map((doc) =>
                  doc.formID === action.payload.docID
                    ? { ...doc, document_name: action.payload.value }
                    : doc
                ),
              }
            : packageItem
        ),
      };
    case "UPDATE_DOC_FILENAME":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? {
                ...packageItem,
                documents: packageItem.documents.map((doc) =>
                  doc.formID === action.payload.docID
                    ? { ...doc, cleaned_file_name: action.payload.value }
                    : doc
                ),
              }
            : packageItem
        ),
      };
    case "DELETE_USER_PACKAGE_DOCUMENT":
      const updatedUserPackages = state.userPackages.map((packageItem) =>
        packageItem.packageID === action.payload.packageID
          ? {
              ...packageItem,
              documents: packageItem.documents.filter(
                (doc) => doc.formID !== action.payload.docID
              ),
            }
          : packageItem
      );

      const remainingSigners = state.processSigners.filter(
        (signer) => signer.packageID === action.payload.packageID
      );

      const isLastSigner = remainingSigners?.length === 1;

      return {
        ...state,
        userPackages: updatedUserPackages,
        processFormOptions: state.processFormOptions.filter(
          (option) =>
            !(
              option.packageID === action.payload.packageID &&
              option.formID === action.payload.docID
            )
        ),
        processSigners: isLastSigner ? [] : state.processSigners,
      };
    case "DELETE_USER_PACKAGE":
      return {
        ...state,
        userPackages: state.userPackages.filter(
          (packageItem) => packageItem.packageID !== action.payload.packageID
        ),
        processFormOptions: state.processFormOptions.filter(
          (option) => !(option.packageID === action.payload.packageID)
        ),
      };
    case "LOAD_EXISTING_OPTIONS":
      return {
        ...state,
        processFormOptions: [
          // Keep all existing options that don't match the new one
          ...state.processFormOptions.filter(
            (option) =>
              !(
                option.packageID === action.payload.packageID &&
                option.formID === action.payload.formID
              )
          ),
          // Add the new/updated option
          action.payload,
        ],
      };
    case "LOAD_EXISTING_SIGNERS":
      return {
        ...state,
        processSigners: [
          // Keep all existing signers except those matching the packageID
          ...state.processSigners.filter(
            (signer) => signer.packageID !== action.payload.packageID
          ),
          // Merge new signers
          ...(Array.isArray(action.payload)
            ? action.payload
            : [action.payload]),
        ],
      };
    case "TOGGLE_DOCUMENT_LOCK":
      return {
        ...state,
        userPackages: state.userPackages.map((packageItem) =>
          packageItem.packageID === action.payload.packageID
            ? {
                ...packageItem,
                documents: packageItem.documents.map((doc) =>
                  doc.formID === action.payload.docID
                    ? { ...doc, locked: action.payload.locked }
                    : doc
                ),
              }
            : packageItem
        ),
      };

    default:
      return state;
  }
};

export default userPackagesReducer;
