export const currentProcessingFileBase = {
  packageID: null,
  packageName: "",
  email: "",
  is_subuser: "",
  user_document_token: "",
  process_form_options: [],
  process_signers: [],
  processing_permit: {},

  document: {
    formID: null,
    document_name: null,
    is_notice_of_commencement: false,
    pdf_url: "",
    img_urls: [],
    cleaned_file_name: "",
    document_location: {
      state: "",
      county: "",
      city: "",
    },
    property_owner: {},
    contractor: {},
    locked: false,
    tempFields: {
      formFields: [],
      selectedFormField: null,
      selectedExtraFields: [],
      currentDocument: {
        base64File: null,
        formName: "",
        fileName: "",
        files: [],
        numPages: 0,
        pageNumber: 1,
        pdfSize: 1000,
        scaleFactor: 1,
      },
      formFieldTools: {
        textField: {
          active: false,
          values: [],
          name: "text_field",
          type: "PDFTextField",
          group: "g",
          disabled: false,
        },
        checkbox: {
          active: false,
          values: [],
          name: "checkbox",
          type: "PDFCheckBox",
          group: "g",
          disabled: false,
        },
        radio: {
          active: false,
          values: [],
          name: "radio",
          type: "PDFRadioGroup",
          group: "g",
          disabled: false,
        },
        date: {
          active: false,
          values: [],
          name: "date_field",
          type: "PDFTextField",
          group: "g",
          disabled: false,
        },
        notarySeal_a: {
          active: false,
          values: [],
          name: "notary_seal_a",
          type: "PDFTextField",
          group: "n",
          disabled: false,
        },
        notarySignature_a: {
          active: false,
          values: [],
          name: "notary_sign_a",
          type: "PDFTextField",
          group: "n",
          disabled: false,
        },
        notarySeal_b: {
          active: false,
          values: [],
          name: "notary_seal_b",
          type: "PDFTextField",
          group: "n",
          disabled: false,
        },
        notarySignature_b: {
          active: false,
          values: [],
          name: "notary_sign_b",
          type: "PDFTextField",
          group: "n",
          disabled: false,
        },
        signatureA: {
          active: false,
          values: [],
          name: "sign_a",
          type: "PDFTextField",
          group: "s",
          disabled: false,
        },
        signatureB: {
          active: false,
          values: [],
          name: "sign_b",
          type: "PDFTextField",
          group: "s",
          disabled: false,
        },
        signatureC: {
          active: false,
          values: [],
          name: "sign_c",
          type: "PDFTextField",
          group: "s",
          disabled: false,
        },
      },
    },
  },
};
