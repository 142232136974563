import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import SendIcon from "@mui/icons-material/Send";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { transformMemorableOption } from "../functions/helperFunctions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  TextField,
  Typography,
  Grid2 as Grid,
  useTheme,
  CircularProgress,
  InputAdornment,
  Card,
  IconButton,
} from "@mui/material";

const ChangeFA = ({
  currentUser,
  currentFA,
  setCurrentFA,
  imageList,
  setImageList,
  currentImg,
  setCurrentImg,
  imgWidth,
  setImgWidth,
  qrCodeSrc,
  mobileSize,
  tabletSize,
  smsConsent,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPasting, setIsPasting] = useState(false);
  const [processLoad, setProcessLoad] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [iconsBlocked, setIconsBlocked] = useState(false);
  const [step, setStep] = useState(1); // To track the current step
  const [emailLoading, setEmailLoading] = useState(false);
  const [mobileInputDisabled, setMobileInputDisabled] = useState(false);
  const [mobileText, setMobileText] = useState("");
  const [text, setText] = useState("");
  const [showMemorable, setShowMemorable] = useState(false);
  const [showQr, setShowQr] = useState(false);

  const [memorableValidated, setMemorableValidated] = useState(false);
  const [memoCheckLoading, setMemoCheckLoading] = useState(false);
  const [memoErrorText, setMemoErrorText] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [codeErrorText, setCodeErrorText] = useState("");

  const initialValues = {
    mobile: "",
    code: "",
    memorableText: "",
  };

  useEffect(() => {
    if (!smsConsent && selectedImage === 1) {
      setSelectedImage(null);
    }
  }, [smsConsent]);

  useEffect(() => {
    if (text !== "") {
      const timer = setInterval(() => {
        setText("");
      }, 5000);

      return () => clearInterval(timer);
    }

    if (mobileText !== "") {
      const mobileTimer = setInterval(() => {
        setMobileText("");
      }, 5000);

      return () => clearInterval(mobileTimer);
    }
  }, [text, mobileText]);

  const handlePhoneInputChange = (event, formik) => {
    const { value } = event.target;

    const digitsOnly = value.replace(/\D/g, ""); // Remove non-numeric characters
    let formattedPhoneNumber = "";

    // Add dashes after 3rd and 6th digits
    for (let i = 0; i < digitsOnly.length; i++) {
      if (i === 3 || i === 6) {
        formattedPhoneNumber += "-";
      }
      formattedPhoneNumber += digitsOnly[i];
    }
    formik.setFieldValue("mobile", formattedPhoneNumber);
  };

  const handleValidateMemorable = async (memorable, formik) => {
    try {
      setMemoCheckLoading(true);
      setShowMemorable(false);
      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/validate-memorable-answer`,
        {
          value: memorable,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );
      if (resp.data.success) {
        setMemorableValidated(true);
      } else {
        setMemoErrorText("Security Answer Does Not Match");
        formik.setFieldValue("memorableText", "");
      }

      setMemoCheckLoading(false);
    } catch (e) {
      setMemoCheckLoading(false);
      setMemoErrorText("Security Answer Does Not Match");
      formik.setFieldValue("memorableText", "");
    }
  };

  useEffect(() => {
    const areAllFieldsFilled = inputValues.every((val) => val !== "");

    const callAuth = async () => {
      setProcessLoad(true);
      const code = inputValues.join("");

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/change-totp-auth`,
          {
            code: code,
            currentSession: currentSession,
          },
          { withCredentials: true }
        );
        if (response.data.success) {
          setText(response.data.message);
          setProcessLoad(false);
          setInputValues(["", "", "", "", "", ""]);
          setSelectedImage(null);
          setImgWidth(127);
          setCurrentFA("totp");
          setCurrentImg("/img/logos/authenticator.png");
        }
      } catch (e) {
        setProcessLoad(false);
        setText(e.response.data.message);
        setInputValues(["", "", "", "", "", ""]);
      }
    };

    if (areAllFieldsFilled) {
      callAuth();
    }
  }, [inputValues]);

  const handleEmailFa = async () => {
    try {
      setEmailLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/change-to-emailfa`,
        { currentSession: currentSession },

        { withCredentials: true }
      );

      if (response.data.success === false) {
        setEmailLoading(false);
      } else {
        setText(response.data.message);
        setSelectedImage(null);
        setEmailLoading(false);
        setImgWidth(80);
        setCurrentFA("emailFa");
        setCurrentImg("/img/logos/emailImage.png");
      }
    } catch (e) {
      setEmailLoading(false);
    }
  };

  const handleMobileFa = async () => {
    try {
      setEmailLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/change-to-mobileFa`,
        { currentSession: currentSession },

        { withCredentials: true }
      );

      if (response.data.success === false) {
        setEmailLoading(false);
      } else {
        setText(response.data.message);
        setSelectedImage(null);
        setEmailLoading(false);
        setImgWidth(140);
        setCurrentFA("smsFa");
        setCurrentImg("/img/logos/phone.png");
      }
    } catch (e) {
      setEmailLoading(false);
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("text");

    if (pasteData.length === 6) {
      const newInputValues = pasteData.split("");
      setInputValues(newInputValues);
      setIsPasting(true);
      setTimeout(() => {
        setIsPasting(false);
      }, 0);
      event.preventDefault(); // Prevent default paste behavior
    }
  };

  const handleInputChange = (index, value) => {
    if (!isPasting && value.length > 1) {
      value = value.slice(0, 1); // Allow only one character
    }
    const newInputValues = [...inputValues];
    newInputValues[index] = value;
    setInputValues(newInputValues);

    if (value && index < 5) {
      const nextInput = document.getElementById(`input-${index + 1}`);
      if (nextInput) {
        nextInput.focus();
      }
    } else if (!value && index > 0) {
      const prevInput = document.getElementById(`input-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const handleImageClick = (index, formik) => {
    formik.resetForm();
    setCodeErrorText("");
    setStep(1);
    setMobileInputDisabled(false);
    setCodeSent(false);
    setMemorableValidated(false);
    setMemoErrorText("");

    if (selectedImage === index) {
      setSelectedImage(null);
    } else {
      setSelectedImage(index);
    }
  };

  const validationSchemas = [
    // Validation schema for Step 1
    Yup.object({
      mobile: Yup.string()
        .min(12, "Number is too short")
        .max(12, "Number is too long")
        .required("Mobile number is required"),
      memorableText: Yup.string()
        .min(4, "Your answer is too short")
        .required("Security question's answer is required"),
    }),
    // Validation schema for Step 2 (code)
    Yup.object({
      code: Yup.string().required("Verification code is required"),
      memorableText: Yup.string().required(
        "Security question's answer is required"
      ),
    }),
  ];

  // Generate a random six-character number

  const maskPhoneNumber = (phoneNumber) => {
    const phoneNumberString = phoneNumber.toString();
    // Remove non-digit characters
    const digitsOnly = phoneNumberString.replace(/\D/g, "");

    // Mask all digits except the last 4
    const maskedDigits =
      "*".repeat(digitsOnly.length - 4) + digitsOnly.slice(-4);

    // Mask the entire phone number, including dashes
    const maskedPhoneNumber =
      "*".repeat(phoneNumberString.length - 4) + phoneNumberString.slice(-4);

    return maskedPhoneNumber;
  };

  const mobileFormik = useFormik({
    initialValues,
    validationSchema: validationSchemas[step - 1], // Use the schema for the current step
    onSubmit: async (values, helpers) => {
      setLoading(true);
      const send_sms = async () => {
        try {
          const resp = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/nocApi/sms/add-mobile-send-code`,
            {
              mobile: values["mobile"],
              memorable: values["memorableText"],
              currentSession: currentSession,
            },
            { withCredentials: true }
          );
          setLoading(false);
          return resp;
        } catch (e) {
          setMobileText(e.response.data.message);
          helpers.setFieldValue("memorableText", "");
          setLoading(false);
        }
      };

      if (step === 1) {
        const send_response = await send_sms();

        if (send_response.data.success === true) {
          setStep(2);
          setMobileInputDisabled(true);
          setMobileText(send_response.data.message);
          setIconsBlocked(true);
          setLoading(false);
          setCodeSent(true);
        } else {
          setLoading(false);
        }
      } else {
        setLoading(true);
        const verify_sms = async () => {
          try {
            const resp = await axios.post(
              `${process.env.REACT_APP_BASE_URL}/nocApi/auth/2fa/confirm-change-mobile`,
              {
                code: values["code"],
                memorableText: values["memorableText"],
                currentSession: currentSession,
              },
              { withCredentials: true }
            );
            setLoading(false);
            setText("Mobile verification has been set successfully");
            setSelectedImage(null);
            setEmailLoading(false);
            setImgWidth(140);
            setCurrentFA("smsFa");
            setCurrentImg("/img/logos/phone.png");
            setIconsBlocked(false);
            return resp;
          } catch (e) {
            helpers.setFieldValue("code", "");
            setLoading(false);
            setCodeErrorText("Invalid Verification code");
            setIconsBlocked(false);
          }
        };

        const smsResp = await verify_sms();

        if (smsResp?.data?.success) {
        } else {
          helpers.setFieldValue("code", "");
          setLoading(false);
          setCodeErrorText("Invalid Verification code");
        }
      }
    },
  });

  return (
    <>
      <Card
        pb={3}
        pt={1}
        px={mobileSize ? 1 : 3}
        mb={4}
        component={Grid}
        sx={{
          width: tabletSize ? "90vw" : "60vw",
          maxWidth: "1100px",
          mx: "auto",
        }}
      >
        <Grid size={{ xs: 12 }} mt={1}>
          <Typography
            sx={{ textAlign: "left", mb: mobileSize ? 2 : 5 }}
            variant="h5"
          >
            Two-Factor Authentication
          </Typography>
          <Typography align="center" variant="h6">
            Current 2FA
          </Typography>
        </Grid>

        <Grid container alignItems="center">
          <Grid size={{ xs: 12 }} textAlign="center">
            <Box>
              <img alt={currentFA} src={currentImg} width={imgWidth} />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            border: "4px solid #f4f4f4",
            marginTop: "1rem",
            marginBottom: "1rem",
          }}
        />
        <Typography align="center" sx={{ mb: "2rem" }} variant="h6">
          Change Two-Factor Authentication
        </Typography>

        <Grid container justifyContent="center">
          {imageList?.map(
            (image, index) =>
              image.src !== currentImg && (
                <Grid size={{ xs: 6, lg: 4 }} key={index}>
                  <Box
                    sx={
                      blocked ||
                      iconsBlocked ||
                      (image?.alt === "mobile" &&
                        (process.env.REACT_APP_DISABLE_SMS_FA === "true" ||
                          !smsConsent))
                        ? {
                            borderRadius: "10px",
                            backgroundColor: "transparent",
                            flexDirection: "column",
                            cursor: "auto",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }
                        : {
                            borderRadius: "10px",
                            backgroundColor:
                              selectedImage === index
                                ? "#f3f3f3"
                                : "transparent",
                            flexDirection: "column",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            transform:
                              hovered === index ? "scale(1.1)" : "scale(1)",
                            transition: "transform 0.3s ease",
                          }
                    }
                    onClick={() =>
                      iconsBlocked ||
                      (image?.alt === "mobile" &&
                        (process.env.REACT_APP_DISABLE_SMS_FA === "true" ||
                          !smsConsent))
                        ? null
                        : handleImageClick(index, mobileFormik)
                    }
                    onMouseEnter={() =>
                      !iconsBlocked ? setHovered(index) : null
                    }
                    // onMouseLeave={handleImageLeave}
                  >
                    <Box sx={{ pb: image.alt === "mobile" ? 3.9 : 3, pt: 1 }}>
                      <img
                        alt={image.alt}
                        src={image.src}
                        width={
                          selectedImage === index
                            ? image.width * 1.1
                            : image.width
                        }
                      />
                    </Box>
                    {image?.alt === "mobile" &&
                    process.env.REACT_APP_DISABLE_SMS_FA === "true" ? (
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="error"
                        align="center"
                        px={2}
                        mt={-3}
                      >
                        SMS-based 2FA verification is temporarily unavailable
                      </Typography>
                    ) : (
                      !smsConsent &&
                      image?.alt === "mobile" && (
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          color="error"
                          align="center"
                          px={2}
                          mt={-3}
                        >
                          SMS-based 2FA verification is not enabled
                        </Typography>
                      )
                    )}
                  </Box>
                </Grid>
              )
          )}
          {selectedImage === 0 && currentFA !== "emailFa" && (
            <>
              <Grid size={{ xs: 12 }} sx={{ mt: 5 }}>
                <LoadingButton
                  // SUBMIT //
                  onClick={handleEmailFa}
                  loading={emailLoading}
                  fullWidth
                  size={mobileSize ? "small" : "large"}
                  sx={{ mt: 0, p: 2 }}
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Grid>
            </>
          )}
          {selectedImage === 1 && currentFA !== "smsFa" && smsConsent && (
            <>
              <Grid container spacing={0}>
                {currentUser?.subuser ? (
                  <>
                    {" "}
                    {currentUser?.subuser?.auth_mobile &&
                    !currentUser?.subuser?.mobile_code ? (
                      <>
                        <Grid sx={{ mt: 6 }} size={{ sm: 12 }}>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h6"
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h5"
                          >
                            {maskPhoneNumber(currentUser?.subuser.auth_mobile)}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid sx={{ mt: 6 }} size={{ xs: 12 }}>
                          <Typography
                            align="left"
                            sx={{ mb: "2rem" }}
                            variant="h6"
                          >
                            ADD Phone Number
                          </Typography>
                        </Grid>
                        <Grid size={{ sm: 12 }}>
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{ mb: 2 }}
                          >
                            {currentUser?.subuser.memorable_option}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} align="center">
                          <TextField
                            size={mobileSize ? "small" : undefined}
                            error={
                              !!(
                                mobileFormik.touched.memorableText &&
                                mobileFormik.errors.memorableText
                              )
                            }
                            helperText={
                              mobileFormik.touched.memorableText &&
                              mobileFormik.errors.memorableText
                            }
                            name="memorableText"
                            disabled={mobileInputDisabled}
                            type={showMemorable ? "text" : "password"}
                            label="Enter your answer here"
                            value={mobileFormik.values.memorableText}
                            onChange={(e) => mobileFormik.handleChange(e)}
                            onBlur={mobileFormik.handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowMemorable(!showMemorable);
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                    onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                    edge="end"
                                  >
                                    {showMemorable ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <form
                          style={{ width: "100%" }}
                          noValidate
                          autoComplete="off"
                          onSubmit={mobileFormik.handleSubmit}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              sx={{ mt: 6, mb: 6 }}
                              size={{ xs: 12, lg: 6 }}
                            >
                              <TextField
                                size={mobileSize ? "small" : undefined}
                                error={
                                  !!(
                                    mobileFormik.touched.mobile &&
                                    mobileFormik.errors.mobile
                                  )
                                }
                                helperText={
                                  mobileFormik.touched.mobile &&
                                  mobileFormik.errors.mobile
                                }
                                onKeyUp={(e) =>
                                  handlePhoneInputChange(e, mobileFormik)
                                }
                                disabled={mobileInputDisabled}
                                name="mobile"
                                label="Mobile Number"
                                variant="filled"
                                fullWidth
                                onBlur={mobileFormik.handleBlur}
                                value={mobileFormik.values.mobile}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +1
                                    </InputAdornment>
                                  ),
                                  inputProps: { pattern: "[0-9]*" }, // Allow only numeric input
                                  // Add any other input props or attributes as needed
                                }}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  if (e.target.value.length > 12) {
                                    return;
                                  } else {
                                    mobileFormik.handleChange(e);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid sx={{ mt: 6 }} size={{ sm: 12, lg: 6 }}>
                              {step === 2 && (
                                <TextField
                                  size={mobileSize ? "small" : undefined}
                                  error={
                                    !!(
                                      mobileFormik.touched.code &&
                                      mobileFormik.errors.code
                                    )
                                  }
                                  fullWidth
                                  helperText={
                                    mobileFormik.touched.code &&
                                    mobileFormik.errors.code
                                  }
                                  name="code"
                                  value={mobileFormik.values.code}
                                  label="Verification Code"
                                  variant="filled"
                                  onChange={(e) => {
                                    mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Grid size={{ sm: 12 }}>
                            <Typography
                              variant="body1"
                              sx={{ minHeight: "1.5rem" }}
                              textAlign="center"
                              my={1}
                              color="error"
                              fontWeight="bold"
                            >
                              {mobileText}
                            </Typography>
                          </Grid>
                          <Grid size={{ sm: 12 }}>
                            <LoadingButton
                              loading={loading}
                              fullWidth
                              size="large"
                              sx={{ mt: 0, p: 2 }}
                              type="submit"
                              variant="contained"
                            >
                              {step === 1
                                ? "Send Verification Code"
                                : "S U B M I T"}
                            </LoadingButton>
                          </Grid>
                        </form>
                      </>
                    )}
                  </>
                ) : currentUser?.role === "notary" ? (
                  <>
                    {currentUser?.auth_mobile && !currentUser?.mobile_code ? (
                      <>
                        <Grid sx={{ mt: 6 }} size={{ sm: 12 }}>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h6"
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h5"
                          >
                            {maskPhoneNumber(currentUser?.auth_mobile)}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid sx={{ mt: 6 }} size={{ xs: 12 }}>
                          <Typography
                            align="left"
                            sx={{ mb: "2rem" }}
                            variant="h6"
                          >
                            ADD Phone Number
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }}>
                          <Typography
                            variant="h6"
                            align="center"
                            sx={{ mb: 2 }}
                          >
                            {currentUser?.memorable_option}
                          </Typography>
                        </Grid>
                        <Grid size={{ xs: 12 }} align="center">
                          <TextField
                            size={mobileSize ? "small" : undefined}
                            error={
                              !!(
                                mobileFormik.touched.memorableText &&
                                mobileFormik.errors.memorableText
                              )
                            }
                            helperText={
                              mobileFormik.touched.memorableText &&
                              mobileFormik.errors.memorableText
                            }
                            name="memorableText"
                            disabled={mobileInputDisabled}
                            type={showMemorable ? "text" : "password"}
                            label="Enter your answer here"
                            value={mobileFormik.values.memorableText}
                            onChange={(e) => mobileFormik.handleChange(e)}
                            onBlur={mobileFormik.handleBlur}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    onClick={() => {
                                      setShowMemorable(!showMemorable);
                                    }}
                                    onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                    onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                    edge="end"
                                  >
                                    {showMemorable ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <form
                          style={{ width: "100%" }}
                          noValidate
                          autoComplete="off"
                          onSubmit={mobileFormik.handleSubmit}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              sx={{ mt: 6, mb: 6 }}
                              size={{ xs: 12, lg: 6 }}
                            >
                              <TextField
                                size={mobileSize ? "small" : undefined}
                                error={
                                  !!(
                                    mobileFormik.touched.mobile &&
                                    mobileFormik.errors.mobile
                                  )
                                }
                                helperText={
                                  mobileFormik.touched.mobile &&
                                  mobileFormik.errors.mobile
                                }
                                onKeyUp={(e) =>
                                  handlePhoneInputChange(e, mobileFormik)
                                }
                                disabled={mobileInputDisabled}
                                name="mobile"
                                label="Mobile Number"
                                variant="filled"
                                fullWidth
                                onBlur={mobileFormik.handleBlur}
                                value={mobileFormik.values.mobile}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      +1
                                    </InputAdornment>
                                  ),
                                  inputProps: { pattern: "[0-9]*" }, // Allow only numeric input
                                  // Add any other input props or attributes as needed
                                }}
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) => {
                                  if (e.target.value.length > 12) {
                                    return;
                                  } else {
                                    mobileFormik.handleChange(e);
                                  }
                                }}
                              />
                            </Grid>
                            <Grid sx={{ mt: 6 }} size={{ xs: 12, lg: 6 }}>
                              {step === 2 && (
                                <TextField
                                  size={mobileSize ? "small" : undefined}
                                  error={
                                    !!(
                                      mobileFormik.touched.code &&
                                      mobileFormik.errors.code
                                    )
                                  }
                                  fullWidth
                                  helperText={
                                    mobileFormik.touched.code &&
                                    mobileFormik.errors.code
                                  }
                                  name="code"
                                  value={mobileFormik.values.code}
                                  label="Verification Code"
                                  variant="filled"
                                  onChange={(e) => {
                                    mobileFormik.handleChange(e); // Trigger Formik's handleChange as well
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                          <Grid size={{ sm: 12 }}>
                            <Typography
                              variant="body1"
                              sx={{ minHeight: "1.5rem" }}
                              textAlign="center"
                              my={1}
                              color="error"
                              fontWeight="bold"
                            >
                              {mobileText}
                            </Typography>
                          </Grid>
                          <Grid size={{ sm: 12 }}>
                            <LoadingButton
                              loading={loading}
                              fullWidth
                              size={mobileSize ? "small" : "large"}
                              sx={{ mt: 0, p: 2 }}
                              type="submit"
                              variant="contained"
                            >
                              {step === 1
                                ? "Send Verification Code"
                                : "S U B M I T"}
                            </LoadingButton>
                          </Grid>
                        </form>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {currentUser?.user?.auth_mobile &&
                    !currentUser?.user?.mobile_code ? (
                      <>
                        <Grid sx={{ mt: 6 }} size={{ xs: 12 }}>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h6"
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            align="center"
                            sx={{ mb: "2rem" }}
                            variant="h5"
                          >
                            {maskPhoneNumber(currentUser?.user.auth_mobile)}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      // What is.... + your ? --> A(replace) | Favorite | Birthplace |
                      <>
                        <Box
                          sx={{
                            border: "2px solid #d3d3d3",
                            mt: 2,
                            pb: 3,
                            borderRadius: "10px",
                            boxShadow: "2px 3px 6px 3px rgba(0, 0, 0, 0.2)",
                            px: 2,
                          }}
                        >
                          <Grid
                            sx={{ mt: mobileSize ? 1 : 3 }}
                            size={{ xs: 12 }}
                          >
                            <Typography
                              sx={{
                                mt: 0,
                                fontWeight: mobileSize ? "bold" : undefined,
                              }}
                              color="primary"
                              variant={mobileSize ? "body2" : "h6"}
                              align="center"
                            >
                              If you would like to set up 2FA with text message,
                              you must enter your security question's answer and
                              confirm your phone number
                            </Typography>
                          </Grid>
                          <Grid container alignItems="center" spacing={1}>
                            <Grid
                              size={{ xs: 12 }}
                              sx={{ mt: mobileSize ? 2 : 4 }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: "bold", fontSize: "15px" }}
                              >
                                {currentUser?.user.memorable_option
                                  ? transformMemorableOption(
                                      currentUser.user.memorable_option
                                    )
                                  : ""}
                              </Typography>
                            </Grid>

                            <Grid size={{ xs: 12, sm: 6 }}>
                              <TextField
                                size={mobileSize ? "small" : undefined}
                                error={
                                  !!(
                                    mobileFormik.touched.memorableText &&
                                    mobileFormik.errors.memorableText
                                  )
                                }
                                helperText={
                                  mobileFormik.touched.memorableText &&
                                  mobileFormik.errors.memorableText
                                }
                                name="memorableText"
                                fullWidth
                                disabled={
                                  mobileInputDisabled ||
                                  memoCheckLoading ||
                                  memorableValidated
                                }
                                type={showMemorable ? "text" : "password"}
                                label="Enter the answer to your security question"
                                value={mobileFormik.values.memorableText}
                                onChange={(e) => {
                                  mobileFormik.handleChange(e);
                                  memoErrorText && setMemoErrorText("");
                                }}
                                onBlur={mobileFormik.handleBlur}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton
                                        onClick={() => {
                                          setShowMemorable(!showMemorable);
                                        }}
                                        onMouseDown={(e) => e.preventDefault()} // Prevent focus change on mouse down
                                        onMouseUp={(e) => e.preventDefault()} // Prevent focus change on mouse up
                                        edge="end"
                                      >
                                        {showMemorable ? (
                                          <Visibility />
                                        ) : (
                                          <VisibilityOff />
                                        )}
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Grid>
                            <Grid size={{ xs: 12, sm: 6 }}>
                              <LoadingButton
                                size={mobileSize ? "small" : undefined}
                                fullWidth
                                onClick={() => {
                                  handleValidateMemorable(
                                    mobileFormik?.values?.memorableText,
                                    mobileFormik
                                  );
                                  mobileFormik.setErrors({});
                                }}
                                sx={{
                                  mb:
                                    mobileFormik.errors.memorableText &&
                                    mobileFormik.touched.memorableText
                                      ? "24px"
                                      : "0px",
                                  ...(memorableValidated && {
                                    color: theme.palette.success.contrastText, // Text color
                                    backgroundColor: theme.palette.success.main, // Background color
                                    "&:hover": {
                                      backgroundColor:
                                        theme.palette.success.dark, // Darken the color a bit on hover
                                    },
                                    // Custom disabled styles
                                    "&.Mui-disabled": {
                                      color: theme.palette.success.contrastText, // Keeps text color on disabled state
                                      backgroundColor:
                                        theme.palette.success.light, // Lighter shade for disabled background
                                    },
                                  }),
                                }}
                                loading={memoCheckLoading}
                                variant="contained"
                                disabled={
                                  mobileFormik?.values?.memorableText?.length <
                                    4 ||
                                  mobileFormik.errors.memorableText ||
                                  !mobileFormik?.values?.memorableText ||
                                  memorableValidated
                                }
                                color="warning" // This color will be overridden when memorableValidated is true
                                endIcon={
                                  memorableValidated ? (
                                    <CheckCircleIcon />
                                  ) : (
                                    <SendIcon />
                                  )
                                }
                              >
                                {memorableValidated
                                  ? "Verified"
                                  : "Validate Answer"}
                              </LoadingButton>
                            </Grid>
                            <Typography
                              variant={mobileSize ? "body2" : "h6"}
                              mx="auto"
                              my={1}
                              color="error"
                              fontWeight="bold"
                            >
                              {memoErrorText}
                            </Typography>
                          </Grid>
                          <form
                            style={{ width: "100%" }}
                            noValidate
                            autoComplete="off"
                            onSubmit={mobileFormik.handleSubmit}
                          >
                            {memorableValidated && (
                              <>
                                <Grid container spacing={0}>
                                  <Grid
                                    size={{ sm: 12 }}
                                    sx={{ mt: mobileSize ? 2 : 4, mb: 2 }}
                                  >
                                    <Typography
                                      sx={{ mt: 0 }}
                                      color="primary"
                                      variant="body2"
                                      fontWeight="bold"
                                      align="center"
                                    >
                                      Enter your phone number, then use the code
                                      that is sent by text message to complete
                                      the process and change your 2FA method.
                                    </Typography>
                                  </Grid>
                                  <Grid sx={{ mb: 0 }} size={{ xs: 12, sm: 4 }}>
                                    <TextField
                                      size={mobileSize ? "small" : undefined}
                                      error={
                                        !!(
                                          mobileFormik.touched.mobile &&
                                          mobileFormik.errors.mobile
                                        )
                                      }
                                      helperText={
                                        mobileFormik.touched.mobile &&
                                        mobileFormik.errors.mobile
                                      }
                                      disabled={mobileInputDisabled}
                                      name="mobile"
                                      onKeyUp={(e) =>
                                        handlePhoneInputChange(e, mobileFormik)
                                      }
                                      label="Mobile Number"
                                      variant="filled"
                                      fullWidth
                                      onBlur={mobileFormik.handleBlur}
                                      value={mobileFormik.values.mobile}
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            +1
                                          </InputAdornment>
                                        ),
                                      }}
                                      InputLabelProps={{ shrink: true }}
                                      onChange={(e) => {
                                        if (e.target.value.length > 12) {
                                          return;
                                        } else {
                                          mobileFormik.handleChange(e);
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid
                                    size={{ xs: 12, sm: 4 }}
                                    mt={mobileFormik ? 1 : undefined}
                                    mb={
                                      (mobileFormik.errors.mobile &&
                                        mobileFormik.touched.mobile) ||
                                      (mobileFormik.errors.code &&
                                        mobileFormik.touched.code)
                                        ? "22px"
                                        : "0px"
                                    }
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {mobileFormik.values.code.length < 6 ? (
                                      <>
                                        <LoadingButton
                                          sx={{
                                            mb: mobileSize ? 1 : undefined,
                                          }}
                                          fullWidth={mobileSize}
                                          loading={loading}
                                          disabled={
                                            mobileFormik.values.mobile
                                              .length !== 12 ||
                                            Boolean(codeSent)
                                          }
                                          variant="contained"
                                          type="submit"
                                          color="warning"
                                          endIcon={<SendIcon />}
                                        >
                                          {codeSent
                                            ? "Verification Code Sent"
                                            : "Send Verification Code"}
                                        </LoadingButton>
                                      </>
                                    ) : (
                                      <>
                                        <LoadingButton
                                          sx={{
                                            mb: mobileSize ? 1 : undefined,
                                          }}
                                          size={
                                            mobileSize ? "small" : undefined
                                          }
                                          loading={loading}
                                          variant="contained"
                                          type="submit"
                                          color="primary"
                                          endIcon={<AssignmentTurnedInIcon />}
                                        >
                                          Complete Verification
                                        </LoadingButton>
                                      </>
                                    )}
                                  </Grid>
                                  <Grid sx={{ mt: 0 }} size={{ xs: 12, sm: 4 }}>
                                    {step === 2 && (
                                      <TextField
                                        size={mobileSize ? "small" : undefined}
                                        error={
                                          !!(
                                            mobileFormik.touched.code &&
                                            mobileFormik.errors.code
                                          )
                                        }
                                        fullWidth
                                        helperText={
                                          mobileFormik.touched.code &&
                                          mobileFormik.errors.code
                                        }
                                        name="code"
                                        value={mobileFormik.values.code}
                                        label="Verification Code"
                                        variant="filled"
                                        onChange={(e) => {
                                          mobileFormik.handleChange(e);
                                          codeErrorText && setCodeErrorText("");
                                        }}
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                                <Typography
                                  variant="body1"
                                  sx={{ minHeight: "1.5rem" }}
                                  align="center"
                                  my={1}
                                  color="error"
                                  fontWeight="bold"
                                >
                                  {codeErrorText}
                                </Typography>
                              </>
                            )}
                          </form>
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Grid>
              {currentUser?.subuser ? (
                <>
                  {currentUser?.subuser?.auth_mobile &&
                    !currentUser?.subuser?.mobile_code && (
                      <Grid size={{ xs: 12 }}>
                        <LoadingButton
                          size={mobileSize ? "small" : "large"}
                          onClick={handleMobileFa}
                          loading={emailLoading}
                          fullWidth
                          sx={{ mt: 0, p: 2 }}
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    )}
                </>
              ) : (
                <>
                  {currentUser?.user?.auth_mobile &&
                    !currentUser?.user?.mobile_code && (
                      <Grid size={{ xs: 12 }}>
                        <LoadingButton
                          onClick={handleMobileFa}
                          loading={emailLoading}
                          fullWidth
                          size={mobileSize ? "small" : "large"}
                          sx={{ mt: 0, p: 2 }}
                          type="submit"
                          variant="contained"
                        >
                          Save
                        </LoadingButton>
                      </Grid>
                    )}
                </>
              )}
            </>
          )}
          {selectedImage === 2 && currentFA !== "totp" && (
            <>
              <Grid size={{ xs: 12 }} align="center" sx={{ mt: 4 }}>
                <Typography
                  variant={mobileSize ? "h6" : "h5"}
                  color="primary"
                  fontWeight="bold"
                >
                  Please input the verification code to select this 2FA
                  Authentication method.
                </Typography>
                <Typography
                  sx={{ mt: mobileSize ? 1 : 2 }}
                  variant="body2"
                  color="primary"
                  fontWeight="bold"
                >
                  If you've misplaced the QR code or are scanning it for the
                  first time, feel free to scan it again.
                </Typography>
              </Grid>
              <Grid size={{ xs: 12 }} align="center" sx={{ mt: 2 }}>
                <Button
                  size={mobileSize ? "small" : undefined}
                  variant="contained"
                  onClick={() => {
                    setShowQr(!showQr);
                  }}
                  startIcon={
                    <>
                      {showQr ? (
                        <VisibilityOff size="small" sx={{ color: "inherit" }} />
                      ) : (
                        <Visibility />
                      )}
                    </>
                  }
                >
                  {showQr ? "Hide QR Code" : "Show QR Code"}
                </Button>
              </Grid>

              {showQr && (
                <Grid size={{ xs: 12 }}>
                  <Box
                    display="flex"
                    justifyContent="center" // Center horizontally
                    alignItems="center" // Center vertically
                    height="100%" // Ensure the box takes the full height
                  >
                    {qrCodeSrc ? (
                      <img
                        width={mobileSize ? 150 : 200}
                        src={qrCodeSrc}
                        alt="QR Code"
                      />
                    ) : (
                      <p>Loading QR Code...</p>
                    )}
                  </Box>
                </Grid>
              )}

              <Grid container>
                <Grid size={{ xs: 12 }} sx={{ mx: "auto" }}>
                  <Box sx={{ display: "flex", ml: "auto", mr: "auto", mt: 2 }}>
                    {processLoad ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                            height: "100%",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <CircularProgress color="warning" size="5rem" />
                        </Box>
                      </>
                    ) : (
                      inputValues.map((value, index) => (
                        <Grid
                          size={{ xs: 12 }}
                          key={index}
                          sx={{ mx: mobileSize ? "2px" : "6px", width: "100%" }}
                        >
                          <TextField
                            size={mobileSize ? "small" : undefined}
                            disabled={blocked}
                            id={`input-${index}`}
                            value={value}
                            variant="outlined"
                            onChange={(e) =>
                              handleInputChange(index, e.target.value)
                            }
                            onPaste={index === 0 ? handlePaste : undefined}
                            inputProps={{
                              maxLength: 1,
                              style: {
                                fontSize: mobileSize ? "20px" : "28px",

                                fontWeight: "bold",
                                width: "100%",
                              },
                            }}
                            sx={{
                              width: mobileSize ? "40px" : "48px",
                              textAlign: "center",
                              border: "3px solid #000",
                              borderRadius: "10px",
                            }}
                          />
                        </Grid>
                      ))
                    )}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Typography
          mt={2}
          variant="body1"
          color="error"
          fontWeight="bold"
          align="center"
        >
          {text}
        </Typography>
      </Card>
    </>
  );
};

export default ChangeFA;
