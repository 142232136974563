import {
  Grid2 as Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import ClearIcon from "@mui/icons-material/Clear"; // Import the ClearIcon

export const Staff = ({ title, staffRows, setManagementData, isSm }) => {
  const currentSession = useSelector((state) => state.sessions.currentSession);
  const [selectedRow, setSelectedRow] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [newCellText, setNewCellText] = useState("");
  const [removeLoading, setRemoveLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [tempToken, setTempToken] = useState({
    email: "",
    token: "",
  });

  useEffect(() => {
    const clearTextInterval = setInterval(() => {
      if (errorText !== "") {
        setErrorText("");
      }
    }, 3000);

    return () => {
      clearInterval(clearTextInterval); // Clear the interval when the component unmounts
    };
  }, [errorText]);

  useEffect(() => {
    if (tempToken?.token && tempToken?.email) {
      // Find the correct staff member in staffRows
      const updatedStaffRows = staffRows.map(
        (staff) =>
          staff.email === tempToken.email
            ? { ...staff, token: tempToken.token } // Update the token if email matches
            : staff // Keep others unchanged
      );

      // Update the management data
      setManagementData((prevData) => ({
        ...prevData,
        admin_registration: updatedStaffRows, // Replace admin_registration with updated list
      }));
      setTempToken({ email: "", token: "" }); // Clear the temp token
    }
  }, [tempToken, staffRows]);

  const handleEdit = async (type) => {
    const dataObj = {
      submitType: type,
      selectedRow: selectedRow,
      changeValue: textFieldValue,
      addValue: newCellText,
    };

    try {
      if (type === "remove") {
        setRemoveLoading(true);
      } else if (type === "edit") {
        setEditLoading(true);
      } else if (type === "add") {
        setAddLoading(true);
      }

      const resp = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/admin/staff-management`,
        {
          dataObj,
          currentSession: currentSession,
        },
        { withCredentials: true }
      );

      const token = resp?.data?.token;

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/nocApi/data/managementData`,
        { currentSession: currentSession },
        { withCredentials: true }
      );

      setManagementData(res.data.data);

      if (token) {
        setTempToken({ email: newCellText, token: token });
      }

      setErrorText(resp.data.message);

      if (type === "remove") {
        setRemoveLoading(false);
      } else if (type === "edit") {
        setEditLoading(false);
      } else if (type === "add") {
        setAddLoading(false);
      }

      setSelectedRow(null);
      setTextFieldValue("");
      setNewCellText("");
    } catch (e) {
      setErrorText(e.response.data.message);
      if (type === "remove") {
        setRemoveLoading(false);
      } else if (type === "edit") {
        setEditLoading(false);
      } else if (type === "add") {
        setAddLoading(false);
      }
    }
  };

  const handleRowClick = (params) => {
    setSelectedRow(params?.row);
    setTextFieldValue(params?.row?.email);
  };

  const renderSelectedValues = (selected) => {
    if (!Array.isArray(selected)) {
      selected = [selected]; // Convert to an array if not already
    }

    if (selected.length === 0) {
      return <em>Select an option</em>;
    }

    // Join the selected items into a string
    const selectedText = selected.join(", ");

    // Define the maximum length you want to display
    const maxLength = 30; // For example, limit to 20 characters

    // Truncate the text if it exceeds the maximum length
    const truncatedText =
      selectedText.length > maxLength
        ? selectedText.substring(0, maxLength) + "..."
        : selectedText;

    return truncatedText;
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const staffColumns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
    },
    {
      field: "is_registered",
      headerName: "Registered",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
      valueGetter: (is_registered) => {
        return is_registered ? "Yes" : "No";
      },
    },
    {
      field: "token",
      headerName: "Token",
      flex: 1,
      width: "100%",
      headerClassName: "management-datagrid-title",
    },
  ];

  return (
    <Card sx={{ minHeight: "35rem" }}>
      <CardHeader title={title} />
      <CardContent>
        <DataGrid
          sx={{
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            minHeight: "24rem",
          }}
          rows={staffRows}
          columns={staffColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[5, 10, 25, 50, 100]}
          onRowClick={handleRowClick}
          classes={{
            cell: "cell", // Apply custom cell class
          }}
        />
        <Grid container spacing={isSm ? 1 : 2} alignItems="center">
          <Grid size={{ xs: 12 }}>
            <Typography sx={{ fontWeight: "bold" }}>
              {selectedRow && (
                <>
                  Selected User -{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {selectedRow.email}
                  </span>
                </>
              )}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 8 }}>
            <TextField
              disabled={selectedRow?.is_registered || !selectedRow}
              label="Edit/Remove Admin user"
              variant="filled"
              fullWidth
              value={textFieldValue || ""}
              onChange={(e) => setTextFieldValue(e.target.value)}
              InputProps={{
                endAdornment: selectedRow ? (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={
                        !selectedRow ||
                        removeLoading ||
                        addLoading ||
                        editLoading
                      }
                      onClick={() => {
                        setTextFieldValue("");
                        setSelectedRow(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <LoadingButton
              loading={editLoading}
              disabled={
                !selectedRow ||
                !textFieldValue ||
                textFieldValue === selectedRow?.email ||
                removeLoading ||
                addLoading
              }
              fullWidth
              variant="contained"
              color="warning"
              onClick={() => {
                handleEdit("edit");
              }}
            >
              Edit
            </LoadingButton>
          </Grid>
          <Grid size={{ xs: 12, md: 2 }}>
            <LoadingButton
              loading={removeLoading}
              disabled={!selectedRow || editLoading || addLoading}
              fullWidth
              variant="contained"
              color="error"
              onClick={() => {
                handleEdit("remove");
              }}
            >
              Remove
            </LoadingButton>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <TextField
              disabled={addLoading}
              label={"Enter New Admin Email"}
              variant="filled"
              fullWidth
              value={newCellText || ""}
              onChange={(e) => setNewCellText(e.target.value)}
            />
          </Grid>
          <Grid isize={{ xs: 12, md: 2 }}>
            <LoadingButton
              loading={addLoading}
              disabled={!newCellText || removeLoading || editLoading}
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => {
                handleEdit("add");
              }}
            >
              Add New
            </LoadingButton>
          </Grid>
        </Grid>
        <Typography
          variant="body1"
          color="primary"
          fontWeight="bold"
          textAlign="center"
          sx={{ mt: "1rem", minHeight: "1.5rem" }}
        >
          {errorText}
        </Typography>
      </CardContent>
    </Card>
  );
};
