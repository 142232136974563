import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Button,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserPackageForm,
  fetchUserPkgFileBase64,
  updateNewFileName,
} from "../../store/actions/userPackagesActions";
import Magnifier from "../customComponents/Magnifier";
import RenderedPackageFormFields from "./RenderedPackageFormFields";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteFormModal from "./modals/DeleteFormModal";
import { currentProcessingFileBase } from "../../data/userPackage/userPackageBase";

const steps = ["Document Overview", "Document Details"];

const SelectedPackage = ({
  currentProcessingFile,
  setCurrentProcessingFile,
  selectedPackage,
  setSelectedPackage,
  setAddingNewPackage,
  setPackagePanelOpen,
  selectedPackageForm,
  setSelectedPackageForm,
  savedPackagesActive,
  currentFormsActive,
}) => {
  const dispatch = useDispatch();
  const userPackages = useSelector((state) => state.userPackages.userPackages);
  const [activeStep, setActiveStep] = useState(0);
  const [loadDocumentLoading, setLoadDocumentLoading] = useState(false);
  const [updatingFileName, setUpdatingFileName] = useState(null);
  const [newFileName, setNewFileName] = useState("");
  const [deleteFormModalOpen, setDeleteFormModalOpen] = useState(false);
  const [deleteFormLoading, setDeleteFormLoading] = useState(false);

  useEffect(() => {
    if (activeStep !== 0) {
      setActiveStep(0);
    }
  }, [selectedPackage?.packageID]);

  const generateRandomName = () => {
    const timestamp = Date.now().toString(); // Get current timestamp as string
    const lastFour = timestamp.slice(-4); // Extract last 4 digits
    return `document_package_form_${lastFour}`;
  };

  const sanitizeFilename = (input) => {
    return input
      .replace(/\s+/g, "_") // ✅ Replace spaces with underscores immediately
      .replace(/[^a-zA-Z0-9._-]/g, "") // ✅ Keep only safe characters
      .replace(/^\.+|\.+$/g, "") // ✅ Remove leading/trailing dots
      .replace(/_{2,}/g, "_") // ✅ Collapse multiple underscores
      .replace(/-{2,}/g, "-"); // ✅ Collapse multiple dashes
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setPackagePanelOpen(false);
      setAddingNewPackage(false);
    }

    setActiveStep((prevStep) => prevStep + 1);
  };
  const handleBack = () => {
    if (activeStep === 0) {
      setAddingNewPackage(false);
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleLoadDocument = async () => {
    try {
      if (
        (selectedPackage && selectedPackage?.documents?.length === 0) ||
        (selectedPackageForm && !selectedPackageForm?.formID) ||
        !selectedPackageForm
      ) {
        return;
      }
      setLoadDocumentLoading(true);
      let base64PDF = null;
      let extractedFields = [];

      const selectedPackageCopy = { ...selectedPackage };
      delete selectedPackageCopy.documents;

      const formattedData = {
        ...selectedPackageCopy,
        document: selectedPackageForm,
      };

      if (!formattedData?.document?.tempFields?.currentDocument?.base64File) {
        const fieldResp = await dispatch(
          fetchUserPkgFileBase64(
            formattedData.document.pdf_url,
            formattedData.packageID,
            formattedData.document.formID
          )
        );

        base64PDF = fieldResp.base64;
        extractedFields = fieldResp.extractedFields;
      } else {
        base64PDF =
          formattedData.document.tempFields.currentDocument.base64File;
      }

      /// ✅ Ensure formattedData is up-to-date before updating state
      const updatedFormattedData = {
        ...formattedData,
        document: {
          ...formattedData.document,
          tempFields: {
            ...formattedData.document.tempFields,
            formFields:
              Array.isArray(extractedFields) && extractedFields.length > 0
                ? extractedFields
                : formattedData.document.tempFields?.formFields || [],
            currentDocument: {
              ...formattedData.document.tempFields.currentDocument,
              base64File: base64PDF,
            },
          },
        },
      };

      const updatedPackageData = {
        ...selectedPackage, // ✅ Keep all package-level data the same
        documents: selectedPackage.documents.map((doc) =>
          doc.formID === formattedData.document.formID
            ? {
                ...doc,
                tempFields: {
                  ...doc.tempFields,
                  formFields:
                    Array.isArray(extractedFields) && extractedFields.length > 0
                      ? extractedFields
                      : doc.tempFields?.formFields || [],
                  currentDocument: {
                    ...doc.tempFields.currentDocument,
                    base64File: base64PDF, // ✅ Preserve existing base64 if not new
                  },
                },
              } // ✅ Only update the matching document
            : doc
        ),
      };
      delete updatedPackageData.document;

      const updatedSelectedForm = updatedPackageData.documents.find(
        (doc) => doc.formID === formattedData.document.formID
      );

      await setCurrentProcessingFile(updatedFormattedData);
      await setSelectedPackage(updatedPackageData);

      await setSelectedPackageForm(updatedSelectedForm);
      setPackagePanelOpen(false);
    } catch (error) {
      console.error("Error loading document:", error);
    } finally {
      setLoadDocumentLoading(false);
    }
  };

  const handleDeleteDocument = async () => {
    try {
      if (
        currentFormsActive &&
        selectedPackage &&
        selectedPackage?.documents?.length === 0
      ) {
        return;
      }
      if (
        savedPackagesActive &&
        Array.isArray(selectedPackage?.documents) &&
        selectedPackage?.documents.length > 0
      ) {
        return;
      }

      setDeleteFormLoading(true);
      const resp = await dispatch(
        deleteUserPackageForm(
          selectedPackage?.packageID,
          selectedPackageForm?.formID,
          savedPackagesActive
        )
      );

      if (resp === true) {
        if (savedPackagesActive) {
          setCurrentProcessingFile(currentProcessingFileBase);

          if (userPackages?.length > 0) {
            setSelectedPackage(userPackages[0]);
            if (userPackages[0]?.documents?.length > 0) {
              setSelectedPackageForm(userPackages[0]?.documents[0]);
            }
          } else {
            setSelectedPackage(null);
          }
        } else {
          const updatedPackageData = {
            ...selectedPackage,
            documents: selectedPackage.documents.filter(
              (doc) => doc.formID !== selectedPackageForm?.formID
            ),
          };

          const updatedSelectedForm =
            updatedPackageData.documents.length > 0
              ? updatedPackageData?.documents[0]
              : currentProcessingFileBase?.document;

          await setCurrentProcessingFile((prevFile) => ({
            ...prevFile,
            document: updatedSelectedForm,
          }));
          await setSelectedPackage(updatedPackageData);
          await setSelectedPackageForm(updatedSelectedForm);
        }
        setDeleteFormModalOpen(false);
      }
    } catch (error) {
    } finally {
      setDeleteFormLoading(false);
    }
  };

  return (
    <>
      {/* Fixed Header with Stepper */}
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          bgcolor: "white",
          p: 2,
          borderBottom: "3px solid #e0e0e0",
        }}
      >
        <Typography mb={0} align="center" variant="h4" color="primary">
          {selectedPackage?.packageName}
        </Typography>
        <Box
          onClick={() => {
            if (updatingFileName === selectedPackageForm?.formID) {
              return;
            }
            setUpdatingFileName(selectedPackageForm?.formID);
            setNewFileName(selectedPackageForm?.cleaned_file_name);
          }}
        >
          {updatingFileName === selectedPackageForm?.formID ? (
            <input
              type="text"
              value={newFileName}
              onChange={(e) => {
                const inputElement = e.target;
                const cursorPosition = inputElement.selectionStart;

                const sanitized = sanitizeFilename(e.target.value);
                setNewFileName(sanitized);

                requestAnimationFrame(() => {
                  inputElement.setSelectionRange(
                    cursorPosition,
                    cursorPosition
                  );
                });
              }}
              autoFocus
              onBlur={() => {
                if (
                  newFileName.trim().replace(/^[_\s]+|[_\s]+$/g, "") !==
                  selectedPackageForm?.cleaned_file_name
                ) {
                  let randomName = null;

                  if (!newFileName.trim().replace(/^[_\s]+|[_\s]+$/g, "")) {
                    randomName = generateRandomName();
                  } else {
                    randomName = newFileName
                      .trim()
                      .replace(/^[_\s]+|[_\s]+$/g, "");
                  }

                  dispatch(
                    updateNewFileName(
                      selectedPackage?.packageID,
                      selectedPackageForm?.formID,
                      randomName
                    )
                  );

                  setCurrentProcessingFile((prevFile) => ({
                    ...prevFile,
                    document: {
                      ...prevFile.document,
                      cleaned_file_name: randomName,
                    },
                  }));
                  setSelectedPackage((prevPackage) => ({
                    ...prevPackage,
                    documents: [
                      ...prevPackage.documents.map((item) =>
                        item.formID === selectedPackageForm.formID
                          ? {
                              ...item,
                              cleaned_file_name: randomName,
                            }
                          : item
                      ),
                    ],
                  }));
                  setSelectedPackageForm((prevForm) => ({
                    ...prevForm,
                    cleaned_file_name: randomName,
                  }));
                  setNewFileName(null);
                }
                setUpdatingFileName(null);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setUpdatingFileName(null);
                }
              }}
              style={{
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                fontSize: "0.875rem",
                fontWeight: "bold",
                lineHeight: "1.5",
                color: "#004976",
                border: "none",
                background: "transparent",
                textAlign: "center",
                width: "100%",
                padding: "0",
                marginBottom: "9px",
                outline: "none",
                cursor: "text",
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                MsUserSelect: "none",
              }}
            />
          ) : (
            <Typography
              mb={1}
              align="center"
              variant="body2"
              fontWeight="bold"
              color="primary"
              sx={{
                userSelect: "none",
                WebkitUserSelect: "none",
                MozUserSelect: "none",
                MsUserSelect: "none",
                cursor: "pointer",
              }}
            >
              {selectedPackageForm?.cleaned_file_name}
            </Typography>
          )}
        </Box>

        <Box sx={{ position: "absolute", right: 0, top: 0 }}>
          <IconButton
            disabled={selectedPackageForm?.locked}
            size="small"
            onClick={() => {
              setPackagePanelOpen(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {/* MUI Stepper */}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      {/* Scrollable Content */}
      <Box sx={{ flexGrow: 1, overflowY: "auto", px: 2, py: 2 }}>
        {activeStep === 0 && (
          <>
            {selectedPackageForm?.img_urls?.map((img, index) => (
              <Magnifier key={index} src={img} lensSize={400} zoomFactor={4} />
            ))}
          </>
        )}
        {activeStep === 1 && (
          <RenderedPackageFormFields
            selectedPackage={selectedPackage}
            setSelectedPackage={setSelectedPackage}
            selectedPackageForm={selectedPackageForm}
            setSelectedPackageForm={setSelectedPackageForm}
            currentProcessingFile={currentProcessingFile}
            setCurrentProcessingFile={setCurrentProcessingFile}
          />
        )}
      </Box>

      {/* Fixed Back-Next Buttons at the Bottom */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 2,
          bgcolor: "white",
          p: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ position: "absolute", left: 0 }}>
          <IconButton
            onClick={() => {
              if (
                currentFormsActive &&
                selectedPackage &&
                selectedPackage?.documents?.length === 0
              ) {
                return;
              }
              setDeleteFormModalOpen(true);
            }}
            disabled={
              selectedPackageForm?.locked ||
              (savedPackagesActive &&
                Array.isArray(selectedPackage?.documents) &&
                selectedPackage?.documents.length > 0) ||
              (currentFormsActive &&
                selectedPackage &&
                selectedPackage?.documents?.length === 0)
            }
            sx={{
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.1)", // ✅ Light red hover effect
              },
            }}
          >
            <DeleteIcon
              sx={{
                color:
                  selectedPackageForm?.locked ||
                  (savedPackagesActive &&
                    Array.isArray(selectedPackage?.documents) &&
                    selectedPackage?.documents.length > 0) ||
                  (currentFormsActive &&
                    selectedPackage &&
                    selectedPackage?.documents?.length === 0)
                    ? undefined
                    : "red",
              }}
            />
          </IconButton>
        </Box>
        <IconButton disabled={activeStep === 0} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>

        <IconButton
          disabled={
            activeStep === steps.length - 1 ||
            !selectedPackageForm ||
            !selectedPackageForm?.formID
          }
          onClick={handleNext}
        >
          <ArrowForwardIcon />
        </IconButton>
        <Box sx={{ position: "absolute", right: 0 }}>
          <LoadingButton
            disabled={
              selectedPackageForm?.locked ||
              (selectedPackage && selectedPackage?.documents?.length === 0) ||
              (selectedPackageForm && !selectedPackageForm?.formID) ||
              !selectedPackageForm
            }
            loading={loadDocumentLoading}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<InsertDriveFileIcon />}
            onClick={handleLoadDocument}
          >
            Load Document
          </LoadingButton>
        </Box>
        <DeleteFormModal
          open={deleteFormModalOpen}
          onClose={() => {
            if (!deleteFormLoading) {
              setDeleteFormModalOpen(false);
            }
          }}
          onConfirm={handleDeleteDocument}
          documentName={selectedPackageForm?.document_name}
          packageName={selectedPackage?.packageName}
          deleteFormLoading={deleteFormLoading}
          savedPackagesActive={savedPackagesActive}
        />
      </Box>
    </>
  );
};

export default SelectedPackage;
