export const handleDynamicFontResize = (
  width,
  height,
  fontSize,
  newValue,
  savedFontSizes,
  oldValue
) => {
  // Convert field width & font size from pt to px
  const fieldWidthPx = width * 1.3333;
  const fieldHeightPx = height * 1.3333;
  const maxFontSize = fieldHeightPx;

  let newFontSize = fontSize || maxFontSize;

  // 🖌️ Use Canvas API for precise text width measurement
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Ensure font matches the input's styling
  ctx.font = `${newFontSize}px Arial`;

  const textWidth = ctx.measureText(newValue).width * 0.86;
  const textHeight = newFontSize;

  // Ensure `fontSizes` is properly copied before modifying

  let fontSizes = savedFontSizes?.length > 0 ? [...savedFontSizes] : [];

  // 🔄 Shrinking Logic (More Accurate)
  if (textWidth > fieldWidthPx || textHeight > fieldHeightPx) {
    fontSizes.push(newFontSize); // Save before shrinking

    // Shrink based on the width and height of the field
    const widthRatio = fieldWidthPx / textWidth;
    const heightRatio = fieldHeightPx / textHeight;

    // Take the smaller of the two ratios to ensure both dimensions fit
    const minRatio = Math.min(widthRatio, heightRatio);

    newFontSize = Math.max(6, newFontSize * minRatio);
  }
  // 🔄 Growing Logic (Restore Font Properly)
  else if (newValue?.length < oldValue?.length && fontSizes?.length > 0) {
    newFontSize = fontSizes.pop(); // Restore last saved font size
  } else if (
    textWidth < fieldWidthPx &&
    textHeight < fieldHeightPx &&
    newFontSize < maxFontSize
  ) {
    // Only grow if there's space & we're not at max font size
    newFontSize = Math.min(maxFontSize, newFontSize + 0.4);
  }

  return { newFontSize, fontSizes };
};
