export const occupancy_classifications = [
  {
    name: "Restaurant",
    code: "A-2",
  },
  {
    name: "Amusement, Recreational/Church, Other Religious",
    code: "A-3",
  },
  {
    name: "Business Condo/Office, Bank, Professional",
    code: "B",
  },
  {
    name: "School, Library, Educational",
    code: "E",
  },
  {
    name: "Industrial",
    code: "F-1",
  },
  {
    name: "Hospital, Institutional",
    code: "I-2",
  },
  {
    name: "Daycare",
    code: "I-4",
  },
  {
    name: "Stores, Mercantile",
    code: "M",
  },
  {
    name: "Hotel, Motel, Dormitory",
    code: "R-1",
  },
  {
    name: "Apartments/Condominiums/Duplex/3 or 4 Families",
    code: "R-2",
  },
  {
    name: "Single Family/Townhouse",
    code: "R-3",
  },
  {
    name: "Service Station, Repair Garage",
    code: "S-1",
  },
  {
    name: "Parking Garage",
    code: "S-2",
  },
  {
    name: "Utilities/Garage/Other",
    code: "U",
  },
];

export const construction_type = [
  {
    name: "Wood frame, non-fire resistive",
    code: "V-B",
  },
  {
    name: "Wood frame, fire resistive",
    code: "V-A",
  },
  {
    name: "Non-combustible exterior, any interior",
    code: "III-B",
  },
  {
    name: "Non-combustible, non-fire resistive",
    code: "II-B",
  },
  {
    name: "Non-combustible, fire resistive",
    code: "I-B",
  },
];

export const occupancy_load_factors = [
  {
    boldText: "A-2",
    name: "Restaurant",
    code: 15,
  },
  {
    boldText: "A-3",
    name: "Amusement, Recreational/Church, Other Religious",
    code: 15,
  },
  {
    boldText: "B",
    name: "Business Condo/Office, Bank, Professional",
    code: 100,
  },
  {
    boldText: "E",
    name: "School, Library, Educational",
    code: 20,
  },
  {
    boldText: "F-1",
    name: "Industrial",
    code: 100,
  },
  {
    boldText: "I-2",
    name: "Hospital, Institutional",
    code: 240,
  },
  {
    boldText: "I-4",
    name: "Daycare",
    code: 35,
  },
  {
    boldText: "M",
    name: "Stores, Mercantile",
    code: 30,
  },
  {
    boldText: "R-1",
    name: "Hotel, Motel, Dormitory",
    code: 200,
  },
  {
    boldText: "R-2",
    name: "Apartments/Condominiums/Duplex/3 or 4 Families",
    code: 200,
  },
  {
    boldText: "R-3",
    name: "Single Family/Townhouse",
    code: 200,
  },
  {
    boldText: "S-1",
    name: "Service Station, Repair Garage",
    code: 200,
  },
  {
    boldText: "S-2",
    name: "Parking Garage",
    code: 200,
  },
  {
    boldText: "U",
    name: "Utilities/Garage/Other",
    code: 300,
  },
];

export const live_load_factors = [
  {
    boldText: "A-2",
    name: "Restaurant",
    code: 100,
  },
  {
    boldText: "A-3",
    name: "Amusement, Recreational/Church, Other Religious",
    code: 100,
  },
  {
    boldText: "B",
    name: "Business Condo/Office, Bank, Professional",
    code: 50,
  },
  {
    boldText: "E",
    name: "School, Library, Educational",
    code: 50,
  },
  {
    boldText: "F-1",
    name: "Industrial",
    code: 125,
  },
  {
    boldText: "I-2",
    name: "Hospital, Institutional",
    code: 60,
  },
  {
    boldText: "I-4",
    name: "Daycare",
    code: 50,
  },
  {
    boldText: "M",
    name: "Stores, Mercantile",
    code: 60,
  },
  {
    boldText: "R-1",
    name: "Hotel, Motel, Dormitory",
    code: 40,
  },
  {
    boldText: "R-2",
    name: "Apartments/Condominiums/Duplex/3 or 4 Families",
    code: 40,
  },
  {
    boldText: "R-3",
    name: "Single Family/Townhouse",
    code: 40,
  },
  {
    boldText: "S-1",
    name: "Service Station, Repair Garage",
    code: 125,
  },
  {
    boldText: "S-2",
    name: "Parking Garage",
    code: 100,
  },
  {
    boldText: "U",
    name: "Utilities/Garage/Other",
    code: 20,
  },
];

export const fieldMapping = {
  design_pro_company: "companyName",
  design_pro_license_name: "licenseName",
  design_pro_license_num: "licenseNumber",
  design_pro_address: "address",
  design_pro_phone: "phone",
  design_pro_fax: "fax",
  design_pro_email: "email",
};

export const customLabels = {
  design_pro_company: "Company Name*",
  design_pro_license_name: "License Name*",
  design_pro_license_num: "License Number*",
  design_pro_address: "Address*",
  design_pro_phone: "Phone*",
  design_pro_fax: "Fax",
  design_pro_email: "Email*",
};

export const fieldsToCheck = [
  "design_pro_company",
  "design_pro_address",
  "design_pro_email",
  "design_pro_fax",
  "design_pro_license_name",
  "design_pro_license_num",
  "design_pro_phone",
];

export const fieldsToCheckNoFax = [
  "design_pro_company",
  "design_pro_address",
  "design_pro_email",
  "design_pro_license_name",
  "design_pro_license_num",
  "design_pro_phone",
];

export const fieldsToCheckProject = ["project_contact", "project_c_phone"];
export const fieldsToCheckFeeSimple = ["fee_simple_name", "fee_simple_address"];
export const fieldsToCheckBonding = ["bonding_name", "bonding_address"];
export const fieldsToCheckMortgage = ["mortgage_name", "mortgage_address"];

export const projectLabel = {
  project_contact: "Project Contact Name",
  project_c_phone: "Project Contact Phone",
};
export const feeSimpleLabel = {
  fee_simple_name: "Name",
  fee_simple_address: "Address",
};
export const bondingLabel = {
  bonding_name: "Name",
  bonding_address: "Address",
};
export const mortgageLabel = {
  mortgage_name: "Name",
  mortgage_address: "Address",
};

export const zones_list = [
  "fenestration",
  "skyligh",
  "glazed",
  "ceiling",
  "wood_frame",
  "mass_wall",
  "floor",
  "basement_wall",
  "slab",
  "crawl",
];

export const climateZones = [
  {
    field: "fenestration",
    climate_zone_1: "NR",
    climate_zone_2: "0.40",
    fail: "more",
  },
  {
    field: "skyligh",
    climate_zone_1: "0.75",
    climate_zone_2: "0.65",
    fail: "more",
  },
  {
    field: "glazed",
    climate_zone_1: "0.25",
    climate_zone_2: "0.25",
    fail: "more",
  },
  {
    field: "ceiling",
    climate_zone_1: "30",
    climate_zone_2: "38",
    fail: "less",
  },
  {
    field: "wood_frame",
    climate_zone_1: "13",
    climate_zone_2: "13",
    fail: "less",
  },
  {
    field: "mass_wall",
    climate_zone_1: "3/4",
    climate_zone_2: "4/6",
    fail: "less",
  },
  {
    field: "floor",
    climate_zone_1: "13",
    climate_zone_2: "13",
    fail: "less",
  },
  {
    field: "basement_wall",
    climate_zone_1: "0",
    climate_zone_2: "0",
    fail: "less",
  },
  {
    field: "slab",
    climate_zone_1: "0",
    climate_zone_2: "0",
    fail: "less",
  },
  {
    field: "crawl",
    climate_zone_1: "0",
    climate_zone_2: "0",
    fail: "less",
  },
];

export const ductValues = [
  {
    field: "a_3",
    minValue: 8,
    maxValue: 999999999999,
  },
  {
    field: "a_4",
    minValue: 6,
    maxValue: 8,
  },
  {
    field: "a_5",
    minValue: 6,
    maxValue: 8,
  },
  {
    field: "a_6",
    minValue: 4.2,
    maxValue: 6,
  },
  {
    field: "a_8",
    installed: 4,
    notInstalled: 3,
  },
];

export const energyDefList = ["b_1", "b_2", "b_3", "c_1", "c_2", "c_3"];

export const energyDefValues = [
  {
    field: "b_3",
    value: 14.3,
  },
  {
    field: "c_1",
    value: 7.5,
  },
  {
    field: "c_2",
    value: 80,
  },
  {
    field: "c_3",
    value: 83,
  },
  {
    field: "d_2",
    values: [
      { minGallon: 40, maxGallon: 50, value: 0.931 },
      { minGallon: 50, maxGallon: 60, value: 0.93 },
      { minGallon: 60, maxGallon: 9999999, value: 2.176 },
    ],
  },
  {
    field: "d_3",
    values: [
      { minGallon: 40, maxGallon: 50, value: 0.64 },
      { minGallon: 50, maxGallon: 60, value: 0.627 },
      { minGallon: 60, maxGallon: 9999999, value: 0.789 },
    ],
  },
];

export const centralSystemCapacity = [
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
  },
  {
    value: 12000,
    ton: 1,
    label: "12,000 btu/h (1 ton)",
  },
  {
    value: 18000,
    ton: 1.5,
    label: "18,000 btu/h (1.5 tons)",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2 tons)",
  },
  {
    value: 30000,
    ton: 2.5,
    label: "30,000 btu/h (2.5 tons)",
  },
  {
    value: 36000,
    ton: 3,
    label: "36,000 btu/h (3 tons)",
  },
  {
    value: 42000,
    ton: 3.5,
    label: "42,000 btu/h (3.5 tons)",
  },
  {
    value: 48000,
    ton: 4,
    label: "48,000 btu/h (4 tons)",
  },
  {
    value: 54000,
    ton: 4.5,
    label: "54,000 btu/h (4.5 tons)",
  },
  {
    value: 60000,
    ton: 5,
    label: "60,000 btu/h (5 tons)",
  },
];

export const airConPTAC_std = [
  {
    value: 1000,
    ton: 0.08,
    label: "1,000 btu/h (0.08 ton)",
    eer: "11.9",
  },
  {
    value: 3000,
    ton: 0.25,
    label: "3,000 btu/h (0.25 ton)",
    eer: "11.9",
  },
  {
    value: 5000,
    ton: 0.42,
    label: "5,000 btu/h (0.42 ton)",
    eer: "11.9",
  },
  {
    value: 7000,
    ton: 0.58,
    label: "7,000 btu/h (0.58 ton)",
    eer: "11.9",
  },
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "11.3",
  },
  {
    value: 11000,
    ton: 0.92,
    label: "11,000 btu/h (0.92 ton)",
    eer: "10.7",
  },
  {
    value: 13000,
    ton: 1.08,
    label: "13,000 btu/h (1.08 tons)",
    eer: "10.1",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "9.5",
  },
  {
    value: 17000,
    ton: 1.42,
    label: "17,000 btu/h (1.42 tons)",
    eer: "9.5",
  },
  {
    value: 19000,
    ton: 1.58,
    label: "19,000 btu/h (1.58 tons)",
    eer: "9.5",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "9.5",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2.00 tons)",
    eer: "9.5",
  },
];

export const airConPTAC_nonStd = [
  {
    value: 1000,
    ton: 0.08,
    label: "1,000 btu/h (0.08 ton)",
    eer: "9.4",
  },
  {
    value: 3000,
    ton: 0.25,
    label: "3,000 btu/h (0.25 ton)",
    eer: "9.4",
  },
  {
    value: 5000,
    ton: 0.42,
    label: "5,000 btu/h (0.42 ton)",
    eer: "9.4",
  },
  {
    value: 7000,
    ton: 0.58,
    label: "7,000 btu/h (0.58 ton)",
    eer: "9.4",
  },
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "9.0",
  },
  {
    value: 11000,
    ton: 0.92,
    label: "11,000 btu/h (0.92 ton)",
    eer: "8.5",
  },
  {
    value: 13000,
    ton: 1.08,
    label: "13,000 btu/h (1.08 tons)",
    eer: "8.1",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "7.7",
  },
  {
    value: 17000,
    ton: 1.42,
    label: "17,000 btu/h (1.42 tons)",
    eer: "7.7",
  },
  {
    value: 19000,
    ton: 1.58,
    label: "19,000 btu/h (1.58 tons)",
    eer: "7.7",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "7.7",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2.00 tons)",
    eer: "7.7",
  },
];

export const pthpCoolingNonStd = [
  {
    value: 1000,
    ton: 0.08,
    label: "1,000 btu/h (0.08 ton)",
    eer: "9.3",
  },
  {
    value: 3000,
    ton: 0.25,
    label: "3,000 btu/h (0.25 ton)",
    eer: "9.3",
  },
  {
    value: 5000,
    ton: 0.42,
    label: "5,000 btu/h (0.42 ton)",
    eer: "9.3",
  },
  {
    value: 7000,
    ton: 0.58,
    label: "7,000 btu/h (0.58 ton)",
    eer: "9.3",
  },
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "8.9",
  },
  {
    value: 11000,
    ton: 0.92,
    label: "11,000 btu/h (0.92 ton)",
    eer: "8.5",
  },
  {
    value: 13000,
    ton: 1.08,
    label: "13,000 btu/h (1.08 tons)",
    eer: "8.0",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "7.6",
  },
  {
    value: 17000,
    ton: 1.42,
    label: "17,000 btu/h (1.42 tons)",
    eer: "7.6",
  },
  {
    value: 19000,
    ton: 1.58,
    label: "19,000 btu/h (1.58 tons)",
    eer: "7.6",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "7.6",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2.00 tons)",
    eer: "7.6",
  },
];

export const spvacCapacity = [
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: 11.0,
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: 11.0,
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: 11.0,
  },
  {
    value: 27000,
    ton: 2.25,
    label: "27,000 btu/h (2.25 tons)",
    eer: 11.0,
  },
  {
    value: 33000,
    ton: 2.75,
    label: "33,000 btu/h (2.75 tons)",
    eer: 11.0,
  },
  {
    value: 39000,
    ton: 3.25,
    label: "39,000 btu/h (3.25 tons)",
    eer: 11.0,
  },
  {
    value: 45000,
    ton: 3.75,
    label: "45,000 btu/h (3.75 tons)",
    eer: 11.0,
  },
  {
    value: 51000,
    ton: 4.25,
    label: "51,000 btu/h (4.25 tons)",
    eer: 11.0,
  },
  {
    value: 57000,
    ton: 4.75,
    label: "57,000 btu/h (4.75 tons)",
    eer: 11.0,
  },
  {
    value: 65000,
    ton: 5.42,
    label: "65,000 btu/h (5.42 tons)",
    eer: 10.0,
  },
  {
    value: 80000,
    ton: 6.67,
    label: "80,000 btu/h (6.67 tons)",
    eer: 10.0,
  },
  {
    value: 120000,
    ton: 10.0,
    label: "120,000 btu/h (10.00 tons)",
    eer: 10.0,
  },
  {
    value: 180000,
    ton: 15.0,
    label: "180,000 btu/h (15.00 tons)",
    eer: 10.0,
  },
  {
    value: 220000,
    ton: 18.33,
    label: "220,000 btu/h (18.33 tons)",
    eer: 10.0,
  },
];

export const pthpHeatingStd = [
  {
    value: 1000,
    ton: 0.08,
    label: "1,000 btu/h (0.08 ton)",
    eer: "3.3",
  },
  {
    value: 3000,
    ton: 0.25,
    label: "3,000 btu/h (0.25 ton)",
    eer: "3.3",
  },
  {
    value: 5000,
    ton: 0.42,
    label: "5,000 btu/h (0.42 ton)",
    eer: "3.3",
  },
  {
    value: 7000,
    ton: 0.58,
    label: "7,000 btu/h (0.58 ton)",
    eer: "3.3",
  },
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "3.2",
  },
  {
    value: 11000,
    ton: 0.92,
    label: "11,000 btu/h (0.92 ton)",
    eer: "3.1",
  },
  {
    value: 13000,
    ton: 1.08,
    label: "13,000 btu/h (1.08 tons)",
    eer: "3.0",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "2.9",
  },
  {
    value: 17000,
    ton: 1.42,
    label: "17,000 btu/h (1.42 tons)",
    eer: "2.9",
  },
  {
    value: 19000,
    ton: 1.58,
    label: "19,000 btu/h (1.58 tons)",
    eer: "2.9",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "2.9",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2.00 tons)",
    eer: "2.9",
  },
];

export const pthpHeatingNonStd = [
  {
    value: 1000,
    ton: 0.08,
    label: "1,000 btu/h (0.08 ton)",
    eer: "2.7",
  },
  {
    value: 3000,
    ton: 0.25,
    label: "3,000 btu/h (0.25 ton)",
    eer: "2.7",
  },
  {
    value: 5000,
    ton: 0.42,
    label: "5,000 btu/h (0.42 ton)",
    eer: "2.7",
  },
  {
    value: 7000,
    ton: 0.58,
    label: "7,000 btu/h (0.58 ton)",
    eer: "2.7",
  },
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "2.7",
  },
  {
    value: 11000,
    ton: 0.92,
    label: "11,000 btu/h (0.92 ton)",
    eer: "2.6",
  },
  {
    value: 13000,
    ton: 1.08,
    label: "13,000 btu/h (1.08 tons)",
    eer: "2.6",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "2.5",
  },
  {
    value: 17000,
    ton: 1.42,
    label: "17000 btu/h (1.42 tons)",
    eer: "2.5",
  },
  {
    value: 19000,
    ton: 1.58,
    label: "19,000 btu/h (1.58 tons)",
    eer: "2.5",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "2.5",
  },
  {
    value: 24000,
    ton: 2,
    label: "24,000 btu/h (2.00 tons)",
    eer: "2.5",
  },
];

export const spvhpHeating = [
  {
    value: 9000,
    ton: 0.75,
    label: "9,000 btu/h (0.75 ton)",
    eer: "3.3",
  },
  {
    value: 15000,
    ton: 1.25,
    label: "15,000 btu/h (1.25 tons)",
    eer: "3.3",
  },
  {
    value: 21000,
    ton: 1.75,
    label: "21,000 btu/h (1.75 tons)",
    eer: "3.3",
  },
  {
    value: 27000,
    ton: 2.25,
    label: "27,000 btu/h (2.25 tons)",
    eer: "3.3",
  },
  {
    value: 33000,
    ton: 2.75,
    label: "33,000 btu/h (2.75 tons)",
    eer: "3.3",
  },
  {
    value: 39000,
    ton: 3.25,
    label: "39,000 btu/h (3.25 tons)",
    eer: "3.3",
  },
  {
    value: 45000,
    ton: 3.75,
    label: "45,000 btu/h (3.75 tons)",
    eer: "3.3",
  },
  {
    value: 51000,
    ton: 4.25,
    label: "51,000 btu/h (4.25 tons)",
    eer: "3.3",
  },
  {
    value: 57000,
    ton: 4.75,
    label: "57,000 btu/h (4.75 tons)",
    eer: "3.3",
  },
  {
    value: 65000,
    ton: 5.42,
    label: "65,000 btu/h (5.42 tons)",
    eer: "3.0",
  },
  {
    value: 80000,
    ton: 6.67,
    label: "80,000 btu/h (6.67 tons)",
    eer: "3.0",
  },
  {
    value: 120000,
    ton: 10.0,
    label: "120,000 btu/h (10.00 tons)",
    eer: "3.0",
  },
  {
    value: 180000,
    ton: 15.0,
    label: "180,000 btu/h (15.00 tons)",
    eer: "3.0",
  },
  {
    value: 220000,
    ton: 18.33,
    label: "220,000 btu/h (18.33 tons)",
    eer: "3.0",
  },
];

export const capacityMapping = {
  b_1: centralSystemCapacity,
  b_5: {
    std: airConPTAC_std,
    non_std: airConPTAC_nonStd,
    pthp_std: airConPTAC_std,
    pthp_non_std: pthpCoolingNonStd,
    spvac: spvacCapacity,
    spvhp: spvacCapacity,
  },
  c_5: {
    pthp_heating_std: pthpHeatingStd,
    pthp_heating_no_std: pthpHeatingNonStd,
    spvhp_heating: spvhpHeating,
  },
};

export const climateZone1 = [
  "Broward",
  "Charlotte",
  "Collier",
  "Glades",
  "Hendry",
  "Lee",
  "Miami-Dade",
  "Monroe",
  "Palm Beach",
];

export const climateZone2 = [
  "Alachua",
  "Baker",
  "Bay",
  "Bradford",
  "Brevard",
  "Calhoun",
  "Citrus",
  "Clay",
  "Columbia",
  "Dixie",
  "Duval",
  "Escambia",
  "Flagler",
  "Franklin",
  "Gadsden",
  "Gilchrist",
  "Gulf",
  "Hamilton",
  "Hardee",
  "Hernando",
  "Highlands",
  "Holmes",
  "Indian River",
  "Jackson",
  "Jefferson",
  "Lafayette",
  "Lake",
  "Levy",
  "Liberty",
  "Madison",
  "Manatee",
  "Marion",
  "Martin",
  "Nassau",
  "Okaloosa",
  "Okeechobee",
  "Orange",
  "Osceola",
  "Pasco",
  "Pinellas",
  "Polk",
  "Putnam",
  "Santa Rosa",
  "Sarasota",
  "Seminole",
  "St Johns",
  "St Lucie",
  "Sumter",
  "Suwannee",
  "Taylor",
  "Union",
  "Volusia",
  "Wakulla",
  "Walton",
  "Washington",
];

export const pdfSizes = [
  { label: "Letter - 8.5in x 11in", value: "letter" },
  { label: "Legal - 8.5in x 14in", value: "legal" },
  { divider: true, label: "Landscape" },
  { label: "Letter (Landscape) - 11in x 8.5in", value: "landscape_letter" },
  { label: "Legal (Landscape) - 14in x 8.5in", value: "landscape_legal" },
];

export const pdf_form_formats = [
  { label: "PDF", value: "pdf" },
  { label: "JPG", value: "jpg" },
  { label: "PNG", value: "png" },
];

export const landscapeImgList = [
  { county: "Duval", city: "atlantic_beach", doc: "pais" },
  { county: "Duval", city: "jacksonville", doc: "pais" },
];
