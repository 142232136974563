import { handleDynamicFontResize } from "../processFont";

export const handleMouseMove = (
  e,
  currentProcessingFile,
  setCurrentProcessingFile,
  pdfContainerRef,
  resizing,
  initialMousePosition,
  initialInputSize,
  inputPosition,
  resizeDirection,
  moving,
  isDrawing,
  startPoint,
  setRect,
  isSelecting,
  freeSelectStartPoint,
  setSelectionRect
) => {
  const scaleFactor =
    currentProcessingFile?.document?.tempFields?.currentDocument?.pdfSize /
    currentProcessingFile?.document?.tempFields?.currentDocument?.actualWidth;

  const containerRect = pdfContainerRef?.current?.getBoundingClientRect();
  if (!containerRect) return;

  const pdfLeft = 0;
  const pdfRight = containerRect.width - 10;
  const pdfBottom = containerRect.height;

  if (resizing) {
    const dx = e.clientX - initialMousePosition.x;
    const dy = e.clientY - initialMousePosition.y;

    let newWidth = initialInputSize.width;
    let newHeight = initialInputSize.height;
    let newX = inputPosition.x;
    let newY = inputPosition.y;

    // MIDDLE-RIGHT
    if (resizeDirection === "e") {
      newWidth = Math.max(initialInputSize.width + dx, 5);
      newX = inputPosition.x;
      if (newX + newWidth > pdfRight) return;
      // MIDDLE-LEFT
    } else if (resizeDirection === "w") {
      newWidth = initialInputSize.width - dx;
      newX = inputPosition.x + dx;
      if (newX < pdfLeft || newWidth < 5) return;
    }
    // BOTTOM-MIDDLE
    else if (resizeDirection === "s") {
      newHeight = initialInputSize.height + dy;
      newY = inputPosition.y - dy;
      if (newY < 5 || newHeight < 5) return;
      // TOP-MIDDLE
    } else if (resizeDirection === "n") {
      newHeight = initialInputSize.height - dy;
      newY = inputPosition.y;

      const maxTop = newY + newHeight;

      if (maxTop + 5 > pdfBottom || newHeight < 5) return;
    }
    // TOP-RIGHT
    else if (resizeDirection === "ne") {
      newHeight = initialInputSize.height - dy;
      newWidth = Math.max(initialInputSize.width + dx, 5);
      newX = inputPosition.x;
      newY = inputPosition.y;

      const maxTop = newY + newHeight + 5;
      const maxRight = newX + newWidth;

      if (maxTop > pdfBottom || maxRight > pdfRight || newHeight < 5) return;
      // BOTTOM-RIGHT
    } else if (resizeDirection === "se") {
      newHeight = initialInputSize.height + dy;
      newX = inputPosition.x;
      newWidth = Math.max(initialInputSize.width + dx, 5);
      newY = inputPosition.y - dy;

      const maxRight = newX + newWidth;

      if (newY < 5 || maxRight > pdfRight || newHeight < 5) return;
      // BOTTOM-LEFT
    } else if (resizeDirection === "sw") {
      newHeight = initialInputSize.height + dy;
      newWidth = initialInputSize.width - dx;

      newY = inputPosition.y - dy;
      newX = inputPosition.x + dx;

      if (newY < 5 || newX < pdfLeft || newHeight < 5 || newWidth < 5) return;
      // TOP-LEFT
    } else if (resizeDirection === "nw") {
      newHeight = initialInputSize.height - dy;
      newWidth = initialInputSize.width - dx;
      newX = inputPosition.x + dx; // Move left edge when width shrinks
      newY = inputPosition.y; // Move top edge when height shrinks

      const maxTop = newY + newHeight;

      if (
        maxTop + 5 > pdfBottom ||
        newX < pdfLeft ||
        newHeight < 5 ||
        newWidth < 5
      )
        return;
    }

    const finalWidth = newWidth / scaleFactor;
    const finalHeight = newHeight / scaleFactor;
    const finalX = newX / scaleFactor;
    const finalY = newY / scaleFactor;

    const { newFontSize, fontSizes } = handleDynamicFontResize(
      finalWidth,
      finalHeight,
      currentProcessingFile?.document?.tempFields?.selectedFormField.fontSize,
      currentProcessingFile?.document?.tempFields?.selectedFormField.value,
      currentProcessingFile?.document?.tempFields?.selectedFormField
        .fontSizes || [],
      currentProcessingFile?.document?.tempFields?.selectedFormField.value
    );

    setCurrentProcessingFile((prevFile) => {
      const updatedFormFields = prevFile.document?.tempFields?.formFields.map(
        (f) =>
          f.name !== prevFile?.document?.tempFields?.selectedFormField?.name
            ? f
            : {
                ...f,
                width: finalWidth,
                height: finalHeight,
                x: finalX,
                y: finalY,
                fontSize: f.autoFontSize ? newFontSize : f.fontSize,
                fontSizes: fontSizes,
              }
      );

      const updatedSelectedExtraFields =
        prevFile.document?.tempFields?.selectedExtraFields.map((field) =>
          field.name === prevFile?.document?.tempFields?.selectedFormField?.name
            ? {
                ...field,
                width: finalWidth,
                height: finalHeight,
                x: finalX,
                y: finalY,
                fontSize: prevFile?.document?.tempFields?.selectedFormField
                  ?.autoFontSize
                  ? newFontSize
                  : prevFile?.document?.tempFields?.selectedFormField?.fontSize,
                fontSizes: fontSizes,
              }
            : field
        );

      return {
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFields: updatedFormFields,
            selectedExtraFields: updatedSelectedExtraFields,
            selectedFormField: prevFile.document.tempFields.selectedFormField
              ? {
                  ...prevFile.document.tempFields.selectedFormField,
                  width: finalWidth,
                  height: finalHeight,
                  x: finalX,
                  y: finalY,
                  fontSize: prevFile?.document.tempFields.selectedFormField
                    ?.autoFontSize
                    ? newFontSize
                    : prevFile?.document.tempFields.selectedFormField?.fontSize,
                  fontSizes: fontSizes,
                }
              : null,
          },
        },
      };
    });
  } else if (moving) {
    if (
      !inputPosition ||
      !currentProcessingFile?.document?.tempFields?.selectedFormField
    )
      return;
    // Calculate movement delta
    const scaleFactor =
      currentProcessingFile?.document?.tempFields?.currentDocument?.pdfSize /
      currentProcessingFile?.document?.tempFields?.currentDocument?.actualWidth;

    // Calculate movement delta (difference from initial position)
    const dx = (e.clientX - initialMousePosition.x) / scaleFactor;
    const dy = (e.clientY - initialMousePosition.y) / scaleFactor;

    // Update position while keeping it within document bounds
    const finalX = Math.max(
      0,
      Math.min(
        inputPosition.x + dx,
        currentProcessingFile?.document?.tempFields?.currentDocument
          .actualWidth - initialInputSize.width
      )
    );
    const finalY = Math.max(
      0,
      Math.min(
        inputPosition.y - dy,
        currentProcessingFile?.document?.tempFields?.currentDocument
          .actualHeight - initialInputSize.height
      )
    );

    if (
      currentProcessingFile?.document?.tempFields?.selectedExtraFields.length >
      0
    ) {
      // Find the parent's original position (before moving)
      const parentOldX =
        currentProcessingFile?.document?.tempFields?.selectedFormField.x;
      const parentOldY =
        currentProcessingFile?.document?.tempFields?.selectedFormField.y;

      // Move all selected fields proportionally
      const updatedFields =
        currentProcessingFile?.document?.tempFields?.selectedExtraFields.map(
          (field) => {
            if (
              field.name ===
              currentProcessingFile?.document?.tempFields?.selectedFormField
                .name
            ) {
              // Replace the parent's copy with the new position
              return {
                ...currentProcessingFile?.document?.tempFields
                  ?.selectedFormField,
                x: finalX,
                y: finalY,
              };
            } else {
              // Convert child's original position to pixels before calculating new position
              const childOldX = field.x;
              const childOldY = field.y;

              // Calculate relative movement based on the parent's movement
              const childDx = childOldX - parentOldX; // Distance from parent before moving
              const childDy = childOldY - parentOldY; // Distance from parent before moving

              // Apply the movement and keep the relative position the same
              const childNewX = finalX + childDx;
              const childNewY = finalY + childDy;

              return {
                ...field,
                x: childNewX,
                y: childNewY,
              };
            }
          }
        );

      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFields: prevFile.document.tempFields.formFields.map(
              (f) => updatedFields.find((uf) => uf.name === f.name) || f
            ),
            selectedExtraFields: updatedFields,
          },
        },
      }));
    } else {
      setCurrentProcessingFile((prevFile) => ({
        ...prevFile,
        document: {
          ...prevFile.document,
          tempFields: {
            ...prevFile.document.tempFields,
            formFields: prevFile.document.tempFields.formFields.map((f) =>
              f.name === prevFile?.document.tempFields.selectedFormField?.name
                ? { ...f, x: finalX, y: finalY }
                : f
            ),
          },
        },
      }));
    }

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          selectedFormField: prevFile.document.tempFields.selectedFormField
            ? {
                ...prevFile.document.tempFields.selectedFormField,
                x: finalX,
                y: finalY,
              }
            : null,
        },
      },
    }));
  } else if (isDrawing) {
    const containerRect = pdfContainerRef.current.getBoundingClientRect();
    const currentX =
      (e.clientX - containerRect.left) /
      currentProcessingFile?.document?.tempFields?.currentDocument.scaleFactor;
    const currentY =
      (e.clientY - containerRect.top) /
      currentProcessingFile?.document?.tempFields?.currentDocument.scaleFactor;

    let width = currentX - startPoint.x;
    let height = currentY - startPoint.y;

    // Adjust for direction of dragging (invert Y-axis)
    const newX = width < 0 ? currentX : startPoint.x;
    const newY = height < 0 ? currentY : startPoint.y;

    const returnRect = {
      x: newX,
      y: newY,
      width: Math.abs(width),
      height: Math.abs(height),
    };

    const maxRight = width + newX;
    const maxTop = newY + height;

    const isOutsideTop = maxTop + 5 > pdfBottom;
    const isOutsideRight = maxRight > pdfRight;
    const isOutsideBottom = newY < 5;
    const isOutsideLeft = newX < pdfLeft;

    if (isOutsideTop || isOutsideRight || isOutsideBottom || isOutsideLeft)
      return;
    // Update the rectangle state with correct scaling and flipping
    setRect(returnRect);
  } else if (isSelecting) {
    const containerRect = pdfContainerRef.current.getBoundingClientRect();
    const currentX =
      (e.clientX - containerRect.left) /
      currentProcessingFile?.document?.tempFields?.currentDocument.scaleFactor;
    const currentY =
      (e.clientY - containerRect.top) /
      currentProcessingFile?.document?.tempFields?.currentDocument.scaleFactor;

    let width = currentX - freeSelectStartPoint.x;
    let height = currentY - freeSelectStartPoint.y;

    // Adjust for direction of dragging (invert Y-axis)
    const newX = width < 0 ? currentX : freeSelectStartPoint.x;
    const newY = height < 0 ? currentY : freeSelectStartPoint.y;

    const newSelectionRect = {
      x: newX,
      y: newY,
      width: Math.abs(width),
      height: Math.abs(height),
    };

    const maxRight = width + newX;
    const maxTop = newY + height;

    const isOutsideTop = maxTop + 5 > pdfBottom;
    const isOutsideRight = maxRight > pdfRight;
    const isOutsideBottom = newY < 5;
    const isOutsideLeft = newX < pdfLeft;

    if (isOutsideTop || isOutsideRight || isOutsideBottom || isOutsideLeft)
      return;
    // Update the rectangle state with correct scaling and flipping
    setSelectionRect(newSelectionRect);

    //? CONVERT INTO PT AND FLIP Y AND SELECT FIELDS
    const scaleFactor =
      currentProcessingFile?.document?.tempFields?.currentDocument?.pdfSize /
      currentProcessingFile?.document?.tempFields?.currentDocument?.actualWidth;

    // Flip the Y-coordinate (for bottom-left origin in PDF)
    let fieldHeight = newSelectionRect.height / scaleFactor;
    let fieldWidth = newSelectionRect.width / scaleFactor;
    let fieldX = newSelectionRect.x / scaleFactor;
    let fieldY =
      currentProcessingFile?.document?.tempFields?.currentDocument
        .actualHeight -
      newSelectionRect.y / scaleFactor -
      fieldHeight;

    setCurrentProcessingFile((prevFile) => ({
      ...prevFile,
      document: {
        ...prevFile.document,
        tempFields: {
          ...prevFile.document.tempFields,
          selectedExtraFields: prevFile.document.tempFields.formFields.filter(
            (field) => {
              const fieldRight = field.x + field.width;
              const fieldBottom = field.y + field.height;

              const selectionRight = fieldX + fieldWidth;
              const selectionBottom = fieldY + fieldHeight;

              return (
                field.x < selectionRight &&
                fieldRight > fieldX &&
                field.y < selectionBottom &&
                fieldBottom > fieldY
              );
            }
          ),
        },
      },
    }));

    if (currentProcessingFile?.document?.tempFields?.selectedFormField) {
      const fieldRight =
        currentProcessingFile?.document?.tempFields?.selectedFormField.x +
        currentProcessingFile?.document?.tempFields?.selectedFormField.width;
      const fieldBottom =
        currentProcessingFile?.document?.tempFields?.selectedFormField.y +
        currentProcessingFile?.document?.tempFields?.selectedFormField.height;

      const selectionRight = fieldX + fieldWidth;
      const selectionBottom = fieldY + fieldHeight;

      const isInside =
        currentProcessingFile?.document?.tempFields?.selectedFormField.x <
          selectionRight &&
        fieldRight > fieldX &&
        currentProcessingFile?.document?.tempFields?.selectedFormField.y <
          selectionBottom &&
        fieldBottom > fieldY;

      if (!isInside) {
        setCurrentProcessingFile((prevFile) => ({
          ...prevFile,
          document: {
            ...prevFile.document,
            tempFields: {
              ...prevFile.document.tempFields,
              selectedFormField: null,
            },
          },
        }));
      }
    }
  }
};
